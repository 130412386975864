import { PayOrderDto } from '@sr/dto';
import { ReactNode } from 'react';
import { Link } from '../link-base';
import { payOrderLinkManager } from 'utils/link-manager';

export const LinkToPayOrder = (props: { payorder: PayOrderDto, children?: ReactNode; }) => {
  const { payorder: { id }, children } = props;

  const link = payOrderLinkManager.link(id);

  if (children)
    return (<Link to={link} >
      {children}
    </Link>);

  return (
    <Link to={link} >
      {id}
    </Link>
  );
};