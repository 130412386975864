import { plainToInstance, Type } from "class-transformer";
import { IsNotEmpty, IsNumber, IsDate } from "class-validator";
import { UserDto } from "./user.dto";

export class CommentDto {
  @IsNumber()
  id: number;

  @Type(() => UserDto)
  user: UserDto;

  content: string;

  @IsDate()
  @Type()
  createdAt: Date;


  static fromPlain(obj: any) { return plainToInstance<CommentDto, any>(CommentDto, obj, { enableImplicitConversion: true }); }
}

export class CreateCommentDto {
  content: string;
}

export class UpdateCommentDto {
  @IsNumber()
  id: number;

  content: string;
}
