import { IManagerLink, ICreateManagerLink } from '@sr/dto';
import { authorizedRequest } from 'shared/api/axios';
import { COMPANY_BASE_URL } from 'shared/api/api';

export type DeleteLinkResponse = {
  deletedLinkId: number;
};


export const fetchLinkedManagers = async (companyId: number) => {
  return authorizedRequest<Request, IManagerLink[]>(
    `${COMPANY_BASE_URL}/${companyId}/linked-managers/`,
    'GET'
  );
};

export const postLinkManager = async (link: ICreateManagerLink) => {
  return authorizedRequest<ICreateManagerLink, IManagerLink>(
    '/company/linkManager/',
    'POST',
    link
  );
};

export const deleteLinkManager = async (linkId: number) => {
  return authorizedRequest<void, void>(
    '/company/unlinkManager/' + linkId,
    'DELETE'
  );
};