import { IsString, IsEmail, MinLength, MaxLength, ValidateIf } from 'class-validator';
import { Duplicates } from './duplicates';

export interface IEmail {
  email: string;
}

export class CreateEmailDto implements IEmail {
  @IsString()
  @IsEmail()
  @MinLength(3)
  @MaxLength(250)
  email: string;
}

export interface ILinkedEmail extends Duplicates {
  id: number;
  email: string;
}
