import {
  CreateMediaTypeDto,
  UpdateMediaTypeBaseDataDto,
} from '@sr/dto';
import { mediaTypesApi } from '.';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';

export const useMediaTypesList = ()=> {
  const { data: mediaTypes, isLoading, error } = mediaTypesApi.useGetMediaTypesListQuery({});
  return  {
    collection: mediaTypes ?? [],
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading
  };
};

export const useMediaType = (id: number) => {
  const { data, isLoading, error } = mediaTypesApi.useGetMediaTypeBaseDataQuery(id);
  return {
    item: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};

export const useDeleteMediaType: (()=>(mediaType: UpdateMediaTypeBaseDataDto) => Promise<void>) = () => {
  const showConfirmDialog = useConfirmDialog();
  const [deleteMediaType] = mediaTypesApi.useDeleteMediaTypeMutation();
  return async (mediaType: UpdateMediaTypeBaseDataDto): Promise<void> => {
    return showConfirmDialog({
      content: `Удалить тип сми: ${mediaType.name}?`,
    })
      .then(() => deleteMediaType(mediaType.id).unwrap());
  };
};

export const useRenameMediaType = () => {
  const [update] = mediaTypesApi.useUpdateMediaTypeBaseDataMutation();
  return async (data: UpdateMediaTypeBaseDataDto) => {
    return update(data).unwrap();
  };
};

export const useAddMediaType = () => {
  const [ addMediaType] = mediaTypesApi.useAddMediaTypeMutation();
  return async (newMediaType: CreateMediaTypeDto) => {
    return addMediaType(newMediaType).unwrap();
  };
};

