import { LeadInfoDto, IPagingData } from '@sr/dto';
import { useCallback } from 'react';
import { fetchPagedReq } from 'shared/api/axios';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

const BASE_URL = '/lead';

export const useLeadsList = (pagination: IPagingData, filter?: string, companyId?: number) => {
  return useReadOnlyRemotePagedCollection<LeadInfoDto>({
    fetchHandler: useCallback(async () => {
      const params = new URLSearchParams(filter);
      companyId && params.append('companyId', companyId.toString());

      const response = await fetchPagedReq<void, LeadInfoDto>(
        `${BASE_URL}?${params.toString()}`,
        pagination,
        LeadInfoDto);

      return response;
    }, [companyId, filter, pagination]),
  });


};
