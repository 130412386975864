import { ReactNode } from 'react';
import { Box, Stack, SxProps, Typography } from '@mui/material'

type Props = {
  title: string;
  actions?: ReactNode;
  sx?: SxProps
}

export const Heading = ({ title, actions, sx }: Props) => {
  return (
    <Box sx={sx}>
      <Stack direction="row" justifyContent="space-between" alignContent="baseline">
        <Box>
          <Typography variant='h5'>{title}</Typography>
        </Box>
        <Box>
          {actions}
        </Box>
      </Stack>
    </Box>
  )
}