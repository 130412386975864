import { Button, Card, CardActions, CardContent, Divider, Stack } from '@mui/material';
import { formTools, FormValues } from './user-profile.form-tools';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { useActionCreators, useStateSelector } from 'shared/store';
import { userThunks } from 'entities/user/profile/thunks';
import { useSnack } from 'shared/ui/snack';
import { changePasswordActions } from '../change-password-dialog';


export const UserProfileCard = () => {
  const userId = useStateSelector(state => state.user.id);
  const user = useStateSelector(state => state.user.profile);

  const { updateUserThunk } = useActionCreators(userThunks);
  const { openDialog } = useActionCreators(changePasswordActions);

  const { showSuccess, showError } = useSnack();

  if (!(user && userId))
    return null;

  const initialValues: FormValues = {
    login: user.login,
    firstName: user.firstName,
    secondName: user.secondName,
    middleName: user.middleName,
  };

  const submitUserBasics = async (formValues: FormValues) => {
    updateUserThunk(formTools.toUpdateDto(userId, formValues))
      .unwrap()
      .then(() => showSuccess('Данные пользователя успешно обновлены'))
      .catch(e => showError(`Ошибка обновления данных пользователя: ${e.message}`));
  };

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitUserBasics}
        validate={formTools.validate}
      >
        {({ submitForm, isSubmitting, isValid, dirty }: FormikProps<FormValues>) => (
          <>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Field
                  name='login'
                  label='Логин'
                  required
                  fullWidth
                  component={TextField}
                />
                <Divider/>
                <Field
                  name='firstName'
                  label='Имя'
                  required
                  fullWidth
                  component={TextField}
                />
                <Field
                  name='secondName'
                  label='Фамилия'
                  fullWidth
                  component={TextField}
                />
                <Field
                  name="middleName"
                  label='Отчество'
                  fullWidth
                  component={TextField}
                />
                <Button
                  variant='text'
                  color='warning'
                  onClick={() => openDialog()}
                >
                                    сменить пароль...
                </Button>
              </Stack>

            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 1 }}
                onClick={submitForm}
                disabled={!dirty || isSubmitting || !isValid}
              >
                                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card>
  );
};
