import { useCallback, useState } from 'react';
import Box                           from '@mui/material/Box';
import OutlinedInput                 from '@mui/material/OutlinedInput';
import InputLabel                    from '@mui/material/InputLabel';
import MenuItem                      from '@mui/material/MenuItem';
import FormControl                   from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip                          from '@mui/material/Chip';
import { SelectProps, fieldToSelect } from 'formik-mui';
import { useUserRolesList } from '../../../entities/user/user-roles/user-roles.hooks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width    : 250,
    },
  },
};

export const UserRolePicker = (props: SelectProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props;
  const [role, setRole] = useState<number[]>(value);
  const handleChange = useCallback((event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    setRole(value as number[]);
    setFieldValue(name, value);
  },[name, setFieldValue]);
  const { collection } = useUserRolesList();

  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel id="role-picker">Роли пользователя</InputLabel>
      <Select
        {...fieldToSelect(props)}
        defaultValue={value}
        multiple
        name={name}
        label={label}
        value={role}
        onChange={handleChange}
        input={<OutlinedInput  label="Роли пользователя" />}
        renderValue={(selected) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                return (
                  <Chip key={value} label={collection
                    .filter((item) => Number(item.id) === Number(value))
                    .map((item) => item.title)
                  } />
                );
              })}
            </Box>
          );
        }}
        MenuProps={MenuProps}
        variant={'outlined'}>
        {collection.map((role) => (
          <MenuItem
            key={role.title}
            value={role.id}
          >
            {role.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
