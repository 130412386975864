import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";
import { RegionInfoDto } from "../region/region.dto";

export class CityBasicDataDto {
  id: number;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsNotEmpty()
  @IsNumber()
  region: RegionInfoDto;

  isCapital: boolean;
  timeZone: string | null;
  phoneCode: string | null;
  publishingDataId: number | null;
}


export class UpdateCityBasicDataDto {
  id: number;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsNotEmpty()
  @IsNumber()
  regionId: number;

  isCapital: boolean;
  timeZone: string | null;
  phoneCode: string | null;

  @IsOptional()
  @IsNumber()
  publishingDataId?: number;
}
