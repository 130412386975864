import { TextField, Autocomplete } from '@mui/material';
import { SignerDto } from '@sr/dto';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useSignersList } from 'hooks/signers.hook';

export const SignerPicker = (props: TextFieldProps & { legalEntityId?: number }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    disabled,
    label,
    legalEntityId,
  } = props;

  const [open, setOpen] = useState(false);
  const { collection: { items, isLoading: fetching } } = useSignersList(legalEntityId);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: SignerDto | null) => {
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name]
  );

  const selectedSigner = items.find(x => x.id === value) ?? null;

  const loading = open && items.length === 0 && fetching;

  const {
    legalEntityId: _,
    ...textFieldProps } = props;

  return (
    <Autocomplete
      value={selectedSigner}
      autoComplete={false}
      options={items}
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.titleForm1}, ${option.positionForm1}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={legalEntityId && isNaN(legalEntityId) ? 'Укажите юрлицо' : 'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};


