import { BulkCreateReportDto, IPagedCollection, WccDto } from '@sr/dto';
import { postReq } from 'shared/api/axios';
import { APP_BASE_URL, INVOICES_BASE_URL } from 'shared/api/api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CollectionParams, setAuthHeader } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';

export const postBulkCreateWcc = async (ids: number[]) => {
  return await postReq<number[], BulkCreateReportDto>(
    `${INVOICES_BASE_URL}/wcc/bulk-create`,
    ids,
    BulkCreateReportDto
  );
};

export const wccBulkCreateApi = createApi({
  reducerPath: 'wcc/bulk-create-api',
  tagTypes: ['*'],
  baseQuery: fetchBaseQuery({ baseUrl: APP_BASE_URL, prepareHeaders: (headers, api) => { setAuthHeader(headers); return headers; } }),
  endpoints: (builder) => ({
    getInvoicesWithoutWcc: builder.query<IPagedCollection<WccDto>, CollectionParams>({
      query: ({ paging, filter }: CollectionParams) => ({
        url: `${INVOICES_BASE_URL}/wcc/noWcc?${filter}`,
        params: paging && pagingDataToParams(paging),
        method: 'GET'
      }),
      providesTags: ['*']
    }),
    runBulkCreate: builder.mutation<BulkCreateReportDto, number[]>({
      query: (idList: number[]) => ({
        url: `${INVOICES_BASE_URL}/wcc/bulk-create`,
        method: 'POST',
        body: idList
      }),
      invalidatesTags: ['*']
    })
  })
});



// const injectedApi = mediaApi.injectEndpoints({
//   endpoints: (builder) => ({
//     addMedia: builder.mutation<void, CreateMediaDto>({
//       query: (dto: CreateMediaDto) => ({
//         url: MEDIA_BASE_URL,
//         body: dto,
//         method: 'POST'
//       }),
//     })
//   }),
//   overrideExisting: true
// });

// export const mediaCreateApi = injectedApi.enhanceEndpoints({
//   addTagTypes: ['*'],
//   endpoints: {
//     addMedia: {
//       invalidatesTags: ['*']
//     }
//   }
// })