import { createSlice } from '@reduxjs/toolkit';
import { emptyPagedCollection } from '@sr/dto';
import { metaState } from 'shared/api/async-meta';
import { initialState } from './state';
import { fetchClientInvoicesThunk } from './thunks';


const slice = createSlice({
  name: 'invoices-list',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClientInvoicesThunk.pending, (state) => {
      state.invoicesList = emptyPagedCollection;
      metaState.pending(state.meta);
    });
    builder.addCase(fetchClientInvoicesThunk.fulfilled, (state, action) => {
      state.invoicesList = { ...action.payload };
      metaState.done(state.meta);

    });
    builder.addCase(fetchClientInvoicesThunk.rejected, (state, action) => {
      state.invoicesList = emptyPagedCollection;
      metaState.error(state.meta, action.error.message);
    });
  }
});


export const { reducer: invoicesListReducer, actions: invoicesListActions } = slice;

