import { AuthorizedUser, LoginDto, LoginResponseDto, RefreshTokenDto } from '@sr/dto';
import { publicRequest } from 'shared/api/axios';

export const fetchLogin = async (login: string, password: string) =>
  publicRequest<LoginDto, LoginResponseDto>('auth/login', 'POST', { login, password });


export const fetchLogout = async (user: AuthorizedUser) => {
};

export const fetchRefresh = async (refreshToken: string) =>
  publicRequest<RefreshTokenDto, LoginResponseDto>('auth/refresh/', 'POST', { refreshToken });

