import { MouseEvent } from 'react';
import MenuParams from 'shared/ui/menu-params';
import { Box, Chip, IconButton, TableCell, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IPagingData, LeadInfoDto } from '@sr/dto';
import { DEFAULT_PAGINATION, GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { formatDate } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';
import { LinkToLead } from 'shared/ui/links/link-to-lead';
import { useLeadsList } from './leads-list.hook';
import { LeadsFilter } from 'features/lead/leads-filter/leads-filter';

const columns = ['Дата создания', 'Тема', 'Статус', 'Фирма', 'Менеджер', 'Бюджет', 'Качество', 'Источник', ''];


type Props = {
    companyId?: number;
    paging?: IPagingData;
    onChangePaging?: (paging: IPagingData) => void;
    filterString?: string;
    onFilter?: (filterString: string) => void;
};

export const LeadsList = ({ companyId, paging, onChangePaging, filterString, onFilter }: Props) => {
  const { openMenu, menuData } = useMenu<LeadInfoDto>({
    deleteHandler: () => {
    }
  });

  const pagingData = paging || DEFAULT_PAGINATION;
  const { collection, isLoading, error } = useLeadsList(pagingData, filterString, companyId);

  return (
    <>
      {filterString !== undefined && onFilter &&
                <Box mb={1}>
                  <LeadsFilter filterString={filterString} onFilter={onFilter}/>
                </Box>
      }

      <GridLayout
        columns={columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.totalItemsCount}
        noItemsText='Список лидов пуст'
        itemRender={(i) => <LeadRow
          key={i.id}
          lead={i}
          onMenuClick={openMenu}/>}
      />

      <RowMenu menuData={menuData}/>
    </>);
};


type RowProps = {
    lead: LeadInfoDto,
    onMenuClick: (menuParams: MenuParams<LeadInfoDto>) => void;
};

export const LeadRow = (props: RowProps) => {
  const { lead, onMenuClick } = props;
  const status = lead.status ? <Chip label={lead.status.label}/> : '-';

  const budget = lead.budget ? lead.budget.label : '-';

  const manager = lead.manager ? formatPerson(lead.manager) : '-';

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: lead });
  };

  return (
    <>
      <TableRow
        key={lead.id}
        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          {formatDate(lead.createdAt)}
        </TableCell>
        <TableCell>
          <LinkToLead lead={lead}/>
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>{manager}</TableCell>
        <TableCell>{budget}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon/></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};



