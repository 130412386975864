import { InvoiceWccDto, CreateInvoiceWccDto } from '@sr/dto';
import { plainToInstance } from 'class-transformer';
import { authorizedRequest } from 'shared/api/axios';
import { INVOICES_BASE_URL } from 'shared/api/api';

export const fetchInvoiceWcc = async (invoiceId: number) => {
  const result = await authorizedRequest<void, InvoiceWccDto>(
    `${INVOICES_BASE_URL}/wcc/${invoiceId}`,
    'GET');
  return plainToInstance(InvoiceWccDto, result, { enableImplicitConversion: true });
};

export const postInvoiceWcc = async (dto: CreateInvoiceWccDto) => {
  return await authorizedRequest<CreateInvoiceWccDto, InvoiceWccDto>(
    `${INVOICES_BASE_URL}/wcc/`,
    'POST',
    dto
  );
};

export const deleteInvoiceWcc = async (invoiceId: number) => {
  return await authorizedRequest<void, void>(
    `${INVOICES_BASE_URL}/wcc/${invoiceId}`,
    'DELETE'
  );
};
