import { Card, CardHeader, Button, CardContent } from '@mui/material'
import { LegalEntityDto } from '@sr/dto';
import { useState } from 'react';
import { InvoiceRows } from './invoice-rows'

type Props = {
  invoiceId: number;
  allowEdit: boolean;
  isClientInvoice: boolean;
  seller: LegalEntityDto
}
export const InvoiceRowsCard = (props: Props) => {
  const {
    invoiceId, isClientInvoice,
    seller: { vat: buyerVat },
    allowEdit
  } = props;
  const [isNewRowVisible, setNewRowVisible] = useState(false);
  return (<Card>
    <CardHeader
      title='Услуги'
      action={<Button
        variant='contained'
        onClick={() => setNewRowVisible(true)}
        disabled={isNewRowVisible || !allowEdit}>
        Добавить услугу
      </Button>}
    ></CardHeader>
    <CardContent>
      <InvoiceRows
        invoiceId={invoiceId}
        allowEdit={allowEdit}
        isClientInvoice={isClientInvoice}
        buyerVat={buyerVat}
        showNewRow={isNewRowVisible}
        onCancelNewRow={() => setNewRowVisible(false)}
      />
    </CardContent>
  </Card>)
}