import { MenuItem, TextField } from '@mui/material';
import { InvoiceStatus, INVOICE_STATUSES } from '@sr/dto';
import { FieldProps } from 'formik';

export const InvoiceStatusPickerField = ({ form, field }: FieldProps<number, any>) => {
  // todo: Я бы предложил не использовать этот компонент больше как минимум для фильтров
  // todo: Давайте лучше использовать InvoiceStatusUrlParamsFilter
  const { setFieldValue, errors, touched } = form;
  const { name, value } = field;
  const error = errors[name];
  const isTouched = touched[name];
  const errorText = isTouched
    ? typeof error === 'string'
      ? error
      : undefined
    : undefined;

  const handleChange = (event: any) => setFieldValue(name, event.target.value);

  return (
    <TextField
      label='Статус'
      fullWidth
      value={value}
      onChange={handleChange}
      error={isTouched && error !== undefined}
      helperText={errorText}
      select
    >
      <MenuItem key={''} value={''}>
        &nbsp;
      </MenuItem>
      {Object.keys(INVOICE_STATUSES).map(key =>
        <MenuItem key={key} value={key}>
          {INVOICE_STATUSES[Number(key) as InvoiceStatus]}
        </MenuItem>
      )
      }
    </TextField>);
};
