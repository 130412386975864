import { MoreVert } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Skeleton } from '@mui/material';
import { LinkToWcc } from 'shared/ui/links/link-to-wcc';
import { MoreMenu, useMoreMenu } from 'shared/ui/more-menu';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { useInvoiceWcc } from './lib';

export const InvoiceWcc = ({ invoiceId }: { invoiceId: number; }) => {
  const { invoiceWcc, createWcc, deleteWcc, isFetchingWcc, isPostingWcc } = useInvoiceWcc(invoiceId);
  const showConfirm = useConfirmDialog();
  const { showSuccess, showError } = useSnack();

  const handleCreateWcc = async () => {
    return createWcc()
      .then(() => showSuccess('Акт выполненных работ успешно создан'))
      .catch(e => showError(`Ошибка создания акта выполненных работ: ${e}`));
  };

  const handleDeleteWcc = async () => {
    showConfirm({ content: 'Удалить акт выполненных работ?' })
      .then(() => {
        deleteWcc()
          .then(() => showSuccess('Акт успешно удален'))
          .catch(e => showError(`Ошибка удаления акта выполненных работ: ${e}`));
      });
  };

  const { openMenu, menuData } = useMoreMenu([
    {
      name: 'Печать',
      icon: <PrintIcon/>,
    },
    // {
    //   name: 'Печать с подписью',
    //   icon: <PrintIcon />,
    //   onClick: () => { console.log('sdfsdfsdf') }
    // },
    { divider: true },
    {
      name: 'Удалить',
      icon: <DeleteIcon/>,
      onClick: handleDeleteWcc
    }
  ]);


  if (isFetchingWcc)
    return <Skeleton/>;

  if (isPostingWcc)
    return <CircularProgress/>;

  if (!invoiceWcc)
    return <Button variant='contained' size='small' onClick={handleCreateWcc}>Сформировать</Button>;

  return (
    <>
      <LinkToWcc wcc={invoiceWcc}/>

      <IconButton onClick={(e) => {
        openMenu(e.currentTarget);
      }}>
        <MoreVert/>
      </IconButton>

      <MoreMenu {...menuData} />
    </>
  );
};

