import { Link } from 'shared/ui/link-base';
import { CompanyDto } from '@sr/dto';
import { companyLinkManager } from 'utils/link-manager';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode,
  company: Pick<CompanyDto, 'id' | 'name'>,
}

export const LinkToCompany = ({ company: { id, name }, children }: Props) => {
  const link = companyLinkManager.link(id);
  return (
    <Link to={link} >
      {children ? children : name}
    </Link>
  );
}