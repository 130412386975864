import { DownloadFileDto, Identifiable } from '@sr/dto';
import { FormikErrors } from 'formik';

export type FormTools<TDto extends Identifiable, TCreateDto, TUpdateDto, TFormData> = {
  toUpdateDto: (id: number, formData: TFormData) => TUpdateDto,
  toCreateDto: (formData: TFormData) => TCreateDto,
  fromDto: (item: TDto) => TFormData,
  validate: (values: TFormData) => FormikErrors<TFormData>,
  emptyValues: () => TFormData
}

export type FormDataFileField = File | DownloadFileDto | null;

export const VALIDATION_REQUIRED_FIELD = 'Обязательное поле';
