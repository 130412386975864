import { CommentDto, CreateCommentDto } from '@sr/dto';
import { useEffect, useState } from 'react';

type CommentsConfig = {
  fetchHandler: () => Promise<CommentDto[]>,
  addHandler: (dto: CreateCommentDto) => Promise<CommentDto>
}

export const useComments = ({ fetchHandler, addHandler }: CommentsConfig) => {
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchHandler()
      .then(result => setComments(result))
      .catch(e => setError(e))
      .finally(() => setLoading(false));
  }, [fetchHandler]);

  const addComment = (dto: CreateCommentDto) => {
    setPosting(true);
    return addHandler(dto)
      .then(newComment => {
        setComments([newComment, ...comments]);
      })
      .finally(() => setPosting(false));
  };

  return {
    comments,
    error,
    loading,
    posting,
    addComment
  };
};
