import { IsIn, IsNotEmpty, IsNumber, IsString } from "class-validator";
import { BankAccountType, BA_TYPES } from "./enums/bank-account-types";

export class BankAccountDto {
  id: number;
  isActive: boolean;
  type: BankAccountType;
  title: string;
}

export class CreateBankAccountDto {
  @IsString()
  @IsNotEmpty()
  title: string;

  // @Type(() => Number)
  @IsIn([...Object.keys(BA_TYPES).map(Number)])
  @IsNumber()
  type: number;
}