import { Box, Stack, Typography } from '@mui/material';
import { RelatedPurchases } from 'entities/invoice-report/related-purchases';
import { useRelatedPurchases } from 'entities/invoice-report/related-purchases.hook';
import { useInvoiceDetails } from 'entities/invoice/invoice.hook';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useTitleHook } from 'hooks/page-title.hook';
import { RelatedPayorders } from 'entities/invoice-report/related-payorders';
import { useRelatedPayOrders } from 'entities/invoice-report/related-payorders.hook';
import { ProfitCard } from 'entities/invoice-report/profit-card';
import { SellerCard } from 'entities/invoice-report/report-seller-card';
import { RelatedInvoices } from 'entities/invoice-report/related-invoices';
import { PageHeader } from 'shared/ui/details-page-header';
import { useIdParam } from 'utils/url-paramters.hook';
import { useInvoiceTotals } from 'entities/invoice/totals';

const InvoiceReportPage = () => {
  const invoiceId = useIdParam('invoiceId');
  const { invoice } = useInvoiceDetails(invoiceId);
  const { totals: invoiceTotals } = useInvoiceTotals(invoiceId);

  useTitleHook(invoice && `Отчет по счету ${invoice.invoiceNumber} для ${invoice.buyerCompany.name}`);

  const {
    purchases,
    purchasesProfit,
    relatedInvoices,
    totalInvoicesCost,
    totalInvoicePaied
  } = useRelatedPurchases(invoiceId);
  const { payOrders, payOrdersTotal } = useRelatedPayOrders(invoiceId);

  if (!invoice)
    return <LoadingBanner />;

  const currentProfit = payOrdersTotal - totalInvoicePaied;
  const targetProfit = invoiceTotals ? (invoiceTotals.discountPriceTotal - totalInvoicesCost) : 0;

  return (
    <>
      <PageHeader
        title={
          <>
            <Typography variant='h5' component="span">Отчет по счету </Typography>
            <LinkToInvoice invoice={invoice}>
              <Typography variant='h5' gutterBottom component="span">{invoice?.invoiceNumber}</Typography>
            </LinkToInvoice>
          </>}
        actions={undefined} />


      <Stack direction="row" spacing={1} alignItems="flex-start">
        <SellerCard
          buyer={invoice.buyerCompany}
          buyerLegalEntity={invoice.buyer}
          sellerLegalEntity={invoice.seller}
          status={invoice.status} />

        {invoiceTotals && <ProfitCard
          fullCost={invoiceTotals.discountPriceTotal}
          purchasesProfit={purchasesProfit}
          payOrdersTotal={payOrdersTotal}
          currentProfit={currentProfit}
          targetProfit={targetProfit}
        />}

      </Stack>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Заявки</Typography>
        <RelatedPurchases purchases={purchases} />
      </Box>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Платежные поручения</Typography>
        <RelatedPayorders payOrders={payOrders} />
      </Box>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Связанные счета</Typography>
        <RelatedInvoices invoices={{
          items: relatedInvoices, isLoading: false, error: '', reload: () => {
          }
        }} />
      </Box>
    </>
  );
};


export default InvoiceReportPage;
