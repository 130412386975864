import { SelectChangeEvent } from '@mui/material';
import { BankAccountDto } from '@sr/dto';
import { GenericSelectPicker } from 'shared/ui/form-fields/generic-select-picker';
import { IRemoteCollection } from 'utils/remote-collection.hook';

export const BankAccountPicker = ({ value, collection, onChange }:
  {
    value?: number,
    collection: IRemoteCollection<BankAccountDto>,
    onChange: (newValue?: number) => void;
  }) => {

  return (
    <GenericSelectPicker
      value={value === undefined ? '' : value}
      onChange={(e: SelectChangeEvent) => onChange(e.target.value === '' ? undefined : Number(e.target.value))}
      textFieldProps={{
        size: 'small',
        fullWidth: true
      }}
      collection={collection}
      isActive={(item) => item.isActive}
      menuItemRender={ba => ba.title}
      selectedItemRender={ba => ba.title}
    />
  );
};