import { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type ActionCallbck = () => void;

type MenuAction = {
  label: string;
  callback: ActionCallbck;
  icon: JSX.Element;
};

export const ActionsMenu = ({ actions } = { actions: [] as MenuAction[] }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const handleActionClick = (actionCallback: ActionCallbck) => {
    setMenuAnchor(null);
    actionCallback();
  };

  return (
    <>
      <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        { actions.length > 0
          ?
          actions.map((action, index)=>
            <MenuItem onClick={()=>handleActionClick(action.callback)} key={index}>
              <ListItemIcon>
                {action.icon}
              </ListItemIcon>
              <ListItemText>
                {action.label}
              </ListItemText>
            </MenuItem>)
          :
          <MenuItem disabled>
            Этот элемент нельзя изменить
          </MenuItem>
        }
      </Menu>
    </>
  );
};
