import { CardPaperTitled } from 'shared/ui/card-paper';
import { ContactsPanel } from 'widgets/contacts/contact-panel';
import { EntitiesWithContacts } from 'features/contact/contacts.types';


const CompanyContactsCard = ({ companyId }: { companyId: number; }) => {

  return (
    <CardPaperTitled title="Контакты">
      <ContactsPanel relationType={EntitiesWithContacts.company} relationId={companyId} />
    </CardPaperTitled>
  );
};

export default CompanyContactsCard;
