import { useBankAccountsDropdown } from 'hooks/bank-accounts.hook';
import { withGenericSelectPicker } from 'shared/ui/form-fields/generic-select-picker.hoc';

export const BankAccountPicker = withGenericSelectPicker({
  itemsProvider: useBankAccountsDropdown,
  isActive: account => account.isActive,
  selectedItemRender: account => account.title,
  dropDownRenderValue: account => account.title
})




