import {IsEmail, IsString, MaxLength, MinLength, ValidateIf} from "class-validator";

export class AddContactDto{
  // todo: rename to CreateContactDto
  @IsString()
  @IsEmail()
  @MinLength(5)
  @MaxLength(250)
  @ValidateIf(o=>!!o.email || !!o.phone || !!o.url)
  email?: string;

  @IsString()
  @MinLength(5)
  @MaxLength(25)
  @ValidateIf(o=>!!o.email || !!o.phone || !!o.url)
  phone?: string;

  @IsString()
  @MinLength(5)
  @MaxLength(250)
  @ValidateIf(o=>!!o.email || !!o.phone || !!o.url)
  url?: string;
}
