import { CreatePayOrderDto, IPagingData, PayOrderDto, UpdatePayOrderDto } from '@sr/dto';
import { authorizedRequest, fetchPagedReq } from 'shared/api/axios';

const PAYORDERS_URL = 'accounting/payorders';

export const fetchPayOrders = async (paging: IPagingData, filterString?: string,) => {
  const params = new URLSearchParams(filterString);
  const response = await fetchPagedReq<void, PayOrderDto>(
    `${PAYORDERS_URL}?${params.toString()}`,
    paging,
    PayOrderDto
  );
  return response;
};

export const fetchPayOrdersRelatedToInvoice = async (invoiceId: number) => {
  const results = await authorizedRequest<void, PayOrderDto[]>(
    `${PAYORDERS_URL}/invoice/${invoiceId}`,
    'GET');
  return results.map(p => PayOrderDto.fromPlain(p));
};

export const fetchPayOrder = async (id: number) => {
  const result = await authorizedRequest<void, PayOrderDto>(
    `${PAYORDERS_URL}/${id}`,
    'GET');
  return PayOrderDto.fromPlain(result);
};

export const putPayOrder = async (dto: UpdatePayOrderDto) => {
  const updatedAccount = await authorizedRequest<UpdatePayOrderDto, PayOrderDto>(
    PAYORDERS_URL,
    'PUT',
    dto
  );
  return PayOrderDto.fromPlain(updatedAccount);
};


export const postPayOrder = async (dto: CreatePayOrderDto) => {
  const newPayOrder = await authorizedRequest<CreatePayOrderDto, PayOrderDto>(
    PAYORDERS_URL,
    'POST',
    dto
  );
  return PayOrderDto.fromPlain(newPayOrder);
};

export const deletePayOrder = async (id: number) => {
  return authorizedRequest<void, void>(
    `${PAYORDERS_URL}/${id}`,
    'DELETE'
  );
};