import { Stack, Grid } from '@mui/material';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { CountryPicker } from 'shared/ui/formik-fields/country-picker';
import { FormValues } from './region-form';


interface Props extends FormikProps<FormValues> {
}

export const RegionFormFields = (props: Props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={5}>
          <Stack direction="column" spacing={2}>
            <Field
              name="name"
              label="Название"
              component={TextField}
            />
            <Field
              component={CountryPicker}
              name="countryId"
              label="Страна"
            />
            <Field
              name="digitalCode"
              label="Цифровой код"
              component={TextField}
            />
          </Stack >
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack direction="column" spacing={2}>
            <Field
              name="population"
              label="Население"
              fullWidth
              component={TextField}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
