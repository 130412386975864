import { UpdateUserProfileBasicDto, UserDto } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';


export type FormValues = {
  login: string;
  firstName: string,
  secondName: string,
  middleName: string,
};


export const formTools: FormTools<UserDto, void, UpdateUserProfileBasicDto, FormValues> = {
  toCreateDto: formData => null,

  toUpdateDto: (id, formData): UpdateUserProfileBasicDto => ({
    id: id,
    login: formData.login,
    firstName: formData.firstName,
    secondName: formData.secondName,
    middleName: formData.middleName,
  }),

  fromDto: (dto): FormValues => ({
    login: dto.login,
    firstName: dto.firstName,
    secondName: dto.secondName,
    middleName: dto.middleName,

  }),

  validate: (values: FormValues): FormikErrors<FormValues> => {
    let validationResult: FormikErrors<FormValues> = {};

    if (!values.login)
      validationResult.login = VALIDATION_REQUIRED_FIELD;

    if (!values.firstName)
      validationResult.firstName = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },

  emptyValues: () => ({
    login: '',
    firstName: '',
    secondName: '',
    middleName: '',
  })
}

