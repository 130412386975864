import { Typography } from '@mui/material';
import { PeriodPicker } from 'entities/period-picker/period-picker';
import { useActionCreators, useStateSelector } from 'shared/store';
import { setPeriodActions } from './cash-balance-period.slice';
import { useCashBalancePeriods } from './cash-balance-period.hook';
import { ErrorBanner } from 'shared/ui/error-banner';


export function CashBalancePeriodPicker() {
  const selectedPeriod = useStateSelector((state) => state.cashBalanceSelectedPeriod.value);
  const { cashBalancePeriods, isLoading, error } = useCashBalancePeriods();
  const { setPeriod } = useActionCreators(setPeriodActions);

  if (error) {
    return <ErrorBanner errorMessage={error} />;
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{ my: 1 }}>Период закрытия</Typography>
      <PeriodPicker isLoading={isLoading} onSelect={setPeriod} periods={cashBalancePeriods}
                    selectedPeriod={selectedPeriod} />
    </>
  );
}
