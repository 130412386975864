import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { InvoiceRowDto } from '@sr/dto';
import { CardPaper } from 'shared/ui/card-paper';
import { formatPrice } from 'utils/price-format-helpers';
import { useInvoiceTotals } from 'entities/invoice/totals';
import { useInvoiceRows } from 'entities/invoice-rows/invoice-rows.hook';

const columns = [
  '№',
  'Наименование товара',
  'Количество',
  'Цена, руб',
  'Сумма, руб'
];

export const WccInvoiceRows = ({ invoiceId }: { invoiceId: number }) => {
  const { totals } = useInvoiceTotals(invoiceId);
  const { rows } = useInvoiceRows(invoiceId);
  return (
    <CardPaper>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              {columns.map((header, idx) => <TableCell key={idx}>{header}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => <Row key={row.id} index={idx} row={row} />)}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={4} align="right">
                Итого:
              </TableCell>
              <TableCell align='right'>
                {totals && formatPrice(totals.discountPriceTotal, { omitZeroAfterDot: true })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </CardPaper>
  );
};

const Row = ({ row, index }: { row: InvoiceRowDto, index: number; }) => {
  return (
    <TableRow >
      <TableCell>
        {index + 1}
      </TableCell>
      <TableCell>
        {row.description}
      </TableCell>
      <TableCell>
        {row.amount} шт
      </TableCell>
      <TableCell align='right'>
        {formatPrice(row.priceDiscount / row.amount, { fractionDigits: 2, omitZeroAfterDot: true })}
      </TableCell>
      <TableCell align='right'>
        {formatPrice(row.priceDiscount, { omitZeroAfterDot: true })}
      </TableCell>
    </TableRow>
  );
};

