import { useContext } from 'react';
import { AddContactsDialogContext } from 'shared/providers/add-contacts-dialog.provider';


const useAddContactsDialogs = () => {
  const ctx = useContext(AddContactsDialogContext);

  return ctx;
};

export default useAddContactsDialogs;
