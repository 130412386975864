import { BankAccountDto, CreateBankAccountDto } from '@sr/dto';
import { useCallback } from 'react';
import { fetchBankAccounts, postBankAccount, putBankAccount, deleteBankAccount, fetchBankAccountsDropDown } from 'shared/api/bank-accounts';
import { useReadOnlyRemoteCollection, useRemoteCollection } from 'utils/remote-collection.hook';

export const useBankAccounts = () => {
  return useRemoteCollection<BankAccountDto, CreateBankAccountDto, BankAccountDto>({
    fetchHandler: useCallback(fetchBankAccounts, []),
    postHandler: postBankAccount,
    updateHandler: putBankAccount,
    deleteHandler: deleteBankAccount,
    sort: useCallback((a: BankAccountDto, b: BankAccountDto) => b.id - a.id, [])
  });
};

export const useBankAccountsDropdown = () => {
  return useReadOnlyRemoteCollection<BankAccountDto>({
    fetchHandler: useCallback(fetchBankAccountsDropDown, []),
  });
};