import {
  MenuItem,
  Stack,
} from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { UserPickerField } from 'features/user/user-picker/ui';
import { initialValues, validate, FormData } from '../form';
import { linkTypes } from 'entities/company/linked-managers/ui/linked-manager-item';
import { useAddManagerLink } from '../model';
import { useSnack } from 'shared/ui/snack';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { FormDialog } from 'shared/ui/form-dialog';

type Props = {
  open: boolean;
  onClose: () => void;
  excludedManagers?: number[]
};

export function AddManagerDialog({ open, onClose, excludedManagers }: Props) {
  const { submit } = useAddManagerLink();
  const { showSuccess, showError } = useSnack();

  const handleSubmit = (formData: FormData) =>
    submit(formData)
      .then(() => {
        showSuccess('Связь с менеджером успешно добавлена');
        onClose();
      })
      .catch((e: any) => showError(`Ошибка добавления связи с менеджером: ${e.message}`));

  return (
    <FormDialog<FormData>
      renderForm={() => (
        <Stack direction="column" spacing={2}>
          <Field
            name='userId'
            component={UserPickerField}
            excludedManagers={excludedManagers} />
          <Field
            name="linkType"
            component={TextField}
            select
            label="Связь"
          >
            {linkTypes.map(el => <MenuItem key={el.slug} value={el.slug}>{el.name}</MenuItem>)}
          </Field>
          <Field
            name='endDate'
            component={DatePickerField}
            label="До какого дня" />
        </Stack>
      )}
      renderTitle="Выберите менеджера"
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      open={open}
      onClose={onClose} />
  );
}
