import { FormDataFileField } from './form-tools';
import { isFile } from '@sr/dto';

/**
 * из FormValue в DTO, Число. Если в форме пустое, то ничего не шлем
 * @param value 
 * @returns 
 */
export const toOptionalNumberField = (value: number | '') => value === '' ? undefined : Number(value);

/**
 * из FormValue в DTO, Число. Если в форме пустое, то отправляем null
 * @param value 
 * @returns 
 */
export const toNullableNumberField = (value: number | '') => value === '' ? null : Number(value);

/**
 * из DTO в FormValue, Число. Если пришел null, то в форме будет пустая строка
 * @param value 
 * @returns 
 */
export const fromNullableNumberField = (value: number | null) => value === null ? '' : value;


/**
 * из FormValue в DTO, Строка. Если в форме пустое, то отправляем null
 * @param value 
 * @returns 
 */
export const toNullableStringField = (value: string) => value === '' ? null : value;

/**
 * из DTO в FormValue, Строка. Если пришел null, то в форме будет пустая строка
 * @param value 
 * @returns 
 */
export const fromNullableStringField = (value: string | null) => value === null ? '' : value;


/**
 * из FormValue в DTO, Поле файла для загрузки. Если в форме пустое, то отправляем null
 * @param value
 * @returns
 */
//export const toFormDataFileField = (value: F) => value === '' ? null : value;


/**
 * из FormValue в DTO
 * @param value
 * @returns
 */
export const toFormFile = (value:  FormDataFileField) =>
  value === null
    ? null
    : isFile(value)
      ? value
      : undefined;
