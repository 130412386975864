import { FieldProps } from 'formik';
import { LeadBudgetPicker } from '../form-fields/lead-budget-picker';

export const LeadBudgetPickerField = ({ field, form }: FieldProps) => {
  const { setFieldValue, errors, touched } = form;
  const { name, value } = field;
  const error = errors[name];
  const isTouched = touched[name];
  const errorText = isTouched
    ? typeof error === 'string'
      ? error
      : undefined
    : undefined;

  const handleChange = (value: any) => setFieldValue(name, value);

  return (
    <LeadBudgetPicker
      value={value}
      onChange={handleChange}
      label='Бюджет'
      error={isTouched && error !== undefined}
      helperText={errorText}
    />);
};
