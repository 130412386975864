import { UpdateCityBasicDataDto } from '@sr/dto';
import { cityBasicDataApi } from 'entities/geo/cities/city-basic-data/apiSlice';
import { useSnack } from 'shared/ui/snack';

export const useUpdateCityBasicData = () => {
  const { showSuccess, showError } = useSnack();

  const [update] = cityBasicDataApi.useUpdateCityBasicDataMutation();

  return async (data: UpdateCityBasicDataDto) => {
    return update(data).unwrap()
      .then(() => showSuccess('Данные о городе успешно обновлены'))
      .catch((error) => showError('Ошибка обновления данных о городе', error));
  };
}
