import { useCallback, useState } from 'react';
import { Avatar, Box, IconButton, Stack, TextField } from '@mui/material';
import { CreateCommentDto } from '@sr/dto';
import SendIcon from '@mui/icons-material/Send';
import { CommentItem } from 'components/Comments/comment-item';
import { fetchComments, postComment } from 'shared/api/purchases';
import { useComments } from 'hooks/comments.hook';

export const PurchaseComments = ({ purchaseId }: { purchaseId: number; }) => {
  const [commentText, setCommentText] = useState('');

  const fetchHandler = useCallback(() => fetchComments(purchaseId), [purchaseId]);

  const { comments, addComment, posting } = useComments({
    fetchHandler: fetchHandler,
    addHandler: (dto: CreateCommentDto) => postComment(purchaseId, dto)
  });


  const handleAddComment = () => {
    addComment({ content: commentText })
      .then(() => setCommentText(''));
  };

  const handleTextChange = (e: any) => {
    setCommentText(e.target.value);
  };

  return (
    <Box maxWidth={600}>
      <Stack direction='column' spacing={2}>
        <Box>
          <Stack direction='row'>
            <Box marginRight={2}>
              <Avatar></Avatar>
            </Box>
            <Box width='100%'>
              <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                <TextField
                  fullWidth
                  value={commentText}
                  placeholder='Добавить комментарий...'
                  rows={1}
                  // multiline
                  onChange={handleTextChange}
                />
                <IconButton disabled={posting} color='primary' onClick={handleAddComment} >
                  <SendIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack direction='column' spacing={2}>
            {comments.map(c => <CommentItem key={c.id} comment={c} />)}
          </Stack>
        </Box>
      </Stack>
    </Box >
  );
}

