import { Chip, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InvoiceStatus, INVOICE_STATUSES } from '@sr/dto';
import { useSearchParams } from 'react-router-dom';

const INPUT_SLICE_INDEX = 3;

const InvoiceStatusValue = (selectedItems:string[]) => {
  if (selectedItems.length <= INPUT_SLICE_INDEX) {
    return selectedItems.map((item: string) => (
      <Chip
        sx={{ mr: 0.5, p: 0 }}
        size={'small'}
        key={item}
        label={INVOICE_STATUSES[Number(item) as InvoiceStatus]}
      />)
    );
  }

  return (
    <>
      {
        selectedItems
          .sort((a, b)=> a.length - b.length)
          .slice(0, INPUT_SLICE_INDEX)
          .map((item: string) => (
            <Chip sx={{ mr: 0.5 }} size={'small'} key={item} label={INVOICE_STATUSES[Number(item) as InvoiceStatus]} />)
          )
      }
      <Chip size={'small'} key={'three-dots-chip'} label={'...'} />
    </>
  );
};

type Props = { queryParamName: string }

export const InvoiceStatusUrlParamsFilter = ({ queryParamName  }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value }, } = event;

    setSearchParams(prev=>{
      if (value.length > 0) {
        prev.set(queryParamName, typeof value === 'string' ? value : value.join(','));
      } else {
        prev.delete(queryParamName);
      }
      return prev;
    });
  };

  const statuses: string[] = searchParams?.get(queryParamName)?.split(',') ?? [];

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="invoice-status-select-label" sx={{ backgroundColor:'white', px: 1 }}>
          Статус счета
        </InputLabel>
        <Select
          labelId="invoice-status-select-label"
          value={statuses}
          renderValue={InvoiceStatusValue}
          onChange={handleChange}
          multiple
          variant={'outlined'}
        >
          {Object.keys(INVOICE_STATUSES).map(key =>
            <MenuItem key={key} value={key}>
              {INVOICE_STATUSES[Number(key) as InvoiceStatus]}
            </MenuItem>
          )
          }
        </Select>
      </FormControl>
    </>);
};
