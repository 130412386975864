import { Stack, Grid } from '@mui/material';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { FormValues } from './country-form';


interface Props extends FormikProps<FormValues> {
}

export const CountryFormFields = (props: Props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={5}>
          <Stack direction="column" spacing={2}>
            <Field
              name="name"
              label="Название"
              component={TextField}
            />
          </Stack >
        </Grid>
      </Grid>
    </>
  );
};
