import { ReactNode } from 'react';
import { PayOrderImportSessionDto } from '@sr/dto';
import { Link } from 'shared/ui/link-base';

export const LinkToImportSession = ({ session, children }: { session: PayOrderImportSessionDto, children: ReactNode }) => {
  return (
    <Link to={`/accounting/import/${session.id}`} >
      Импорт № {session.id}
    </Link>
  );
}