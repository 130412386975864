import { InvoiceBasicResource } from '@sr/dto';
import { AsyncMeta, initialMeta } from 'shared/api/async-meta';

export const ActionPrefix = 'invoiceMutableData';

export type State = {
  data: InvoiceBasicResource | null;
  meta: AsyncMeta;
}

export const initialState: State = {
  data: null,
  meta: initialMeta
};