import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridLayout } from 'shared/ui/grid-layout';
import { CashflowDto, CreateCashflowDto, UpdateCashflowDto } from '@sr/dto';
import TableCell from '@mui/material/TableCell';
import { Box, Button, IconButton, TableRow } from '@mui/material';
import { useCashflows } from 'hooks/cashflows.hook';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { formTools, FormData } from './cashflows.form-tools';
import { useSnack, NotificationsConfig } from 'shared/ui/snack';
import { FormDialog } from 'shared/ui/form-dialog';
import { CashflowFormFelds } from './cashflow.form-fields';
import { CashflowChip } from 'shared/ui/cashflow-chip';


const columns = [
  'Статья движения средств',
  'Направление',
  ''
];

const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'статья движения денежных средств',
  itemTitleForm2: 'статьи движения денежных средств',
  gender: 'female'
};

const CashflowsList = () => {
  const { collection, addItem, updateItem, removeItem } = useCashflows();

  const { showSuccessDelete, showFailedDelete } = useSnack(notificationsConfig);
  const showConfirm = useConfirmDialog();

  const { dialogProps, openToAdd, openToEdit, itemToEdit } = useCreateOrEditDialog<CashflowDto, CreateCashflowDto, UpdateCashflowDto, FormData>({
    addHandler: async (dto) => { await addItem(dto); },
    updateHandler: async (dto) => { await updateItem(dto.id, dto); },
    formTools: formTools,
    notificationsConfig
  });


  const handleDelete = (cashflow: CashflowDto) => {
    showConfirm({
      content: 'Удалить статью?'
    })
      .then(() => removeItem(cashflow.id))
      .then(() => showSuccessDelete())
      .catch(e => showFailedDelete(e));
  };

  return (
    <Box>
      <Button
        variant='contained'
        sx={{ mb: 2 }}
        onClick={openToAdd}
      >
        Добавить
      </Button>

      <GridLayout<CashflowDto>
        columns={columns}
        items={collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        itemRender={cf => <Row
          key={cf.id}
          cashflow={cf}
          onEditClick={() => openToEdit(cf)}
          onDeleteClick={() => handleDelete(cf)}
        />}
        noItemsText="Список статей пуст" />

      <FormDialog<FormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новая статья движения денежных средств' : 'Статья движения денежных средств'}
        renderForm={(props) => <CashflowFormFelds {...props} />}
      />
    </Box>
  );
};

type Props = {
  cashflow: CashflowDto,
  onEditClick: () => void,
  onDeleteClick: () => void;
};



const Row = ({ cashflow, onEditClick, onDeleteClick }: Props) => {
  return (
    <TableRow>
      <TableCell>{cashflow.title}</TableCell>
      <TableCell>
        <CashflowChip direction={cashflow.direction} />
        {/* {CFD_TYPES[cashflow.direction]} */}
      </TableCell>
      <TableCell align="right">
        <IconButton edge="end" onClick={onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow >
  );
};

export default CashflowsList;


