import { ReactNode, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogProps as MuiDialogProps, DialogTitle, } from '@mui/material';
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues, } from 'formik';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { Dialog } from 'shared/ui/dialog';

export type DialogProps<TFormData extends FormikValues> = {
    renderForm: (props: FormikProps<TFormData>) => ReactNode;
    renderTitle: ReactNode | string;
    open: boolean;
    onClose: () => void;
    onClosed?: () => void;
    onSubmit: (values: TFormData) => Promise<void>;
    validate: (
        values: TFormData
    ) => FormikErrors<TFormData> | Promise<FormikErrors<TFormData>>;
    dialogProps?: Partial<MuiDialogProps>;
    initialValues: TFormData;
    validateOnChange?: boolean;
    options?: {
        noResetAfterSubmit?: boolean;
        disableOkButton?: boolean;
    };
  submitOnEnter?: boolean
};

export function FormDialog<TFormData extends FormikValues>(
  props: DialogProps<TFormData>
) {
  const {
    renderForm,
    renderTitle,
    open,
    onClose,
    submitOnEnter = false,
    onClosed,
    dialogProps,
    onSubmit,
    initialValues,
    validate,
    validateOnChange = false,
    options,
  } = props;

  const showConfirm = useConfirmDialog();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleClose = ({ dirty, resetForm }: FormikProps<TFormData>) => {
    if (dirty) {
      showConfirm({ content: 'Несохраненные данные будут утеряны' }).then(
        () => {
          resetForm();
          onClose();
        }
      );
    } else {
      onClose();
    }
  };

  const handleSubmit = async (
    values: TFormData,
    formikHelpers: FormikHelpers<TFormData>
  ) => {
    return onSubmit(values).then(() => {
      if (!options?.noResetAfterSubmit) formikHelpers.resetForm();
    });
  };

  const disableOkButton = options && options.disableOkButton;

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={hasSubmitted || validateOnChange}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <Dialog
            {...dialogProps}
            open={open}
            onClose={() => {
              if (!formikProps.isSubmitting) handleClose(formikProps);
            }}
            onClosed={onClosed}
          >
            <Form>
              <DialogTitle>
                {renderTitle}
                <Dialog.CloseButton
                  disabled={formikProps.isSubmitting}
                  onClick={() => handleClose(formikProps)}
                />
              </DialogTitle>

              <DialogContent onKeyDown={(event) => {
                if (event.key === 'Enter' && submitOnEnter) {
                  setHasSubmitted(true);
                  formikProps.submitForm();
                } }} dividers>{renderForm(formikProps)}</DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  autoFocus
                  type="submit"
                  onClick={() => {
                    setHasSubmitted(true);
                    formikProps.submitForm();
                  }}
                  disabled={
                    formikProps.isSubmitting ||
                    !formikProps.dirty ||
                    (formikProps.touched && !formikProps.isValid) ||
                    disableOkButton
                  }
                >
                  {formikProps.isSubmitting ? 'Подождите...' : 'Сохранить'}
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => handleClose(formikProps)}
                  disabled={formikProps.isSubmitting}
                >
                  Закрыть
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
}
