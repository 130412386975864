import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField } from 'formik-mui';
import { TextFieldProps } from 'formik-mui';
import { useFormikContext } from 'formik';

export const TextFieldClearable = (props : TextFieldProps) => {
  const { setFieldValue } = useFormikContext();
  return <TextField
    {...props}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear field"
            onClick={() => setFieldValue(props.field.name, '', false)}
            edge="end"
          >
            <ClearIcon/>
          </IconButton>
        </InputAdornment>
      ),
    }}
  />;
};
