import { CreateLeadDto } from '@sr/dto';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { toOptionalNumberField } from 'utils/form-helper';

export type FormData = {
  subject: string,
  content: string,
  statusId: number | '',
  budgetId: number | '',
  qualityId: number | '',
  userId: number | '';
};


export const initialValues: FormData = {
  subject: '',
  content: '',
  statusId: 1,
  budgetId: '',
  qualityId: '',
  userId: ''
};

export const toCreateDto = (formData: FormData) => {
  const lead: CreateLeadDto = {
    subject: formData.subject,
    content: formData.content,
    qualityId: toOptionalNumberField(formData.qualityId),
    budgetId: toOptionalNumberField(formData.budgetId),
    userId: toOptionalNumberField(formData.userId),
    statusId: Number(formData.statusId)
  };

  return lead;
};

export const validate = (values: FormData): FormikErrors<FormData> => {
  let validationResult: FormikErrors<FormData> = {};

  if (!values.subject)
    validationResult.subject = VALIDATION_REQUIRED_FIELD;

  if (!values.content)
    validationResult.content = VALIDATION_REQUIRED_FIELD;

  if (!values.statusId)
    validationResult.statusId = VALIDATION_REQUIRED_FIELD;

  // if (!values.budgetId)
  //   validationResult.budgetId = VALIDATION_REQUIRED_FIELD;

  // if (!values.qualityId)
  //   validationResult.qualityId = VALIDATION_REQUIRED_FIELD;


  return validationResult;
};
