import { IsBoolean, IsNotEmpty, IsNumber, IsString } from "class-validator";
import { CountryInfoDto } from "../countries/country.dto";
import { MacroRegionDto, MacroRegionInfoDto } from "../macro-regions/macro-region.dto";

export class CreateRegionDto {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsNotEmpty()
  @IsNumber()
  countryId: number;
}


export class RegionInfoDto {
  id: number;
  name: string;
  slug: string;
  macroRegion?: MacroRegionInfoDto;
  country: CountryInfoDto;
  publishingDataId: number | null;
}


// export class CreateRegionDto {
//   @IsString()
//   @IsNotEmpty()
//   name: string = '';

//   @IsNotEmpty()
//   @IsNumber()
//   countryId: number = NaN;

//   population: number = NaN;
//   digitalCode: string = '';

// }

export class UpdateRegionDto extends CreateRegionDto {
  id: number;
  // publishingDataId: number;
}

// export class RegionDto extends CreateRegionDto {
//   id: number = NaN;
// }
