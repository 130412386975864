import { Box } from '@mui/material';
import { IPagingData } from '@sr/dto';
import { ExportButton, ExportList } from 'features/wcc-export';
import { WccExportProgressDialog } from 'features/wcc-export/ui/export-progress-dialog';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useState } from 'react';
import { DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { NotificationsConfig, useSnack } from 'shared/ui/snack';

const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'акт выполненных работ',
  itemTitleForm2: 'акта выполненных работ',
  gender: 'neutral'
};

export const ExportWccTab = () => {
  const { showSuccessDelete, showFailedDelete } = useSnack(notificationsConfig);
  const showConfirmDialog = useConfirmDialog();

  const [paging, setPaging] = useState<IPagingData>(DEFAULT_PAGINATION);



  return (
    <Box>
      <ExportButton />

      <ExportList />

      <WccExportProgressDialog />
    </Box>
  )
}