import React from 'react';
import { createRoot } from 'react-dom/client';
import 'reflect-metadata';
//import './index.css';
import './fonts';
import { Provider } from 'react-redux';
import { store } from './shared/store/store';
import App from './app/app';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,

);
