import { ReactNode } from 'react';
import { Box, Paper, PaperProps, Typography, createTheme } from '@mui/material';

export const CardPaper = ({ children, ...rest }: PaperProps) => (
  <Paper {...rest}>
    <Box paddingX={2} paddingY={1}>
      {children}
    </Box>
  </Paper>
);

export const CardPaperTitled = ({ title, children }: { title: ReactNode, children: ReactNode; }) => {
  const titleElement = typeof title === 'string' ? <CardPaperHeader title={title} /> : title;
  return (
    <Paper>
      <Box paddingX={2} paddingY={1}>
        <Box mb={1}>
          {titleElement}
        </Box>
        <Box>
          {children}
        </Box>
      </Box>
    </Paper>);
};

export const CardPaperHeader = ({ title }: { title: string; }) =>
  <Typography variant='h6' color="grey.600">
    {title}
  </Typography>;