import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { CompanyDto } from '@sr/dto';
import { CompanyMultiPickerField } from 'shared/ui/formik-fields/company-picker';
import FilePickerField from 'shared/ui/formik-fields/file-picker';
import { emptyValues, FormData } from './price-form-data';

type Props = {
    open: boolean;
    onSubmit: (data: FormData) => Promise<void>;
    onClose: () => void;
};

const AddPriceDialog = (props: Props) => {
  const { open, onClose, onSubmit } = props;

  const [selectedCompanies, setSelectedCompanies] = useState<CompanyDto[]>([]);
  const [cmp, setCmp] = useState<CompanyDto | null>(null);

  const addCompany = (company: CompanyDto) => setSelectedCompanies([...selectedCompanies, company]);

  const onChange = (e: any, v: CompanyDto) => {
    setCmp(v);
  };


  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>Новый прайс</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={emptyValues}
          onSubmit={onSubmit}
        >
          {(p: FormikProps<FormData>) => (<>
            <Form>
              <Stack direction='column' spacing={2} sx={{ pt: 1 }}>
                <Field
                  name='companies'
                  label='Поставщик'
                  component={CompanyMultiPickerField}/>
                <Box>
                                        Основной файл
                  <Field
                    name='priceFile'
                    component={FilePickerField}
                  />
                </Box>
                <Box>
                                        Доп. файлы
                  <Field
                    name='extraFiles'
                    component={FilePickerField}
                    multiple
                  />
                </Box>
              </Stack>
            </Form>
            <DialogActions>
              <Button variant="contained"
                autoFocus
                onClick={p.submitForm}
                disabled={p.isSubmitting}>
                {p.isSubmitting ? 'Подождите...' : 'Сохранить'}
              </Button>
              <Button variant="outlined"
                onClick={onClose}
                disabled={p.isSubmitting}>
                                    Закрыть
              </Button>
            </DialogActions>
          </>

          )}
        </Formik>
      </DialogContent>
    </Dialog>);

};

export default AddPriceDialog;
