import { CreateEventDto, EventDto } from '@sr/dto';
import { eventsApi } from '../api';

export const useUpdateEventToggle = () => {
  const [update] = eventsApi.useUpdateEventToggleMutation();
  return async (data: Pick<EventDto, 'id'|'completed'|'important'>) => {
    return update(data).unwrap();
  };
};

export const useAddEvent = (companyId: number) => {
  const [addEvent] = eventsApi.useAddEventMutation();
  return async (newEvent : CreateEventDto)=>{
    return addEvent({ companyId, dto: newEvent }).unwrap();
  };
};
