import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { salaryApi } from '../salary.api';

export const useSalaryPeriods = () => {
  const { data, isLoading, error } = salaryApi.useGetSalaryPeriodsQuery({});
  return {
    salaryPeriods: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};
