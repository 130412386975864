import { Box, Button, Stack } from '@mui/material';
import { CreateCompanyDto, LeadDto } from '@sr/dto';
import { formatDateTime } from 'utils/date-format-helpers';
import { useState } from 'react';
import { AddCompanyDialog } from '../../../components/Companies/CompanyDialog/company-dialog.add';
import { companyLinkManager } from '../../../utils/link-manager';
import { useSnack } from '../../../shared/ui/snack';
import { useNavigate } from 'react-router-dom';
import { postCompany } from '../../company/details/api';
import AddIcon from '@mui/icons-material/Add';
import { useContacts } from '../../../features/contact/contacts.hooks';
import { EntitiesWithContacts } from '../../../features/contact/contacts.types';

export const LeadSubheader = ({ lead }: { lead: LeadDto }) => {
  const [isAddCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
  const { showSuccess, showError } = useSnack();
  const navigate = useNavigate();
  const { contacts } = useContacts({ relationId: lead.id, relationType: EntitiesWithContacts.lead });

  const onAddCompanySubmit = async (companyData: CreateCompanyDto) => {
    return postCompany(companyData)
      .then((newCompany) => {
        showSuccess('Компания успешно добавлена');
        navigate(companyLinkManager.link(newCompany.id));
      })
      .catch(error => showError('Ошибка добавления компании: ' + error.message));
  };

  const handleAddCompanyBtnClick = () => {
    if (!contacts || Object.values(contacts).every((contact) => contact.length === 0)) {
      return showError('Чтобы добавить компанию, укажите хотя бы один контакт для лида');
    }

    setAddCompanyDialogOpen(true);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        {formatDateTime(lead.createdAt)}
      </Box>
      <Box>
        <Button
          variant="contained"
          startIcon={<AddIcon/>}
          onClick={handleAddCompanyBtnClick}
        >
                    Добавить компанию
        </Button>
      </Box>
      <AddCompanyDialog
        open={isAddCompanyDialogOpen}
        contacts={contacts}
        onSubmit={onAddCompanySubmit}
        onClose={() => setAddCompanyDialogOpen(false)}
      />
    </Stack>
  );

};
