import { CircularProgress, IconButton } from '@mui/material';
import { useState } from 'react';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { DupesPopover } from './dupes-popover';
import { ContactType } from '@sr/dto';
import { useDupes } from './hook';
import { DupesDisplayOptions } from './types';

type Props = {
  contactType: ContactType;
  contactString: string;
  options?: DupesDisplayOptions;
}

export const DupesIndicator = ({ contactType, contactString, options }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { dupes, isLoading } = useDupes(contactType, contactString, options?.excludeLists);

  if (!dupes) {
    if (isLoading)
      return (
        <CircularProgress size={20} />
      );
    else
      return null;
  }

  if (dupes.dupesCompanies.length === 0 && dupes.dupesEmployees.length === 0)
    return null;

  return (
    <>
      <IconButton edge="end" size='small' color='warning' onClick={handlePopoverOpen}>
        <AutoAwesomeMotionIcon />
      </IconButton>

      <DupesPopover dupes={dupes} anchorEl={anchorEl} open={open} onClose={handlePopoverClose} options={options} />
    </>
  );
}