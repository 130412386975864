import matches from 'validator/lib/matches';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

export const validateSlug = (value: string): string | undefined => {
  let result = undefined;

  if (value === '')
    result = VALIDATION_REQUIRED_FIELD;
  else if (value.length < 3)
    result = 'Минимум 3 символа';
  else if (value.length >= 64)
    result = 'Максимум 64 символов';

  else if (!matches(value, '^[a-z_]+$'))
    result = 'Недопустимые символы. Разрешены строчные a-z и _';

  return result;
}