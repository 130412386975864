import { REFETCH_TIME_SPAN, extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { invoiceTotalsApi } from './invoice-totals.slice';

export const useInvoiceTotals = (invoiceId: number) => {
  const { data, isLoading, error, refetch } = invoiceTotalsApi.useGetInvoiceTotalsQuery(invoiceId, { refetchOnMountOrArgChange: REFETCH_TIME_SPAN })

  return {
    totals: data,
    loading: isLoading,
    error: extractNestJsErrorMessage(error),
    reload: () => refetch()
  };
};