import { MenuItem, SelectChangeEvent, TextField, TextFieldProps } from '@mui/material';
import { Identifiable } from '@sr/dto';
import { ChangeEvent, ChangeEventHandler, ReactNode, useEffect, useState } from 'react';
import { IRemoteCollection } from 'utils/remote-collection.hook';

type Props<TItem> = {
  value: number | '',
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  collection: IRemoteCollection<TItem>,
  isActive: (item: TItem) => boolean,
  menuItemRender: (item: TItem) => ReactNode,
  selectedItemRender: (item: TItem) => ReactNode
  textFieldProps?: TextFieldProps,
}

export function GenericSelectPicker<TItem extends Identifiable>(props: Props<TItem>) {
  const {
    textFieldProps,
    value,
    onChange,
    isActive,
    menuItemRender,
    selectedItemRender,

    collection,
  } = props;

  const disabled = textFieldProps ? textFieldProps.disabled : false;

  // Поле позволяет отображать "неактивные" варианты, например, удаленный расчетный счет
  // т.е. варианты, которые есть в базе, но выбирать их из дропдауна нельзя
  // isEditable - в каком режиме отображать комбобокс: если изначально выбран "неактивный" вариант
  // то отключаем весь комбобокс и пользователь выбирать ничего не может
  const [isEditable, setEditable] = useState(true);
  // const [selected, setSelected] = useState<TItem | null>(null);
  const [availableItems, setAvaliableItems] = useState<TItem[]>([]);

  useEffect(() => {
    if (collection.items.length > 0) {
      setAvaliableItems(collection.items.filter(i => isActive(i)));
      if (value === '') {
        // setSelected(null);
        setEditable(true);
        //setFieldValue(name, '');
      }
      else {
        const initialSelected = collection.items.find(x => x.id === value);
        if (initialSelected) {
          // setSelected(initialSelected);
          setEditable(isActive(initialSelected));
          //setFieldValue(name, initialSelected.id);
          // если изначально выбран "неактивный" вариант, то дропдаун должен содержать все варианты,
          // включая "неактивный", при этом, весь поле - отключено! 
          // это потому, что select умеет показывать выбранный элемент, только если он есть в списке всех элементов
          if (!isActive(initialSelected))
            setAvaliableItems(collection.items);
        }
        else {
          // setSelected(null);
          setEditable(false);
          // setFieldValue(name, '');
        }
      }
    }
    // else {
    //   setSelected(null);
    //   setEditable(false);
    //   setFieldValue(name, '');
    // }

  }, [collection, isActive, value]);


  const handlenChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setFieldValue(name, e.target.value);
    // const foundItem = availableItems.find(x => x.id === Number(e.target.value));
    onChange(e);
    // setSelected(foundItem ? foundItem : null);
  };

  const selectProps = selectedItemRender ? {
    renderValue: (value: any) => {
      const foundItem = availableItems.find(x => x.id === Number(value));
      return foundItem && selectedItemRender(foundItem);
    }
  } : undefined;

  const val = availableItems.length === 0
    ? ''
    : value;

  return (
    <TextField
      {...textFieldProps}
      value={val}
      onChange={handlenChange}
      select
      disabled={disabled || !isEditable}
      SelectProps={selectProps}
    >
      {availableItems.map(item => (
        <MenuItem key={item.id} value={item.id}>
          {menuItemRender(item)}
        </MenuItem>
      ))}
    </TextField >
  )
};
