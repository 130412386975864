import { ChangeEvent, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import { isPossibleNumber } from 'libphonenumber-js';
import { FormikErrors, FormikProps } from 'formik';
import { FormDialog } from './form-dialog';
import 'react-phone-input-2/lib/material.css';

type FormValues = {
  phone: string;
}

const initialValues: FormValues = {
  phone: ''
};

type DialogProps = {
  open: boolean;
  onSubmit: (phone: string) => Promise<void>;
  onClose: () => void;
}


export const PhoneDialog = ({ open, onSubmit, onClose }: DialogProps) => {
  const handleSubmit = (values: FormValues) => {
    return onSubmit(values.phone).then(onClose);
  };

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      submitOnEnter
      renderTitle="Добавление нового телефона"
      renderForm={(props) =>
        <FormInput formikProps={props} />
      }
      dialogProps={{ maxWidth: 'xs', fullWidth: true }} />
  );
};


function validate(values: FormValues): FormikErrors<FormValues> {
  if (!isPossibleNumber(values.phone))
    return { phone: 'Неверный формат' };
  return {};
}

const FormInput = ({ formikProps: props,  }: { formikProps: FormikProps<FormValues> }) => {
  const inputRef = useRef<HTMLElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange = (value: string, data: any, e: ChangeEvent<HTMLInputElement>) => {
    props.handleChange(e);
  };

  return (
    <PhoneInput
      placeholder="+7 XXX XX XX"
      disableDropdown={true}
      specialLabel='Телефон'
      defaultErrorMessage={props.errors.phone}
      country='ru'
      onlyCountries={['ru']}
      preferredCountries={['ru']}
      value={props.values.phone}
      onChange={handleChange}
      inputProps={{
        id: 'phone',
        name: 'phone',
        ref: inputRef
      }}
      isValid={() => !props.errors.phone}
    />
  );
};
