import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, INVOICES_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';
import { CreateInvoiceRowDto, InvoiceRowDto, UpdateInvoiceRowDto } from '@sr/dto';
import { purchaseApi } from '../purchase/purchase.api';

export const invoiceRowsApi = createApi({
  reducerPath: 'invoice-rows',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_BASE_URL}${INVOICES_BASE_URL}`,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInvoiceRows: builder.query<InvoiceRowDto[], {invoiceId:number, invoiceRowId?: number}>({
      query: ({ invoiceId, invoiceRowId }) => ({
        url: `${invoiceId}/rows`,
        method: 'GET',
        params: invoiceRowId ? { invoiceRowId } : {}
      }),
      providesTags:(result, error, { invoiceId }) =>([{ type: 'invoice-rows', id: invoiceId }]),
    }),
    addInvoiceRow: builder.mutation<InvoiceRowDto, CreateInvoiceRowDto>({
      query: (dto) => ({
        url: 'rows',
        body: dto,
        method: 'POST'
      }),
      invalidatesTags:(result, error, query) =>([{ type: 'invoice-rows', id: query.invoiceId }]),
    }),
    deleteInvoiceRow: builder.mutation<void, {invoiceRowId: number, invoiceId: number}>({
      query: ({ invoiceRowId, invoiceId })=> ({
        url: `/rows/${invoiceRowId}`,
        method: 'DELETE' ,
      }),
      invalidatesTags:(result, error, query) =>([{ type: 'invoice-rows', id: query.invoiceId }]),
    }),
    updateInvoiceRow: builder.mutation<InvoiceRowDto, UpdateInvoiceRowDto & {purchaseId?: number}>({
      query: (query) => ({
        url: '/rows',
        method:'PUT',
        body: query,
      }),
      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        if (query.purchaseId) {
          // если редактируем строку на странице заявке, надо обновить всю заявку
          dispatch(purchaseApi.util.invalidateTags([{ type: 'purchases', id: query.purchaseId }]));
        }
      },
      invalidatesTags:(result, error, query) =>([{ type: 'invoice-rows', id: query.invoiceId }]),
    }),

  }),
  tagTypes: ['invoice-rows'],
});
