import { Type } from "class-transformer";
import { IsString, IsNumber, IsIn } from "class-validator";
import { WccStatus, WCC_STATUSES_KEYS } from "./enums/wcc-statuses";
import { InvoiceDto } from "./invoice.dto";


export class WccDto {
  @IsNumber()
  id: number;

  @IsString()
  wccNumber: string;

  startDate: string;
  endDate: string;

  status: WccStatus;

  @Type()
  invoice: InvoiceDto;

  amount: number;
}

export class UpdateWccDto {
  @IsNumber()
  id: number;

  @IsIn(WCC_STATUSES_KEYS)
  status: WccStatus;

  // @IsString()
  // wccNumber: string;

  @Type()
  endDate: Date;

  amount: number;
}


export class InvoiceWccDto {
  wcc: WccDto
}

export class CreateInvoiceWccDto {
  @IsNumber()
  invoiceId: number;
}





