import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SearchResultsDto } from '@sr/dto';
import { APP_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';

const SEARCH_BASE_PATH = 'search';

export const searchApi = createApi({
  reducerPath: 'search-results',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSearchResults: builder.query<SearchResultsDto, string>({
      query: (query) => ({
        url: `${SEARCH_BASE_PATH}?${query}`,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
  
  }),
  tagTypes: ['*'],
});

