import { Button, FormControlLabel, MenuItem, Stack, Switch, Typography } from "@mui/material";
import { FORM_CAPTIONS_VARIANT } from "@sr/dto";
import { legalEntitiesTypeApi } from "entities/legal-entities/legal-entities-type/legal-entities-type.api";
import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import { TextField } from 'formik-mui';
import { useTitleHook } from "hooks/page-title.hook";
import { extractNestJsErrorMessage } from "shared/api/rtk-query";
import { PageHeader } from "shared/ui/details-page-header";
import { PageBreadCrumbs } from "shared/ui/page-breadcrumbs";
import { useSnack } from "shared/ui/snack";
import { useIdParam } from "utils/url-paramters.hook";

export interface FormData {
  name: string;
  description: string;
  printFormCaptionsVariant: number | undefined | string;
  active: boolean;
}

let initialValues: FormData = {
  name: '',
  description: '',
  printFormCaptionsVariant: 0,
  active: false
};

export const useEntityType = (id: number) => {
  const { data, isLoading, isFetching, error } = legalEntitiesTypeApi.useGetLegalEntityTypeStatusActiveQuery(id);
  return {
    data: data, 
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};

const LegalEntityTypePage = () => {
  const links = [
    { text: 'Настройки', to: '/settings/home' },
    { text: 'Формы собственности', to: '/settings/form-of-ownership' }
  ]; 

  const entityTypeId = useIdParam();  

  const { data, isLoading, error } = useEntityType(entityTypeId);

  useTitleHook('Настройка юридического лица');

  initialValues = {
    name: String(data?.name),
    description: String(data?.description),
    printFormCaptionsVariant: String(data?.printFormCaptionsVariant),
    active: Boolean(data?.active)
  }

  const [updateItem,{isError}] = legalEntitiesTypeApi.useUpdateLegalEntityTypeMutation()
  const { showSuccess, showError } = useSnack();
   
  const handleSubmit =  async (values: FormikValues) => {
    await updateItem(({id:entityTypeId, name: values.name, description: values.description, printFormCaptionsVariant: Number(values.printFormCaptionsVariant), active: values.active}))
      .unwrap()
      .then(() => 
        {
          showSuccess('Запись успешно обновлена')
        }
      )
      .catch(e => showError(`Ошибка записи: ${e}`))
  };

  return (
    <>
    <PageBreadCrumbs links={links}/>
    <PageHeader
          title={
            <Typography variant="h5" color="primary">{data?.name}</Typography>
          }
    />
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      <Form>
      <Stack spacing={2}>
              <Field component={TextField} name="name" label="Название"/>           
              <Field component={TextField} name="description" label="Описание"/>
              <Field label='Тип' component={TextField} select name="printFormCaptionsVariant">
                {  
                  FORM_CAPTIONS_VARIANT.map((i)=> 
                      <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                )}  
              </Field>
              <Field
                name='active'
                control={<Switch />}
                type="checkbox"
                as={FormControlLabel}
                label='Активна'
              />
         <Button variant="contained" sx={{ ml: 'auto', mr: 1 }} type="submit">Сохранить</Button>
      </Stack>

      </Form>
    </Formik>
    </>
  );

};

export default LegalEntityTypePage;