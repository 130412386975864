import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';
import { PeriodDto } from '@sr/dto';
import { SALARY_BASE_URL } from 'shared/api/api';

export const salaryApi = createApi({
  reducerPath: 'salary',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_BASE_URL}${SALARY_BASE_URL}`,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSalaryPeriods: builder.query<PeriodDto[], unknown>({
      query: () => ({
        url: '/periods',
        method: 'GET',
      }),
      providesTags: ['periods'],
    }),
  }),
  tagTypes: ['periods'],
});
