import { useTitleHook } from 'hooks/page-title.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useIdParam } from 'utils/url-paramters.hook';
import { useUserRole } from '../../entities/user/user-roles/user-roles.hooks';
import { GridLayout } from '../../shared/ui/grid-layout';
import { useUsersList } from "../../entities/user/users-list/users-list.hook";
import { IPagingData, UserDto } from "@sr/dto";
import { useSearchParams } from 'react-router-dom';
import { TableRow, TableCell} from "@mui/material";

type RowProps = {
  item: UserDto,
};

export const Row = ({ item }: RowProps) => {
  return (
    <TableRow key={item.id}>
      <TableCell>
        {item.id}
      </TableCell>
      <TableCell align="left">
        {item.firstName} {item.middleName} {item.secondName}
      </TableCell>
    </TableRow>
  );
};
const RoleDetailsPage = () => {
  const roleId = useIdParam('id');
  const [searchParams] = useSearchParams();
  searchParams.set('roleId', String(roleId));

  const { item, error, isLoading } = useUserRole(roleId);
  const { collection } = useUsersList({itemsPerPage: 100, page: 0} as IPagingData, searchParams);
  const columns = ['ID пользователя', 'Имя пользователя'];
  useTitleHook(item?.title && `Роль ${item?.title}`);

  if (!item)
    if (error)
      return <ErrorBanner errorMessage={error} />;
    else
      return <LoadingBanner />;

  return (
    <>
      <PageHeader
        title={
          <PageHeader.Text>
            <PageHeader.Text.Stressed>{`Роль ${item?.title}`}</PageHeader.Text.Stressed>
          </PageHeader.Text>
        }
        subHeader={
          <PageHeader.Text>
            {`Список пользователей с ролью ${item?.title}`}
          </PageHeader.Text>
        }
      />
      <GridLayout
        columns={columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        totalItemsCount={0}
        noItemsText='Список пользователей пуст'
        itemRender={(i) => <Row key={i.id} item={i} />}
      />
    </>
  );
};

export default RoleDetailsPage;
