import { OurLegalEntityForm } from './our-legal-entity.form';
import { useOurEntityData } from './our-legal-entities.hook';
import { LoadingBanner } from '../../../shared/ui/loading-banner';
import { ErrorBanner } from '../../../shared/ui/error-banner';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const OurLegalEntitySettings = ({ legalEntityId }: {legalEntityId: number}) => {
  const { legalEntityOurData, isLoading, error } = useOurEntityData(legalEntityId);
  if (isLoading) {
    return <LoadingBanner/>;
  }
  if (error) {
    return <ErrorBanner errorMessage={error}/>;
  }

  return<>
    {!legalEntityOurData &&
      <Alert sx={{ m: 1 }} severity="error">
        <AlertTitle>Важно!</AlertTitle>
      Работа с юрлицом ограничена. Заполните все необходимые поля для разблокировки юрлица.
      </Alert>
    }
    <OurLegalEntityForm legalEntityId={legalEntityId} serverData={legalEntityOurData}/>
  </>;
};
