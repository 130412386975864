import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateInvoiceResource } from '@sr/dto';
import { metaState } from 'shared/api/async-meta';
import { fetchInvoiceBasics, putInvoiceBasics } from './api';
import { ActionPrefix, State } from './state';


export const fetchInvoiceBasicsThunk = createAsyncThunk(
  `${ActionPrefix}/fetch`,
  async (id: number) => fetchInvoiceBasics(id)
);

export const updateInvoiceBasicsThunk = createAsyncThunk(
  `${ActionPrefix}/put`,
  async (data: UpdateInvoiceResource) => putInvoiceBasics(data)
);

export const buildExtraReducers = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(fetchInvoiceBasicsThunk.pending, (state) => {
    state.data = null;
    metaState.pending(state.meta);
  });
  builder.addCase(fetchInvoiceBasicsThunk.fulfilled, (state, { payload }) => {
    state.data = payload;
    metaState.done(state.meta);
  });
  builder.addCase(fetchInvoiceBasicsThunk.rejected, (state, action) => {
    state.data = null;
    metaState.error(state.meta, action.error.message);
  });

  builder.addCase(updateInvoiceBasicsThunk.pending, (state) => {
    metaState.pending(state.meta);
  });
  builder.addCase(updateInvoiceBasicsThunk.fulfilled, (state, { payload }) => {
    state.data = payload;
    metaState.done(state.meta);
  });
  builder.addCase(updateInvoiceBasicsThunk.rejected, (state, action) => {
    state.data = null;
    metaState.error(state.meta, action.error.message);
  });
}

export const invoiceBasicsThunks = {
  fetchInvoiceBasicsThunk,
  updateInvoiceBasicsThunk
};
