import { Step, StepLabel, Stepper } from '@mui/material'
import { WccStatus, WCC_STATUSES, WCC_STATUSES_KEYS } from '@sr/dto'

export const WccStatusStepper = ({ status }: { status: WccStatus }) => {

  const steps = WCC_STATUSES_KEYS.map(status => WCC_STATUSES[status])

  return (<Stepper activeStep={status - 1}  >
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>)
}