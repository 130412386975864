import { Divider, Tab, Tabs } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Outlet, Route, Routes, Link, useLocation } from 'react-router-dom';

export type NavTabInfo = {
  path: string;
  label: string;
  icon?: ReactElement;
  component: ReactNode;
  disabled?: boolean;
}


export const NavTabs = ({ tabs }: { tabs: NavTabInfo[] }) => {
  const location = useLocation();
  const tabName = location.pathname.split('/').at(-1);

  return (
    <>
      <Tabs value={tabName} variant="scrollable" scrollButtons="auto">
        {tabs.map(({ path, label, icon, disabled }, i) => {
          return <Tab
            key={i}
            label={label}
            value={path}
            iconPosition="start"
            icon={icon}
            to={path}
            component={Link}
            disabled={disabled}
          />;
        })}
      </Tabs>
      <Divider />
      <Routes>
        <Route element={<Outlet />}>
          {tabs.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
          ))}
        </Route>
      </Routes>
    </>
  );
};
