import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, SALARY_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';
import { TimeSheetDataDto, TimeSheetDataRowDto } from '@sr/dto';
import { salaryEntriesApi } from 'entities/salary/salary-entries/salary-entries.api';

export type TimeSheetQuery = {
  year: number,
  month: number
};

export type TimeSheetBaseMutation = TimeSheetQuery & {
  userId: number | null,
}

export type TimeSheetMutation = TimeSheetBaseMutation & {
  payload: Record<string, number>
}

const TIME_SHEET_BASE_ULR = SALARY_BASE_URL + '/time-sheet';

export const timeSheetApi = createApi({
  reducerPath: 'time-sheet',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  tagTypes: ['time-sheet'],
  endpoints: (builder) => ({
    getTimeSheetData: builder.query<TimeSheetDataDto, TimeSheetQuery>({
      query: ({ year, month }: TimeSheetQuery) => ({
        url: `${TIME_SHEET_BASE_ULR}/${year}/${month}`,
      }),
      providesTags: (result, error, query)=> result
        ? [{ type: 'time-sheet', id: `${query.year}-${query.month}` }]
        : ['time-sheet']
    }),
    putTimeSheetData: builder.mutation<TimeSheetDataRowDto, TimeSheetMutation>({
      query: ({ userId, month, year, payload }: TimeSheetMutation) => ({
        url: `${TIME_SHEET_BASE_ULR}/${year}/${month}/`,
        body: payload,
        params: userId ? { userId } : {},
        method: 'PUT',
      }),
      async onQueryStarted({ userId, year, month, payload }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        try {
          const { data: updatedRow } = await queryFulfilled;
          dispatch(
            timeSheetApi.util.updateQueryData('getTimeSheetData', { year, month }, (draft) => {
              Object.assign(
                draft, {
                  ...draft,
                  rows: draft.rows.map(row=> (
                    row.user?.id === userId || (row.user === null && updatedRow.user === null)
                      ? { ...row, payload: updatedRow.payload }
                      : row
                  )) });
            })
          );
          await queryFulfilled;
        } catch (e){
          console.error('RTK Query onQueryStarted error', e);
        }
      },
    }),
    closeTimeSheetData: builder.mutation<TimeSheetDataRowDto, TimeSheetMutation>({
      query: ({ userId, month, year, payload }: TimeSheetMutation) => ({
        url: `${TIME_SHEET_BASE_ULR}/${year}/${month}/`,
        body: payload,
        params: userId ? { userId } : {},
        method: 'POST',
      }),
      async onQueryStarted({ userId, year, month, payload }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        try {
          const { data: updatedRow } = await queryFulfilled;
          if (userId) {
            dispatch(salaryEntriesApi.util.invalidateTags([{ type: 'salary-entries', id: `${year}-${month}-${userId}` }]));
          }
          dispatch(
            timeSheetApi.util.updateQueryData('getTimeSheetData', { year, month }, (draft) => {
              Object.assign(
                draft, {
                  ...draft,
                  rows: draft.rows.map(row=> (
                    row.user?.id === userId || (row.user === null && updatedRow.user === null)
                      ? updatedRow
                      : row
                  )) });
            })
          );
        } catch (e){
          console.error(e);
        }
      },
    }),
    openTimeSheetData: builder.mutation<TimeSheetDataRowDto, TimeSheetBaseMutation>({
      query: ({ userId, month, year })=> ({
        url: `${TIME_SHEET_BASE_ULR}/${year}/${month}/`,
        params: userId ? { userId } : {},
        method: 'DELETE',
      }),
      async onQueryStarted({ userId, year, month }, { dispatch, queryFulfilled, getCacheEntry, getState }) {
        try {
          const { data: updatedRow } = await queryFulfilled;
          if (userId) {
            dispatch(salaryEntriesApi.util.invalidateTags([{ type: 'salary-entries', id: `${year}-${month}-${userId}` }]));
          }
          dispatch(
            timeSheetApi.util.updateQueryData('getTimeSheetData', { year, month }, (draft) => {
              Object.assign(
                draft, {
                  ...draft,
                  rows: draft.rows.map(row=> (
                    row.user?.id === userId || (row.user === null && updatedRow.user === null)
                      ? updatedRow
                      : row
                  )) });
            })
          );
        } catch (e){
          console.error(e);
        }
      },
    }),
  })
});
