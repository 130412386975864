import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { useTitleHook } from 'hooks/page-title.hook';
import { Heading } from 'shared/ui/list-page-heading';
import { UsersList } from 'entities/user/users-list/users-list';
import { AddUserDialog } from 'features/user/create';
import { UsersListFilter } from 'features/user/user-list-filter/ui';

const UsersListPage = () => {
  useTitleHook('Пользователи');

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  return (
    <>
      <Heading
        title="Пользователи"
        actions={
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
          >
            Добавить
          </Button>
        } />


      <Box mb={1}>
        <UsersListFilter />
      </Box>

      <UsersList/>

      <AddUserDialog
        open={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
      />
    </>
  );
};

export default UsersListPage;
