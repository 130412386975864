import { INVOICE_PRINT_NOT_SHOW_DATE, InvoiceBasicResource, UpdateInvoiceResource } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { isBefore } from 'date-fns';

export type FormData = {
  startDate: Date | '',
  endDate: Date | '',
  managerId: number | null;
  printFormShowMediaLink: boolean;
  printFormShowDateViewState: number;
};

export const formTools: FormTools<InvoiceBasicResource, void, UpdateInvoiceResource, FormData> = {
  toUpdateDto: (id: number, formData: FormData) => ({
    id: id,
    startDate: new Date(formData.startDate),
    endDate: new Date(formData.endDate),
    managerId: Number(formData.managerId),
    printFormShowMediaLink: formData.printFormShowMediaLink,
    printFormShowDateViewState: formData.printFormShowDateViewState,
  }),
  toCreateDto: (formData: FormData) => { },
  fromDto: function (dto: InvoiceBasicResource): FormData {
    return {
      managerId: dto.managerId,
      startDate: new Date(dto.startDate),
      endDate: new Date(dto.endDate),
      printFormShowMediaLink: dto.printFormShowMediaLink,
      printFormShowDateViewState: dto.printFormShowDateViewState,
    };
  },
  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (values.startDate === '') validationResult.startDate = VALIDATION_REQUIRED_FIELD;

    if (values.startDate !== '' && values.endDate !== '' &&
      isBefore(values.endDate, values.startDate)) {
      validationResult.startDate = validationResult.endDate = 'Дата выставления не может быть позже даты завершения';
    }

    if (!values.managerId)
      validationResult.managerId = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },
  emptyValues: () => {
    const now = new Date();
    const nextMonth = new Date();

    nextMonth.setMonth(now.getMonth() + 1);
    return {
      startDate: now,
      endDate: nextMonth,
      managerId: null,
      printFormShowMediaLink: false,
      printFormShowDateViewState: INVOICE_PRINT_NOT_SHOW_DATE,
    };
  }
};
