import { ContractInfoDto } from '@sr/dto';
import { Chip } from '@mui/material';

const ContractStatus = ({ contract }: {contract: ContractInfoDto}) => {
  return (
    contract.isActive
      ? <Chip label='Действует' color="success" size="small" variant='outlined'/>
      : <Chip label='Неактивный' color="warning" size="small" variant='outlined'/>
  );
};

export default ContractStatus;
