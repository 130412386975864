import { useState } from 'react';
import LinkedManagerItem from './linked-manager-item';
import { IManagerLink } from '@sr/dto';
import MenuParams from 'shared/ui/menu-params';
import { linkedManagersThunks } from 'entities/company/linked-managers/linked-managers.slice';
import { useAppDispatch } from 'shared/store';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { ListLayout } from 'shared/ui/list-layout';
import { useLinkedManagers } from '../model';

type Props = {
  onMoreClick: (anchor: HTMLElement, link: IManagerLink) => void;
};

export const LinkedManagersList = ({ onMoreClick }: Props) => {
  const { links } = useLinkedManagers();

  const dispatch = useAppDispatch();

  const [currentItemMenu, setCurrentItemMenu] = useState<MenuParams<IManagerLink> | null>(null);

  const handleMenuClick = (anchor: HTMLElement, link: IManagerLink) => {
    onMoreClick(anchor, link);
  };


  return (
    <>
      <ListLayout
        items={links}
        noItemsText="Нет привязанных менеджеров"
        itemRender={(link) =>
          <LinkedManagerItem
            key={link.id}
            link={link}
            onMenuClick={(anchor) => handleMenuClick(anchor, link)} />}
      />
    </>
  );
};
