import { Autocomplete, Stack, TextField } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useLegalEntities } from 'entities/legal-entities/legal-entities.hook';
import { LegalEntityBaseInfoDto } from '@sr/dto';
import { formatLegalEntity } from 'utils/legal-entity-format-helpers';
import { EntityRoles } from 'entities/invoice/types';

type LegalEntityPickerProps = {
  companyIdFilter?: number;
  disableInactive?: boolean;
  entityRole?: EntityRoles;
}

export const LegalEntityPicker = (props: TextFieldProps & LegalEntityPickerProps) => {
  const {
    form: { setFieldValue },
    field: { name , value },
    label,
    disableInactive = false,
    entityRole,
    companyIdFilter
  } = props;

  const [ open, setOpen] = useState(false);
  const [ searchInput, setSearchInput] = useState<string>('');
  const { isLoading: fetching, legalEntities: items } = useLegalEntities({ companyId: companyIdFilter, search: searchInput });

  const selectedEntity = items.find(item=>item.id === value) ?? null;
  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: LegalEntityBaseInfoDto | null) => {
      setFieldValue(name, newValue ? newValue.id : null);
    },
    [setFieldValue, name]
  );
  const loading = open && items.length === 0 && fetching;

  const {
    companyIdFilter: _, disableInactive: __,  entityRole: ___,
    ...textFieldProps } = props;
  return (
    <Autocomplete
      disabled={textFieldProps.disabled}
      value={selectedEntity}
      onChange={onChange}
      autoComplete={false}
      options={items}
      open={open}
      onOpen={() => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => formatLegalEntity(option)}
      getOptionDisabled={getOptionDisabled(disableInactive, entityRole)}
      filterOptions={
        (options, { inputValue }) =>
          options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()) || option.inn.includes(inputValue))
      }
      renderOption={(props, option) => renderLegalEntityOption(props, option)}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..."/>
      }
    />);
};

const renderLegalEntityOption = (props: React.HTMLAttributes<HTMLElement>, option: LegalEntityBaseInfoDto) => {
  return (
    <Stack {...props} direction="column" sx={{ alignItems: 'flex-start' }}>
      <span style={{ color: option.isActive ? 'inherit' : 'grey', textAlign: 'left', width: '100%' }}>
        {formatLegalEntity(option)}
      </span>
      <span style={{ textAlign: 'left', width: '100%', color: 'grey', fontSize: '0.875rem' }}>
        ИНН: {option.inn} / КПП: {option.kpp}
      </span>
    </Stack>
  );
};

const getOptionDisabled = (disableInactive: boolean, entityRole?: EntityRoles) =>
  (option: LegalEntityBaseInfoDto) => {
    return (disableInactive && !option.isActive) ||
      (entityRole === EntityRoles.INVOICE_ISSUER && !option?.canIssueInvoice) ||
      (entityRole === EntityRoles.INVOICE_ACCEPTOR && !option?.canAcceptInvoice);
  };
