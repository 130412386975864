
type Options = {
  fractionDigits?: number,
  omitZeroAfterDot?: boolean
}

const defaultOptions: Options = {
  fractionDigits: 2,
  omitZeroAfterDot: false
}

export const formatPrice = (price: number, options?: Options): string => {
  const fractionDigits = options?.fractionDigits || defaultOptions.fractionDigits;
  const omitZeroAfterDot = options?.omitZeroAfterDot || defaultOptions.omitZeroAfterDot;

  const priceString = price.toFixed(fractionDigits);

  if (fractionDigits === 0)
    return priceString;

  if (omitZeroAfterDot) {
    const priceSplit = priceString.split('.');
    if (Array.from(priceSplit[1]).every(s => s === '0'))
      return priceSplit[0];
  }

  return priceString;
}