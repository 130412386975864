import { Switch, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { useSnack } from 'shared/ui/snack';
import { legalEntitiesActiveStatusApi } from './api';

export const LegalEntityActiveStatus = ({ legalEntityId }: { legalEntityId: number }) => {
  const { data, isFetching, isError } = legalEntitiesActiveStatusApi.useGetLegalEntityActiveStatusQuery(legalEntityId, { refetchOnMountOrArgChange: true });
  const [updateData, { isLoading: isUpdating }] = legalEntitiesActiveStatusApi.useUpdateLegalEntityActiveStatusMutation();

  const { showSuccess, showError } = useSnack();

  const isActive = data ? data.isActive : true;

  const handleChange = (e: ChangeEvent, checked: boolean) => {
    updateData({ legalEntityId, data: { isActive: checked } })
      .unwrap()
      .then(({ isActive }) => showSuccess(`Юрлицо теперь ${isActive ? 'активно' : 'не активно'}`))
      .catch(e => showError('Ошибка изменения статуса юрлица', e));
  };

  return (
    <FormControlLabel
      disabled={isFetching || isUpdating || isError}
      control={
        <Switch checked={isActive} onChange={handleChange} />
      }
      label="юрлицо активно"
    />
  );
};
