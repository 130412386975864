import { CityBasicDataDto, UpdateCityBasicDataDto, } from '@sr/dto';
import { cityApi } from 'entities/geo/cities/cityApiSlice';
import { CITIES_BASE_URL } from 'shared/api/api';


const injectedApi = cityApi.injectEndpoints({
  endpoints: (builder) => ({
    getCityBasicData: builder.query<CityBasicDataDto, number>({
      query: (id: number) => ({
        url: `${CITIES_BASE_URL}/${id}/basic-data`,
        method: 'GET'
      }),
    }),
    updateCityBasicData: builder.mutation<CityBasicDataDto, UpdateCityBasicDataDto>({
      query: (data: UpdateCityBasicDataDto) => ({
        url: `${CITIES_BASE_URL}/basic-data`,
        body: data,
        method: 'PUT'
      }),
    })
  }),
  overrideExisting: true
});

export const cityBasicDataApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['city-basic-data'],
  endpoints: {
    getCityBasicData: {
      providesTags: ['city-basic-data']
    },
    updateCityBasicData: {
      invalidatesTags: ['city-basic-data']
    }
  }
})