import { PurchaseStatusDto } from "../purchase.dto";

export const PURCHASE_STATUS_DRAFT_ID = 1;
export const PURCHASE_STATUS_NEW_ID = 2;
export const DELETABLE_PURCHASES = [PURCHASE_STATUS_DRAFT_ID, PURCHASE_STATUS_NEW_ID]

export const PURCHASE_STATUSES: PurchaseStatusDto[] = [
  { id: PURCHASE_STATUS_DRAFT_ID, 'label': 'Черновик' },
  { id: PURCHASE_STATUS_NEW_ID, 'label': 'Новая' },
  { id: 3, 'label': 'Внесена' },
  { id: 4, 'label': 'Согласована' },
  { id: 5, 'label': 'Готова к оплате' },
  { id: 6, 'label': 'Оплачена' },
  { id: 7, 'label': 'Виртуальная' },
]

export const canDeletePurchase = (purchaseStatusId: number) => {
  return DELETABLE_PURCHASES.includes(purchaseStatusId)
}