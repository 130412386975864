import { InvoiceDto, InvoiceInfoDto } from '@sr/dto';
import { ReactNode } from 'react';
import { Link } from 'shared/ui/link-base';

import { invoiceLinkManager } from 'utils/link-manager';
import { Typography } from '@mui/material';

type InvoiceLike = Pick<InvoiceInfoDto, 'id' | 'invoiceNumber'>;

export const LinkToInvoice = (props: { invoice: InvoiceLike, children?: ReactNode }) => {
  const { invoice: { id, invoiceNumber }, children } = props;

  const number = invoiceNumber || <Typography component='span' fontStyle='italic' variant='inherit'>[без номера]</Typography>;
  const link = invoiceLinkManager.link(id);

  if (children)
    return (
      <Link to={link} >
        {children}
      </Link>);

  return (
    <Link to={link} >
      {number}
    </Link>
  );
}