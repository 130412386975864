import { LeadDto, UpdateLeadPropsDto } from '@sr/dto';
import { FormikErrors, FormikValues } from 'formik';
import { toOptionalNumberField } from 'utils/form-helper';

export type FormValues = {
  budgetId: number | '';
  qualityId: number | '';
};

export const validate = (values: FormValues): FormikErrors<FormValues> | undefined => {
  return undefined;
};

export const toUpdateDto = (id: number, values: FormValues): UpdateLeadPropsDto => ({
  id: id,
  budgetId: toOptionalNumberField(values.budgetId),
  qualityId: toOptionalNumberField(values.qualityId),
});

export const fromDto = ({ budget, quality }: LeadDto): FormValues => ({
  budgetId: budget ? budget.id : '',
  qualityId: quality ? quality.id : '',
});

// export const initialValues = (): FormValues => ({
//   budgetId: budgetId ? budgetId : '',
//   managerId: manager ? manager.id : '',
//   createdDate: formatDate(createdAt),
//   statusId: status ? status.id : 1
// });
