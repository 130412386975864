import { Box, Button, DialogActions, Divider, Stack } from '@mui/material';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { initialValues, validate, FormValues } from '../form';
import { Dialog } from 'shared/ui/dialog';
import { createNewUser } from '../model';
import { useNavigate } from 'react-router-dom';
import { useSnack } from 'shared/ui/snack';
import { userLinkManager } from 'utils/link-manager';


type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddUserDialog = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const { showSuccess, showError } = useSnack();

  const handleSubmit = async (values: FormValues) => {
    createNewUser(values)
      .then(response => {
        showSuccess('Пользователь успешно добавлен');
        navigate(userLinkManager.link(response.id));
      })
      .catch(error => {
        showError('Ошибка добавления пользователя: ' + error.message);
      });
  };


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ submitForm, isSubmitting }) => (
        <Dialog onClose={onClose} open={open} maxWidth='xl'>
          <Dialog.Title>Новый пользователь</Dialog.Title>
          <Dialog.Content>
            <Box sx={{ minWidth: 650 }}>
              <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                <Field
                  name='login'
                  label='Логин'
                  required
                  fullWidth
                  component={TextField}
                />
                <Field
                  name='password1'
                  label='Пароль'
                  required
                  fullWidth
                  component={TextField}
                  type='password'
                />
                <Field
                  name='password2'
                  label='Пароль еще раз'
                  required
                  fullWidth
                  type='password'
                  component={TextField}
                />
                <Divider />
                <Field
                  name='firstName'
                  label='Имя'
                  required
                  fullWidth
                  component={TextField}
                />
                <Field
                  name='secondName'
                  label='Фамилия'
                  fullWidth
                  component={TextField}
                />
                <Field
                  name="middleName"
                  label='Отчество'
                  fullWidth
                  component={TextField}
                />
              </Stack>
            </Box>
          </Dialog.Content>
          <DialogActions>
            <Button variant="contained"
              autoFocus
              onClick={submitForm}
              disabled={isSubmitting}>
              {isSubmitting ? 'Подождите...' : 'Сохранить'}
            </Button>
            <Button variant="outlined"
              onClick={onClose}
              disabled={isSubmitting}>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>)
      }
    </Formik>
  );
};


