import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import './ck-editor.css';

export type ContentProviderRef = () => string | undefined;

export type RichEditorProps = {
  content: string;
  disabled: boolean;
  config?: EditorConfig
  onChange?: ()=>void
}

export const RichEditor = forwardRef(({ content, disabled, config, onChange }: RichEditorProps, ref: ForwardedRef<ContentProviderRef>) => {
  const editorRef = useRef<typeof Editor>();
  useImperativeHandle(ref,
    () => () => editorRef.current?.data.get() // ? editorRef.current.data.get()
    , [editorRef]);

  return (
    <CKEditor
      editor={Editor}
      config={config}
      disabled={disabled}
      data={content}
      onChange={onChange}
      onReady={(editor: typeof Editor) => {
        editorRef.current = editor;
      }}
    />);
});
