import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { IProgressState } from './common';
import { CompanyDto, UpdateCompanyDto } from '@sr/dto';
import { AsyncMeta, initialMeta, metaState } from 'shared/api/async-meta';
import { fetchCompanyDetails, putCompany } from './api';

export type State = {
  company: CompanyDto | null;
  date: Date | null;
  meta: AsyncMeta
};

const initialState: State = {
  company: null,
  meta: initialMeta,
  date: null
};

const fetchCompanyThunk = createAsyncThunk(
  'company/fetch',
  async (companyId: number) => fetchCompanyDetails(companyId)
);

const updateCompanyThunk = createAsyncThunk(
  'company/update',
  async (data: UpdateCompanyDto) => putCompany(data)
);


const slice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyThunk.pending, (state, action) => {
      state.company = null;
      metaState.pending(state.meta);
    });
    builder.addCase(fetchCompanyThunk.fulfilled, (state, action) => {
      state.company = { ...action.payload };
      metaState.done(state.meta);
    });
    builder.addCase(fetchCompanyThunk.rejected, (state, action) => {
      state.company = null;
      metaState.error(state.meta, action.error.message);
    });

  }
});


export const { reducer: companyReducer, actions: companyActions } = slice;
export const companyThunks = {
  fetchCompanyThunk,
  updateCompanyThunk
};
