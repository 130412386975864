import { Box, Stack } from '@mui/material';
import { useCashflowsDropdown } from 'hooks/cashflows.hook';
import { CashflowDirectionListIcon } from 'shared/ui/cashflow-chip';
import { withGenericSelectPicker } from 'shared/ui/form-fields/generic-select-picker.hoc';

export const CashflowPicker = withGenericSelectPicker({
  itemsProvider: useCashflowsDropdown,
  isActive: cashflow => !cashflow.isDeleted,
  selectedItemRender: cashflow => (
    <Stack direction="row" >
      <CashflowDirectionListIcon direction={cashflow.direction} />
      <Box>
        {cashflow.title}
      </Box>
    </Stack>),
  dropDownRenderValue: cashflow => (
    <Stack direction="row" >
      <CashflowDirectionListIcon direction={cashflow.direction} />
      <Box>
        {cashflow.title}
      </Box>
    </Stack>)

});

