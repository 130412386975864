import { Chip } from '@mui/material';
import { InvoiceDto, InvoiceInfo, InvoiceInfoDto, InvoiceStatus, INVOICE_STATUSES, INVOICE_STATUS_CANCELED, INVOICE_STATUS_CLOSED, INVOICE_STATUS_DEBT, INVOICE_STATUS_NEW, INVOICE_STATUS_PAID, INVOICE_STATUS_PARTIAL_PAID, INVOICE_STATUS_WCC_CREATED } from '@sr/dto';
import DoneIcon from '@mui/icons-material/Done';

type AppearanceParams = {
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  variant: 'filled' | 'outlined'
}

const appearance: { [key in InvoiceStatus]: AppearanceParams } =
{
  [INVOICE_STATUS_NEW]: {
    color: 'default',
    variant: 'outlined'
  },
  [INVOICE_STATUS_PAID]: {
    color: 'success',
    variant: 'filled'
  },
  [INVOICE_STATUS_PARTIAL_PAID]: {
    color: 'success',
    variant: 'outlined'
  },
  [INVOICE_STATUS_DEBT]: {
    color: 'default',
    variant: 'filled'
  },
  [INVOICE_STATUS_CLOSED]: {
    color: 'default',
    variant: 'filled'
  },
  [INVOICE_STATUS_CANCELED]: {
    color: 'primary',
    variant: 'outlined'
  },
  [INVOICE_STATUS_WCC_CREATED]: {
    color: 'success',
    variant: 'outlined'
  },
};

type Props = {
  status: InvoiceStatus,
  size?: 'small' | 'medium'
}

export const InvoiceStatusSpan = ({ status, size = 'medium' }: Props) => {
  const icon = status === INVOICE_STATUS_PAID ? <DoneIcon /> : undefined;
  const params = appearance[status] || { color: 'default', variant: 'default' };

  return (
    <Chip
      variant={params.variant}
      label={INVOICE_STATUSES[status]}
      size={size}
      icon={icon}
      color={params.color}
    />
  );
};
