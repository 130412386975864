import { useEffect, useState } from 'react';
import { useSnack } from 'shared/ui/snack/snack.hook';
import {  PermissionDto } from '@sr/dto';
import { authorizedRequest } from 'shared/api/axios';
import {  PERMISSIONS_BASE_URL } from 'shared/api/api';

export const usePermissionsList = (load: boolean = true) => {
  const { showError } = useSnack();
  const [list, setList] = useState<PermissionDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!load)
      return;

    setLoading(true);
    authorizedRequest<void, PermissionDto[]>(`${PERMISSIONS_BASE_URL}`, 'GET')
      .then(list => setList(list))
      .catch(error => showError(error))
      .finally(() => {
        setLoading(false);
      });
  }, [load, showError]);

  return ({
    list: list,
    loading
  });
};
