import { Field, FormikProvider, useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import {
  formValuesFromUrlSearchParams,
  mergeFormValuesVsCurrentSearchParams,
} from 'utils/url-search-params.helper';
import { useEffect, useMemo } from 'react';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';

type FormValues = Record<string, string | null>

type Props = {
  queryParamName: string,
  companyIdFilter?: number, // filter only legal entities from specific company
  size?: 'small' | 'medium',
  label?: string,
}

/**
 * Когда используете данный фильтр по юр.лицу он просто обновляет URLSearchParams а адресной строке браузера.
 * Компонент списка должен подписаться на URLSearchParams и обновлять состояние соответственно
 */
export const LegalEntityUrlParamsFilter = ({ queryParamName, companyIdFilter, size = 'medium', label = 'Юр. лицо' }: Props) => {
  const defaultValues: FormValues = useMemo(()=>({
    [queryParamName]: null,
  }), [queryParamName]);

  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik<FormValues>({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultValues),
    onSubmit: () => {},
    validateOnChange: true,
  });

  const filterValue: string | null = formik.values[queryParamName] ?? null;

  useEffect(() => {
    if (!formik.isValid) return ;
    setSearchParams(prev => {
      const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultValues);
      if (Object.keys(formik.touched).length || formik.dirty) {
        // раз пользователь обновил фильтр, сбрасываем страницу на 0
        params.delete(PAGE_FIELD_NAME);
      }
      return params;
    });
  }, [filterValue]);

  return (
    <FormikProvider value={formik}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        disableInactive={false}
        companyIdFilter={companyIdFilter}
        size={size}
        component={LegalEntityPicker}
      />
    </FormikProvider>
  );
};
