import { LegalEntityDto, LegalEntityBaseInfoDto } from '@sr/dto';
import { formatLegalEntity } from 'utils/legal-entity-format-helpers';
import { Link } from 'shared/ui/link-base';
import { legalEntityManager } from 'utils/link-manager';
import { ReactNode } from 'react';
import { Chip } from '@mui/material';

type Props = {
  children?: ReactNode,
  legalEntity: LegalEntityBaseInfoDto | LegalEntityDto
}

export const LinkToLegalEntity = ({ legalEntity, children }: Props) => {

  const link = legalEntityManager.link(legalEntity.id);

  return (
    <Link to={link} >
      {children || formatLegalEntity(legalEntity)}
      {!legalEntity.isActive && <Chip label='Неактивно' color="warning" size="small" variant='outlined'/>}
    </Link>
  );
};
