import { IsString, MinLength, MaxLength, ValidateIf } from 'class-validator';
import { Duplicates } from './duplicates';

export interface IPhone {
  phone: string;
  ext?: string;
}

export interface ICreateLinkedPhone {
  phone: string;
  ext?: string;
}

export interface ILinkedPhone extends ICreateLinkedPhone, Duplicates {
  id: number;
}

export class CreatePhoneDto implements IPhone {
  @IsString()
  @MinLength(5)
  @MaxLength(25)
  phone: string;

  ext?: string | undefined;
}

// export interface IEmployeePhone {
//   phone: string;
//   ext: string;
// }
