import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { CheckboxWithLabel } from 'formik-mui';
import { Field, FormikProvider, useFormik } from 'formik';
import { FilterFormValues } from '@sr/dto';
import { CardPaper } from 'shared/ui/card-paper';
import { TextFieldClearable } from 'shared/ui/form-fields/text-field-clearable';
import { formValuesFromUrlSearchParams, mergeFormValuesVsCurrentSearchParams } from 'utils/url-search-params.helper';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';

type UserFilter = {
  userName: string;
  isActiveOnly: boolean;
  includeFired: boolean;
}

export const defaultFormValues: UserFilter = {
  userName: '',
  includeFired: false,
  isActiveOnly: true
};

export type FormValues = FilterFormValues<UserFilter>;

const DEBOUNCE_DELAY = 500;

export const UsersListFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultFormValues),
    onSubmit: () => {},
    validateOnChange: true,
    initialTouched: { userName: true },
    validate: (values)=> {
      if (!!values.userName && values.userName.length > 0 && values.userName.length <3) {
        return { userName: 'Наберите хотя бы 3 символа для поиска' };
      }
    }
  });

  useEffect(() => {
    const setCurrentSearchParams = () => {
      setSearchParams(prev => {
        const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultFormValues);
        if (formik.dirty) {
          // раз пользователь обновил фильтр, сбрасываем страницу на 0
          params.set(PAGE_FIELD_NAME, '0');
        }
        return params;
      });
    };

    const cancel = setTimeout(()=>{
      if (formik.isValid) {
        setCurrentSearchParams();
      }
    }, DEBOUNCE_DELAY);
    return () => clearTimeout(cancel);
  }, [formik.values, formik.isValid]);

  return (
    <CardPaper>
      <FormikProvider value={formik}>
        <Stack spacing={3} direction="row">
          <Box sx={{
            minWidth: 300, flexGrow:2, pt: 1
          }}>
            <Field
              fullWidth
              type="text"
              name="userName"
              label="Фамилия/Имя"
              helperText="Начните набирать для фильтрации"
              component={TextFieldClearable}
            />
          </Box>
          <Stack direction="column" sx={{ minWidth: 200, flexGrow: 1 }}>
            <Box>
              <Field
                type="checkbox"
                name="includeFired"
                Label={{ label: 'Показать уволенных' }}
                component={CheckboxWithLabel}
              />
            </Box>
            <Box>
              <Field
                type="checkbox"
                name="isActiveOnly"
                Label={{ label: 'Только активные' }}
                component={CheckboxWithLabel}
              />
            </Box>
          </Stack>
        </Stack>
      </FormikProvider>
    </CardPaper >
  );
};
