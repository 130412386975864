import { Box, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { FoundCompanies } from './found-companies';
import { FoundInvoices } from './found-invoices';
import { FoundLegalEntities } from './found-legal-entities';
import { useSearchResults } from '../search-results.hook';
import { FoundMedia } from './found-media';
import { FoundLeads } from './found-leads';
import { FoundPurchases } from './found-purchases';

export const SearchResults = ({ query }: { query: string }) => {
  const [queryParams] = useSearchParams();
  const { data, isLoading, error } = useSearchResults(queryParams);

  if (error) return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />

  if (isLoading) return <LoadingBanner />;

  if (!data) return null;

  const nothingFound = !data.foundCompanies && !data.foundInvoices && !data.foundLegalEntities;

  return (
    <Stack direction='column' gap={2}>
      {data.foundCompanies && <FoundCompanies list={data.foundCompanies} highlight={query} />}
      {data.foundInvoices && <FoundInvoices list={data.foundInvoices} highlight={query} />}
      {data.foundLegalEntities && <FoundLegalEntities list={data.foundLegalEntities} highlight={query} />}
      {data.foundMedia && <FoundMedia list={data.foundMedia} highlight={query} />}
      {data.foundLeads && <FoundLeads list={data.foundLeads} highlight={query} />}
      {data.foundPurchases && <FoundPurchases list={data.foundPurchases} highlight={query} />}

      {nothingFound &&
        <Box display="flex" alignItems="center">
          <Typography variant='h6'>Ничего не найдено</Typography>
        </Box>
      }
    </Stack >
  )
}