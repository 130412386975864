import SearchIcon from '@mui/icons-material/Search';
import { KeyboardEvent, useRef } from 'react';
import { Search, StyledInputBase } from 'shared/ui/search-input';
import { SearchQueryDto } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { useSearchRequest } from '../search-request.hook';
import { useLocalStorageState } from 'shared/hooks/local-storage-state';
import { IconButton, InputAdornment } from '@mui/material';

export const SearchBar = () => {
  const { navigateToResults } = useSearchRequest();
  const ref = useRef<HTMLInputElement>(null);

  const [storedSearchOptions] = useLocalStorageState('searchOptions', '');

  const { showWarning } = useSnack();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  const search = () => {
    const queryString = ref.current?.value;

    if (!queryString) {
      navigateToResults();
      return;
    }

    const queryDto = new SearchQueryDto();
    queryDto.query = queryString;

    const sParams = new URLSearchParams(storedSearchOptions);

    queryDto.searchCompanies = sParams.get('searchCompanies') ? sParams.get('searchCompanies') === 'true' : true;
    queryDto.searchInvoices = sParams.get('searchInvoices') ? sParams.get('searchInvoices') === 'true' : true;
    queryDto.searchLegalEntities = sParams.get('searchLegalEntities') ? sParams.get('searchLegalEntities') === 'true' : true;
    queryDto.searchMedia = sParams.get('searchMedia') ? sParams.get('searchMedia') === 'true' : true;
    queryDto.searchLeads = sParams.get('searchLeads') ? sParams.get('searchLeads') === 'true' : true;
    queryDto.searchPurchases = sParams.get('searchPurchases') ? sParams.get('searchPurchases') === 'true' : true;

    navigateToResults(queryDto);
  };


  return (
    <Search>
      <StyledInputBase
        inputRef={ref}
        placeholder="Поиск..."
        onKeyDown={handleKeyDown}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={search}
            >
              <SearchIcon sx={{ color: 'common.white' }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </Search>
  );
};
