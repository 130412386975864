import { ReactNode } from 'react';
import { WccDto } from '@sr/dto';
import { Link } from 'shared/ui/link-base';

import { wccLinkManager } from 'utils/link-manager';

export const LinkToWcc = (props: { wcc: WccDto, children?: ReactNode }) => {
  const { wcc: { id, wccNumber }, children } = props;

  const link = wccLinkManager.link(id);

  if (children)
    return (
      <Link to={link} >
        {children}
      </Link>);

  return (
    <Link to={link} >
      Акт {wccNumber}
    </Link>
  );
}