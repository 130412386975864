import { Button, Stack } from '@mui/material';
import { PageHeader } from 'shared/ui/details-page-header';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useContractTemplate } from 'entities/contract-template/contract-templates.hook';
import { ContractTemplateFields, TemplateDataRef } from 'features/contract-template/contract-template-fields';
import { useTitleHook } from 'hooks/page-title.hook';
import { useSnack } from 'shared/ui/snack';
import { useRef } from 'react';
import { HeaderText } from 'shared/ui/header-text';
import { useIdParam } from 'utils/url-paramters.hook';
import { PageBreadCrumbs } from 'shared/ui/page-breadcrumbs';

export const ContractTemplatePage = () => {
  const templateId = useIdParam();
  const { item, updateItem, loading } = useContractTemplate(templateId);

  useTitleHook(`Шаблон договора ${item?.name}`);

  const { showSuccess, showError } = useSnack();

  const dataRef = useRef<() => TemplateDataRef>(null);

  const handleSave = () => {
    if (dataRef.current) {
      const templateContent = dataRef.current?.().templateContent;
      const name = dataRef.current?.().name;
      if (templateContent && name)
        updateItem({
          id: templateId,
          name: name,
          templateContent: templateContent
        })
          .then(() => showSuccess('Данные шаблона успешно обновлены'))
          .catch(e => showError(`Ошибка обновления данных шаблона: ${e}`));
    }
  };

  if (!item)
    return <LoadingBanner />;

  return (
    <>
      <PageBreadCrumbs links={[
        { text: 'Настройки', to: '/settings/home' },
        { text: 'Шаблоны договоров', to: '/settings/contract-templates' },
        { text: item.name },
      ]} />

      <PageHeader
        title={
          <HeaderText>
            <Stack direction="row" alignItems="center" sx={{ whiteSpace: 'pre' }}>
              Шаблон договора <HeaderText.Stressed>{item.name}</HeaderText.Stressed>
            </Stack>
          </HeaderText>
        }
        actions={<Button variant='contained' onClick={handleSave} disabled={loading}>сохранить</Button>} />

      <ContractTemplateFields name={item.name} content={item.templateContent} disabled={loading} ref={dataRef} />
    </>
  );
};

export default ContractTemplatePage;
