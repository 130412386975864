import {
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem
} from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkIcon from '@mui/icons-material/Link';
import { MenuClickHandlers } from './types';


type Props = {
  menuAnchor: HTMLElement | null,
  onClose: () => void,
  menuClickHandlers: MenuClickHandlers
}

export const AddContactMenu = (props: Props) => {
  const { menuAnchor, onClose,
    menuClickHandlers: { onAddEmailClick, onAddPhoneClick, onAddUrlClick }
  } = props;

  const handleAddEmailClick = () => {
    onClose();
    onAddEmailClick();
  };

  const handleAddPhoneClick = () => {
    onClose();
    onAddPhoneClick();
  };

  const handleAddUrlClick = () => {
    onClose();
    onAddUrlClick();
  };

  return (
    <Menu
      anchorEl={menuAnchor}
      open={!!menuAnchor}
      onClose={onClose}
    >
      <MenuItem onClick={handleAddEmailClick}>
        <ListItemIcon>
          <AlternateEmailIcon />
        </ListItemIcon>
        <ListItemText>добавить Email</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleAddPhoneClick}>
        <ListItemIcon>
          <PhoneIcon />
        </ListItemIcon>
        <ListItemText>добавить телефон</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleAddUrlClick}>
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText>добавить сайт</ListItemText>
      </MenuItem>
    </Menu>
  );
};
