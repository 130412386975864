import { IPagedCollection, PurchaseInfoDto } from '@sr/dto';
import { TextWithHighlight } from 'shared/ui/text-with-highlight';
import { SearchResultSection } from './search-result-section';
import { LinkToPurchase } from 'shared/ui/links/link-to-purchase';

type Props = {
  highlight: string;
  list?: IPagedCollection<PurchaseInfoDto>;
}

export const FoundPurchases = ({ list, highlight }: Props) => {
  if (!list)
    return null;

  return (
    <SearchResultSection
      title="Заявки"
      items={list}
      content={list.items.map(entity => {

        return (
          <div key={entity.id}>
            <LinkToPurchase purchase={entity}>
              <TextWithHighlight component='span' variant='body1' hlText={highlight}>Заявка №{entity.id} </TextWithHighlight>
            </LinkToPurchase>

          </div>);
      }
      )}
      emptyResultText="Заявки не найдены"
    />
  );
};

