import * as React from 'react';
import { Box } from '@mui/material';

import { useSnack } from 'shared/ui/snack';
import { useStateSelector } from 'shared/store';
import { CashBalanceGrid } from 'entities/reports/cash-balance/cash-balance-grid';
import { CashBalancePeriodPicker } from 'entities/reports/cash-balance/cash-balance-period/cash-balance-period-picker';

export const CashBalance = () => {
  const selectedPeriod = useStateSelector((state) => state.cashBalanceSelectedPeriod.value);

  const { showError, showSuccess } = useSnack();

  return (
    <>
      <Box sx={{ minWidth: 200, maxWidth: 308, mb: 1 }}>
        <CashBalancePeriodPicker />
      </Box>
      <CashBalanceGrid year={selectedPeriod.year} month={selectedPeriod.month} />
    </>
  );
};
