import { useState } from 'react';


export const usePrintPopup = (fetchHandler: () => Promise<string>) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openWindow = () => {
    setLoading(true);
    fetchHandler()
      .then(response => {
        if (!response) return;

        const wnd = window.open('', '', 'title=');
        wnd?.document.write(response);
      })
      .finally(() => setLoading(false));
  }

  return { openWindow, loading, error };
}

