import { LeadDto } from "@sr/dto";
import { NotificationsConfig, useSnack } from "shared/ui/snack";
import { useUpdateLeadManager } from "./lib";
import { UserPicker } from "features/user/user-picker/user-picker";

type Props = {
  lead?: LeadDto
}

const notificationConfig: NotificationsConfig = {
  itemTitleForm1: 'закрепленный менеджер',
  itemTitleForm2: 'закрепленного менеджера',
  gender: "male"
}

export const LeadManagerChanger = ({ lead }: Props) => {
  const { showSuccessUpdate, showFailedUpdate } = useSnack(notificationConfig)
  const { update, isUpdating } = useUpdateLeadManager();

  const handleManagerChange = (value: string | number) => {
    if (!lead)
      return;
    const newManager = Number(value);
    if (isNaN(newManager) || newManager === 0)
      return;

    update({ leadId: lead.id, managerId: newManager })
      .unwrap()
      .then(showSuccessUpdate)
      .catch(e => showFailedUpdate(e));
  }

  const val = lead?.manager?.id ?? '';

  return (
    <UserPicker value={val} onChange={handleManagerChange} label="Менеджер" disabled={!lead || isUpdating} />
  )
}