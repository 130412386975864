import { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSnack } from 'shared/ui/snack';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { AddRegionDialog } from 'entities/geo/regions/add-region-dialog';
import RegionsList from 'entities/geo/regions/regions-list';
import useRegionsList from 'hooks/regions-list.hook';
import { FormValues } from 'entities/geo/regions/region-form';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { RegionInfoDto } from '@sr/dto';
import { Heading } from 'shared/ui/list-page-heading';


const RegionsListPage = () => {
  const { loading, error, regionsList, addRegion, removeRegion } = useRegionsList();
  const { showSuccess, showError } = useSnack();
  const showConfirmDialog = useConfirmDialog();

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const handleAddRegion = (data: FormValues) => {
    return addRegion(data)
      .then(() => {
        setAddDialogOpen(false);
        showSuccess('Регион успешно добавлен');
      }
      )
      .catch(error => showError(`Ошибка добавления региона: ${error.message}`));
  };

  const handleDeleteRegion = (region: RegionInfoDto) => {
    showConfirmDialog({
      title: 'Вы уверены?',
      content: `Удалить регион ${region?.name}?`
    })
      .then(() => {
        removeRegion(region.id)
          .then(() => showSuccess('Регион успешно удален'))
          .catch((e) => showError(`Ошибка удаления региона: ${e.message}`));
      });
  };


  if (loading)
    return <LoadingBanner />;

  if (error)
    return <ErrorBanner errorMessage={error} />;

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Регионы"
        actions={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
          >
            Добавить
          </Button>}
      />

      <RegionsList
        list={regionsList}
        onDelete={handleDeleteRegion}
      />

      <AddRegionDialog
        open={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleAddRegion}
      />

    </>
  );
};

export default RegionsListPage;
