import { CreateEmailDto, ILinkedEmail } from './email';
import { ILinkedPhone, CreatePhoneDto } from './phone';
import { CreateUrlDto, ILinkedUrl, IUrl } from './url';
import { Type } from 'class-transformer';

export interface IContacts {
  linkedEmails: ILinkedEmail[];
  linkedPhones: ILinkedPhone[];
  linkedUrls: ILinkedUrl[];
}

export const EMPTY_CONTACTS: IContacts = {
  linkedEmails: [],
  linkedPhones: [],
  linkedUrls: [],
};

export type ContactType = 'email' | 'phone' | 'url';

export class CreateContactsDto {
  @Type(() => CreateEmailDto)
  emails: CreateEmailDto[];

  @Type(() => CreatePhoneDto)
  phones: CreatePhoneDto[];

  @Type(() => CreateUrlDto)
  urls: CreateUrlDto[];
}
