import { useState, useRef } from 'react';
import { Identifiable } from '@sr/dto';
import { DialogProps } from 'shared/ui/form-dialog';
import { FormTools } from 'utils/form-tools';
import { FormikValues } from 'formik';
import { useSnack, NotificationsConfig } from 'shared/ui/snack/snack.hook';


export type DialogConfig<
  TItem extends Identifiable,
  TCreateItem,
  TUpdateItem,
  TFormData extends FormikValues
> = {
  addHandler: (createdDto: TCreateItem) => Promise<void>,
  updateHandler: (updateDto: TUpdateItem) => Promise<void>,
  formTools: FormTools<TItem, TCreateItem, TUpdateItem, TFormData>,
  notificationsConfig: NotificationsConfig;
};

export function useCreateOrEditDialog<TItem extends Identifiable, TCreateItem, TUpdateItem, TFormData extends FormikValues>(
  { formTools, addHandler, updateHandler, notificationsConfig }
    : DialogConfig<TItem, TCreateItem, TUpdateItem, TFormData>) {

  const { showSuccessAdd, showFailedAdd, showSuccessUpdate, showFailedUpdate } = useSnack(notificationsConfig);

  const [openDialog, setOpenDialog] = useState(false);

  const itemToEditRef = useRef<TItem | null>(null);

  const handleSubmit = async (data: TFormData) => {
    if (itemToEditRef.current) {
      return updateHandler(formTools.toUpdateDto(itemToEditRef.current.id, data))
        .then(() => {
          showSuccessUpdate();
          setOpenDialog(false);
        })
        .catch(e => showFailedUpdate(e));
    } else {
      return addHandler(formTools.toCreateDto(data))
        .then(() => {
          showSuccessAdd();
          setOpenDialog(false);
        })
        .catch(e =>
          showFailedAdd(e)
        );
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClosed = () => {
    itemToEditRef.current = null;
  };

  const openForAdd = () => {
    setOpenDialog(true);
  };

  const openForEdit = (item: TItem) => {
    itemToEditRef.current = item;
    setOpenDialog(true);
  };

  const dialogProps: Omit<DialogProps<TFormData>, 'renderForm' | 'renderTitle'> = {
    open: openDialog,
    onClose: handleClose,
    onClosed: handleClosed,
    validate: formTools.validate,
    onSubmit: handleSubmit,
    initialValues: itemToEditRef.current ? formTools.fromDto(itemToEditRef.current) : formTools.emptyValues(),
  };


  return {
    openToAdd: openForAdd,
    openToEdit: openForEdit,
    itemToEdit: itemToEditRef.current,
    dialogProps
  };
}
