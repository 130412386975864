import { useCallback } from 'react';
import { ContractInfoDto, ContractsDropdownFilter } from '@sr/dto';
import { CONTRACTS_BASE_URL } from 'shared/api/api';
import { fetchCollectionReq } from 'shared/api/axios';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';

export const useContractsDropdown = (filter?: ContractsDropdownFilter) => {
  return useReadOnlyRemoteCollection<ContractInfoDto>({
    fetchHandler: useCallback(async () => {
      if (!filter)
        return Promise.resolve<ContractInfoDto[]>([]);

      const response = await fetchCollectionReq<ContractInfoDto>(
        `${CONTRACTS_BASE_URL}/dropdown`,
        ContractInfoDto,
        filter
      );
      // return response.map(x => plainToInstance(ContractInfoDto, x, { enableImplicitConversion: true }));
      return response;
    }, [filter]),
  });
};
