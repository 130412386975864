import { emptyPagedCollection, IPagingData, UserDto } from '@sr/dto';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { userListApi } from './api';
import { extractNestJsErrorMessage } from '../../../shared/api/rtk-query';

export const useUsersList = (pagination: IPagingData, filter?: URLSearchParams): IRemotePagedCollection<UserDto> => {
  const { data, isLoading, error } = userListApi.useGetUserListQuery(
    { paging: pagination, filter: filter?.toString() || '' },
    { refetchOnMountOrArgChange: 60 }
  );

  return {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };
};


