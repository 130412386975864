import { usePermissionsList } from './hook';
import { fieldToAutocomplete, AutocompleteProps } from 'formik-mui';
import { PermissionDto } from '@sr/dto';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const PermissionPicker = (props: AutocompleteProps<string | PermissionDto, true, boolean, boolean>) => {
  const { list = [], loading } = usePermissionsList();

  return (
    <Autocomplete
      {...fieldToAutocomplete(props)}
      options={list}
      loading={loading}
      multiple
      getOptionLabel={(option: string | PermissionDto) => (option as PermissionDto).title}
      isOptionEqualToValue={(option: string | PermissionDto, value: string | PermissionDto) =>
        (option as PermissionDto).id === (value as PermissionDto).id}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          variant="outlined"
          label="Доступные пермисии"
          placeholder="Выберите пермисии"
        />
      )}
    />
  )
};
