import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, REPORTS_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';

export const reportsApi = createApi({
  reducerPath: 'reports',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_BASE_URL}${REPORTS_BASE_URL}`,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});
