import { useEffect } from 'react';
import { InvoiceInfo, IPagingData } from '@sr/dto';
import { deleteInvoice } from '../api';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { useActionCreators, useStateSelector } from 'shared/store';
import { clientInvoicesThunks } from '../model/thunks';

export const useClientInvoices = (paging: IPagingData, filterString?: string, companyId?: number) => {
  const { fetchClientInvoicesThunk } = useActionCreators(clientInvoicesThunks);

  useEffect(() => {
    fetchClientInvoicesThunk({ paging, filter: filterString, companyId });
  }, [paging, filterString, companyId, fetchClientInvoicesThunk]);

  const collection: IRemotePagedCollection<InvoiceInfo> = {
    collection: useStateSelector(state => state.invoicesList.invoicesList),
    isLoading: useStateSelector(state => state.invoicesList.meta.status === 'loading'),
    error: useStateSelector(state => state.invoicesList.meta.error),
  };

  const delInvoice = (id: number) => deleteInvoice(id);

  return {
    collection,
    deleteInvoice: delInvoice
  };
};


