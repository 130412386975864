import { emptyPagedCollection, IPagingData, LeadMediaplanDto } from '@sr/dto';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { leadMediaplanApi } from 'features/lead/lead-mediaplan/api';

export const useLeadMediaPlans = (pagination: IPagingData, filter?: URLSearchParams,): IRemotePagedCollection<LeadMediaplanDto> => {
  const { data, isLoading, error } = leadMediaplanApi.useGetMediaPlansQuery(
    { paging: pagination, filter: filter?.toString() || '' },
    { refetchOnMountOrArgChange: 60 }
  );

  return {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };
};
