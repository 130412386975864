import { useState, useEffect } from 'react';
import { useSnack } from 'shared/ui/snack/snack.hook';
import { CountryInfoDto, UpdateCountryBasicDataDto } from '@sr/dto';
import { fetchCountryDetails, putCountry } from 'shared/api/countries';

const useCountry = (id: number) => {
  const { showError, showSuccess } = useSnack();
  const [country, setCountry] = useState<CountryInfoDto | null>(null);

  useEffect(() => {
    fetchCountryDetails(id)
      .then(country => setCountry(country))
      .catch(error => showError(`Ошибка получения данных о стране: ${error.message}`));
  }, [id]);

  const update = async (dto: UpdateCountryBasicDataDto) => {
    return putCountry(id, dto)
      .then((updated) => {
        setCountry(updated);
        showSuccess('Данные о стране успешно обновлены');
      })
      .catch((error) => showError(`Ошибка обновления данных о стране: ${error.message}`));
  };

  return {
    country,
    update
  };
};


export default useCountry;