import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LayersIcon from '@mui/icons-material/Layers';
import { Tooltip, IconButton } from '@mui/material'
import { InvalidReasons } from '@sr/dto'

export const RowStatusInfoCell = ({ reasons }: { reasons?: InvalidReasons }) => {

  return (
    <>
      {reasons?.invoiceNotFound && <Tooltip title="Не найдены счета">
        <IconButton size='small'>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>}

      {reasons?.hasDuplicate && <Tooltip title="Есть дубликат">
        <IconButton size='small'>
          <LayersIcon />
        </IconButton>
      </Tooltip>}
    </>
  )
}