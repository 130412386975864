import { IPagingData, PurchaseInfoDto } from '@sr/dto';
import { useCallback } from 'react';
import { fetchPagedReq } from 'shared/api/axios';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const useMediaPurchases = (paging: IPagingData, filter?: URLSearchParams,) => {
  const collection = useReadOnlyRemotePagedCollection<PurchaseInfoDto>({
    fetchHandler: useCallback(() => fetchMediaPurchases(paging, filter?.toString() || ''), [filter, paging]),
  });

  return collection;

};

const BASE_URL = '/purchases';

const fetchMediaPurchases = async (paging: IPagingData, filterString?: string) => {
  const params = new URLSearchParams(filterString);
  const response = await fetchPagedReq<void, PurchaseInfoDto>(
    `${BASE_URL}/media-purchases?${params.toString()}`,
    paging);
  return response;
};
