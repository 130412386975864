import { InvoiceInfoDto, IPagedCollection } from '@sr/dto';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { TextWithHighlight } from 'shared/ui/text-with-highlight';
import { SearchResultSection } from './search-result-section';

type Props = {
  highlight: string;
  list: IPagedCollection<InvoiceInfoDto>;
}

export const FoundInvoices = ({ list, highlight }: Props) => {
  return (
    <SearchResultSection
      title="Счета"
      items={list}
      content={list.items.map(invoice =>
        <div key={invoice.id}>
          <LinkToInvoice invoice={invoice}>
            <TextWithHighlight component='span' variant='body1' hlText={highlight}>{invoice.invoiceNumber}</TextWithHighlight>
          </LinkToInvoice>
        </div>)}
      emptyResultText="Счета не найдены"
    />
  );
};




