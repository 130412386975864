import { salaryEntriesApi, SalaryEntryQuery } from 'entities/salary/salary-entries/salary-entries.api';
import { SalaryEntryDto } from '@sr/dto';
import { SalaryEntryCreationFormValues } from './salary-entry.form';

export type SalaryEntryMutation = SalaryEntryQuery & {
  formValues: Omit<SalaryEntryCreationFormValues, 'userId'>
};

export const salaryEntryCreationApi = salaryEntriesApi.injectEndpoints({
  endpoints: (builder) => ({
    createSalaryEntry: builder.mutation<SalaryEntryDto, SalaryEntryMutation>({
      query: ({ month, year, userId, formValues }: SalaryEntryMutation) => ({
        url: `entries/${year}/${month}/${userId}`,
        body: formValues,
        method: 'POST',
      }),
      async onQueryStarted(
        { userId, year, month, formValues },
        { dispatch, queryFulfilled, getCacheEntry, getState }) {
        try {
          const { data: newRow } = await queryFulfilled;
          dispatch(
            salaryEntriesApi.util.updateQueryData('getSalaryEntries', { year, month, userId }, (draft) => {
              draft.push(newRow);
            })
          );
        } catch (e){
          console.error(e);
        }
      },
    }),
  })
});
