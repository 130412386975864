import { TextField, Autocomplete } from '@mui/material';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useCitiesDropdown } from './hook';
import { CityInfoDto } from '@sr/dto';

export const CityPicker = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props;

  const [open, setOpen] = useState(false);
  const { list: citiesList, loading: fetching } = useCitiesDropdown(open || Boolean(value));
  const [selectedCity, setSelectedCity] = useState<CityInfoDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: CityInfoDto | null) => {
      setSelectedCity(newValue);
      setFieldValue(name, newValue ? newValue.id : '');
    },
    [setFieldValue, name, setSelectedCity]
  );

  useEffect(() => {
    setSelectedCity(citiesList.find(x => x.id === value) ?? null);
  }, [citiesList, value]);


  const loading = open && citiesList.length === 0 && fetching;


  return (
    <Autocomplete
      value={selectedCity}
      autoComplete={false}
      options={citiesList.sort((b, a) => ((a.region ? a.region.id : 0) - (b.region ? b.region.id : 0)))}
      // options={citiesList}
      //sx={{ mt: 2 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      groupBy={(option) => option.region ? option.region.name.toString() : ' '}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};


