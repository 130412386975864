import { Stack, Box, Chip } from '@mui/material';
import { useTitleHook } from 'hooks/page-title.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { ContractStatusStepper } from 'components/Contracts/contract-status-stepper';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { formatLegalEntity } from 'utils/legal-entity-format-helpers';
import { ContractBasicsCard } from 'components/Contracts/contract-basic-card';
import { ErrorBanner } from 'shared/ui/error-banner';
import { FormData, formTools } from 'components/Contracts/contract-basic.form-tools';
import { useSnack } from 'shared/ui/snack';
import { ContractTabs } from 'components/Contracts/contract-tabs';
import { PrintContractButton } from 'features/contract/print-form-button';
import { useIdParam } from 'utils/url-paramters.hook';
import { useContract } from 'entities/contract/lib';
import { PartiesCard } from 'widgets/contracts/parties-card';
import ContractStatus from 'entities/contract/contract-status';

export const ContractDetailsPage = () => {
  const contractId = useIdParam('contractId');

  const { item: contract, error, updateItem } = useContract(contractId);

  const contractNumber = contract?.name || `№ ${contract?.id}`;
  useTitleHook(`Договор ${contractNumber}`);

  const { showSuccess, showError } = useSnack();

  const handleUpdate = (formData: FormData) => {
    const dto = formTools.toUpdateDto(contract!.id, formData);
    return updateItem(dto)
      .then(() => showSuccess('Данные договора обновлены'))
      .catch(e => showError(`Ошибка обновления данных договора: ${e}`));
  };

  if (!contract)
    if (error)
      return <ErrorBanner errorMessage={error} />;
    else
      return <LoadingBanner />;


  return (
    <>
      <PageHeader
        title={
          <PageHeader.Text>
            Договор <PageHeader.Text.Stressed>{contractNumber} </PageHeader.Text.Stressed>
            с компанией <PageHeader.Text.Stressed>{formatLegalEntity(contract.buyer)}</PageHeader.Text.Stressed>
          </PageHeader.Text>
        }
        actions={<PrintContractButton allowPrintWithStamp={true} contractId={contractId} />}
        subHeader={
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box>
              <ContractStatus contract={contract}/>
            </Box>
            <Box maxWidth={850} minWidth={800}>
              <ContractStatusStepper status={contract?.status} />
            </Box>
          </Box>
        }
      />


      <Stack direction="row" spacing={2}>
        <Box>
          <ContractBasicsCard contract={contract} updateContract={handleUpdate} />
        </Box>
        <Box>
          <PartiesCard contract={contract} />
        </Box>
        <Box flexGrow={1}>
          <ContractTabs contract={contract} />
        </Box>
      </Stack>

    </>
  );
};

export default ContractDetailsPage;

