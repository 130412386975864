import { Box, Stack } from '@mui/material';
import { LeadDto } from '@sr/dto';
import { LeadStatusChanger } from '../lead-status-changer';
import { LeadManagerChanger } from '../lead-manager-changer';

export const LeadHeaderActions = ({ lead }: { lead: LeadDto }) => {

  return (
    <Box width={500}>
      <Stack direction="row" justifyContent="flex-end" alignContent="flex-end" gap={2}>
        <Box width={250}>
          <LeadManagerChanger lead={lead} />
        </Box>
        <Box width={250}>
          <LeadStatusChanger lead={lead} />
        </Box>
      </Stack>
    </Box>
  )
}