import { validate, FormData, initialValues } from './signers.form';
import { SignerFormFields } from './signer.form-fields';
import { FormDialog } from 'shared/ui/form-dialog';

type DialogProps = {
  open: boolean;
  title: string;
  values?: FormData;
  submit: (formData: FormData) => Promise<void>;
  onClose: () => void;
};

export function SignerDialog({ open, onClose, submit, title, values }: DialogProps) {
  return (
    <FormDialog
      onClose={onClose}
      open={open}
      initialValues={values ? values : initialValues}
      onSubmit={submit}
      validate={validate}
      renderTitle={title}
      renderForm={(props) => <SignerFormFields />}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    />
  );
}
