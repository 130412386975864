import { createSlice } from '@reduxjs/toolkit';
import { AsyncMeta, initialMeta } from 'shared/api/async-meta';
import { buildExtraReducers } from './thunks';

export type State = {
  id: number | null,
  profile: {
    login: string,
    firstName: string;
    secondName: string;
    middleName: string;
  } | null,
  meta: AsyncMeta,

}

export const initialState: State = {
  id: null,
  profile: null,
  meta: initialMeta,
};

const slice = createSlice({
  name: 'user/profile',
  initialState: initialState,
  reducers: {},
  extraReducers: buildExtraReducers
});

export const { reducer: userProfileReducer, actions: userProfileActions } = slice;
