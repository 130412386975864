import { Box } from '@mui/material';
import { IPagedCollection } from '@sr/dto';

type Props = {
  list: IPagedCollection<unknown>,
  noItems: string;
}

export function SectionFoundCounters({ list, noItems }: Props) {
  if (!list)
    return null;

  if (list.totalItemsCount === 0)
    return <>{noItems}</>;


  return (
    <Box component="p">
      показано: <strong>{list.items.length}</strong>, всего найдено: <strong>{list.totalItemsCount}</strong>
    </Box>
  );
};

