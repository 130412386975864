import { extractNestJsErrorMessage } from '../../../shared/api/rtk-query';
import { cashBalanceApi } from './cash-balance.api';

export const useCashBalanceData = (year: number, month: number) => {
  const { data, isLoading, error } = cashBalanceApi.useGetCashBalanceQuery(
    { year, month },
    { refetchOnMountOrArgChange: 120 },
  );

  return {
    rows: data ? data : [] as any[],
    error: extractNestJsErrorMessage(error),
    isLoading,
  };
};
