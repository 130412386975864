import { Alert, Autocomplete, Box, Button, TextField, Typography } from "@mui/material"
import { CompanyToCombineDto } from "@sr/dto"
import { Dialog } from "shared/ui/dialog"
import { useCombine } from "../combine/use-combine"
import { useNavigate } from "react-router-dom"
import { companyLinkManager } from "utils/link-manager"
import { useState } from "react"
import { useSnack } from "shared/ui/snack"

type Props = {
  companies: CompanyToCombineDto[],
  open: boolean,
  onClose: () => void
}

export const CombineCompaniesDialog = ({ companies, open, onClose }: Props) => {
  const { combineCompanies, isLoading } = useCombine();
  const navigate = useNavigate();

  const [newCompanyName, setNewCompanyName] = useState<string | null>('');
  const { showSuccess, showError } = useSnack();

  const handleCombine = async () => {
    if (!newCompanyName) return;

    combineCompanies(newCompanyName, companies.map(x => x.id))
      .then((newCompany) => {
        showSuccess("Компании успешно объединены")
        navigate(companyLinkManager.link(newCompany.id));
      })
      .catch((error) => showError("Ошибка объединения компаний:", error));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Title>Объединение компаний</Dialog.Title>
      <Dialog.Content>
        <Typography component="div" variant="body1" mb={2}>
          Выбранные компании будут удалены. Будет создана новая компания. Все данные будут перенесены в новую компанию:
          <ul>
            <li>Контакты</li>
            <li>Юрлица</li>
            <li>Счета</li>
            <li>Сотрудники</li>
          </ul>
        </Typography>
        <Alert severity="warning">
          Внимание! После объединения компаний, отменить операцию будет невозможно
        </Alert>
        <Typography component="div" variant="body1" my={1}>
          Введите название или выберите из списка:
        </Typography>
        <Autocomplete
          freeSolo
          options={companies.map(x => x.name)}
          renderInput={(params) => <TextField {...params} label="Название новой компании" onChange={(e) => setNewCompanyName(e.target.value)} />}
          onChange={(e, value) => setNewCompanyName(value)}
          value={newCompanyName}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="contained" disabled={isLoading || !newCompanyName} onClick={handleCombine}>Объединить</Button>
        <Button variant="outlined" disabled={isLoading} onClick={onClose}>закрыть</Button>
      </Dialog.Actions>
    </Dialog>

  )
}
