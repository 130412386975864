import { IMediaPriceInfo } from 'interfaces/IMediaPriceInfo';
import { authorizedFormDataRequest, authorizedRequest, toFormData } from 'shared/api/axios';
import { ICreatePrice } from 'interfaces/IPrice';

export const fetchMediaPrices = (mediaId: number) => {
  return authorizedRequest<void, IMediaPriceInfo[]>(`/media/${mediaId}/prices`, 'GET');
};

export const uploadPrice = (priceData: ICreatePrice, priceFile: File, extraFiles: File[] = []) => {
  return authorizedFormDataRequest('prices/upload', 'POST', {
    mainFile: priceFile,
    extraFiles,
    ...toFormData(priceData),
  });
  //return authorizedRequest<void, IMediaPriceInfo[]>(`/media/${mediaId}/prices`, 'GET');
};
