import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IManagerLink, ICreateManagerLink } from '@sr/dto';
import { fetchLinkedManagers, postLinkManager, deleteLinkManager } from 'entities/company/linked-managers/api';
import { LoadingStatus } from 'shared/api/async-meta';


export type State = {
  status: LoadingStatus;
  error: string;
  postNewLinkStatus: LoadingStatus;
  links: IManagerLink[];
};

const initialState: State = {
  status: null,
  error: '',
  postNewLinkStatus: null,
  links: [],
};

export const loadThunk = createAsyncThunk<IManagerLink[], number, { rejectValue: string; }>(
  'loadLinks',
  async (companyId: number, thunkAPI) => fetchLinkedManagers(companyId)
);

export const postNewLinkThunk = createAsyncThunk<IManagerLink, ICreateManagerLink>(
  'addLink',
  async (data: ICreateManagerLink, thunkAPI) => postLinkManager(data)
);

export const deleteLinkThunk = createAsyncThunk<number, number>(
  'deleteLink',
  async (linkId: number, thunkAPI) => {
    return deleteLinkManager(linkId)
      .then(() => linkId);

  }
);

const slice = createSlice({
  name: 'linkedManagers',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadThunk.pending, (state, action) => {
      state.status = 'loading';
      state.links = [];
    });
    builder.addCase(loadThunk.fulfilled, (state, action) => {
      state.status = 'done';
      state.links = action.payload;
    });
    builder.addCase(loadThunk.rejected, (state, action) => {
      state.status = 'error';
      state.error = action.error.message || '';
      state.links = [];
    });


    builder.addCase(postNewLinkThunk.pending, (state) => {
      state.postNewLinkStatus = 'loading';
    });
    builder.addCase(postNewLinkThunk.fulfilled, (state, action) => {
      state.postNewLinkStatus = 'done';
      state.links.push(action.payload);
    });
    builder.addCase(postNewLinkThunk.rejected, (state, action) => {
      state.postNewLinkStatus = 'error';
      state.error = action.error.message || '';
    });


    builder.addCase(deleteLinkThunk.pending, (state) => {
      state.postNewLinkStatus = 'loading';
    });
    builder.addCase(deleteLinkThunk.fulfilled, (state, action) => {
      state.postNewLinkStatus = 'done';
      state.links = state.links.filter(x => x.id !== action.payload);
    });
    builder.addCase(deleteLinkThunk.rejected, (state, action) => {
      state.postNewLinkStatus = 'error';
      state.error = action.error.message || '';
    });

  }

});

export const { reducer: linkedManagersReducer, actions: linkedManagersActions } = slice;
export const linkedManagersThunks = {
  loadThunk, postNewLinkThunk, deleteLinkThunk
};
