import {
  Button,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PersonsList } from './Persons/persons-list';
import { AddPersonDialog, FormData } from './Persons/add-person-dialog';

import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { fetchCompanyPersons, postCompanyPerson, removeCompanyPerson } from 'shared/api/company-persons';
import { useEffect, useState } from 'react';
import { CompanyEmployeeDto } from '@sr/dto';
import { CardPaperTitled } from 'shared/ui/card-paper';

const CompanyPersons = (props: { companyId: number; }) => {
  const { companyId } = props;

  const [isAddLinkOpen, setAddLinkOpen] = useState(false);
  const [links, setLinks] = useState<CompanyEmployeeDto[]>([]);

  const { showSuccess, showError } = useSnack();
  const showConfirmDeleteDialog = useConfirmDialog();

  useEffect(() => {
    //setLoading(true);
    refetchPersonsLinks();
  }, [companyId]);

  const refetchPersonsLinks = () => {
    fetchCompanyPersons(companyId)
      .then(result => {
        setLinks(result);
      })
      .catch(error => showError(`Ошибка получения сотрудников компании: ${error.message}`));
  };

  const handleAddClick = () => {
    setAddLinkOpen(true);
  };

  const handleAddLinkClose = () => {
    setAddLinkOpen(false);
  };

  const handleSubmit = async (formData: FormData) => {
    //setAddLinkOpen(false);

    postCompanyPerson(companyId, {
      person: {
        firstName: formData.firstName,
        secondName: formData.secondName,
        middleName: formData.middleName
      },
      position: formData.position
    })
      .then((newLink: CompanyEmployeeDto) => {
        showSuccess('Сотрудник успешно добавлен');
        setLinks([...links, newLink]);
        setAddLinkOpen(false);
      })
      .catch(e => {
        showError(`Ошибка добавления сотрудника к фирме: ${e.message}`);
      });

  };

  const handleAddLinkFailed = (msg: string) => {
    setAddLinkOpen(false);
    showError(msg);
  };

  const handleDeleteLink = async (linkId: CompanyEmployeeDto) => {
    return showConfirmDeleteDialog({
      content: 'Удалить контакт?'
    })
      .then(() => {
        removeCompanyPerson(linkId.id)
        setLinks(links.filter(link => link.id !== linkId.id));
        showSuccess('Связь успешно удалена');
      });
  };


  return (
    <>
      <CardPaperTitled title="Сотрудники">
        <Box mt={1}>
          <PersonsList list={links} onLinkDelete={handleDeleteLink} companyId={companyId} />
        </Box>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button size='small' startIcon={<AddIcon />} onClick={handleAddClick}>Добавить</Button>
        </Box>
      </CardPaperTitled >

      <AddPersonDialog
        open={isAddLinkOpen}
        onClose={handleAddLinkClose}
        submit={handleSubmit}
      />
    </>
  );
};

export default CompanyPersons;
