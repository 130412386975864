import { authorizedRequest } from 'shared/api/axios';
import { CreateSignerDto, SignerDto, UpdateSignerDto } from '@sr/dto';

const SIGNERS_BASE_URL = '/signers';

export const getSignersList = async (legalEntityId: number) => {
  return authorizedRequest<void, SignerDto[]>(
    `${SIGNERS_BASE_URL}?legalEntityId=${legalEntityId}`,
    'GET'
  );
};

export const postSigner = async (data: CreateSignerDto) => {
  return authorizedRequest<CreateSignerDto, SignerDto>(
    SIGNERS_BASE_URL,
    'POST',
    data
  );
};

export const putSigner = async (data: UpdateSignerDto) => {
  return authorizedRequest<UpdateSignerDto, SignerDto>(
    `${SIGNERS_BASE_URL}`,
    'PUT',
    data
  );
};

export const deleteSigner = async (id: number) => {
  return authorizedRequest<void, void>(
    `${SIGNERS_BASE_URL}/${id}`,
    'DELETE'
  );
};