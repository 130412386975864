import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { InvoiceInfoDto } from '@sr/dto';
import { useSupplierInvoices } from 'entities/client-invoices-list/lib/supplier-invoices-list.hook';
import { Heading } from 'shared/ui/list-page-heading';
import { SupplierInvoicesList } from 'entities/invoice/list';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useSearchParams } from 'react-router-dom';

const SuppliersInvoicesListPage = () => {
  useTitleHook('Входящие счета');

  const [paging, setPaging] = usePagingParams();
  const [searchParams] = useSearchParams();
  const { collection, /*addInvoice*/ } = useSupplierInvoices(paging, undefined, searchParams.toString());

  const showConfirmDialog = useConfirmDialog();

  const handleConfirmDelete = (invoice: InvoiceInfoDto) => {
    if (!invoice)
      return;
  };

  const handleDeleteInvoice = (invoice: InvoiceInfoDto) => {
    showConfirmDialog({
      content: 'Удалить счет?'
    })
      .then(() => handleConfirmDelete(invoice));
  };
  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Входящие счета" />

      <SupplierInvoicesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onDelete={handleDeleteInvoice}
      />
    </>
  );
};

export default SuppliersInvoicesListPage;
