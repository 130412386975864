import { useMemo } from 'react';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { Paper } from '@mui/material';
import { BulkCreateTab } from 'pages/wcc/tabs/bulk-create-tab';
import { NavTabInfo, NavTabs } from 'shared/ui/nav-tabs';
import { ClientWccListTab } from './tabs/client-wcc-tab';
import { ExportWccTab } from './tabs/export-wcc-tab';


const WccListPage = () => {
  useTitleHook('Акты выполненных работ');

  const tabInfos: NavTabInfo[] = useMemo(() =>
    [
      {
        path: 'bulk-create',
        label: 'Создание',
        icon: <StorageIcon />,
        component: <BulkCreateTab />
      },
      {
        path: 'export',
        label: 'Экспорт',
        icon: <AccountBalanceIcon />,
        component: <ExportWccTab />,
      },
      {
        path: 'supplier',
        label: 'Входящие',
        icon: <AccountBalanceIcon />,
        component: <></>,
        disabled: true
      },
      {
        path: 'client',
        label: 'Исходящие',
        icon: <AccountBalanceIcon />,
        component: <ClientWccListTab />,
      },
    ], []);

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Акты выполненных работ" />

      <Paper sx={{ px: 1 }}>
        <NavTabs tabs={tabInfos} />
      </Paper>
    </>
  );
};

export default WccListPage;
