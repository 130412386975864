import { Typography, TypographyProps } from '@mui/material';
import { splitHighlight } from 'utils/split-highlight';

export const TextWithHighlight = ({ hlText, children, ...rest }: TypographyProps & { hlText: string }) => {
  if (typeof children === 'string') {
    const parts = splitHighlight(children, hlText);
    return (
      <>
        {parts.map((x, idx) =>
          <Typography
            {...rest}
            key={idx}
            style={{ backgroundColor: x.hl ? 'yellow' : 'inherit' }}
          >
            {x.text}
          </Typography>)}
      </>);
  }

  return (
    <>{children}</>
  );
};



