import { emptyPagedCollection, InvoiceInfo, IPagedCollection } from '@sr/dto';
import { AsyncMeta, initialMeta } from 'shared/api/async-meta';

export type State = {
  invoicesList: IPagedCollection<InvoiceInfo>;
  meta: AsyncMeta;
};

export const initialState: State = {
  invoicesList: emptyPagedCollection,
  meta: initialMeta
};
