import { CityInfoDto, emptyPagedCollection, IPagingData } from '@sr/dto';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { cityListApi } from './apiSlice';

export const useCitiesList = (paging: IPagingData) => {

  const { data: cities, isLoading, error } = cityListApi.useGetCitiesListQuery({ paging });

  const collection: IRemotePagedCollection<CityInfoDto> = {
    collection: cities || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };

  return collection;
};
