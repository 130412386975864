import React, { useMemo, useState } from 'react';
import { NavTabInfo, NavTabs } from 'shared/ui/nav-tabs';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import HailIcon from '@mui/icons-material/Hail';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import AddIcon from '@mui/icons-material/Add';
import { useSnack } from 'shared/ui/snack';
import { addCompany } from 'hooks/companies.hook';
import { CompanyQueryType, CreateCompanyDto } from '@sr/dto';
import { Button } from '@mui/material';
import { AddCompanyDialog } from 'components/Companies/CompanyDialog/company-dialog.add';
import { Heading } from 'shared/ui/list-page-heading';
import { useNavigate } from 'react-router-dom';
import { companyLinkManager } from 'utils/link-manager';
import { ClientsListTab } from '../../features/company/client-list-tab';

const ClientsListPage = () => {
  const heading = 'Клиенты';

  const [isAddCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);

  const { showSuccess, showError } = useSnack();
  const navigate = useNavigate();

  const handleAddClick = () => {
    setAddCompanyDialogOpen(true);
  };

  const handleAddCompany = async (companyData: CreateCompanyDto) => {
    return addCompany(companyData)
      .then((newCompany) => {
        showSuccess('Компания успешно добавлена');
        navigate(companyLinkManager.link(newCompany.id));
      })
      .catch(error => showError('Ошибка добавления компании: ' + error.message));
  };

  const tabInfos: NavTabInfo[] = useMemo(() =>
    [
      {
        path: 'list-client-all',
        label: 'Все',
        icon: <Diversity3Icon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_ALL}/>,
      },
      {
        path: 'list-client-department',
        label: 'Моего отдела',
        icon: <Diversity1Icon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_DEPARTMENT}/>,
        disabled: true
      },
      {
        path: 'list-client-my',
        label: 'Мои',
        icon: <AccessibilityIcon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_MY}/>
      },
      {
        path: 'list-client-checks',
        label: 'Свободные со счетами',
        icon: <HailIcon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_FREE_WITH_INVOICES}/>
      },
      {
        path: 'list-client-lids',
        label: 'Свободные с лидами',
        icon: <BabyChangingStationIcon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_WITH_LEADS}/>
      },
      {
        path: 'list-client-free',
        label: 'Все свободные',
        icon: <Diversity2Icon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_FREE}/>
      },
      {
        path: 'list-client-fired',
        label: 'Уволенных менеджеров',
        icon: <GroupRemoveIcon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_ON_FIRED_MANAGERS}/>
      },
      {
        path: 'list-client-detachment',
        label: 'Открепление',
        icon: <AssistWalkerIcon />,
        component: <ClientsListTab queryType={CompanyQueryType.CLIENTS_TO_DETACH}/>,
      },
    ],
  []);

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title={heading}
        actions={
          <Button
            variant="contained"
            startIcon={<AddIcon/>}
            onClick={handleAddClick}
          >
                        Добавить
          </Button>}
      />
      <NavTabs tabs={tabInfos}/>
      <AddCompanyDialog
        open={isAddCompanyDialogOpen}
        onSubmit={handleAddCompany}
        onClose={() => setAddCompanyDialogOpen(false)}
      />
    </>
  );
};

export default ClientsListPage;
