
export type WccStatus = 1 | 2 | 3 | 4 | 5;

export const WCC_STATUS_READY = 1;

export const WCC_STATUSES: { [key in WccStatus]: string } = {
  1: 'Готов',
  2: 'Отправлен',
  3: 'Загружен скан',
  4: 'Подтвержден скан',
  5: 'Получен оригинал',
}

export const WCC_STATUSES_KEYS = Object.keys(WCC_STATUSES).map(key => Number(key) as WccStatus);