import { Link, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Box, Tab, Tabs } from '@mui/material';
import { PublishingDataForm } from 'features/publishing-data/publishing-data.form';
import CountryHome from 'entities/geo/countries/country-home';
import useCountry from 'entities/geo/countries/country.hook';
import { PublishingDataRelations } from 'entities/publishing-data/publishing-data.hooks';
import { PageHeader } from 'shared/ui/details-page-header';

const tabInfos = {
  'home': {
    label: 'Основное',
    icon: <StorageIcon />,
    disabled: false
  },
  'publishing': {
    label: 'Публикация на сайте',
    icon: <AccountBalanceIcon />,
    disabled: false
  },
};

export const CountryDetailsPage = () => {
  const { countryId: countryIdParam } = useParams<{ countryId: string }>();
  const countryId = Number(countryIdParam);
  const { country } = useCountry(countryId);
  if (!country) {
    return <>Нет страны</>;
  }


  return (
    <>
      <PageHeader title={<PageHeader.Text>{country?.name ?? ''}</PageHeader.Text>} sx={{ mt: 1, mb: 0 }} />
      <CountryTabs/>
      <Routes>
        <Route element={<Outlet />}>
          <Route path='home' element={<CountryHome countryId={countryId} />} />
          <Route path='publishing' element={
            <PublishingDataForm id={country.publishingDataId}
              relatedType={PublishingDataRelations.country}
              relatedId={countryId}/>
          }/>
        </Route>
      </Routes>


    </>
  );
};


const CountryTabs = () => {
  const tabs = Object.entries(tabInfos);
  const location = useLocation();
  const tabName = location.pathname.split('/').at(-1);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
      <Tabs value={tabName}>
        {tabs.map(([route, info], i) => {
          return <Tab
            key={i}
            label={info.label}
            value={route}
            iconPosition="start"
            icon={info.icon}
            to={route}
            component={Link}
            disabled={info.disabled} />;
        })}
      </Tabs>
    </Box>
  );
};

export default CountryDetailsPage;
