import { Link } from 'shared/ui/link-base';
import { RegionInfoDto } from '@sr/dto';
import { geoLinkManager } from 'utils/link-manager';

export const LinkToRegion = ({ region: { id, name } }: { region: RegionInfoDto }) => {
  const link = geoLinkManager.linkToRegion(id);
  return (
    <Link to={link} >
      {name}
    </Link>
  );
}