import { MouseEvent } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { formatDate } from 'utils/date-format-helpers';
import { IManagerLink, LinkType } from '@sr/dto';

export const linkTypes: { name: string, status: string, slug: LinkType; }[] = [
  { name: 'Закрепить', status: 'закреплен', slug: 'link' },
  { name: 'Заблокировать', status: 'блок', slug: 'block' }
];

type Props = {
  link: IManagerLink,
  onMenuClick: (anchor: HTMLElement) => void;
};

const LinkedManagerItem = ({ link, onMenuClick }: Props) => {
  const { manager, linkType, endDate } = link;

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    onMenuClick(event.currentTarget);
  };

  const managerName = `${manager.firstName} ${manager.secondName}`;
  const endDateFormatted = formatDate(new Date(endDate));

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton edge="end" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>}
      >
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={managerName}
          secondary={`${linkTypes.find(
            el => el.slug === linkType)?.status} до ${endDateFormatted}`}
        />
      </ListItem>
    </>
  );
};

export default LinkedManagerItem;
