import { createSlice } from '@reduxjs/toolkit';

type State = {
  isDupesCheckInProgress: boolean;
};

const initialState: State = {
  isDupesCheckInProgress: false,
};

const slice = createSlice({
  name: 'ui/dupes',
  initialState: initialState,
  reducers: {
    startDupesCheck: (state) => {
      state.isDupesCheckInProgress = true;
    },
    finishDupesCheck: (state) => {
      state.isDupesCheckInProgress = false;
    },
  },
});

export const { reducer: dupesReducer, actions: dupesActions } = slice;
