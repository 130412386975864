
import { Box, Button, Stack } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useSnack } from 'shared/ui/snack';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { CardPaper } from 'shared/ui/card-paper';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { userPersonalDataApi } from './api';
import { FormValues, initialValues } from './form';


export const UserPersonalData = ({ userId }: { userId: number }) => {
  const { data, isLoading, error } = userPersonalDataApi.useGetUserPersonalDataQuery(userId, { refetchOnMountOrArgChange: true });
  const [updateData] = userPersonalDataApi.useUpdateUserPersonalDataMutation();

  const { showSuccess, showError } = useSnack();

  if (isLoading) return <LoadingBanner />;

  if (error)
    return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />;


  const handelSubmit = async (formValues: FormValues) => {
    return updateData({ userId, data: formValues })
      .unwrap()
      .then(() => showSuccess('Персональные данные успешно обновлены'))
      .catch(e => showError('Ошибка обновления персональных данных', e));
  };

  return (
    <CardPaper>
      <Box mt={1}>
        <Formik
          initialValues={initialValues(data)}
          onSubmit={handelSubmit}
          enableReinitialize
        >
          {({ submitForm, dirty }) => (
            <>
              <Stack direction="column" spacing={2}>
                <Field
                  name="dob"
                  label="Дата рождения"
                  fullWidth
                  component={DatePickerField} />
                <Field
                  name="passportData"
                  label="Паспортные данные"
                  placeholder="серия номер, кем выдан, дата выдачи"
                  fullWidth
                  multiline
                  rows={6}
                  component={TextField} />
                <Field
                  name="residenceAddress"
                  label="Адрес прописки"
                  placeholder="страна, город, улица, дом, квартира"
                  fullWidth
                  multiline
                  rows={6}
                  component={TextField} />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant='contained'
                    onClick={submitForm}
                    disabled={!dirty}
                  >
                    сохранить
                  </Button>
                </Box>
              </Stack>
            </>
          )}
        </Formik>
      </Box>
    </CardPaper>
  );
};
