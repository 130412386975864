import { WccDto } from '@sr/dto';
import { PageHeader } from 'shared/ui/details-page-header';
import { formatDate } from 'utils/date-format-helpers';

export const WccTitle = ({ wcc }: { wcc: WccDto; }) => {
  const wccNumber = `№ ${wcc.wccNumber} от ${formatDate(wcc.startDate)}`;
  return (
    <PageHeader.Text>
      Акт выполненных работ {<PageHeader.Text.Stressed>{wccNumber}</PageHeader.Text.Stressed>} по счету {<PageHeader.Text.Stressed> {wcc.invoice.invoiceNumber}</PageHeader.Text.Stressed>}
    </PageHeader.Text>
  );
};
