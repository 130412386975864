import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Tabs, Tab, Box, Typography, Grid, Stack, Paper } from '@mui/material';
import { PageHeader } from 'shared/ui/details-page-header';
import { ErrorBanner } from 'shared/ui/error-banner';
import { useLegalEntity } from 'entities/legal-entities/legal-entities.hook';
import { LegalEntityBasicInfo } from 'entities/legal-entities/details/legal-entity-basic-info';
import { LegalEntityLinkedCompanies } from 'entities/legal-entities/details/legal-entity-linked-companies';
import { LegalEntitySigners } from 'entities/legal-entities/details/legal-entity-signers';
import { LegalEntityBankDetails } from 'entities/legal-entities/details/legal-entity-bank-details';
import { OurLegalEntitySettings } from 'entities/legal-entities/our-legal-entities/our-legal-entity';
import { TabPanel } from 'shared/ui/tab-panel';
import {
  LegalEntityActiveStatus
} from 'entities/legal-entities/active-status/legal-entity-active-status';

export const LegalEntityDetailsPage = () => {
  const { legalEntityId: legalEntityIdStr } = useParams<{ legalEntityId: string; }>();
  const legalEntityId = Number(legalEntityIdStr);

  const { legalEntity, error } = useLegalEntity(legalEntityId);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  if (error)
    return <ErrorBanner errorMessage={error} />;

  return (
    <>
      <PageHeader
        title={
          <>
            <Typography variant="h5" color="primary">{legalEntity?.name}</Typography>
            <Typography component="span" variant='subtitle2' color="grey.500">
              {legalEntity?.legalEntityType.description}
            </Typography>
          </>
        }
        subHeader={legalEntity && <LegalEntityActiveStatus legalEntityId={legalEntity.id}/>}
        sx={{ mt: 1, mb: 2 }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack direction='column' spacing={2}>
            <LegalEntityBasicInfo legalEntityId={legalEntityId} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 1 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
              <Tabs value={value} onChange={handleChange} >
                <Tab label="Реквизиты" />
                <Tab label="Подписанты" />
                <Tab label="Фирмы" />
                <Tab label="Аудит" />
                {!!legalEntity?.isOurLegalEntity && <Tab label="Наше юрлицо" />}
              </Tabs>
            </Box>
            <Box>
              <TabPanel value={value} index={0}>
                <LegalEntityBankDetails legalEntityId={legalEntityId} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <LegalEntitySigners legalEntityId={legalEntityId} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <LegalEntityLinkedCompanies legalEntityId={legalEntityId} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                Аудит. TO DO
              </TabPanel>
              <TabPanel value={value} index={4}>
                {!!legalEntity?.isOurLegalEntity && <OurLegalEntitySettings legalEntityId={legalEntityId}/>}
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default LegalEntityDetailsPage;
