import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { validate, initialValues, FormValues } from './region-form';
import { RegionFormFields } from './region-form-fields';

export interface DialogProps<T> {
  open: boolean;
  onClose: () => void;
  onSubmit: (newCompanyData: T) => Promise<void>;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
}

export const AddRegionDialog = (props: Props) => {
  const { open, onClose, onSubmit } = props;

  const handleClose = () => {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='lg'>
      <DialogTitle>Новый регион</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {(p: FormikProps<FormValues>) => (<>
            <Form>
              <RegionFormFields  {...p} />
            </Form>
            <DialogActions>
              <Button variant="contained"
                autoFocus
                onClick={p.submitForm}
                disabled={p.isSubmitting}>
                {p.isSubmitting ? 'Подождите...' : 'Сохранить'}
              </Button>
              <Button variant="outlined"
                onClick={onClose}
                disabled={p.isSubmitting}>
                Закрыть
              </Button>
            </DialogActions>
          </>
          )}
        </Formik>

      </DialogContent>
    </Dialog>);
}