import { UpdateUserCompanyDataDto, UserCompanyDataDto } from '@sr/dto';
import { toOptionalNumberField } from 'utils/form-helper';

export type FormValues = {
  position: string;
  baseSalary: number | '';
  salesPlan: number | '';
  supervisorId: number;
}


export const initialValuesFromDto = (dto?: UserCompanyDataDto): FormValues => (
  dto
    ? { ...dto }
    : {
      baseSalary: '',
      salesPlan: '',
      position: '',
      supervisorId: NaN,
    });

export const toDto = (formValues: FormValues): UpdateUserCompanyDataDto => ({
  baseSalary: toOptionalNumberField(formValues.baseSalary),
  salesPlan: toOptionalNumberField(formValues.salesPlan),
  position: formValues.position,
  supervisorId: formValues.supervisorId,
})

