export interface FormData {
    companies: number[];
    media: number[];
    priceFile: File | null;
    extraFiles?: File[]
}

export const emptyValues: FormData = {
  companies: [],
  media: [],
  priceFile: null
};