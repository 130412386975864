import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InvoiceInfo } from '@sr/dto';
import { useClientInvoices } from 'entities/client-invoices-list/lib/client-invoices-list.hook';
import { AddClientInvoiceDialog } from 'features/invoice/create-client-invoice/add-client-invoice-dialog';
import { FormData, formTools } from 'features/invoice/create-client-invoice/client-invoice.form-tools';
import { InvoicesList } from 'entities/invoice/list';
import { useState } from 'react';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { useAddInvoice } from 'entities/invoice/invoice.hook';
import { useSearchParams } from 'react-router-dom';
import { usePagingParams } from 'shared/hooks/paging-params';

type Props = {
  companyId: number;
};

export const CompanyClientInvoices = ({ companyId }: Props) => {
  const [ paging, setPaging ] = usePagingParams();
  const [ searchParams ] = useSearchParams();

  const { collection, deleteInvoice } = useClientInvoices(paging, searchParams.toString(), companyId);
  const addInvoice = useAddInvoice();
  const ourCompanyId = useOurCompany();

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { showError, showSuccess } = useSnack();
  const showConfirm = useConfirmDialog();

  const handleAddInvoice = async (data: FormData) => {

    const dto = formTools.toCreateDto(data);
    dto.buyerCompanyId = companyId;
    dto.sellerCompanyId = ourCompanyId;
    addInvoice(dto)
      .then(() => {
        showSuccess('Счет успешно добавлен');
        setAddDialogOpen(false);
        setPaging({ ...paging });
      })
      .catch(e => showError(`Ошибка добавления счета: ${e.data.message}`));
  };

  const handelDeleteInvoice = async (invoice: InvoiceInfo) =>
    showConfirm({ content: 'Удалить счет?' })
      .then(() => deleteInvoice(invoice.id))
      .catch(e => showError(`Ошибка удаления счета: ${e.message}`));

  return (
    <>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setAddDialogOpen(true)}
      >
        Выставить счет
      </Button>

      <InvoicesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onDelete={handelDeleteInvoice}
      />

      <AddClientInvoiceDialog
        open={addDialogOpen}
        clientCompanyId={companyId}
        ourCompanyId={ourCompanyId}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleAddInvoice} />
    </>);
};
