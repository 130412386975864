import { MouseEvent } from 'react';
import MenuParams from 'shared/ui/menu-params';
import {
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Chip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { LinkToUser } from 'shared/ui/links/link-to-user';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useUsersList } from './users-list.hook';
import { useSearchParams } from 'react-router-dom';

const columns = ['', 'Имя', 'Должность', 'Активен', 'Работает', 'Телефон', 'Email', 'День рождения', ''];

export const UsersList = () => {
  const { openMenu, menuData } = useMenu<UserDto>({ deleteHandler: () => { } });
  const [ paging, setPaging ] = usePagingParams();
  const [searchParams] = useSearchParams();

  const { collection, isLoading, error } = useUsersList(paging, searchParams);

  return (
    <>
      <GridLayout
        columns={columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        pagingData={paging}
        onChangePaging={setPaging}
        totalItemsCount={collection.totalItemsCount}
        noItemsText='Список пользователей пуст'
        itemRender={(i) => <UserRow
          key={i.id}
          user={i}
          onMenuClick={openMenu} />}
      />
      <RowMenu menuData={menuData} />
    </>);
};

type RowProps = {
  user: UserDto,
  onMenuClick: (menuParams: MenuParams<UserDto>) => void;
};

export const UserRow = ({ user, onMenuClick }: RowProps) => {
  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: user });
  };

  return (
    <>
      <TableRow key={user.id}>
        <TableCell >
          <Avatar></Avatar>
        </TableCell>
        <TableCell>
          <LinkToUser user={user} />
        </TableCell>
        <TableCell >
          {user.position}
        </TableCell>
        <TableCell padding='checkbox' align='left'>
          {user.isActive
            ? <Chip size='small' color="primary" label="Активен" variant="filled" />
            : <Chip size='small' color="default" label="Неактивен" variant="filled" />}
        </TableCell>
        <TableCell padding='checkbox'>
          {user.isFired
            ? <Chip size='small' color="error" label="Уволен" variant="outlined" />
            : <Chip size='small' color="primary" label="Работает" variant="outlined" />}
        </TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};



