import { UpdateLeadPropsDto } from '@sr/dto';
import { leadApi } from '../api';

export const useLeadProps = (leadId: number) => {
  const [update, { isLoading }] = leadApi.useUpdateLeadMutation();

  return {
    update: (data: UpdateLeadPropsDto) => update(data).unwrap(),
    isLoading,
  };
};
