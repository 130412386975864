import { ContactDupesDto } from '@sr/dto';
import { companiesApi } from 'entities/company/api.slice';
import { DupesDisplayOptions } from 'entities/contact-dupes';
import { useNavigate } from 'react-router-dom';

export const useNavigateToCombinePage = (ids: number[]) => {
  const navigate = useNavigate();

  return () => {
    navigate(`/combine-companies?companies=${ids.join(',')}`);
  };
};

export const extractCompaniesFromDupes = (
  dupes: ContactDupesDto,
  options?: DupesDisplayOptions
) => {
  const dupesCompaniesIds = [
    ...dupes.dupesCompanies.map((c) => c.id),
    ...dupes.dupesEmployees.flatMap((e) => e.companies.map((c) => c.id)),
    // добавляем компании, которые исключены для показа дублей, однако, для объединения, они, очевидно, нужны
    ...(options?.excludeLists?.company ?? []),
  ];

  return Array.from(new Set(dupesCompaniesIds));
};
