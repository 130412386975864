import { useLeadStatuses } from 'entities/lead/lead-status';
import { LeadStatusDto } from '@sr/dto';
import { withRemoteCollectionDropDownPicker } from 'shared/ui/form-fields/with-remote-collection-dropdown';


export const LeadStatusPicker = withRemoteCollectionDropDownPicker<LeadStatusDto, number>({
  collectionProvider: useLeadStatuses,
  renderDropdownLabel: (item: LeadStatusDto | '') => typeof item === 'string' ? item : item.label,
  renderEmptyValue: <>&nbsp;</>
});
