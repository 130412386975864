import { Autocomplete, Box, TextField } from '@mui/material';
import { PURCHASE_STATUS_DRAFT_ID, PURCHASE_STATUS_NEW_ID, PurchaseDto } from '@sr/dto';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useUnlinkedPurchases } from 'hooks/purchases-list.hook';
import PriceSpan from 'shared/ui/price-span';

export const PurchasePicker = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
  } = props;

  const [open, setOpen] = useState(false);
  const {
    items: purchases,
    isLoading: fetching
  } = useUnlinkedPurchases([PURCHASE_STATUS_DRAFT_ID, PURCHASE_STATUS_NEW_ID]);
  const [selectedEntity, setSelectedEntity] = useState<PurchaseDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: PurchaseDto | null) => {
      setSelectedEntity(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name, setSelectedEntity]
  );

  useEffect(() => {
    setSelectedEntity(purchases.find(x => x.id === value) ?? null);
  }, [purchases, value]);


  const loading = open && purchases.length === 0 && fetching;

  return (
    <Autocomplete
      value={selectedEntity}
      autoComplete={false}
      options={purchases}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.clientInvoice.invoiceNumber} |
        ${option.clientInvoiceRow.description} ${option.clientInvoiceRow.media?.name ? `(${option.clientInvoiceRow.media?.name})` : ''} |
        ${option.clientInvoice.totals.discountPriceTotal}`
      }
      // renderValue={(props, value) => getOptionLabel(value)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.id} :
          {option.clientInvoice.invoiceNumber} |
          {option.clientInvoiceRow.description} {option.clientInvoiceRow.media?.name ? `(${option.clientInvoiceRow.media?.name})` : ''}
          |
          <PriceSpan price={option.clientInvoice.totals.discountPriceTotal} />
        </Box>
      )}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};


