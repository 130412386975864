import {
  ListItemText,
  IconButton,
  ListItemIcon,
  ListItem,
  Stack,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactNode } from 'react';


type Props = {
  icon: ReactNode;
  contactText: ReactNode;
  dupesIndicator: ReactNode;
  onDelete: () => void;
}

export const ContactListItem = ({ icon, contactText, onDelete, dupesIndicator }: Props) => {

  return (
    <>
      <ListItem secondaryAction={
        <Stack direction="row" alignItems='center'>
          {dupesIndicator}

          <IconButton edge="end" size='small' onClick={onDelete}>
            <ClearIcon />
          </IconButton>
        </Stack>}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText>
          {contactText}
        </ListItemText>
      </ListItem>
    </>
  );
};


