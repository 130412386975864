import { Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Card, IconButton, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CashflowDto, ImportedPayorderDto, ImportedPayorderProps, PayOrderGroup, UpdateImportedPayorderDto } from '@sr/dto';
import { useUpdateSelected as useUpdateSelectedState } from 'hooks/payorders-import.hook';
import { useRef, useState } from 'react';
import { MoreMenu, useMoreMenu } from 'shared/ui/more-menu';
import { formatDate } from 'utils/date-format-helpers';
import { useCashflowsDropdown } from 'hooks/cashflows.hook';
import { IRemoteCollection } from 'utils/remote-collection.hook';
import { useSnack } from 'shared/ui/snack';
import PriceSpan from 'shared/ui/price-span';
import { RowStatusInfoCell } from './cell-status';
import { InvoiceCellContent } from './cell-invoices';
import { CashflowCellContent } from './cell-cashflow';
import { GroupCellContent } from './cell-group';

const columns = [
  'Дата / Номер документа',
  'Плательщик',
  'Получатель',
  'Группа',
  'Назначение платежа',
  'Счет',
  'Статья движения средств',
  'Сумма',
];


type Props = {
  sessionId: number,
  rows: ImportedPayorderDto[],
  reload: () => void,
  onUpdateRow: (data: UpdateImportedPayorderDto) => Promise<void>;
};

export const ImportedPayordersList = ({ sessionId, rows, reload, onUpdateRow }: Props) => {
  const { showError } = useSnack();
  const { updateAllState } = useUpdateSelectedState();

  const cashflowsDropDownList = useCashflowsDropdown();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const { openMenu, menuData } = useMoreMenu([{
    name: 'Выделить всё',
    icon: <CheckBoxIcon />,
    onClick: () => updateAllState(sessionId, true).then(reload).catch(e => showError('Ошибка: ' + e.message))
  },
  {
    name: 'Cнять всё',
    icon: <CheckBoxOutlineBlankIcon />,
    onClick: () => updateAllState(sessionId, false).then(reload).catch(e => showError('Ошибка: ' + e.message))
  }]);

  const handleSelectAllClick = () => {
    openMenu(anchorRef.current!);
  };

  return (
    <>
      <Card>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' width="40px">
                <IconButton
                  color='primary'
                  ref={anchorRef}
                  // variant='outlined'
                  // size="small"
                  onClick={handleSelectAllClick}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </TableCell>
              {columns.map((column, idx) => <TableCell key={idx}>{column}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row =>
              <ImportedRow
                key={row.id}
                importedPayorder={row}
                onChange={onUpdateRow}
                cashflowsDropDownList={cashflowsDropDownList}
              />)}
          </TableBody>
        </Table>

      </Card>

      <MoreMenu {...menuData} />
    </>
  );
};


type RowProps = {
  importedPayorder: ImportedPayorderDto;
  onChange: (data: UpdateImportedPayorderDto) => Promise<void>;
  cashflowsDropDownList: IRemoteCollection<CashflowDto>;
};

const ImportedRow = ({ importedPayorder, onChange, cashflowsDropDownList }: RowProps) => {
  const isRowValid = !importedPayorder.invalidReasons;
  const [disabled, setDisabled] = useState(false);

  const handleCheckToggle = async (_: any, checked: boolean) => {
    setDisabled(true);
    const newData: UpdateImportedPayorderDto = {
      id: importedPayorder.id,
      ...importedPayorder.importProps,
      selected: checked
    };
    await onChange(newData);
    setDisabled(false);
  };

  const handleInvoiceChange = async (invoiceId: number | null) => {
    setDisabled(true);
    const newData: UpdateImportedPayorderDto = {
      id: importedPayorder.id,
      ...importedPayorder.importProps,
      selectedInvoiceId: invoiceId
    };

    await onChange(newData);
    setDisabled(false);
  };

  const handleCashflowChange = async (cashflowId: number | null) => {
    setDisabled(true);
    const newData: UpdateImportedPayorderDto = {
      id: importedPayorder.id,
      ...importedPayorder.importProps,
      selectedCashflowId: cashflowId
    };

    await onChange(newData);
    setDisabled(false);
  };

  const handleGroupChange = async (group: PayOrderGroup | null) => {
    setDisabled(true);
    const newData: UpdateImportedPayorderDto = {
      id: importedPayorder.id,
      ...importedPayorder.importProps,
      selectedGroup: group
    };

    await onChange(newData);
    setDisabled(false);
  };

  const isDisabled = (props: ImportedPayorderProps) =>
    !(props.selectedCashflowId && props.selectedGroup && props.selectedInvoiceId);


  return (
    <TableRow sx={!isRowValid ? { bgcolor: 'grey.200' } : undefined}>
      <TableCell align='center' width="40px">
        {isRowValid
          ?
          <Checkbox
            checked={importedPayorder.importProps.selected}
            disabled={isDisabled(importedPayorder.importProps) || disabled}
            onChange={handleCheckToggle} />
          :
          <RowStatusInfoCell reasons={importedPayorder.invalidReasons} />
        }
      </TableCell>
      <TableCell>
        <div> {formatDate(importedPayorder.paymentDate)} </div>
        <div> {importedPayorder.documentNumber} </div>
      </TableCell>
      <TableCell>
        <div>{importedPayorder.payerName}</div>
        <Typography variant="inherit" color="grey.500">{importedPayorder.payerInn}</Typography>
      </TableCell>
      <TableCell>
        {importedPayorder.recieverName}
        <Typography variant="inherit" color="grey.500">{importedPayorder.recieverInn}</Typography>
      </TableCell>
      <TableCell>
        {isRowValid && <GroupCellContent importedPayorder={importedPayorder} onChange={handleGroupChange} />}
      </TableCell>
      <TableCell>
        {importedPayorder.paymentDescription}
      </TableCell>
      <TableCell>
        {isRowValid && <InvoiceCellContent importedPayorder={importedPayorder} onChange={handleInvoiceChange} />}
      </TableCell>
      <TableCell>
        {isRowValid && <CashflowCellContent
          importedPayorder={importedPayorder}
          onChange={handleCashflowChange}
          cashflowsDropDown={cashflowsDropDownList} />}
      </TableCell>
      <TableCell>
        <PriceSpan size="small" price={importedPayorder.paymentAmount} />
      </TableCell>
    </TableRow>
  );
};
