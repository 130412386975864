import { Box, Button } from '@mui/material'
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { useActionCreators, useStateSelector } from 'shared/store';
import { wccExportActions } from '../export-list/store-slice';

export const ExportButton = () => {

  const checkedItems = useStateSelector(state => state.wcc.export.checkedItems);
  const { openDialog } = useActionCreators(wccExportActions);

  return (
    <Box my={2}>
      <Button
        variant="contained"
        startIcon={<UpgradeIcon />}
        onClick={() => { openDialog() }}
        disabled={checkedItems.length === 0}
      >
        экспортировать...
      </Button>
    </Box>
  )
}