import { useState, useEffect } from 'react';
import { fetchRegionDetails, putRegion } from 'shared/api/regions';
import { useSnack } from 'shared/ui/snack';
import { RegionInfoDto, UpdateRegionDto } from '@sr/dto';

const useRegion = (regionId: number) => {
  const { showError, showSuccess } = useSnack();
  const [region, setRegion] = useState<RegionInfoDto | null>(null);

  useEffect(() => {
    fetchRegionDetails(regionId)
      .then(media => setRegion(media))
      .catch(error => showError(`Ошибка получения данных о регионе: ${error.message}`));
  }, [regionId]);

  const update = async (region: UpdateRegionDto) => {
    return putRegion(regionId, region)
      .then((updated) => {
        setRegion(updated);
        showSuccess('Данные о регионе успешно обновлены');
      })
      .catch((error) => showError(`Ошибка обновления данных о регионе: ${error.message}`));
  };

  return {
    region,
    update
  };
};

export default useRegion;