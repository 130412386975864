import { SearchRequestFilter, SearchResultTitle, SearchResults } from 'entities/search';
import { useTitleHook } from 'hooks/page-title.hook';
import { useSearchParams } from 'react-router-dom';

const SearchResultPage = () => {
  const [queryParams] = useSearchParams();
  const querySubstring = queryParams.get('query') || '';
  useTitleHook(`Результаты поиска: '${querySubstring}'`);

  return (
    <>
      <SearchRequestFilter />
      <SearchResultTitle query={querySubstring} />
      <SearchResults query={querySubstring} />
    </>
  );
};

export default SearchResultPage;

