import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAddSalaryEntryDialogOpened: false
};

export const salaryDialog = createSlice({
  name: 'salary_dialog',
  initialState: initialState,
  reducers: {
    openDialog(state) {
      state.isAddSalaryEntryDialogOpened = true;
    },
    closeDialog(state) {
      state.isAddSalaryEntryDialogOpened = false;
    }
  },
}
);

export const { reducer: salaryDialogReducer, actions: salaryDialogActions } = salaryDialog;
