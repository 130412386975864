import { Stack, Card, CardHeader, CardContent, Divider, Button, } from '@mui/material';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { InvoiceDto, InvoiceRowDto } from '@sr/dto';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { InvoiceStatusSpan } from 'entities/invoice/status-span/status-span';
import PriceSpan from 'shared/ui/price-span';
import { LegendItem } from 'shared/ui/legend-list';
import { AddSupplierInvoiceDialog } from 'features/invoice/create-supplier-invoice/add-supplier-invoice-dialog';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { FormData, formTools } from 'features/invoice/create-supplier-invoice/supplier-invoice.form-tools';
import { useOurCompany } from 'hooks/portal-settings.hook';
import ManagerSpan from 'shared/ui/manager-span';
import { useSnack } from 'shared/ui/snack';
import { useAddInvoice } from 'entities/invoice/invoice.hook';

type Props = {
  invoice: InvoiceDto,
  invoiceRow: InvoiceRowDto;
  purchaseId: number;
};

export const PurchaseSupplierInvoiceCard = ({ invoice, invoiceRow, purchaseId }: Props) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const isInvoiceAvailable = invoice && invoiceRow;
  const ourCompanyId = useOurCompany();
  const addInvoice = useAddInvoice();
  const { showError, showSuccess } = useSnack();

  const handleAddInvoice = async (data: FormData) => {
    const dto = formTools.toCreateDto(data);

    dto.buyerCompanyId = ourCompanyId;
    addInvoice(dto, purchaseId)
      .then(() => {
        showSuccess('Счет успешно добавлен');
        setAddDialogOpen(false);
      })
      .catch(e => showError(`Ошибка добавления счета: ${e.data.message}`));
  };

  return (
    <Card>
      <CardHeader title="Подрядчик" />
      <CardContent sx={{ pt: 0 }}>
        {isInvoiceAvailable
          ?
          <Stack direction='column'>
            <LegendItem title="Фирма: " value={<LinkToCompany company={invoice.sellerCompany} />}></LegendItem>
            <LegendItem title="Юрлицо: " value={<LinkToLegalEntity legalEntity={invoice.seller} />} />
            <LegendItem title="Наше юрлицо: " value={<LinkToLegalEntity legalEntity={invoice.buyer} />} />
            <Divider sx={{ my: 1 }} />
            <LegendItem title="Счет: " value={
              <>
                <LinkToInvoice invoice={invoice} />
                &nbsp;
                <InvoiceStatusSpan status={invoice.status} size="small"/>
              </>
            }
            />
            <LegendItem title="Менеджер: " value={<ManagerSpan person={invoice.manager}/>} />
            <LegendItem title="Сумма счета: " value={<PriceSpan price={invoice.totals.priceTotal}/>} />
            <LegendItem title="Сумма оплат: " value={<PriceSpan price={invoice.totals.partialPaidAmount} />} />
          </Stack>
          :
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
          >
              Внести счет
          </Button>
        }
      </CardContent>
      <AddSupplierInvoiceDialog
        open={addDialogOpen}
        supplierCompanyId={undefined}
        ourCompanyId={ourCompanyId}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleAddInvoice}
        isCompanyPageMode={false}
      />
    </Card >
  );
};
