import { IsNotEmpty, IsString, IsArray, IsNumber } from 'class-validator';
import { PermissionDto }                           from './permission.dto';

export class RoleDto {
  id: number;
  title: string;
  permissions: PermissionDto[];
}

export class CreateRoleDto {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsArray()
  permissions: number[];
}

export class UpdateRoleDto {
  @IsNumber()
  id: number;

  @IsString()
  title: string;

  @IsArray()
  permissions: number[];
}
