import { SearchQueryDto } from '@sr/dto';
import { useNavigate } from 'react-router-dom';
import { useLocalStorageState } from 'shared/hooks/local-storage-state';

const SEARCH_URL_PATH = '/search';

export const useSearchRequest = () => {
  const navigate = useNavigate();

  const [_, saveSearchOptions] = useLocalStorageState('searchOptions', '');


  const handleRequest = (query?: SearchQueryDto) => {

    if (!query)
      return navigate({
        pathname: SEARCH_URL_PATH
      });
    
    
    const sParams = new URLSearchParams();
    sParams.set('query', query.query);

    query.searchCompanies && sParams.set('searchCompanies', query.searchCompanies.toString());
    query.searchInvoices && sParams.set('searchInvoices', query.searchInvoices.toString());
    query.searchLegalEntities && sParams.set('searchLegalEntities', query.searchLegalEntities.toString());
    query.searchMedia && sParams.set('searchMedia', query.searchMedia.toString());
    query.searchLeads && sParams.set('searchLeads', query.searchLeads.toString());
    query.searchPurchases && sParams.set('searchPurchases', query.searchPurchases.toString());


    navigate({
      pathname: SEARCH_URL_PATH,
      search: sParams.toString(),
    });

    // на последок, сохраним параметры поиска в локальное хранилище
    sParams.delete('query');
    saveSearchOptions(sParams.toString());
  };

  return {
    navigateToResults: handleRequest,
  };
};
