import { CityBasicDataDto } from '@sr/dto'
import { useMemo } from 'react'
import { PageBreadCrumbs } from 'shared/ui/page-breadcrumbs'
import { geoLinkManager } from 'utils/link-manager'

type Props = {
  city: CityBasicDataDto
}

export const CityPageBreadCrumbs = ({ city }: Props) => {
  const country = city.region.country;
  const region = city.region;

  const links = useMemo(() => {
    const links = [];
    country && links.push({ text: country.name, to: geoLinkManager.linkToCountry(country.id) });
    links.push({ text: region.name, to: geoLinkManager.linkToRegion(region.id) });
    links.push({ text: city.name });
    return links;
  }, [city]);


  return (
    <PageBreadCrumbs links={links} />
  )

}