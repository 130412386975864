import { LeadDto } from '@sr/dto';
import { Typography } from '@mui/material';
import { CardPaperTitled } from 'shared/ui/card-paper';

export const LeadContentWidget = ({ lead }: { lead: LeadDto }) => {
  return (
    <CardPaperTitled title="Содержание">
      <Typography style={{ whiteSpace: 'pre-line' }} variant='body2'>
        {lead.content}
      </Typography>
    </CardPaperTitled>
  );
};
