import { GridRenderCellParams } from '@mui/x-data-grid';
import { NULL_USER_ID } from './time-sheet.hook';

const getTotalWorkingHours = (payload: any): number => {
  const { id, name, isClosed, ...values } = payload;
  return Object.values(values).reduce((acc: number, curr)=> acc + Number(curr), 0);
};

export const HoursSumCell = (props: GridRenderCellParams) => {
  const totalWorkingHours = getTotalWorkingHours(props.row);
  if (props.id === NULL_USER_ID) return (<span>{totalWorkingHours}</span>);
  const workingScheduledHours = getTotalWorkingHours(props.api.getRow(NULL_USER_ID));
  return (<span>{totalWorkingHours} / {workingScheduledHours}</span>);
};
