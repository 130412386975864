import {
  TableCell, TableRow
} from '@mui/material';
import { LegalEntityBaseInfoDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';


type Props = {
  legalEntities: LegalEntityBaseInfoDto[];
  isLoading: boolean;
  error: string;
};

export const LegalEntitiesGrid = (props: Props) => {
  const { legalEntities, isLoading, error,  } = props;

  return (
    <>
      <GridLayout<LegalEntityBaseInfoDto>
        columns={['Название', 'ИНН', 'КПП']}
        items={legalEntities}
        isLoading={isLoading}
        error={error}
        itemRender={(item) => <CompanyLegalEntityRow
          key={item.id}
          legalEntity={item}
        />}
        noItemsText='Список юрлиц пуст'
      />
    </>);
};


type RowProps = {
  legalEntity: LegalEntityBaseInfoDto;
};

const CompanyLegalEntityRow = (props: RowProps) => {
  const { legalEntity } = props;

  return (
    <>
      <TableRow
        key={legalEntity.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToLegalEntity legalEntity={legalEntity} />
        </TableCell>
        <TableCell>{legalEntity.inn}</TableCell>
        <TableCell>{legalEntity.kpp}</TableCell>
      </TableRow>
    </>
  );
};
