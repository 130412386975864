import * as React from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useSnack } from 'shared/ui/snack';
import { Heading } from 'shared/ui/list-page-heading';
import { useActionCreators, useStateSelector } from 'shared/store';

import { UserPicker } from 'features/user/user-picker/user-picker';
import { SalaryEntryForm } from 'entities/salary/salary-entry-dialog/salary-entry.form';
import { SalaryEntriesGrid } from 'entities/salary/salary-entries/salary-entries-grid';
import { setUserSalary } from 'entities/salary/salary-user-picker.slice';
import { salaryDialogActions } from 'entities/salary/salary-entry-dialog/salary-entry.slice';

export const SalaryEntries = () => {
  const selectedPeriod = useStateSelector((state) => state.salarySelectedPeriod.value);
  const selectedUserId = useStateSelector((state) => state.salaryUserSelect.value);
  const { setUser } = useActionCreators(setUserSalary);

  const { showError, showSuccess } = useSnack();
  const { openDialog } = useActionCreators(salaryDialogActions);

  const handleUserChange = (value: number|string) => {
    if (typeof value === 'number'){
      setUser(value);
    }
  };

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Начисления/Удержания"
        actions={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => openDialog()}
          >
            Добавить запись
          </Button>} />
      <Box sx={{ minWidth: 200, maxWidth: 308, mb: 1 }}>
        <UserPicker value={selectedUserId} onChange={handleUserChange} label="Менеджер" size="small" />
      </Box>
      <SalaryEntryForm />
      {
        typeof selectedUserId === 'number'
          ? <SalaryEntriesGrid year={selectedPeriod.year} month={selectedPeriod.month} userId={selectedUserId} />
          : <Box sx={{ pt: 2 }}>Выберите менеджера для просмотра заработной платы</Box>
      }
    </>
  );
};
