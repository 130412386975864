import { CreateCityDto } from '@sr/dto';
import { cityApi } from 'entities/geo/cities/cityApiSlice';
import { CITIES_BASE_URL } from 'shared/api/api';


const injectedApi = cityApi.injectEndpoints({
  endpoints: (builder) => ({
    addCity: builder.mutation<void, CreateCityDto>({
      query: (dto: CreateCityDto) => ({
        url: CITIES_BASE_URL,
        body: dto,
        method: 'POST'
      }),
    })
  }),
  overrideExisting: true
});

export const cityCreateApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['*'],
  endpoints: {
    addCity: {
      invalidatesTags: ['*']
    }
  }
})