import { Identifiable } from '@sr/dto';
import { useEffect, useState } from 'react';

export interface IRemoteItem<T> {
  item: T;
  isLoading: boolean;
  error: string;
}

type RemoteItemConfig<TItem extends Identifiable, TUpdateItem = void> = {
  fetchHandler?: () => Promise<TItem>;
  updateHandler?: (itemData: TUpdateItem) => Promise<TItem>;
}

export const useRemoteItem =
  <TItem extends Identifiable, TUpdateItem = void>(config: RemoteItemConfig<TItem, TUpdateItem>) => {
    const { fetchHandler, updateHandler } = config;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [item, setItem] = useState<TItem | null>(null);

    useEffect(() => {
      if (!fetchHandler)
        return;

      setLoading(true);

      fetchHandler()
        .then(response => setItem(response))
        .catch(e => setError(e.message))
        .finally(() => setLoading(false));

    }, [fetchHandler]);

    const updateItem = updateHandler
      ? (async (data: TUpdateItem) => {
        const response = await updateHandler(data);
        setItem(response);
      })
      : async () => {
        throw new Error('Update handler is not specified');
      };

    return {
      item,
      loading,
      error,
      updateItem,
    };
  };



