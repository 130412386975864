export interface Identifiable {
  id: number;
}

export class Identifiable implements Identifiable {
  id: number;
}

export interface Timestamped {
  createdAt: Date;
  updatedAt?: Date;
}
