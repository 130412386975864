import { FormDialog } from 'shared/ui/form-dialog';
import { useOurCompany } from 'hooks/portal-settings.hook';
import {
  validate,
  FormValues,
  initialValues,
} from 'components/Contracts/contract.form';
import { ContractFormFields } from './contract.form-fields';

interface DialogProps {
  open: boolean;
  title: string;
  values?: FormValues;
  buyerCompanyId: number;
  ourCompanyId: number;
  submit: (formData: FormValues) => Promise<void>;
  onClose: () => void;
}

export function AddContractDialog(props: DialogProps) {
  const { open, onClose, submit, title, values, buyerCompanyId } = props;

  const ourCompanyId = useOurCompany();

  return (
    <FormDialog
      renderForm={() => (
        <ContractFormFields
          ourCompanyId={ourCompanyId}
          buyerCompanyId={buyerCompanyId}
        />
      )}
      renderTitle={title}
      open={open}
      onClose={onClose}
      onSubmit={submit}
      validate={validate}
      validateOnChange={true}
      initialValues={values ? values : initialValues}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    />
  );
}
