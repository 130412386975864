import { Box, Tab, Tabs } from '@mui/material';
import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import { useTitleHook } from 'hooks/page-title.hook';
import { TimeSheet } from 'features/salary/time-sheet/time-sheet';
import { SalaryEntries } from 'features/salary/salary-entries';
import { salaryLinkManager } from 'utils/link-manager';
import { SalaryPeriodPicker } from 'entities/salary/salary-period/salary-period-picker';

const tabInfos = [
  {
    path: salaryLinkManager.tabs.home,
    label: 'Зарплата',
    icon: <StorageIcon />,
    component: <SalaryEntries/>
  },
  {
    path: salaryLinkManager.tabs.reserveFund,
    label: 'Фонд риска',
    icon: <AccountBalanceIcon />,
    component: <>TO-DO risk depo</>
  },
  {
    path: salaryLinkManager.tabs.timeSheet,
    label: 'Табель',
    icon: <ArticleIcon />,
    component: <TimeSheet/>
  }
];

const SalaryPage = () => {
  useTitleHook('Зарплата');
  return (
    <>
      <SalaryPeriodPicker/>
      <SalaryTabs />

      <Routes>
        <Route element={<Outlet />}>
          {
            tabInfos.map(tab=>
              (<Route path={tab.path} element={tab.component} key={tab.path}/>))
          }
        </Route>
      </Routes>
    </>);
};

const SalaryTabs = () => {
  const location = useLocation();

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
      <Tabs value={location.pathname}>
        {tabInfos.map((tab) => (<Tab
          key={tab.path}
          label={tab.label}
          value={`/${salaryLinkManager.baseUrl}/${tab.path}`}
          iconPosition="start"
          icon={tab.icon}
          to={tab.path}
          component={Link}/>
        ))}
      </Tabs>
    </Box>
  );
};
export default SalaryPage;
