import isNumeric from 'validator/lib/isNumeric';

export interface FormData {
  bankName: string;
  bik: string;
  accountNumber: string;
  corrAccountNumber: string;
}

export const initialValues: FormData = {
  bankName: '',
  bik: '',
  accountNumber: '',
  corrAccountNumber: ''
};

type ValidationData = Partial<Record<keyof FormData, string>>;

export const validate = (values: FormData) => {
  const errors: ValidationData = {};

  const requiredMsg = 'Поле обязательно';
  if (!values.bankName) errors.bankName = requiredMsg;

  if (!isNumeric(values.bik)) errors.bik = 'БИК может содержать только цифры';
  else if (values.bik.length !== 9) errors.bik = 'БИК должен содержать 9 цифр';

  if (!values.accountNumber) errors.accountNumber = requiredMsg;
  else if (!isNumeric(values.accountNumber)) errors.accountNumber = 'Номер счета может содержать только цифры';
  else if (values.accountNumber.length !== 20) errors.accountNumber = 'Номер счета должен содержать 20 цифр';

  if (!values.corrAccountNumber) errors.corrAccountNumber = requiredMsg;
  else if (!isNumeric(values.corrAccountNumber))
    errors.corrAccountNumber = 'Номер корр. счета может содержать только цифры';
  else if (values.corrAccountNumber.length !== 20)
    errors.corrAccountNumber = 'Номер корр. счета должен содержать 20 цифр';

  return errors;
};
