import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, ButtonProps, Link as MuiLink } from '@mui/material';
import { ReactNode } from 'react';

type LinkProps = {
  to: string,
  download?: boolean,
  target?: string,
  children: ReactNode,
}

export const Link = ({ to, children, download, target }: LinkProps) => {
  return (
    <MuiLink component={RouterLink} to={to} download={download} target={target}>
      {children}
    </MuiLink>
  );
}


export const ButtonLink = ({ to, children, download, target, buttonProps }: LinkProps & { buttonProps?: ButtonProps }) => {
  const navigate = useNavigate();

  return (
    <Button
      {...buttonProps}
      onClick={() => navigate(to)}>
      {children}
    </Button>)


}