import PeopleIcon from '@mui/icons-material/People';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PublicIcon from '@mui/icons-material/Public';
import MapIcon from '@mui/icons-material/Map';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArticleIcon from '@mui/icons-material/Article';
import OutboxIcon from '@mui/icons-material/Outbox';
import SettingsIcon from '@mui/icons-material/Settings';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalculateIcon from '@mui/icons-material/Calculate';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ListItemLink from 'components/Mui/list-item-link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Collapse, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocalStorageState } from 'shared/hooks/local-storage-state';
import { Drawer } from 'shared/ui/drawer';
import { reportsLinkManager, salaryLinkManager } from 'utils/link-manager';

type Props = {
  open: boolean;
  toggleOpen: () => void;
}

export const SideMenu = ({ open, toggleOpen }: Props) => {
  const [geoMenuOpen, setGeopMenuOpen] = useLocalStorageState('geo-menu-open', false);
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleOpen}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemLink primary="Клиенты" to='/clients/list-client-all' icon={<BusinessCenterIcon />} />
        <ListItemLink primary="Поставщики" to='/suppliers' icon={<ShoppingCartIcon />} />
        <ListItemLink primary="Договоры" to='/contracts' icon={<ArticleIcon />} />
        <ListItemLink primary="Клиентские счета" to='/client-invoices' icon={<OutboxIcon />} />
        <ListItemLink primary="Входящие счета" to='/supplier-invoices' icon={<MoveToInboxIcon />} />
        <ListItemLink primary="Заявки" to='/purchases' icon={<PostAddIcon />} />
        <ListItemLink primary="Лиды" to='/leads' icon={<AutoGraphIcon />} />
        <ListItemLink primary="СМИ и услуги" to='/media-list/list' icon={<LiveTvIcon />} />
        <ListItemLink primary="Медиапланирование" to='/media-planning' icon={<LiveTvIcon />} />
        <ListItemLink primary="Дела" to='/events/all' icon={<ListAltIcon />} />
        <ListItemLink primary="Бухгалтерия" to='/accounting' icon={<CalculateIcon />} />
        <ListItemLink primary="Зарплата" to={`/${salaryLinkManager.baseUrl}/${salaryLinkManager.tabs.home}`} icon={<CurrencyRubleIcon />} />
        <ListItemLink primary="Акты" to='/wccs/bulk-create' icon={<FactCheckIcon />} />
        <ListItemButton onClick={() => setGeopMenuOpen(prev => !prev)}>
          <ListItemIcon >
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary="География" />
          {geoMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={geoMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemLink sx={{ pl: 4 }} primary="Города" to='/cities' icon={<LocationCityIcon />} />
            <ListItemLink sx={{ pl: 4 }} primary="Регионы" to='/regions' icon={<MapIcon />} />
            <ListItemLink sx={{ pl: 4 }} primary="Страны" to='/countries' icon={<PublicIcon />} />
          </List>
        </Collapse>
        <ListItemLink primary="Пользователи" to='/users' icon={<PeopleIcon />} />
        <ListItemLink primary="Отчеты" to={`/${reportsLinkManager.baseUrl}/${reportsLinkManager.tabs.cashBalance}`} icon={<BarChartIcon />} />
        <ListItemLink primary="Настройки" to='/settings/home' icon={<SettingsIcon />} />
      </List >
    </Drawer>
  );
};
