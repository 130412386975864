import { IPerson } from './person.dto';
import {IsBoolean, IsDateString, IsNumber, IsOptional, IsString} from "class-validator";
import {Type} from "class-transformer";
import { RoleDto } from './role.dto';

export class UserDto implements IPerson {
  id: number;
  login: string;
  firstName: string;
  secondName: string;
  middleName: string;
  position: string;
  isActive: boolean;
  isFired: boolean;
  roles: RoleDto[];
}

export class CreateUserDto {
    login: string;
    password: string;

    firstName: string;
    secondName: string;
    middleName: string;
}

export class UpdateUserRolesDto {
  id: number;
  roles: number[];
}

export class UserRolesDto {
  id: number;
  roles: RoleDto[];
}

export class UpdateUserProfileBasicDto {
    @IsNumber()
    id: number;
    @IsString()
    login: string;
    @IsString()
    firstName: string;
    @IsString()
    secondName: string;
    @IsString()
    middleName: string;
}

export class ChangePasswordDto {
    // currentPassword: string;
    newPassword: string;
}

export class UserPersonalDataDto {
  @IsOptional()
  @Type(() => Date)
  @IsDateString()
  dob: Date|null = null;

  @IsString()
  passportData: string;

  @IsString()
  residenceAddress: string;
}

export class UserCompanyDataDto {
    position: string;
    baseSalary: number;
    salesPlan: number;
    supervisorId: number;
    supervisedUsers: UserDto[]
}

export interface UpdateUserCompanyDataDto extends Partial<UserCompanyDataDto> {}

export class UserActiveStatusDto {
    isActive: boolean;
}

export class UserFiredStatusDto {
  @IsBoolean()
  isFired: boolean;
}
