import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLogin, fetchLogout, fetchRefresh } from 'shared/api/auth';
import { IProgressState } from './common';
import { AuthorizedUser, LoginDto, RefreshTokenDto } from '@sr/dto';
import { clearAuthData, setAccessToken, setRefreshToken } from 'shared/api/token';

interface State extends IProgressState {
    user: AuthorizedUser | null;
}

const initialState: State = {
  user: null,
  pendingRequest: false,
  requestFailed: false,
  error: null
};

export const loginThunk = createAsyncThunk(
  'auth/login',
  async ({ login, password }: LoginDto, thunkAPI) => fetchLogin(login, password)
);

export const refreshThunk = createAsyncThunk(
  'auth/refresh',
  async ({ refreshToken }: RefreshTokenDto, thunkAPI) => fetchRefresh(refreshToken)
);

export const logoutThunk = createAsyncThunk(
  'auth/logout',
  async (user: AuthorizedUser, thunkAPI) => fetchLogout(user)
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    ///////////// login
      .addCase(loginThunk.pending, (state, action) => {
        state.pendingRequest = true;
        state.requestFailed = false;
      })
      .addCase(loginThunk.fulfilled, (state, { payload: { authorizedUser, accessToken, refreshToken } }) => {
        state.pendingRequest = false;
        state.requestFailed = false;
        state.user = authorizedUser;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.pendingRequest = false;
        state.requestFailed = true;
        state.error = action.error.message || null;
      })

    ///////////// logout
      .addCase(logoutThunk.pending, (state, action) => {
        state.pendingRequest = true;
        state.requestFailed = false;
      })
      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.pendingRequest = false;
        state.requestFailed = false;
        state.user = null;
        clearAuthData();
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.pendingRequest = false;
        state.requestFailed = true;
        state.error = action.error.message || '';
      })

    ///////////// refresh
      .addCase(refreshThunk.pending, (state, action) => {
        state.pendingRequest = true;
        state.requestFailed = false;
      })
      .addCase(refreshThunk.fulfilled, (state, { payload: { authorizedUser, accessToken, refreshToken } }) => {
        state.pendingRequest = false;
        state.requestFailed = false;

        state.user = authorizedUser;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
      })
      .addCase(refreshThunk.rejected, (state, action) => {
        state.pendingRequest = false;
        state.requestFailed = true;
        state.error = action.error.message || '';

        state.user = null;
        clearAuthData();
      });
  }
});

//export const {refreshAccessToken, updateUser, userCheckoutRequest, userRequestFailed} = authSlice.actions;

export const { reducer: authReducer } = authSlice;


