import { UserFiredStatusDto } from '@sr/dto';
import { userApi } from 'entities/user/api';


const injectedApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserFiredStatus: builder.query<UserFiredStatusDto, number>({
      query: (userId: number) => `users/${userId}/fired-status`,
    }),
    updateUserFiredStatus: builder.mutation<UserFiredStatusDto, { userId: number, data: UserFiredStatusDto }>({
      query: ({ userId, data }) => (
        {
          url: `users/${userId}/fired-status`,
          body: data,
          method: 'PUT'
        }),
    }),
  }) });

export const userFiredStatusApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['userFireStatus', 'userList'],
  endpoints: {
    getUserFiredStatus: {
      providesTags: (result, _, userId) => (
        result ? [{ type: 'userFireStatus', id: userId }] : ['userFireStatus']
      ),
    },
    updateUserFiredStatus: {
      invalidatesTags: (res, error, query) => (
        res && !error ? [{ type: 'userFireStatus', id: query.userId }, 'userList'] : ['userFireStatus']
      ),
    },
  },
});



