import { CompanyAnnounce } from 'entities/company/announce/company-announce';
import { useLeadConnections } from './lib';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { ErrorBanner } from 'shared/ui/error-banner';
import { useSnack } from 'shared/ui/snack';

export const LeadCompanies = ({ leadId }: { leadId: number }) => {
  const { companies, isLoading, error } = useLeadConnections(leadId);
  const { showError } = useSnack();


  if (isLoading)
    return <p>Загружается...</p>;

  if (error) {
    const errorMessage = extractNestJsErrorMessage(error);
    showError(errorMessage);
    return <ErrorBanner errorMessage={errorMessage} />;
  }

  if (!companies || !companies.length)
    return <p>Нет фирм</p>;

  return (
    <>
      {companies.map((company, idx) => <CompanyAnnounce key={idx} company={company} />)}
    </>
  );
};
