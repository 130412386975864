import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { LegalEntitiesGrid } from 'entities/legal-entities/legal-entities-grid';
import { LegalEntityCreateDialog } from 'entities/legal-entities/legal-entity-create-dialog';

import { CreateLegalEntityDto } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import {
  useAddCompanyLegalEntity,
  useLegalEntities
} from 'entities/legal-entities/legal-entities.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';


type Props = {
  companyId: number;
};

export const CompanyLegalEntities = (props: Props) => {
  const { companyId } = props;
  const companyEntitiesCollection = useLegalEntities({ companyId });
  const addLegalEntity = useAddCompanyLegalEntity(companyId);

  const [createEntityOpen, setCreateEntityOpen] = useState(false);

  const { showSuccess, showError } = useSnack();

  const handleAddLegalEntity = async (legalEntity: CreateLegalEntityDto) => {
    return addLegalEntity(legalEntity)
      .then(() => {
        setCreateEntityOpen(false);
        showSuccess('Юридическое лицо успешно добавлено');
      })
      .catch(e => {
        showError(`Ошибка создания юридического лица: ${extractNestJsErrorMessage(e)}`);
      });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" color="grey.700">Юридические лица</Typography>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setCreateEntityOpen(true)}
        >
          Добавить
        </Button>
      </Stack>

      <LegalEntitiesGrid {...companyEntitiesCollection}/>

      <LegalEntityCreateDialog
        open={createEntityOpen}
        onClose={() => setCreateEntityOpen(false)}
        onSubmit={handleAddLegalEntity} />

    </>);
};
