import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import {
  CreateMediaTypeDto,
  MediaTypeBaseDataDto, UpdateMediaTypeBaseDataDto,
} from '@sr/dto';
import {
  useAddMediaType,
  useDeleteMediaType,
  useMediaTypesList, useRenameMediaType,
  MediaTypeFromFields,
  formTools, PopupFormData,
  mediaTypesNotificationsConfig
} from 'entities/media/media-types';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { Link } from 'shared/ui/link-base';
import { GridLayout } from 'shared/ui/grid-layout';
import { FormDialog } from 'shared/ui/form-dialog';
import { useSnack } from 'shared/ui/snack';
import { mediaTypeLinkManager } from 'utils/link-manager';
import AddIcon from '@mui/icons-material/Add';


const columns = [
  'Тип СМИ',
  'Действия'
];

const MediaTypesList = () => {
  const { collection, isLoading, error } = useMediaTypesList();
  const navigate = useNavigate();
  const removeItem  = useDeleteMediaType();
  const updateItem = useRenameMediaType();
  const addItem = useAddMediaType();
  const { dialogProps, openToAdd, openToEdit, itemToEdit }
    = useCreateOrEditDialog<MediaTypeBaseDataDto, CreateMediaTypeDto, UpdateMediaTypeBaseDataDto, PopupFormData>({
      addHandler: async (dto) => {
        await addItem(dto).then(mediaType=>{ navigate(mediaTypeLinkManager.link(mediaType.id)) });
      },
      updateHandler: async (dto) => { await updateItem(dto) },
      formTools: formTools,
      notificationsConfig: mediaTypesNotificationsConfig
    });

  const { showSuccessDelete, showFailedDelete } = useSnack(mediaTypesNotificationsConfig);
  const handleDelete = async (id: number, name: string) => {
    return removeItem({ id, name })
      .then(()=>{
        showSuccessDelete();
      })
      .catch(e=>{
        showFailedDelete(e);
      });
  };

  return (
    <Box>
      <Button
        startIcon={<AddIcon />}
        variant='contained'
        sx={{ mb: 2 }}
        onClick={openToAdd}
      >
        Добавить
      </Button>

      <GridLayout<MediaTypeBaseDataDto>
        columns={columns}
        items={collection}
        isLoading={isLoading}
        error={error}
        itemRender={mediaType =>
          <Row
            key={mediaType.id}
            mediaType={mediaType}
            onEditClick={() => openToEdit(mediaType)}
            onDeleteClick={()=>handleDelete(mediaType.id, mediaType.name)}
          />}
        noItemsText="Список типов СМИ пуст" />
      <FormDialog<PopupFormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новый тип СМИ' : 'Тип СМИ'}
        renderForm={(props) => <MediaTypeFromFields {...props} />}
      />
    </Box>
  );
};

type Props = {
  mediaType: MediaTypeBaseDataDto,
  onEditClick: () => void,
  onDeleteClick: () => Promise<void>;
};

const Row = ({ mediaType, onEditClick, onDeleteClick }: Props) => {
  return (
    <TableRow>
      <TableCell>
        <Link to={mediaTypeLinkManager.link(mediaType.id)}>{mediaType.name}</Link>
      </TableCell>
      <TableCell align="right">
        <IconButton edge="end" onClick={onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow >
  );
};

export default MediaTypesList;
