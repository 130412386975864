import { useLeadBudgets } from 'entities/lead/lead-budget.hook';
import { withRemoteCollectionDropDownPicker } from './with-remote-collection-dropdown';
import { LeadBudgetDto } from '@sr/dto';

export const LeadBudgetPicker = withRemoteCollectionDropDownPicker<LeadBudgetDto, number>({
  collectionProvider: useLeadBudgets,
  renderDropdownLabel: (item: LeadBudgetDto | '') => typeof item === 'string' ? item : item.label,
  renderEmptyValue: <>&nbsp;</>
});

