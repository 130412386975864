import { IPagedCollection, WccDto } from '@sr/dto';
import { WCC_BASE_URL } from 'shared/api/api';
import { CollectionParams } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';
import { wccApi } from '../api';


const injectedApi = wccApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientWccList: builder.query<IPagedCollection<WccDto>, CollectionParams>({
      query: ({ paging, filter }: CollectionParams) => ({
        url: `${WCC_BASE_URL}?${filter}`,
        params: paging && pagingDataToParams(paging),
        method: 'GET'
      }),
    }),
    deleteWcc: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `${WCC_BASE_URL}/${id}`,
        method: 'DELETE'
      }),
    })
  }),
  overrideExisting: true
});

export const wccListApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['*'],
  endpoints: {
    getClientWccList: {
      providesTags: ['*']
    },
    deleteWcc: {
      invalidatesTags: ['*']
    }
  }
})