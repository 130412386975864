import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { AgentCard } from 'entities/agent-card/agent-card';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { useTitleHook } from 'hooks/page-title.hook';
import { WccInvoiceRows } from 'entities/wcc/invoice-rows/wcc-rows';
import { WccBasicsCard } from 'features/wcc/wcc-basic-card';
import { WccInvoiceCard } from 'entities/wcc/invoice-details-card/wcc-invoice-card';
import { PageHeader } from 'shared/ui/details-page-header';
import { WccStatusStepper } from 'entities/wcc/status-stepper/wcc-status-stepper';
import { useWcc } from 'entities/wcc/wcc-details/lib';
import { WccTitle } from 'entities/wcc/wcc-title';
import { PrintWccButton } from 'features/wcc/wcc-print-form/print-button';

export const WccDetailsPage = () => {
  const { wccId: wccIdStr } = useParams<{ wccId: string; }>();
  const wccId = Number(wccIdStr);
  const { item: wcc, error, updateItem } = useWcc(wccId);

  useTitleHook(wcc && `Акт ${wcc?.wccNumber}`);

  if (wcc === null) {
    if (error)
      return <ErrorBanner errorMessage={error} />;

    return <LoadingBanner />;
  }

  return (
    <>
      <PageHeader
        title={<WccTitle wcc={wcc} />}
        actions={
          <PrintWccButton wccId={wccId} />
        }
        subHeader={
          <Box maxWidth={800}>
            <WccStatusStepper status={wcc.status} />
          </Box>}
      />

      <Stack direction="row" spacing={2} alignContent="flex-end" justifyItems="center">
        <Box>
          <WccBasicsCard wcc={wcc} updateWcc={updateItem} />
        </Box>
        <Box>
          <WccInvoiceCard invoice={wcc.invoice} />
        </Box>

        <Box>
          <Stack direction="column" spacing={2}>
            <AgentCard title="Покупатель" company={wcc.invoice.buyerCompany} legalEntity={wcc.invoice.buyer} />
            <AgentCard title="Продавец" company={wcc.invoice.sellerCompany} legalEntity={wcc.invoice.seller} />
          </Stack>
        </Box>


      </Stack>
      {/* <Box >
          <CardPaper sx={{
            p: 1, mb: 2,
            // width: 'auto'
          }}>
            <Stepper activeStep={3} orientation="vertical" >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </CardPaper>
        </Box> */}
      <Box mt={3}>
        <WccInvoiceRows invoiceId={wcc.invoice.id} />
      </Box>

    </>
  );
};

export default WccDetailsPage;

