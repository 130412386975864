import { authorizedRequest } from 'shared/api/axios';
import { PRINT_FORMS_BASE_URL } from 'shared/api/api';



export function fetchPrintForm(contractId: number, withStamp = false) {
  return authorizedRequest<void, string>(
    `${PRINT_FORMS_BASE_URL}/contract/${contractId}?stamp=${withStamp ? 'true' : ''}`,
    'GET');
}
