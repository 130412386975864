import { Type } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsPositive,
  IsString,
} from 'class-validator';
import { IManager } from './manager.dto';
import { UserDto } from './user.dto';

export type LinkType = "link" | "block";

export interface IManagerLink {
  id: number;
  manager: IManager;
  linkType: LinkType;
  startDate: Date;
  endDate: Date;
  isActive?: Boolean;
}

export type ICreateManagerLink = {
  companyId: number;
  managerId: number;
  linkType: LinkType;
  startDate: Date;
  endDate: Date;
};

export class CreateLinkManagerDto implements ICreateManagerLink {
  @IsNotEmpty()
  @IsInt()
  @IsPositive()
  managerId: number;

  @IsNotEmpty()
  @IsInt()
  @IsPositive()
  companyId: number;

  @IsNotEmpty()
  @IsString()
  @IsEnum(['link', 'block'])
  linkType: LinkType;

  @Type()
  // @MinDate(new Date('2021-12-01T01:00:00'))
  startDate: Date;

  @Type()
  endDate: Date;
}

export class ManagerLinkDto implements IManagerLink {
  id: number;
  manager: UserDto;
  linkType: LinkType;
  startDate: Date;
  endDate: Date;
}
