type CLIENT = 1;
type SUPPLIER = 2;

// исходящие клиентские счета
export const INVOICE_TYPE_CLIENT: CLIENT = 1;

// входящие счета от поставщиков
export const INVOICE_TYPE_SUPPLIER: SUPPLIER = 2;

export type InvoiceType = CLIENT | SUPPLIER;

// type InvTypeNames = 'client' | 'supplier'

// export const InvType: { [key in InvTypeNames]: InvoiceType } = {
//   client: INVOICE_TYPE_CLIENT,
//   supplier: INVOICE_TYPE_SUPPLIER
// }