import { CashflowDto, CreateCashflowDto, UpdateCashflowDto } from '@sr/dto';
import { useCallback } from 'react';
import { deleteCashflow, fetchCashflowsDropDown, fetchCashflows, postCashflow, putCashflow } from 'shared/api/cashflows';
import { useReadOnlyRemoteCollection, useRemoteCollection } from 'utils/remote-collection.hook';

export const useCashflows = () => {
  return useRemoteCollection<CashflowDto, CreateCashflowDto, UpdateCashflowDto>({
    fetchHandler: useCallback(() => fetchCashflows(), []),
    postHandler: postCashflow,
    updateHandler: putCashflow,
    deleteHandler: deleteCashflow
  });
};


export const useCashflowsDropdown = () => {
  return useReadOnlyRemoteCollection<CashflowDto>({
    fetchHandler: useCallback(fetchCashflowsDropDown, []),
  });
};