import { companiesApi } from 'entities/company/api.slice';

export const useCombine = () => {
  const [update, { isLoading }] = companiesApi.useCombineCompaniesMutation();

  return {
    combineCompanies: async (
      newCompanyName: string,
      companiesIds: number[]
    ) => {
      return update({ newName: newCompanyName, companiesIds }).unwrap();
    },
    isLoading,
  };
};
