import { Box, Button, FormControlLabel, MenuItem, Stack, TableCell, TableRow, Switch } from '@mui/material';
import { LegalEntityTypeBaseInfoDto } from '@sr/dto';
import { useLegalEntityTypesList } from 'entities/legal-entities/legal-entities-type/legal-entities-type.hooks';
import { useState } from 'react'
import { FormDialog } from 'shared/ui/form-dialog';
import { GridLayout } from 'shared/ui/grid-layout';
import AddIcon from '@mui/icons-material/Add';
import { Field, FormikErrors, FormikValues } from 'formik';
import { LinkToLegalEntityType } from 'shared/ui/links/link-to-legal-entity-type';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { TextField } from 'formik-mui';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { legalEntitiesTypeApi } from 'entities/legal-entities/legal-entities-type/legal-entities-type.api';
import { usePagingParams } from 'shared/hooks/paging-params';
import {FORM_CAPTIONS_VARIANT} from '@sr/dto';

const columns = [
    'Форма собственности',
    'Описание',
    'Тип',
    'Статус'
  ];

export interface FormValues {
  name: string,
  description: string,
  printFormCaptionsVariant: string,
  active: boolean
}

  export const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
  
    if (!values.name) errors.name = VALIDATION_REQUIRED_FIELD;
    if (!values.description) errors.description = VALIDATION_REQUIRED_FIELD;
    if (values.printFormCaptionsVariant == "") errors.printFormCaptionsVariant = VALIDATION_REQUIRED_FIELD;
  
    return errors;
  }

  export const initialValues: FormValues = {
    name: '',
    description: '',
    printFormCaptionsVariant: '',
    active: false
  }
  
export const OwnerShipList = () => {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const { paging, onChangePaging, collection, isLoading, error} = useLegalEntityTypesList();
  
  if (collection.length === 0) collection.items = []
  
  const showConfirmDialog = useConfirmDialog();
  const { showSuccess, showError } = useSnack();
  const [addItem,{isError}] = legalEntitiesTypeApi.useCreateLegalEntityTypeMutation()
    
  const handleAddLegalEntityType = async (values: FormikValues) => {    
        
      await addItem(({name: values.name, description: values.description, 
        contractHeadLineTemplate: '', contractBottomDetailsTemplate: '',
        printFormCaptionsVariant: Number(values.printFormCaptionsVariant), active: values.active}))
        .unwrap()
        .then(() => 
          {showSuccess('Запись успешно добавлена')
          setShowAddDialog(false)}
        )
        .catch(e => showError(`Ошибка записи: ${e}`))
    };

  return (
    <>
    <Box>
        <Button variant="contained" startIcon={<AddIcon />}
          onClick={() => setShowAddDialog(true)}>добавить
        </Button>
    </Box>
        

    <GridLayout<LegalEntityTypeBaseInfoDto>
        columns={columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.totalItemsCount}
        noItemsText='Список юрлиц пуст'
        itemRender={(item) => <LegalEntityTypeRow
          key={item.id}
          legalEntityType={item}
        />}
      />
   
      <FormDialog
        initialValues={initialValues}
        renderForm={() =>
          <Box minWidth={450} pb={1}>
            <Stack spacing={2}>
              <Field component={TextField} name="name" label="Название" />           
              <Field component={TextField} name="description" label="Описание" />
              <Field label='Тип' component={TextField} select name="printFormCaptionsVariant">
                {  
                FORM_CAPTIONS_VARIANT.map((i)=> 
                    <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                )}   
              </Field>
              <Field
                name='active'
                control={<Switch />}
                type="checkbox"
                as={FormControlLabel}
                label='Активна'
              />
            </Stack>
          </Box>
          }
        renderTitle="Новая форма собственности"
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onSubmit={handleAddLegalEntityType}
        validate={validate}
        dialogProps={{
          fullWidth: true,
        }}
      />
    
    </>
  )
}

type RowProps = {
  legalEntityType: LegalEntityTypeBaseInfoDto;
};

const LegalEntityTypeRow = (props: RowProps) => {
  const { legalEntityType } = props;
  let res = '';
  FORM_CAPTIONS_VARIANT.filter((item) => item.id === legalEntityType.printFormCaptionsVariant)
    .map((i)=> (res = i.name));
  return (
    <>
      <TableRow
        key={legalEntityType.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToLegalEntityType legalEntityType={legalEntityType} />
        </TableCell>
        <TableCell>{legalEntityType.description}</TableCell>
        <TableCell>{ res }</TableCell>
        <TableCell>
          {legalEntityType.active ? 
            <span style={{color:"green"}}>Активирована</span> : 
            <span style={{color:"red"}}>Деактивирована</span>
          }</TableCell>
      </TableRow>
    </>
  );
};

export default OwnerShipList;