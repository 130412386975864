import { ICreatePerson, IPerson } from './person.dto';

export class CompanyEmployeeDto {
  id: number;
  person: IPerson;
  position?: string;
}

export class CreateCompanyEmployeeDto {
  person: ICreatePerson;
  position?: string;
}
