import { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import CompanyFeedHeader from './Feed/feed-header';
import AddEventDialog from './Feed/add-event-dialog';
import { EventsList, useCompanyEvents } from 'features/events/events-list';
import { useSearchParams } from 'react-router-dom';

type Props = {
  companyId: number;
};

const CompanyFeed = ({ companyId }: Props) => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [ searchParams] = useSearchParams();
  const paramsRef = useRef<URLSearchParams>();

  useEffect(() => {
    setCurrentPage(0);
  }, [searchParams]);

  useEffect(() => {
    paramsRef.current = searchParams;
  }, [currentPage, searchParams]);

  const { collection, isLoading } = useCompanyEvents(companyId, currentPage, paramsRef.current);

  return (
    <>
      <CompanyFeedHeader onAddClick={() => setOpenAddDialog(true)} />

      <Stack direction='column' spacing={1}>
        <EventsList collection={collection} isLoading={isLoading} loadMore={()=>setCurrentPage(x=>x+1)} showCompany={false}/>
      </Stack>

      <AddEventDialog
        open={openAddDialog}
        companyId={companyId}
        onClose={() => { setOpenAddDialog(false) }}
      />
    </>);
};

export default CompanyFeed;
