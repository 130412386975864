import { useMemo } from 'react';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { Paper, Stack } from '@mui/material';
import { useTitleHook } from 'hooks/page-title.hook';
import { EventsListTab } from 'features/events/events-list';
import { EventsListFilter } from 'features/events/events-filter/events-filter';
import { Heading } from 'shared/ui/list-page-heading';
import { NavTabInfo, NavTabs } from 'shared/ui/nav-tabs';

const EventsListPage = () => {
  useTitleHook('Список дел');
  const tabInfos: NavTabInfo[] = useMemo(() =>
    [
      {
        path: 'all',
        label: 'Все дела',
        icon: <FormatAlignJustifyIcon />,
        component: <EventsListTab key="all" tab='all'/>
      },
      {
        path: 'my',
        label: 'Мои дела',
        icon: <SelfImprovementIcon />,
        component: <EventsListTab key='my' tab='my'/>,
      },
      {
        path: 'overdue',
        label: 'Просроченные дела',
        icon: <SnoozeIcon />,
        component: <EventsListTab key='overdue' tab='overdue'/>,
      },
    ],
  []);

  return (
    <Stack direction='column'>
      <Heading
        sx={{ mb: 2 }}
        title="Список дел"
      />
      <Paper sx={{ p:1 }}>
        <EventsListFilter />
      </Paper>
      <NavTabs tabs={tabInfos} />
    </Stack>);
};
export default EventsListPage;
