import { authorizedRequest } from 'shared/api/axios';
import { BankAccountDto, CreateBankAccountDto } from '@sr/dto';

const BANK_ACCOUNTS_URL = 'accounting/bank-accounts';

export const fetchBankAccountsDropDown = async () => {
  return authorizedRequest<void, BankAccountDto[]>(
    `${BANK_ACCOUNTS_URL}/dropdown`,
    'GET');
};

export const fetchBankAccounts = async () => {
  return authorizedRequest<void, BankAccountDto[]>(
    BANK_ACCOUNTS_URL,
    'GET');
};

export const putBankAccount = async (dto: BankAccountDto) => {
  const updatedAccount = await authorizedRequest<BankAccountDto, BankAccountDto>(
    BANK_ACCOUNTS_URL,
    'PUT',
    dto
  );
  return updatedAccount;
};


export const postBankAccount = async (dto: CreateBankAccountDto) => {
  const newAccount = await authorizedRequest<CreateBankAccountDto, BankAccountDto>(
    BANK_ACCOUNTS_URL,
    'POST',
    dto
  );
  return newAccount;
};

export const deleteBankAccount = async (id: number) => {
  return authorizedRequest<void, void>(
    `${BANK_ACCOUNTS_URL}/${id}`,
    'DELETE'
  );
};

