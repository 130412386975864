import { TextField } from '@mui/material';
import isURL from 'validator/lib/isURL';
import { FormikErrors, FormikProps } from 'formik';
import { FormDialog } from './form-dialog';
import { useRef, useEffect } from 'react';

type FormValues = {
  url: string;
}

const initialValues: FormValues = {
  url: ''
}

type DialogProps = {
  open: boolean;
  onSubmit: (url: string) => Promise<void>;
  onClose: () => void;
}


export const UrlDialog = ({ open, onSubmit, onClose }: DialogProps) => {

  const handleSubmit = async (values: FormValues) => {
    return onSubmit(values.url).then(onClose);
  };

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      renderTitle="Добавление нового сайта"
      renderForm={(props) =>
        <FormInput {...props} />
      }
      dialogProps={{ maxWidth: 'xs', fullWidth: true }} />
  );
}


const FormInput = (props: FormikProps<FormValues>) => {
  const inputRef = useRef<HTMLElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <TextField
      fullWidth
      id="url"
      name="url"
      label="URL"
      inputRef={inputRef}
      value={props.values.url}
      onChange={props.handleChange}
      error={Boolean(props.errors.url)}
      helperText={props.errors.url}
    />)
}

function validate(values: FormValues): FormikErrors<FormValues> {
  if (!values.url)
    return { url: 'Обязательное поле' }
  if (!isURL(values.url))
    return { url: 'Неверный формат адреса сайта' }
  return {};
}