import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CombineCompaniesDto,
  CompanyDto,
  CompanyToCombineDto,
  CompanyWithManagersDto,
} from '@sr/dto';
import { APP_BASE_URL, COMPANY_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';

// TODO: однажды надо будет перенсти все АПИ методы работы с компаниями сюда
const URL = 'combine-companies';

export const companiesApi = createApi({
  reducerPath: 'companies',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listCombiningCompanies: builder.query<CompanyToCombineDto[], string>({
      query: (comapnyIdsList) => ({
        url: URL,
        method: 'GET',
        params: { companies: comapnyIdsList },
      }),
    }),
    combineCompanies: builder.mutation<CompanyDto, CombineCompaniesDto>({
      query: (query) => ({
        url: URL,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: ['Contacts', 'dupes'],
    }),
  }),
  tagTypes: ['Contacts', 'dupes'],
});
