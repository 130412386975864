import { plainToInstance, Type } from "class-transformer";
import { ArrayMinSize, IsArray } from "class-validator";
import { BankAccountDto } from "./bank-account.dto";
import { CashflowDto } from "./cashflow.dto";
import { PayOrderGroup, PayOrderStatus } from "./enums/payorder";
import { Identifiable } from "./identifiable";
import { InvoiceDto } from "./invoice.dto";


export class PayOrderDto extends Identifiable {
  description: string;
  group: PayOrderGroup;

  bankAccount: BankAccountDto;
  cashflow: CashflowDto;

  status: PayOrderStatus;
  documentNumber: string;

  @Type()
  paymentDate: Date;

  @Type(() => PayOrderRowDto)
  rows: PayOrderRowDto[];

  static fromPlain(plain: any) { return plainToInstance(PayOrderDto, plain) };
}

export class CreatePayOrderDto {
  status: PayOrderStatus;
  description: string;
  documentNumber: string;

  @Type()
  paymentDate: Date;

  group: PayOrderGroup;
  accountId: number;
  cashflowId: number;

  @Type()
  @IsArray()
  @ArrayMinSize(1)
  rows: CreateUpdatePayOrderRowDto[];
}

export class UpdatePayOrderDto extends CreatePayOrderDto implements Identifiable {
  id: number;
}


export class CreateUpdatePayOrderRowDto {
  id: number;
  invoiceId: number;
  amount: number;
}

export class PayOrderRowDto extends CreateUpdatePayOrderRowDto {
  @Type(() => InvoiceDto)
  invoice: InvoiceDto;
}
