import { CreateInvoiceDto, INVOICE_TYPE_CLIENT, InvoiceDto, UpdateInvoiceResource } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import {
  sharedEmptyValues,
  SharedFormData,
  sharedFromDto,
  sharedToDto,
  sharedValidate
} from 'entities/invoice/shared-invoice.form';

export type FormData = SharedFormData & {
    contractId: number | '',
};

export const formTools: FormTools<InvoiceDto, CreateInvoiceDto, UpdateInvoiceResource, FormData> = {
  toUpdateDto: function (id: number, formData: FormData): UpdateInvoiceResource {
    throw new Error('Function not implemented.');
  },

  toCreateDto: function (formData: FormData): CreateInvoiceDto {
    const dto = sharedToDto(formData);
    dto.type = INVOICE_TYPE_CLIENT;
    dto.contractId = Number(formData.contractId);

    return dto;
  },

  fromDto: function (dto: InvoiceDto): FormData {
    return {
      ...sharedFromDto(dto),
      contractId: dto.contract && dto.contract.id,
    };
  },

  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult = sharedValidate(values) as FormikErrors<FormData>;

    if (!values.contractId)
      validationResult.contractId = VALIDATION_REQUIRED_FIELD;

    if (!values.managerId) {
      validationResult.managerId = VALIDATION_REQUIRED_FIELD;
    }

    return validationResult;
  },

  emptyValues: () => (
    {
      ...sharedEmptyValues(),
      contractId: '',
    })
};


