import { CompanyDto, CompanyWithManagersDto } from '../company.dto';
import { EmployeeDto } from '../person.dto';

export class ContactDupesDto {
  dupesCompanies: CompanyWithManagersDto[];
  dupesEmployees: EmployeeDto[];
}

export interface Duplicates {
  dupes?: ContactDupesDto;
}
