import { reportsApi } from '../reports.api';
import { CreateFinalBalanceDto, PeriodDto } from '@sr/dto';

export type CashBalanceQuery = {
  year: number,
  month: number,
};

const injectedApi = reportsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCashBalance: builder.query<any[], CashBalanceQuery>({
      query: ({ year, month }: CashBalanceQuery) => ({
        url: `cash-balance/${year}/${month}`,
      }),
    }),
    saveFinalBalance: builder.mutation<Omit<CreateFinalBalanceDto, 'createdById'>, Omit<CreateFinalBalanceDto, 'createdById'>>({
      query: (dto) => ({
        url: `cash-balance/save`,
        body: dto,
        method: 'POST',
      }),
    }),
    getCashBalancePeriods: builder.query<PeriodDto[], void>({
      query: () => ({
        url: 'cash-balance/periods',
        method: 'GET',
      }),
    }),
  }),
});

export const cashBalanceApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['cash-balance'],
  endpoints: {
    getCashBalance: {
      providesTags: (result, error, query) => result
        ? [{ type: 'cash-balance', id: `${query.year}-${query.month}` }]
        : ['cash-balance'],
    },
  },
});
