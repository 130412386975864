import { IsNotEmpty, IsString } from 'class-validator';
import { PersonDto } from './person.dto';
import { UserDto } from './user.dto';
import { CreateContactsDto, ICompanyContacts } from './contacts';
import { Type } from 'class-transformer';
import { LegalEntityBaseInfoDto } from './legal-entity.dto';
import { InvoiceInfoDto } from './invoice.dto';
import { LeadInfoDto } from './lead.dto';
import { ManagerLinkDto } from './link-manager.dto';

export class CreateCompanyDto {
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  type: number;

  @Type(() => CreateContactsDto)
  contacts?: CreateContactsDto;
}

export class CompanyDto extends CreateCompanyDto {
  id: number;
  // users: UserDto[];
}

export class UpdateCompanyDto extends CreateCompanyDto {
  id: number;
}

export class LegalEntityCompanyDto {
  id: number;
  company: CompanyDto;
}

export class CreateCompanyLegalEntityDto {
  companyId: number;
  legalEntityId: number;
}

export class CompanyWithManagersDto extends CompanyDto {
  viaPerson?: PersonDto;
  managers: UserDto[];
}


export class CompanyToCombineDto {
  id: number;
  name: string;
  managers: ManagerLinkDto[];
  legalEntitiesCount: number;
  clientInvoicesCount: number;
  supplierInvoicesCount: number;
  leadsCount: number;
}

export class CombineCompaniesDto {
  newName: string;
  companiesIds: number[];
}