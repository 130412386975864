import {Type} from "class-transformer";

export class PurchaseFilter {
    ourLegalEntityId?: number;
    managerId?: number;

    statusId?: number;

    @Type()
    fromDate?: Date;

    @Type()
    toDate?: Date;
}
