import { LeadDto } from "@sr/dto";
import { NotificationsConfig, useSnack } from "shared/ui/snack";
import { useUpdateLeadStatus } from "./lib";
import { LeadStatusPicker } from "entities/lead/lead-status-picker";

type Props = {
  lead?: LeadDto
}

const notificationConfig: NotificationsConfig = {
  itemTitleForm1: 'статус лида',
  itemTitleForm2: 'статуса лида',
  gender: "male"
}

export const LeadStatusChanger = ({ lead }: Props) => {
  const { showSuccessUpdate, showFailedUpdate } = useSnack(notificationConfig)
  const { update, isUpdating } = useUpdateLeadStatus();

  const handlestatusChange = (value: string | number) => {
    if (!lead)
      return;
    const newStatus = Number(value);
    if (isNaN(newStatus) || newStatus === 0)
      return;

    update({ leadId: lead.id, statusId: newStatus })
      .unwrap()
      .then(showSuccessUpdate)
      .catch(e => showFailedUpdate(e));
  }

  const val = lead?.status?.id ?? '';

  return (
    <LeadStatusPicker value={val} onChange={handlestatusChange} label="Статус" disabled={!lead || isUpdating} />
  )
}