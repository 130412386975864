import { Typography } from '@mui/material';
import { INVOICE_TYPE_CLIENT, InvoiceDto } from '@sr/dto';
import { useInvoiceMutableData } from 'entities/invoice/mutable-data/lib';
import { formatDate } from 'utils/date-format-helpers';

export const InvoiceTitle = ({ invoice }: { invoice: InvoiceDto }) => {

  const mutableData = useInvoiceMutableData();

  if (!invoice) return null;
  if (!mutableData) return null;


  const invoiceNumber =
    <Typography variant='h5' component='span' color='primary'>
      {invoice.invoiceNumber}
    </Typography>;

  return (
    <>
      <Typography variant='h5' component='span'>
        {invoice.type === INVOICE_TYPE_CLIENT
          ? <>Исходящий cчет {invoiceNumber} от {formatDate(mutableData.startDate)} для компании </>
          : `Входящий cчет от ${formatDate(mutableData.startDate)} от компании `}
      </Typography>
      <Typography variant='h5' component='span' color='primary'>
        {invoice.type === INVOICE_TYPE_CLIENT
          ? invoice.buyerCompany.name
          : invoice.sellerCompany.name}
      </Typography>
    </>
  );
};
