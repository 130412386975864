import { authorizedRequest } from 'shared/api/axios';
import { BankDetailsDto, CreateBankDetailsDto, UpdateBankDetailsDto } from '@sr/dto';

const BANK_DETAILS_BASE_URL = '/bank-details';

export const fetchBankDetailsList = async (legalEntityId: number) => {
  return authorizedRequest<void, BankDetailsDto[]>(
    `${BANK_DETAILS_BASE_URL}?legalEntityId=${legalEntityId}`,
    'GET'
  );
};

export const postBankDetails = async (bankDetails: CreateBankDetailsDto) => {
  return authorizedRequest<CreateBankDetailsDto, BankDetailsDto>(
    BANK_DETAILS_BASE_URL,
    'POST',
    bankDetails
  );
};

export const putBankDetails = async (bankDetails: UpdateBankDetailsDto) => {
  return authorizedRequest<UpdateBankDetailsDto, BankDetailsDto>(
    `${BANK_DETAILS_BASE_URL}`,
    'PUT',
    bankDetails
  );
};

export const deleteBankDetails = async (bankDetailsId: number) => {
  return authorizedRequest<void, void>(
    `${BANK_DETAILS_BASE_URL}/${bankDetailsId}`,
    'DELETE'
  );
};
