import { CompanySearchResultEntryDto, IPagedCollection, OptionalContacts } from '@sr/dto';
import { TextWithHighlight } from 'shared/ui/text-with-highlight';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { SearchResultSection } from './search-result-section';
import { formatPerson } from 'utils/person-format-helpers';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { ContactSpan } from './contact-span';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

type Props = {
  highlight: string;
  list: IPagedCollection<CompanySearchResultEntryDto>;
}

export const FoundCompanies = ({ list, highlight }: Props) => {
  return (
    <Box>
      <SearchResultSection
        title="Компании"
        items={list}
        content={list.items.map((entry, idx) =>
          <CompanySearchResultEntry
            key={`${entry.company.id}-${idx}`}
            entry={entry}
            highlight={highlight}
          />)}
        emptyResultText="Компании не найдены"
      />
    </Box>
  );
};


type CompanySearchResultEntryProps = {
  entry: CompanySearchResultEntryDto;
  highlight: string;
}

export const CompanySearchResultEntry = ({ entry, highlight }: CompanySearchResultEntryProps) => {

  return (
    <Paper sx={{ p: 0, mt: 1 }} >
      <Stack direction="column">
        <Box m={1}>
          <LinkToCompany company={entry.company}>
            <TextWithHighlight component='span' variant='body1' hlText={highlight}>
              {entry.company.name}
            </TextWithHighlight>
          </LinkToCompany>
        </Box>

        <Stack direction="row" alignItems="start" >
          {entry.directContacts &&
            <ContactPanel>
              <Typography component='span' color="grey.600">Прямые контакты:</Typography><br></br>
              {renderContacts(entry.directContacts, highlight)}
            </ContactPanel>
          }

          {entry.viaPeople?.map(p =>
            <div>
              <ContactPanel>
                <Stack direction="row" alignItems="center">
                  <AccountBoxIcon color='disabled' sx={{ mr: 1 }} />
                  <Typography component='span' color="grey.600">{formatPerson(p.person)}</Typography><br></br>
                </Stack>
                {p.personContact && renderContacts(p.personContact, highlight)}
              </ContactPanel>
            </div>
          )
          }

        </Stack>
      </Stack >
    </Paper >
  );
};

const renderContacts = (contacts: OptionalContacts, highlight: string) => {
  return (
    <Stack direction="column">
      {contacts.emails &&
        <Box>
          <ContactSpan contactType="email">
            {contacts.emails.map(x =>
              (<TextWithHighlight key={x} component='span' variant='caption' hlText={highlight}>{`${x}, `}</TextWithHighlight>)
            )}
          </ContactSpan>
        </Box>
      }
      {contacts.phones &&
        <Box>

          <ContactSpan contactType="phone">
            {contacts.phones.map(x =>
              (<TextWithHighlight key={x} component='span' variant='caption' hlText={highlight}>{`${x}, `}</TextWithHighlight>)
            )}
          </ContactSpan>
        </Box>
      }
      {contacts.urls &&
        <Box>
          <ContactSpan contactType="url">
            {contacts.urls.map(x =>
              (<TextWithHighlight key={x} component='span' variant='caption' hlText={highlight}>{`${x}, `}</TextWithHighlight>)
            )}
          </ContactSpan>
        </Box>
      }
    </Stack>
  )
}

const ContactPanel = ({ children }: { children: React.ReactNode }) => (
  <Paper sx={{ p: 1, m: 1, backgroundColor: "grey.200" }} variant='elevation' elevation={0}>
    {children}
  </Paper>
)

{/* <ContactSpan contactType={contactInfo[0]}>
  <TextWithHighlight component='span' variant='caption' hlText={highlight}>
    {contactInfo[1]}
  </TextWithHighlight>
</ContactSpan> */}




