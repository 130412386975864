import { Button, Card, CardActions, CardContent, MenuItem, Stack } from '@mui/material';
import { CONTRACT_STATUSES, CONTRACT_STATUSES_KEYS, ContractDto } from '@sr/dto';
import { FormData, formTools } from './contract-basic.form-tools';
import { Field, Formik, FormikProps } from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { Select, TextField } from 'formik-mui';
import { UserPickerField } from 'features/user/user-picker/ui';
import { SwitchField } from 'shared/ui/formik-fields/switch-field';
import { ContractTemplatePickerField } from 'shared/ui/formik-fields//contract-template-picker-field';
import FilePickerField from 'shared/ui/formik-fields/file-picker';

type Props = {
    contract: ContractDto,
    updateContract: (formData: FormData) => Promise<void>;
};

export const ContractBasicsCard = ({ contract, updateContract }: Props) => {
  const initialValues = formTools.fromDto(contract);
  return (
    <Card>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={updateContract}
        validate={formTools.validate}
        validateOnChange={true}
      >
        {({ submitForm, isSubmitting, isValid, dirty, values }: FormikProps<FormData>) => (
          <>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Field
                  name="name"
                  label="Название"
                  component={TextField}
                />

                <Field
                  name="startDate"
                  label="Дата договора"
                  component={DatePickerField}
                />

                <Field
                  disabled={values.unlimited}
                  name="endDate"
                  label="Дата окончания действия договора"
                  component={DatePickerField}
                />
                <Field
                  name="unlimited"
                  label="Бессрочный"
                  type="checkbox"
                  component={SwitchField}
                />

                <Field
                  name="autoprolong"
                  label="Автопродление"
                  type="checkbox"
                  component={SwitchField}
                />

                <Field
                  name="scanFile"
                  label="Файл скана"
                  component={FilePickerField}
                />

                <Field
                  name="contractTemplateId"
                  label="Тип договора"
                  component={ContractTemplatePickerField}
                />

                <Field
                  name="status"
                  label="Статус"
                  component={Select}
                >
                  {CONTRACT_STATUSES_KEYS.map(key =>
                    <MenuItem key={key} value={key}>
                      {CONTRACT_STATUSES[key]}
                    </MenuItem>
                  )}
                </Field>

                <Field
                  name="managerId"
                  label="Менеджер"
                  component={UserPickerField}/>

              </Stack>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 1 }}
                onClick={submitForm}
                disabled={!dirty || isSubmitting || !isValid}
              >
                                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card>
  );
};
