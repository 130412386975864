import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { wccBulkCreateApi } from './api'

export const useBulkCreate = () => {

  const [runBulkCreate, { data, isLoading, error, reset }] = wccBulkCreateApi.useRunBulkCreateMutation();

  // const [report, setReport] = useState<BulkCreateReportDto | null>(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState('');

  const create = async (idList: number[]) => {
    if (idList.length === 0) return Promise.reject();

    return runBulkCreate(idList).unwrap();
  }

  // const handleSubmitBulkCreate = async (ids: number[]) => {
  //   setLoading(true);
  //   return postBulkCreateWcc(ids)
  //     .then((response) => setReport(response))
  //     .catch(e => setError(e.message))
  //     .finally(() => setLoading(false));
  // }

  return {
    create,
    report: data || null,
    loading: isLoading,
    error: extractNestJsErrorMessage(error),
    reset
  }
}