import { CheckAllRowsDto, CreatePayOrderImportSessionDto, ImportedPayorderDto, PayOrderImportSessionDto, UpdateImportedPayorderDto } from '@sr/dto';
import { plainToInstance } from 'class-transformer';
import { useCallback } from 'react';
import { authorizedRequest } from 'shared/api/axios';
import { useRemoteCollection } from 'utils/remote-collection.hook';
import { useRemoteItem } from 'utils/remote-item.hook';

const BASE_URL = 'accounting/payorders/import';

export const usePayOrdersImportSessions = () => {
  return useRemoteCollection<PayOrderImportSessionDto, CreatePayOrderImportSessionDto, void>({
    fetchHandler: useCallback(async () => {
      const results = await authorizedRequest<void, PayOrderImportSessionDto[]>(
        BASE_URL,
        'GET');
      return results.map(p => plainToInstance(PayOrderImportSessionDto, p, { enableImplicitConversion: true }));
    }, []),
    deleteHandler: useCallback(async (id: number) => {
      await authorizedRequest(
        `${BASE_URL}/${id}`,
        'DELETE');
    }, []),
    sort: useCallback((a: PayOrderImportSessionDto, b: PayOrderImportSessionDto) => b.id - a.id, [])
  });
};

export const usePayOrderImportSession = (sessionId: number) => {
  return useRemoteItem<PayOrderImportSessionDto>({
    fetchHandler: useCallback(async () => {
      const result = await authorizedRequest<void, PayOrderImportSessionDto>(
        `${BASE_URL}/${sessionId}`,
        'GET');
      return plainToInstance(PayOrderImportSessionDto, result, { enableImplicitConversion: true });
    }, [sessionId]),
  });
};

export const useImportedPayorders = (sessionId: number) => {
  return useRemoteCollection<ImportedPayorderDto, void, UpdateImportedPayorderDto>({
    fetchHandler: useCallback(async () => {
      const result = await authorizedRequest<void, ImportedPayorderDto[]>(
        `${BASE_URL}/${sessionId}/rows`,
        'GET');
      return plainToInstance(ImportedPayorderDto, result, { enableImplicitConversion: true });
    }, [sessionId]),

    updateHandler: useCallback(async (dto: UpdateImportedPayorderDto) => {
      const response = await authorizedRequest<UpdateImportedPayorderDto, ImportedPayorderDto>(
        `${BASE_URL}/rows`,
        'PUT',
        dto);
      return plainToInstance(ImportedPayorderDto, response, { enableImplicitConversion: true });
    }, [])
  });
};

export const useUpdateSelected = () => {
  const updateAllState = useCallback(async (sessionId: number, checked: boolean) =>
    await authorizedRequest<CheckAllRowsDto, CheckAllRowsDto>(
      `${BASE_URL}/rows/checkAll`,
      'PUT',
      { sessionId, checked }
    ), []);

  return {
    updateAllState
  };
};
