import { MediaInfoDto } from '@sr/dto';
// import { deleteMedia } from 'entities/media/api';
import { mediaListApi } from 'entities/media/media-list/api-slice';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';

export const useDeleteMedia = () => {
  const showConfirmDialog = useConfirmDialog();
  const { showSuccess, showError } = useSnack();


  const [deleteMedia] = mediaListApi.useDeleteMediaMutation();

  return (media: MediaInfoDto) => {
    showConfirmDialog({
      title: 'Вы уверны?',
      content: `Удалить СМИ ${media?.name}?`
    })
      .then(() =>
        deleteMedia(media.id).unwrap()
          .then(() => showSuccess('СМИ успешно удалено'))
          .catch(e => showError('Ошибка удаления СМИ', e))
      );

  };
}