import { useState } from 'react';
import { Button, CardActions, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BankDetailsDto } from '@sr/dto';
import { useBankDetailsList } from 'hooks/bank-details.hook';
import { BankDetailsDialog } from 'components/Companies/BankDetails/bank-details-dialog';
import { FormData } from 'components/Companies/BankDetails/bank-details.form';
import { useSnack } from 'shared/ui/snack';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import MenuParams from 'shared/ui/menu-params';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { ListLayout } from 'shared/ui/list-layout';

export const LegalEntityBankDetails = (props: { legalEntityId: number; }) => {
  const { legalEntityId } = props;

  const { showSuccess, showError } = useSnack();
  const showConfirm = useConfirmDialog();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [editBankDetails, setEditBankDetails] = useState<BankDetailsDto | undefined>(undefined);

  const { collection, addBankDetails, updateBankDetails, removeBankDetails } = useBankDetailsList(legalEntityId);

  const { openMenu, menuData } = useMenu<BankDetailsDto>({
    deleteHandler: (bankDetails: BankDetailsDto) => {
      showConfirm({
        content: 'Удалить банковские реквезиты?'
      })
        .then(() => {
          return removeBankDetails(bankDetails.id);
        })
        .then(() => showSuccess('Реквизиты успешно удалены'))
        .catch(e => showError(`Ошибка удаления реквезитов: ${e.message}`));
    },
    editHandler: (bankDetails: BankDetailsDto) => {
      setEditBankDetails(bankDetails);
      setEditDialogOpen(true);
    }
  });

  const handleAddBankDetails = async (formData: FormData) => {
    return addBankDetails(formData)
      .then(() => {
        setAddDialogOpen(false);
        showSuccess('Реквизиты успешно добавлены');
      })
      .catch(e => showError(`Ошибка добавления реквезитов: ${e.message}`));
  };

  const handleUpdateBankDetials = async (formData: FormData) => {
    if (!editBankDetails) return;

    return updateBankDetails(editBankDetails.id, formData)
      .then(() => {
        closeEditDialog();
        showSuccess('Реквизиты успешно обновлены');
      })
      .catch(e => showError(`Ошибка обновления реквезитов: ${e.message}`));
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setEditBankDetails(undefined);
  };

  return (
    <>

      <ListLayout<BankDetailsDto>
        items={collection}
        itemRender={
          (i) => <BankDetailsItem key={i.id} bankDetails={i} onMenuClick={openMenu} />
        }
        noItemsText='Список реквизитов пуст'
      />
      {/* <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {list.map(x =>
            )
          }
        </List> */}
      <CardActions>
        <Button
          sx={{ mt: 2, ml: 'auto' }}
          variant="contained"
          autoFocus
          onClick={() => setAddDialogOpen(true)}
        >
          Добавить
        </Button>
      </CardActions>


      <BankDetailsDialog
        open={addDialogOpen}
        title='Добавить банковские реквизиты'
        onClose={() => setAddDialogOpen(false)}
        submit={handleAddBankDetails} />

      <BankDetailsDialog
        open={editDialogOpen}
        title='Редактировать банковские реквизиты'
        values={editBankDetails}
        onClose={closeEditDialog}
        submit={handleUpdateBankDetials} />

      <RowMenu menuData={menuData} />
    </>
  );
};



type ItemProps = {
  bankDetails: BankDetailsDto;
  onMenuClick: (menuParams: MenuParams<BankDetailsDto>) => void;
};

const BankDetailsItem = (props: ItemProps) => {
  const {
    bankDetails,
    onMenuClick
  } = props;

  const { bankName, bik, accountNumber, corrAccountNumber } = bankDetails;

  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" onClick={(e) => onMenuClick({ anchor: e.currentTarget, target: bankDetails })}>
          <MoreVertIcon />
        </IconButton>}
    >
      <ListItemText
        primary={`${bankName}, БИК: ${bik}`}
        secondary={
          <>
            <Typography component="span" color="text.secondary">Счет: </Typography>
            <Typography component="span" color="text.primary">{accountNumber}</Typography>,
            &nbsp;
            <Typography component="span" color="text.secondary">Корр. счет: </Typography>
            <Typography component="span" color="text.primary">{corrAccountNumber}</Typography>
          </>}
      />
    </ListItem>);
};