import { Stack, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { InvoiceDto, InvoiceRowDto } from '@sr/dto';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { InvoiceStatusSpan } from 'entities/invoice/status-span/status-span';
import { LegendItem } from 'shared/ui/legend-list';
import PriceSpan from 'shared/ui/price-span';
import ManagerSpan from 'shared/ui/manager-span';


type Props = {
  invoice: InvoiceDto,
  invoiceRow: InvoiceRowDto;
};

export const PurchaseClientInvoiceCard = ({ invoice, invoiceRow }: Props) => {
  return (
    <Card>
      <CardHeader title="Клиент"></CardHeader>
      <CardContent sx={{ pt: 0 }}>
        <Stack direction='column'>
          <LegendItem title="Фирма: " value={<LinkToCompany company={invoice.buyerCompany} />}></LegendItem>
          <LegendItem title="Юрлицо:" value={<LinkToLegalEntity legalEntity={invoice.buyer} />} />
          <LegendItem title="Наше юрлицо:" value={<LinkToLegalEntity legalEntity={invoice.seller} />} />
          <Divider sx={{ my: 1 }} />
          <LegendItem title="Счет: " value={
            <>
              <LinkToInvoice invoice={invoice} />
              &nbsp;
              <InvoiceStatusSpan status={invoice.status} size="small"/>
            </>
          } />
          <LegendItem title="Менеджер: " value={<ManagerSpan person={invoice.manager}/>} />
          <LegendItem title="Сумма счета: " value={<PriceSpan price={invoice.totals.priceTotal}/>} />
          <LegendItem title="Сумма оплат: " value={<PriceSpan price={invoice.totals.partialPaidAmount} />} />
        </Stack>
      </CardContent>
    </Card>
  );
};
