import { Box } from '@mui/material';
import { IPagingData, WccDto } from '@sr/dto';
import { WccList } from 'entities/wcc/wcc-list';
import { useWccList } from 'entities/wcc/wcc-list/lib';
import { deleteInvoiceWcc } from 'features/invoice/invoice-wcc/api';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useState } from 'react';
import { DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { NotificationsConfig, useSnack } from 'shared/ui/snack';

const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'акт выполненных работ',
  itemTitleForm2: 'акта выполненных работ',
  gender: 'neutral'
};

export const ClientWccListTab = () => {
  const { showSuccessDelete, showFailedDelete } = useSnack(notificationsConfig);
  const showConfirmDialog = useConfirmDialog();

  const [paging, setPaging] = useState<IPagingData>(DEFAULT_PAGINATION);
  const collection = useWccList(paging);

  const handleDeleteWcc = (wcc: WccDto) => {
    showConfirmDialog({
      content: 'Удалить акт выполненных работ?'
    })
      .then(() => {
        deleteInvoiceWcc(wcc.invoice.id)
          .then(showSuccessDelete)
          .catch(e => showFailedDelete(e))
          .finally(() => setPaging({ ...paging }));
      });
  };

  return (
    <Box mt={2}>
      <WccList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onDelete={handleDeleteWcc} />
    </Box>
  )
}