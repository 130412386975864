import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import {
  LEGAL_ENTITY_SEARCH_MAX_LENGTH,
  LEGAL_ENTITY_SEARCH_MIN_LENGTH,
  legalEntitiesApi
} from './legal-entities.api';
import { CreateLegalEntityDto, LegalEntityDto, UpdateLegalEntityDto } from '@sr/dto';
import { useEffect, useState } from 'react';

export const useLegalEntities = ({ companyId, search }: {companyId?: number, search?: string}) => {
  const [debouncedSearch, setDebouncedSearch] = useState('');
  useEffect(() => {
    if (!companyId && (!search || search.length < LEGAL_ENTITY_SEARCH_MIN_LENGTH || search.length > LEGAL_ENTITY_SEARCH_MAX_LENGTH)) {
      return;
    }
    const debouncer = setTimeout(() => {
      setDebouncedSearch(search ?? '');
    }, 500);
    return () => clearTimeout(debouncer);
  }, [search]);

  const { data, isLoading, isFetching, error } = legalEntitiesApi.useGetCompanyLegalEntitiesQuery(
    { companyId, search: debouncedSearch },
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    legalEntities: data ? data : [] as LegalEntityDto[],
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};

export const useLegalEntity = (id: number) => {
  const { data, isLoading, isFetching, error } = legalEntitiesApi.useGetLegalEntityQuery(
    id,
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    legalEntity: data ? data : undefined,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};

export const useAddCompanyLegalEntity = (companyId: number) => {
  const [create, { isLoading, error }] = legalEntitiesApi.useAddCompanyLegalEntityMutation();
  return async (formData: CreateLegalEntityDto)=> {
    return create({ companyId, ...formData }).unwrap();
  };
};

export const useUpdateLegalEntity = () => {
  const [update, { isLoading, error }] = legalEntitiesApi.useUpdateLegalEntityMutation();
  return async (legalEntity: UpdateLegalEntityDto) => {
    return update({ ...legalEntity }).unwrap();
  };
};
