import { IPagedCollection, LeadMediaplanDto, UpdateLeadMediaplanDto } from '@sr/dto';
import { leadBaseApi } from 'entities/lead/api';
import { LEAD_BASE_URL } from 'shared/api/api';
import { toRtkFormData } from 'utils/to-form-data';
import { CollectionParams } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';

const injectedApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMediaPlan: builder.query<LeadMediaplanDto, { leadId: number }>({
      query: ({ leadId }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/mediaplan`,
        method: 'GET',
      }),
    }),
    getMediaPlans: builder.query<IPagedCollection<LeadMediaplanDto>, CollectionParams>({
      query: ({ paging, filter }: CollectionParams) => ({
        url: `${LEAD_BASE_URL}/lead-media-plans/?${filter}`,
        params: paging && pagingDataToParams(paging),
        method: 'GET'
      }),
    }),
    updateMediaPlan: builder.mutation<LeadMediaplanDto, { id: number; data: UpdateLeadMediaplanDto }>({
      query: ({ id, data }) => ({
        url: `${LEAD_BASE_URL}/${id}/mediaplan`,
        body: toRtkFormData<UpdateLeadMediaplanDto>(data),
        formData: true,
        method: 'PUT',
      }),
    }),
  }),
  overrideExisting: true,
});

export const leadMediaplanApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['leadMediaPlan', 'leadMediaPlans'],
  endpoints: {
    getMediaPlan: {
      providesTags: (result) => (
        result ? [{ type: 'leadMediaPlan', id: result.id }] : ['leadMediaPlan']
      ),
    },
    getMediaPlans: {
      providesTags: (result, error, query) => (
        result ?
          [{
            type: 'leadMediaPlans',
            id: `${String(query.filter)}_${String(query.paging?.page)}_${String(query.paging?.itemsPerPage)}`
          }]
          : ['leadMediaPlans']
      ),
    },
    updateMediaPlan: {
      invalidatesTags: ['leadMediaPlan'],
    },
  },
});

// export const fetchLeadMediaplan = async (leadId: number) => {
//   const response = await authorizedRequest<void, LeadMediaplanDto>(
//     `${LEAD_MEDIAPLAN_URL}/${leadId}/mediaplan`,
//     'GET'
//   );
//   return response;
// };

// export const putLeadMediaplan = async (
//   leadId: number,
//   data: UpdateLeadMediaplanDto
// ) =>
//   putReq(`${LEAD_MEDIAPLAN_URL}/${leadId}/mediaplan`, data, LeadMediaplanDto);
