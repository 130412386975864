export type LoadingStatus = null | 'loading' | 'done' | 'error';

export type AsyncMeta = {
  status: LoadingStatus;
  error: string;
}

export const initialMeta: AsyncMeta = {
  status: null,
  error: ''
}

export const metaState = {
  pending: (meta: AsyncMeta) => {
    meta.status = 'loading';
    meta.error = '';
  },

  done: (meta: AsyncMeta) => {
    meta.status = 'done';
    meta.error = '';
  },

  error: (meta: AsyncMeta, msg?: string) => {
    meta.status = 'error';
    meta.error = msg || '';
  }
}
