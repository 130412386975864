import { CityBasicDataDto, UpdateCityBasicDataDto } from '@sr/dto';
import { FormikErrors } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { fromNullableStringField, toNullableStringField } from 'utils/form-helper';

export interface FormValues {
  name: string;
  regionId: number | '';
  isCapital: boolean;
  phoneCode: string;
  timeZone: string;
}


export const fromDto = (dto: CityBasicDataDto): FormValues => {
  return {
    name: dto.name,
    regionId: dto.region.id,
    isCapital: dto.isCapital,
    phoneCode: fromNullableStringField(dto.phoneCode),
    timeZone: fromNullableStringField(dto.timeZone)
  };

};

export const toUpdateDto = (id: number, formData: FormValues): UpdateCityBasicDataDto => ({
  id: id,
  name: formData.name,
  regionId: Number(formData.regionId),
  isCapital: formData.isCapital,
  phoneCode: toNullableStringField(formData.phoneCode),
  timeZone: toNullableStringField(formData.timeZone),
})


export const initialValues: FormValues = {
  name: '',
  regionId: '',
  isCapital: false,
  timeZone: '',
  phoneCode: '',
}

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.name) errors.name = VALIDATION_REQUIRED_FIELD;

  if (!values.regionId) errors.regionId = VALIDATION_REQUIRED_FIELD;

  return errors;
}
