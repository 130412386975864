import {
  CreateRoleDto, PermissionDto, RoleDto,
  UpdateRoleDto
} from "@sr/dto";
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { NotificationsConfig } from 'shared/ui/snack';
import { FormikErrors } from 'formik';

export type PopupFormData = {
  title: string,
  permissions: PermissionDto[]
};

export const userRolesNotificationConfigs: NotificationsConfig = {
  itemTitleForm1: 'новая роль',
  itemTitleForm2: 'новую роль',
  gender: 'female'
};

export const formTools: FormTools<RoleDto, CreateRoleDto, UpdateRoleDto, PopupFormData> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    title: formData.title,
    permissions: formData.permissions.map((item) => item.id),
  }) ,
  toCreateDto: formData => ({
    title: formData.title,
    permissions: formData.permissions.map((item) => item.id),
  }),
  fromDto: (dto) => ({
    title: dto.title,
    permissions: dto.permissions,
  }),
  validate: (values: PopupFormData): FormikErrors<PopupFormData> => {
    let validationResult: FormikErrors<PopupFormData> = {};
    if (!values.title) {
      validationResult.title = VALIDATION_REQUIRED_FIELD;
    }

    if (!values.permissions.length) {
      validationResult.permissions = VALIDATION_REQUIRED_FIELD;
    }

    return validationResult;
  },
  emptyValues: () => ({
    title: '',
    permissions: []
  })
};
