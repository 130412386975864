export class ContractTemplateInfoDto {
  id: number;
  name: string;
}

export class ContractTemplateDto extends ContractTemplateInfoDto {
  templateContent: string;
}

export class CreateContractTemplateDto {
  name: string;
  templateContent: string;
}

export class UpdateContractTemplateDto {
  id: number;
  name: string;
  templateContent: string;
}
