import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { fetchOurCompanyId } from 'entities/company/details/api';
import { createContext, ReactNode, useEffect, useState } from 'react';

export type PortalSettings = {
  ourCompanyId: number;
};

export const PortalSettingsContext = createContext<PortalSettings>({} as PortalSettings);

const PortalSettingsProvider = ({ children }: { children: ReactNode; }) => {
  const [settings, setSettings] = useState<PortalSettings | null>(null);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    fetchOurCompanyId()
      .then(response => setSettings({ ourCompanyId: response }))
      .catch(setError);
  }, []);

  if (!settings)
    if (error)
      return <ErrorBanner errorMessage='Ошибка получения настроек портала' />;
    else
      return <LoadingBanner />;

  return (
    <PortalSettingsContext.Provider value={settings}>
      {children}
    </PortalSettingsContext.Provider>
  );
};


export default PortalSettingsProvider;