import {PersonDto} from "./person.dto";
import {UserDto} from "./user.dto";
import {CompanyDto} from "./company.dto";
import {IsDate, IsEnum, IsNotEmpty, IsNumber, IsString} from "class-validator";
import {Type} from "class-transformer";

export type ToggleEventDto = Pick<EventDto, 'id'|'completed'|'important'>

const eventTypes = ['call', 'email', 'meeting'] as const;

export type EventTaskType = typeof eventTypes[number];

export class EventDto {
  id: number;
  taskType: string;
  content: string;
  company: CompanyDto;
  createdDate: Date;
  dueDate?: Date;
  important: boolean;
  completed: boolean;
  contactPerson?: PersonDto;
  manager: UserDto;
  leadId?: number;
}

export class CreateEventDto implements Partial<EventDto> {
  @IsString()
  @IsNotEmpty()
  @IsEnum(eventTypes)
  taskType: EventTaskType;

  @IsString()
  @IsNotEmpty()
  content: string;

  @IsDate()
  @IsNotEmpty()
  @Type(()=>Date)
  dueDate: Date;

  @IsNotEmpty()
  @IsNumber()
  contactPersonId: number;
}

