import { InvoicesList } from 'entities/invoice/list/invoices-list';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { InvoiceInfo, } from '@sr/dto';
import { useClientInvoices } from 'entities/client-invoices-list/lib/client-invoices-list.hook';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useSearchParams } from 'react-router-dom';

const title = 'Клиентские счета';

const ClientInvoicesListPage = () => {
  useTitleHook(title);
  const [paging, setPaging] = usePagingParams();
  const [searchParams] = useSearchParams();

  const { collection } = useClientInvoices(paging, searchParams.toString());

  const showConfirmDialog = useConfirmDialog();

  const handleConfirmDelete = (invoice: InvoiceInfo) => {
    if (!invoice)
      return;
  };

  const handleDeleteInvoice = (invoice: InvoiceInfo) => {
    showConfirmDialog({
      content: 'Удалить счет?'
    })
      .then(() => handleConfirmDelete(invoice));
  };

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title={title} />

      <InvoicesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onDelete={handleDeleteInvoice}
      />
    </>
  );
};

export default ClientInvoicesListPage;
