export const toRtkFormData = <T extends Object>(dto: T): FormData=> {
  const formData = new FormData();
  for (const [key, value] of Object.entries(dto)) {
    if (value===null){
      formData.append(key, 'null');
      continue;
    }
    if (typeof value==='string' || value instanceof Blob){
      formData.append(key, value);
    }
    if (typeof value ==='number'){
      formData.append(key, String(value));
    }
    if (typeof value==='boolean'){
      formData.append(key, String(value));
    }
  }
  return formData;
};
