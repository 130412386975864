import { TextField, Autocomplete, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { InvoiceInfoDto } from '@sr/dto';
import { useInvoicesDropdown } from 'entities/client-invoices-list/lib/dropdown.hook';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { formatDate } from 'utils/date-format-helpers';

export const InvoicePickerField = (props: TextFieldProps & { legalEntityIdFilter?: number; }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
    disabled,
    legalEntityIdFilter
  } = props;


  // let legalEntityId;
  // if (legalEntityIdFilter)
  //   legalEntityId = legalEntityIdFilter;

  // const [selectedEntity, setSelectedEntity] = useState<InvoiceInfoDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: any) => {
      // setSelectedEntity(newValue);
      setFieldValue(name, newValue ? newValue.id : '');
    },
    [setFieldValue, name]
  );

  // useEffect(() => {
  //   setSelectedEntity(items.find(x => x.id === value) ?? null);
  // }, [items, value]);


  return (
    <InvoicePicker
      value={value}
      label={label}
      textFieldProps={fieldToTextField(props)}
      onChange={onChange}
    />

    // <Autocomplete
    //   value={selectedEntity}
    //   autoComplete={false}
    //   options={items}
    //   open={open}
    //   disabled={disabled}
    //   onOpen={() => {
    //     setOpen(true);
    //   }}
    //   onClose={() => {
    //     setOpen(false);
    //   }}
    //   onChange={onChange}
    //   isOptionEqualToValue={(option, value) => option.id === value.id}
    //   getOptionLabel={(option) => `${option.invoiceNumber} от ${formatDate(option.startDate)}`}
    //   loading={loading}
    //   loadingText={'Загрузка...'}
    //   noOptionsText={'Ничего не найдено'}
    //   fullWidth
    //   renderInput={
    //     (params) => <TextField
    //       {...fieldToTextField(props)}
    //       {...params}
    //       name={name}

    //       label={label}
    //       placeholder="Начните набирать..." />
    //   }
    // />
  );
};



type Props = {
  label: ReactNode;
  value: number | '';
  onChange: (e: any, newValue: InvoiceInfoDto | null) => void;
  disabled?: boolean;
  textFieldProps?: MuiTextFieldProps;
};

export const InvoicePicker = ({ label, value, onChange, disabled, textFieldProps }: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<InvoiceInfoDto | null>(null);
  const { items, isLoading } = useInvoicesDropdown();

  useEffect(() => {
    value && setSelectedEntity(items.find(x => x.id === value) ?? null);
  }, [items, value]);


  const handleChange = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue: InvoiceInfoDto | null) => {
      setSelectedEntity(newValue);
      // setFieldValue(name, newValue?.id);
      onChange(e, newValue);
    },
    [setSelectedEntity, onChange]
  );

  const loading = open && items.length === 0 && isLoading;


  return (
    <Autocomplete
      value={loading ? null : selectedEntity}
      autoComplete={false}
      options={items}
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.invoiceNumber} от ${formatDate(option.startDate)}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      fullWidth
      renderInput={
        (params) => <TextField
          {...textFieldProps}
          {...params}
          label={label}
          placeholder="Начните набирать..." />
      }
    />
  );
}


