import { Type } from 'class-transformer';
import {
  IsString,
  IsNotEmpty,
  IsNumber,
  IsDate,
  IsOptional,
  IsInt
} from 'class-validator';
import { CompanyDto } from './company.dto';
import { UserDto } from './user.dto';
import { DownloadFileDto } from './download-file.dto';
import { CityInfoDto } from "./geo/city";
import { MediaTypeBaseDataDto } from "./media-type.dto";

export class LeadInfoDto {
  @IsNumber()
  id: number;

  @IsString()
  @IsNotEmpty()
  subject: string;

  @Type(() => LeadStatusDto)
  status: LeadStatusDto;

  @Type(() => LeadBudgetDto)
  budget: LeadBudgetDto;

  @Type(() => LeadQualityDto)
  quality: LeadQualityDto;

  manager: UserDto;

  @Type(() => CompanyDto)
  company: CompanyDto;

  @IsDate()
  @Type(() => Date)
  createdAt: Date;
}

export class CreateLeadDto {
  @IsString()
  @IsNotEmpty()
  subject: string;

  // @Type(() => Number)
  statusId: number;

  @IsString()
  @IsNotEmpty()
  content: string;

  budgetId?: number;

  qualityId?: number;

  userId?: number;
}

export class UpdateLeadPropsDto {
  id: number;
  budgetId?: number;
  qualityId?: number;
}

export class LeadDto extends CreateLeadDto {
  id: number;
  status: LeadStatusDto;
  budget: LeadBudgetDto;
  quality: LeadQualityDto;
  company: CompanyDto;

  @IsDate()
  @Type(() => Date)
  createdAt: Date;

  manager: UserDto;
}

export class LeadStatusDto {
  id: number;
  label: string;
}

export class LeadBudgetDto {
  id: number;
  label: string;
}

export class LeadQualityDto {
  id: number;
  label: string;
}

export class LeadMediaplanDto {
  id: number;
  cityId: number | null;
  city: CityInfoDto | null;
  mediaTypeId: number | null;
  mediaType: MediaTypeBaseDataDto | null;
  offerFile: DownloadFileDto | null;
  createdBy: UserDto | null;
  createdAt: string;
}

export class LeadMediaplanFilter {
  @IsOptional()
  @IsInt()
  cityId?: number;

  @IsOptional()
  @IsInt()
  mediaTypeId?: number;
}

export class UpdateLeadMediaplanDto {
  cityId: number | null;
  mediaTypeId: number | null;
  offerFile: File | null | undefined;
}
