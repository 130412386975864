import { plainToInstance, Type } from "class-transformer";
import {
    IsBoolean,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";
import { Identifiable } from "./identifiable";

export class CreateLegalEntityTypeDto {
    @IsNotEmpty()
    @IsString()
    name: string;
  
    @IsString()
    description: string;
  
    @IsString()
    contractHeadLineTemplate: string;
  
    @IsString()
    contractBottomDetailsTemplate: string;

    @IsNumber()
    printFormCaptionsVariant: number;

    @IsBoolean()
    active: boolean;
  }
  
  export class LegalEntityTypeDto extends CreateLegalEntityTypeDto {
    @IsNumber()
    id: number;
  }

  // export declare class UpdateLegalEntityTypeDto extends CreateLegalEntityTypeDto implements Identifiable {
  //   @IsNumber()    
  //   id: number;
  // }
  
  export declare class UpdateLegalEntityTypeActiveDto {
    @IsNumber()    
    id: number;

    @IsBoolean()
    active: boolean;
  }

  export declare class UpdateLegalEntityTypeDto {
    @IsNumber()    
    id: number;

    @IsNotEmpty()
    @IsString()
    name: string;
  
    @IsString()
    description: string;

    @IsNumber()
    printFormCaptionsVariant: number;

    @IsBoolean()
    active: boolean;
}

  export declare class LegalEntityTypeBaseInfoDto {
    id: number;
    name: string;
    description: string;
    printFormCaptionsVariant: number;
    active: boolean;
    static fromPlain(obj: any): LegalEntityTypeDto;
  }