import { useEffect } from 'react';
import { IManagerLink } from '@sr/dto';
import { useActionCreators, useStateSelector } from 'shared/store';
import { linkedManagersThunks } from 'entities/company/linked-managers/linked-managers.slice';
import { IRemoteCollection } from 'utils/remote-collection.hook';

export const useLinkedManagers = () => {
  const companyId = useStateSelector(state => state.companyDetails.company?.id);
  const { loadThunk, postNewLinkThunk, deleteLinkThunk } = useActionCreators(linkedManagersThunks);

  useEffect(() => {
    companyId && loadThunk(companyId);
  }, [companyId]);


  const handleAddLinkSubmit = (newLink: IManagerLink) => {
    // dispatch({ type: 'closeDialog' });
    // showSuccess('Связь с менеджером успешно создана');
    // dispatch({ type: 'addLink', payload: newLink });
    // setLinks([...links, newLink]);
  };

  // const handleSubmit = async (formData: FormData) => {
  //   if (!companyId) {
  //     console.error('companyId is undefined');
  //     return;
  //   }

  //   const dto = toCreateDto(companyId, formData);

  //   return postNewLinkThunk(dto)
  //     .unwrap()
  //     .then(() => {
  //       showSuccess('Связь с менеджером успешно добавлена');
  //     })
  //     .catch((e: any) => showError(`Ошибка добавления связи с менеджером: ${e.message}`));
  // };


  // const handleSubmit1 = async (postData: ICreateManagerLink) => {
  //   postLinkManager(postData)
  //     .then(result => {
  //       // props.onSubmitSuccess(result);
  //     })
  //     .catch(error => {
  //       // props.onSubmitFailure(error.message);
  //     });
  // };

  // const handleDeleteLink = (linkId: number) => {
  //   // showConfirmDeleteDialog({
  //   //   content: 'Удалить связь с менеджером?'
  //   // })
  //   //   .then(() => {
  //   deleteLinkManager(linkId)
  //     .then((response) => {
  //       // setLinks(links.filter(link => link.id !== response.deletedLinkId));
  //       // showSuccess('Связь успешно удалена');
  //     })
  //     .catch(error => {
  //       // showError(`Ошибка удаления связи: ${error.message}`);
  //       refetchManagerLinks();
  //     });
  //   // });
  // };

  const links = useStateSelector(state => state.companyLinkedManagers);

  const items: IRemoteCollection<IManagerLink> = {
    items: links.links,
    isLoading: links.status === 'loading',
    error: links.error,
    reload: () => { }
  };

  return {
    links: items,
    // loadLinks: () => loadThunk(companyId),
    // addLink: (data: ICreateManagerLink) => postNewLinkThunk(data),
    // removeLink: () => deleteLinkThunk()
  };
};
