import {
  Box,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import MediaSuppliersPrices from 'features/prices/media-suppliers-prices';
import { MediaBasicDataCard } from 'features/media/basic-data-card/media-basic-data-card';

type Props = {
  mediaId: number;
}

export const MediaHomeTab = ({ mediaId }: Props) => {

  return (
    <Box mt={2}>
      <Stack direction='row' spacing={2}>
        <Box minWidth={500}>
          <MediaBasicDataCard mediaId={mediaId} />

        </Box>

        <Box minWidth={400} flexGrow={1}>
          <Paper sx={{ p: 2 }}>
            <Typography variant='h6' color='primary'>
              Поставщики и прайсы
            </Typography>
            <MediaSuppliersPrices mediaId={mediaId} />
          </Paper>
        </Box>
      </Stack>
    </Box>

  );
}
