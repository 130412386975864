import { useId } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { TextFieldProps } from 'formik-mui';
import { allSalaryTypes, manuallyAddedSalaryTypes } from '../salary-entries/salary-entry-types';

export const SalaryTypePicker = ({ field, form, label }: TextFieldProps) => {
  const { errors, setFieldValue } = form;
  const handleChange = async (e: SelectChangeEvent) => {
    await setFieldValue(field.name, e.target.value, true);
  };
  const id = useId();
  const error = errors[field.name] ? String(errors[field.name]) : '';
  return <FormControl fullWidth>
    <InputLabel id={`select-label-${id}`}>{label}</InputLabel>
    <Select
      labelId={`select-label-${id}`}
      id={`select-${id}`}
      label={label}
      value={field.value ?? ''}
      onChange={handleChange}
      disabled={false}
      error={!!error}
    >
      {allSalaryTypes
        .filter(type=> manuallyAddedSalaryTypes.includes(type.value))
        .map((type) => (
          <MenuItem
            key={type.value}
            value={type.value}
          >
            {type.label}
          </MenuItem>
        ))}
    </Select>
    {error && <FormHelperText error={true}>{error}</FormHelperText>}
  </FormControl>;
};
