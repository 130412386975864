import { BankDetailsDto, CreateBankDetailsDto, UpdateBankDetailsDto } from '@sr/dto';
import { fetchBankDetailsList, postBankDetails, putBankDetails, deleteBankDetails } from 'shared/api/bank-details';
import { FormData } from 'components/Companies/BankDetails/bank-details.form';
import { plainToInstance } from 'class-transformer';
import { useRemoteCollection } from 'utils/remote-collection.hook';
import { useCallback } from 'react';


export const useBankDetailsList = (legalEntityId?: number) => {
  const { collection, addItem, updateItem, removeItem } =
    useRemoteCollection<BankDetailsDto, CreateBankDetailsDto, UpdateBankDetailsDto>({
      fetchHandler: useCallback(() => {
        if (legalEntityId && !isNaN(legalEntityId)) return fetchBankDetailsList(legalEntityId);
        return Promise.resolve([] as BankDetailsDto[]);
      }, [legalEntityId]),
      postHandler: postBankDetails,
      updateHandler: putBankDetails,
      deleteHandler: deleteBankDetails,
    });

  const addBankDetails = async (formData: FormData) => {
    const dto = plainToInstance<CreateBankDetailsDto, FormData>(CreateBankDetailsDto, formData);
    dto.legalEntityId = legalEntityId!;
    await addItem(dto);
  };

  const updateBankDetails = async (id: number, formData: FormData) => {
    const dto = plainToInstance<UpdateBankDetailsDto, FormData>(UpdateBankDetailsDto, formData);
    await updateItem(id, dto);
  };

  const removeBankDetails = async (id: number) => {
    await removeItem(id);
  };

  return {
    collection,
    addBankDetails,
    updateBankDetails,
    removeBankDetails
  };
};



