import { Link } from 'shared/ui/link-base';
import { ContractTemplateInfoDto } from '@sr/dto';
import { contractLinkManager } from 'utils/link-manager';

export const LinkToContractTemplate = ({ template }: { template: ContractTemplateInfoDto }) => {
  const link = contractLinkManager.templateLink(template.id);
  return (
    <Link to={link} >
      {template.name}
    </Link>
  );
}