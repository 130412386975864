import { INVOICES_BASE_URL } from 'shared/api/api';
import { invoiceApi } from '../api-slice';
import { purchaseApi } from 'entities/purchase/purchase.api';
import { CreateInvoiceDto, InvoiceDto, InvoiceInfoDto } from '@sr/dto';

const injectedApi = invoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoice: builder.query<InvoiceDto, number>({
      query: (id: number) => ({
        url: `${INVOICES_BASE_URL}/${id}`,
        method: 'GET',
      }),
    }),
    addInvoice: builder.mutation<InvoiceInfoDto, { dto: CreateInvoiceDto, purchaseId?: number }>({
      query: ({ dto, purchaseId }: { dto: CreateInvoiceDto, purchaseId?: number }) => ({
        url: purchaseId ? `${INVOICES_BASE_URL}/for-purchase/${purchaseId}` : `${INVOICES_BASE_URL}`,
        body: JSON.stringify(dto),
        method: 'POST',
      }),
      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (query.purchaseId) {
            dispatch(purchaseApi.util.invalidateTags([{ type: 'purchases', id: query.purchaseId }]));
          }
        } catch (e) {
          console.error('RTK Query onQueryStarted error', e);
        }
      },
    }),
  }),
});

export const invoiceDataApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['invoice-data'],
  endpoints: {
    getInvoice: {
      providesTags: (result, error, arg) => ([{ type: 'invoice-data', id: arg }]),
    },
    addInvoice: {
      invalidatesTags: (result, error, query) => (result
        ? [{ type: 'invoice-data', id: result.id }]
        : [{ type: 'invoice-data' }]),
    },
  },
});
