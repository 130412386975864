import * as React from 'react';
import { IconButton, TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import {
  useDeleteSalaryEntry,
  useSalaryData
} from 'entities/salary/salary-entries/salary-entries.hook';
import { ADDITION_TYPES, DEDUCTION_TYPES, SalaryEntryDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { formatDateTime } from 'utils/date-format-helpers';
import { salaryTypesObj } from './salary-entry-types';
import PriceSpan from 'shared/ui/price-span';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnack } from 'shared/ui/snack';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';


const columns = [
  'Тип', 'Сумма', 'Описание', 'Внес', 'Дата внесения', 'Удаление'
];

const SalaryRow = ({ row, onDeleteClick } : { row: SalaryEntryDto, onDeleteClick: ( id:number) => Promise<void> })=> {
  return(
    <TableRow>
      <TableCell>
        {salaryTypesObj[row.type] ?? '-- неизвестный тип --'}
      </TableCell>
      <TableCell align='right'>
        <PriceSpan price={row.sum} size="small"/>
      </TableCell>
      <TableCell>
        {row.description}
      </TableCell>
      <TableCell>
        {row.createdBy.secondName} {row.createdBy.firstName}
      </TableCell>
      <TableCell>
        {formatDateTime(row.createdAt)}
      </TableCell>
      <TableCell align="right" sx={{ pr: 5 }}>
        <IconButton onClick={()=> onDeleteClick(row.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow >);
};

const SalaryTotal = (rows: SalaryEntryDto[]) => {
  const total = rows.reduce<number>((acc,row) => {
    if (ADDITION_TYPES.includes(row.type)){
      return acc + row.sum;
    }
    if (DEDUCTION_TYPES.includes(row.type)){
      return acc - row.sum;
    }
    throw new Error('Salary Total Calculation not possible. Unknown type');
  }, 0);
  return (
    <TableFooter>
      <TableRow>
        <TableCell><Typography variant='subtitle1'>Итого:</Typography> </TableCell>
        <TableCell align='right'><PriceSpan price={total} size="small"/></TableCell>
      </TableRow>
    </TableFooter>
  );
};

export const SalaryEntriesGrid = ({ year, month, userId }: {year: number, month: number, userId: number} ) =>{
  const { rows, isLoading, error } = useSalaryData(year, month,userId);
  const deleteSalaryEntry = useDeleteSalaryEntry(year, month, userId);
  const { showSuccess, showError } = useSnack();

  const handleRowDelete = async (id: number) => {
    await deleteSalaryEntry(id)
      .then(()=>{ showSuccess('Запись успешно удалена') })
      .catch(e=>{ showError('Ошибка удаления: ' + extractNestJsErrorMessage(e)) });
  };

  return <GridLayout
    columns={columns}
    items={rows}
    isLoading={isLoading}
    error={error}
    footer={SalaryTotal}
    itemRender={(row) => <SalaryRow key={row.id} row={row} onDeleteClick={handleRowDelete}/>}
    noItemsText="У выбраного пользователя нету начислений/удержаний в выбранном периоде"/>;
};
