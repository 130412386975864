import { Link } from 'shared/ui/link-base';
import { LeadDto, LeadInfoDto } from '@sr/dto';
import { leadLinkManager } from 'utils/link-manager';
import { ReactNode } from 'react';

export const LinkToLead = ({ lead: { id, subject }, children }: { lead: LeadDto | LeadInfoDto, children?: ReactNode }) => {
  const link = leadLinkManager.link(id);

  return (
    <Link to={link}>
      {children || subject}
    </Link>
  );
}