//import { IsNotEmpty, IsString } from "class-validator";

import { IsBoolean, IsNotEmpty, IsString } from "class-validator";

export class CreateCountryDto {
  @IsString()
  @IsNotEmpty()
  name: string;
}

export class CountryDto extends CreateCountryDto {
  id: number;
}

export class CountryInfoDto {
  id: number;
  name: string;
  publishingDataId: number | null;
}

export class UpdateCountryBasicDataDto {
  id: number;

  @IsString()
  @IsNotEmpty()
  name: string;
}
