import { useEffect, useState } from 'react';
import { Identifiable, IPagedCollection, IPagingData } from '@sr/dto';

export interface IRemotePagedCollection<T> {
    collection: IPagedCollection<T>,
    isLoading: boolean;
    error: string;
    reload?: () => void
}

interface ReadOnlyRemoteCollectionConfig<TItem extends Identifiable> {
    fetchHandler: () => Promise<IPagedCollection<TItem>>;
    sort?: (a: TItem, b: TItem) => number;
}


const emptyCollection = {
  items: [], totalItemsCount: 0
};

export const useReadOnlyRemotePagedCollection = <TItem extends {
    id: number;
}>(config: ReadOnlyRemoteCollectionConfig<TItem>) => {
  const { fetchHandler, sort } = config;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [collection, setCollection] = useState<IPagedCollection<TItem>>(emptyCollection);


  const reload = () => {
    if (fetchHandler === undefined) return;

    setCollection(emptyCollection);
    setLoading(true);
    setError('');

    fetchHandler()
      .then(response => {
        sort && response.items.sort(sort);
        setCollection(response);
      })
      .catch(e => setError(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(reload, [fetchHandler, sort]);


  const result: IRemotePagedCollection<TItem> = {
    collection: collection,
    isLoading: loading,
    error: error,
    reload
  };

  return result;
};

export const pagingDataToParams = (pagingData: IPagingData) => ({
  page: pagingData.page.toString(),
  itemsPerPage: pagingData.itemsPerPage.toString()
});
