import { UserDto } from '../user.dto';

export class CashBalanceDto {
  bankAccountId: number;
  bankAccountTitle: string;
  startDateBalanceValue: number;
  income: number;
  expenses: number;
  endDateBalanceValue: number;
  isSavedBalance: boolean;
  user?: UserDto;
  isAvailableForSave: boolean
}
