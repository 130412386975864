import { CreatePayOrderDto, IPagingData, PayOrderDto, UpdatePayOrderDto } from '@sr/dto';
import { useCallback } from 'react';
import { fetchPayOrders, postPayOrder, putPayOrder, deletePayOrder, fetchPayOrdersRelatedToInvoice } from 'shared/api/payorders';
import { useReadOnlyRemoteCollection, useRemoteCollection } from 'utils/remote-collection.hook';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const usePayOrders = (paging: IPagingData, filterString?: string) => {
  const collection = useReadOnlyRemotePagedCollection<PayOrderDto>({
    fetchHandler: useCallback(() => fetchPayOrders(paging, filterString), [filterString, paging]),
    sort: useCallback((a: PayOrderDto, b: PayOrderDto) => b.id - a.id, [])
  });

  const addItem = (data: CreatePayOrderDto) => postPayOrder(data);
  const updateItem = (data: UpdatePayOrderDto) => putPayOrder(data);
  const removeItem = (id: number) => deletePayOrder(id);

  return {
    collection,
    addItem,
    updateItem,
    removeItem
  }
};

export const useInvoiceRelatedPayOrders = (invoiceId: number) => {
  return useReadOnlyRemoteCollection<PayOrderDto>({
    fetchHandler: useCallback(() => fetchPayOrdersRelatedToInvoice(invoiceId), [invoiceId])
  });
};
