import { useLeadQualityDropdown } from 'entities/lead/lead-quality.hook';
import { withRemoteCollectionDropDownPicker } from './with-remote-collection-dropdown';
import { LeadQualityDto } from '@sr/dto';


export const LeadQualityPicker = withRemoteCollectionDropDownPicker({
  collectionProvider: useLeadQualityDropdown,
  renderDropdownLabel: (item: LeadQualityDto | '') => typeof item === 'string' ? item : item.label,
  renderEmptyValue: <>&nbsp;</>
});
