import { authorizedRequest } from 'shared/api/axios';
import { CompanyEmployeeDto, CreateCompanyEmployeeDto } from '@sr/dto';

export const fetchCompanyPersons = async (companyId: number) => {
  return authorizedRequest<void, CompanyEmployeeDto[]>(`/company-employees/${companyId}/persons`, 'GET');
};

export const postCompanyPerson = async (companyId: number, data: CreateCompanyEmployeeDto) => {
  return authorizedRequest<CreateCompanyEmployeeDto, CompanyEmployeeDto>(`/company-employees/${companyId}/persons`, 'POST', data);
};

export const removeCompanyPerson = async (personCompanyId: number) => {
  return authorizedRequest<void, void>(`/company-employees/link/${personCompanyId}`, 'DELETE');
};
// export const deleteCompanyEmail = async (companyEmailLinkId: number) => {
//   return authorizedRequest<void, void>(
//     '/company/emails/' + companyEmailLinkId,
//     'DELETE'
//   );
// };
