import { Switch, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { useSnack } from 'shared/ui/snack';
import { userActiveStatusApi } from './api';

export const UserActiveStatus = ({ userId }: { userId: number }) => {
  const { data, isFetching, isError } = userActiveStatusApi.useGetUserActiveStatusQuery(userId, { refetchOnMountOrArgChange: true });
  const [updateData, { isLoading: isUpdating }] = userActiveStatusApi.useUpdateUserActiveStatusMutation();

  const { showSuccess, showError } = useSnack();

  const isActive = data ? data.isActive : true;

  const handleChange = (e: ChangeEvent, checked: boolean) => {
    updateData({ userId, data: { isActive: checked } })
      .unwrap()
      .then(({ isActive }) => showSuccess(`Пользователь теперь ${isActive ? 'может' : 'не может'} входить в базу`))
      .catch(e => showError('Ошибка изменения статуса пользователя', e));
  };

  return (
    <FormControlLabel
      disabled={isFetching || isUpdating || isError}
      control={
        <Switch checked={isActive} onChange={handleChange} />
      }
      label="пользователь активен"
    />
  );
};
