import { MediaBasicDataDto, UpdateMediaBasicDto } from '@sr/dto';
import { FormikErrors } from 'formik';

export interface FormValues {
  name: string;
  mediaTypeId: number | '';
  cityId: number | '';
}

export const fromMedia = (media: MediaBasicDataDto): FormValues => ({
  mediaTypeId: media.mediaType.id,
  name: media.name,
  cityId: media.city.id,
});

export const toUpdateMedia = (id: number, formData: FormValues): UpdateMediaBasicDto => ({
  id: id,
  name: formData.name,
  typeId: Number(formData.mediaTypeId),
  cityId: Number(formData.cityId),
});

export const emptyValues: FormValues = {
  name: '',
  cityId: '',
  mediaTypeId: '',
};


export const validate = (values: FormValues) => {
  let errors: FormikErrors<FormValues> = {};

  const required = 'Обязательное поле';

  if (!values.name)
    errors.name = required;
  else if (values.name.length < 3)
    errors.name = 'Минимум 3 символа';
  else if (values.name.length >= 256)
    errors.name = 'Максимум 256 символов';


  if (!values.mediaTypeId)
    errors.mediaTypeId = required;

  if (!values.cityId)
    errors.cityId = required;

  return errors;
};
