import { localStorageWrapper } from 'utils/storage-wrapper';

const AuthTokenKey = 'authToken';
const RefreshTokenKey = 'refreshToken';

export const getAccessToken = () => localStorageWrapper.get(AuthTokenKey) as string;
export const setAccessToken = (token: string) => localStorageWrapper.set(AuthTokenKey, token);

export const getRefreshToken = () => localStorageWrapper.get(RefreshTokenKey) as string;
export const setRefreshToken = (token: string) => localStorageWrapper.set(RefreshTokenKey, token);

export const clearAuthData = () => {
  localStorageWrapper.remove(AuthTokenKey);
  localStorageWrapper.remove(RefreshTokenKey);
}