import { MenuData, MoreMenu, useMoreMenu } from 'shared/ui/more-menu';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';


export const LinkedManagerMoreMenu = ({ menuData }: { menuData: MenuData; }) => {


  return (
    <MoreMenu {...menuData} />
  );
};


type MoreConfig = {
  deleteHandler: () => void;
};

export const useMore = ({ deleteHandler }: MoreConfig) => {
  return useMoreMenu([
    {
      name: 'Удалить',
      icon: <DeleteIcon />,
      onClick: deleteHandler
    }
  ]);
};


{/* <Menu
  anchorEl={currentItemMenu?.anchor}
  open={!!currentItemMenu}
  onClose={() => { setCurrentItemMenu(null); }}
>
  <MenuItem onClick={handleDeleteClick}>
    <ListItemIcon>
      <DeleteIcon />
    </ListItemIcon>
    <ListItemText>Удалить</ListItemText>
  </MenuItem>
</Menu> */}


