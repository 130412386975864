import { authorizedRequest } from 'shared/api/axios';
import { RegionInfoDto, CreateRegionDto, UpdateRegionDto } from '@sr/dto';

export const fetchRegions = async () => {
  return authorizedRequest<void, RegionInfoDto[]>('/regions', 'GET');
};

export const fetchRegionDetails = async (regionId: number) => {
  return authorizedRequest<void, RegionInfoDto>(
    `/regions/${regionId}`,
    'GET'
  );
};

export const postRegion = async (region: CreateRegionDto) => {
  return authorizedRequest<CreateRegionDto, RegionInfoDto>(
    '/regions',
    'POST',
    region
  );
};

export const putRegion = async (regionId: number, data: UpdateRegionDto) => {
  return authorizedRequest<UpdateRegionDto, RegionInfoDto>(
    `/regions/${regionId}`,
    'PUT',
    data
  );
};

export const deleteRegion = async (regionId: number) => {
  return authorizedRequest<void, void>(
    `/regions/${regionId}`,
    'DELETE'
  );
};
