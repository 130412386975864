import { Box, Button, IconButton, Paper, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import { ContractTemplateInfoDto } from '@sr/dto';
import { LinkToContractTemplate } from 'shared/ui/links/link-to-contract-template';
import { useContractTemplatesList } from 'entities/contract-template/contract-templates.hook';
import { ListLayout } from 'shared/ui/list-layout';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { FormDialog } from 'shared/ui/form-dialog';
import { FormikProps, FormikValues, FormikErrors, Field } from 'formik';
import { ReactNode, useState } from 'react';
import { TextField } from 'formik-mui';
import { useNavigate } from 'react-router-dom';
import { contractLinkManager } from 'utils/link-manager';

export const ContractTemplatesTab = () => {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const navigate = useNavigate();
  const { collection, addItem, removeItem } = useContractTemplatesList();
  const { showSuccess, showError } = useSnack();
  const showConfirm = useConfirmDialog();

  const handleDelete = (id: number) => {
    showConfirm({
      content: 'Удалить шаблон?'
    }).then(() =>
      removeItem(id)
        .then(() => showSuccess('Шаблон успешно удален'))
        .catch(e => showError(`Ошбика удаления шаблона: ${e}`))
    );
  };

  const handleAddTemplate = async (values: FormikValues) => {
    addItem({ name: values.name, templateContent: '' })
      .then((response) => navigate(contractLinkManager.templateLink(response.id)))
      .catch(e => showError(`Ошибка добавления шаблона: ${e}`));
  };

  return (
    <>
      <Box maxWidth="50%">
        <Button sx={{ mb: 1 }} variant="contained" startIcon={<AddIcon />}
          onClick={() => setShowAddDialog(true)}>добавить
        </Button>

        <ListLayout
          items={collection}
          itemRender={(template: ContractTemplateInfoDto) =>
            <TemplateItem
              key={template.id}
              template={template}
              onDelete={() => handleDelete(template.id)} />}
          noItemsText="Нет шаблонов" />

      </Box>

      <FormDialog
        renderForm={() =>
          <Box minWidth={450}>
            <Field fullWidth component={TextField} name="name" label="Название" />
          </Box>}
        renderTitle="Новый шаблон"
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onSubmit={handleAddTemplate}
        validate={(values) => ({})}
        initialValues={{ name: '' }} />

      {/* <Stack direction='row' spacing={2}>
        {collection.items.map(template => <TemplateItem key={template.id} template={template} />)}
      </Stack> */}
    </>
  );
};

const TemplateItem = ({ template, onDelete }: { template: ContractTemplateInfoDto, onDelete: () => void; }) => (
  <Paper sx={{ px: 2, mt: 1 }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <LinkToContractTemplate template={template} />
      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
    </Stack>
  </Paper>
)


