import { Box, Stack } from '@mui/material';
import { LeadDto } from '@sr/dto';
import { FormValues, fromDto, initialValues } from './lead-mediaplan.form';
import { Field, Formik, FormikProps } from 'formik';
import { FormCardLayout } from 'shared/ui/form/form-card-layout';
import FilePickerField from 'shared/ui/formik-fields/file-picker';
import { NotificationsConfig, useSnack } from 'shared/ui/snack';
import { CityPicker } from 'entities/geo/cities/city-picker';
import { MediaTypePickerField } from 'entities/media/media-type-picker';
import { isForbiddenToModifyFile, useLeadMediaplan } from './lib';
import { TextField } from 'formik-mui';

const notificationSettings: NotificationsConfig = {
  itemTitleForm1: 'данные лида',
  itemTitleForm2: 'данных лида',
  gender: 'male'
};

export const LeadMediaPlan = ({ lead }: { lead: LeadDto }) => {
  const { showSuccessUpdate, showFailedUpdate, } = useSnack(notificationSettings);
  // const [open, setOpen] = useState(false);
  const { leadMediaplan, update } = useLeadMediaplan(lead.id);

  const handleSubmit = async (values: FormValues) => {
    if (leadMediaplan)
      update(values)
        .then(showSuccessUpdate)
        .catch(e => showFailedUpdate(e));
  };


  return (
    <>
      <Box sx={{ p: 1 }}>
        <Formik
          initialValues={leadMediaplan ? fromDto(leadMediaplan) : initialValues()}
          enableReinitialize
          onSubmit={handleSubmit}
          // validate={validate}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <FormCardLayout
              formikProps={formikProps}
              renderForm={() => (
                <>
                  <Stack direction="column" spacing={2}>
                    <Field
                      name="offerFile"
                      label="Файл"
                      component={FilePickerField}
                      disabled={isForbiddenToModifyFile(lead.status.id)}
                    />

                    <Stack direction="row" spacing={1}>
                      <Field
                        name="createdBy"
                        label="Добавлен"
                        disabled
                        fullWidth
                        component={TextField}/>

                      <Field
                        disabled
                        name="createdAt"
                        fullWidth
                        label="Дата добавления"
                        component={TextField}/>
                    </Stack>

                    <Field
                      name='cityId'
                      label='Город'
                      component={CityPicker}/>

                    <Field
                      name='mediaTypeId'
                      label='СМИ'
                      component={MediaTypePickerField}/>


                  </Stack>
                </>)}
            />)}
        </Formik>
        {/*<Button variant="outlined" onClick={()=> setOpen(true)}>Прикрепить медиаплан...</Button>*/}
      </Box>

      {/*<AddMediaPlanDialog*/}
      {/*  open={open}*/}
      {/*  onClose={()=> setOpen(false)}*/}
      {/*  lead={lead}*/}
      {/*/>*/}
    </>
  );
};


