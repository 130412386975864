import { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import CountriesList from 'entities/geo/countries/countries-list';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { AddCountryDialog } from 'entities/geo/countries/add-country-dialog';
import { useCountriesList } from 'entities/geo/countries/countries-list.hook';
import { FormValues } from 'entities/geo/countries/country-form';
import { CountryInfoDto } from '@sr/dto';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';


const CountriesListPage = () => {
  useTitleHook('Страны');
  const showConfirmDialog = useConfirmDialog();
  const { showSuccess, showError } = useSnack();
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const { isLoading, error, list, addCountry, removeCountry } = useCountriesList();

  const handleAddCountry = async (data: FormValues) => {
    return addCountry(data)
      .then(() => {
        setAddDialogOpen(false);
        showSuccess('Страна успешно добавлена');
      }
      )
      .catch(error => showError(`Ошибка добавления города: ${error.message}`));
  };

  const handleDeleteCcountry = (country: CountryInfoDto) => {
    showConfirmDialog({
      title: 'Вы уверны?',
      content: `Удалить страну ${country?.name}?`
    })
      .then(() => {
        removeCountry(country.id)
          .then(() => showSuccess('Страна успешно удалена'))
          .catch((e) => showError(`Ошибка удаления страны: ${e.message}`));
      });
  };

  if (isLoading)
    return <LoadingBanner />;

  if (error)
    return <ErrorBanner errorMessage={error} />;

  return (
    <>
      <Heading
        title="Страны"
        actions={
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
          >
            Добавить
          </Button>} />



      <CountriesList
        list={list}
        onDelete={handleDeleteCcountry}
      />

      <AddCountryDialog
        open={isAddDialogOpen}
        onSubmit={handleAddCountry}
        onClose={() => setAddDialogOpen(false)}
      />

    </>
  );
};

export default CountriesListPage;


