import { IManager } from '@sr/dto';
import { useManagersDropdownList } from './ui';
import { formatPerson } from 'utils/person-format-helpers';
import { withRemoteCollectionDropDownPicker } from 'shared/ui/form-fields/with-remote-collection-dropdown';


export const UserPicker = withRemoteCollectionDropDownPicker<IManager, number>({
  collectionProvider: useManagersDropdownList,
  renderDropdownLabel: (item) => formatPerson(item),
  renderEmptyValue: <>&nbsp;</>
})

