import { Box, Button, Paper, Stack } from '@mui/material';
import { Formik, FormikProps, Form } from 'formik';
import { useUpdateCityBasicData } from 'features/geo/city/basic-data/lib';
import { FormValues, fromDto, toUpdateDto, useCityBasicData, validate, initialValues } from 'entities/geo/cities/city-basic-data';
import { CityBasicDataFormFields } from 'entities/geo/cities/city-basic-data/city-basic-data-form-fields';
import { FormCard } from 'shared/ui/form-card';

type Props = {
  cityId: number;
}

export const CityHomeTab = ({ cityId }: Props) => {
  const { cityBasicData } = useCityBasicData(cityId);

  const update = useUpdateCityBasicData();

  const handleSubmit = async (data: FormValues) => {
    const updateData = toUpdateDto(cityId, data)
    return update(updateData);
  }

  return (
    <>
      <Box maxWidth={500}>
        <FormCard
          initialValues={cityBasicData ? fromDto(cityBasicData) : initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          renderForm={() => <CityBasicDataFormFields />}
        />
      </Box>
      {/* <Stack direction='column' spacing={2}>
        {cityBasicData && <>
          <Paper sx={{ p: 2 }}>
            <Formik
              initialValues={fromDto(cityBasicData)}
              enableReinitialize={true}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {(p: FormikProps<FormValues>) => (
                <>
                  <Form>
                    <CityBasicDataFormFields />
                    <Button type='submit' variant='contained'>Сохранить</Button>
                  </Form>
                </>)}
            </Formik>
          </Paper>
        </>
        }
      </Stack> */}
    </>
  );
}

