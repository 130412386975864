import { Type } from "class-transformer";
import {IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";
import { CityInfoDto } from "./geo/city";
import {MediaTypeBaseDataDto} from "./media-type.dto";

export class CreateMediaDto {
  @IsNumber()
  typeId: number;

  @IsNumber()
  cityId: number;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class UpdateMediaBasicDto {
  id: number;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsNumber()
  typeId: number;

  @IsNumber()
  cityId: number;

  @IsOptional()
  @IsNumber()
  publishingDataId?: number;
}

export class MediaBasicDataDto {
  id: number;
  name: string;
  mediaType: MediaTypeBaseDataDto;
  city: CityInfoDto;
  publishingDataId: number | null;
}

export class MediaInfoDto {
  id: number;

  @IsString()
  @IsNotEmpty()
  name: string;

  city: CityInfoDto;

  @Type(() => MediaTypeBaseDataDto)
  mediaType: MediaTypeBaseDataDto;
}
