import { UpdateMediaBasicDto } from '@sr/dto';
import { mediaBasicDataApi } from './api-slice';


export const useMediaBasicData = (id: number) => {
  const { data: media, isLoading, error } = mediaBasicDataApi.useGetMediaBasicDataQuery(id, { refetchOnMountOrArgChange: true });
  return {
    media,
    isLoading,
    error
  }
}


export const useUpdataMediaBasicData = () => {
  const [updateMediaBasicData] = mediaBasicDataApi.useUpdateMediaBasicDataMutation();
  return async (media: UpdateMediaBasicDto) => {
    return updateMediaBasicData(media).unwrap();
  };
};

