import { CreateCompanyDto, IContacts, EMPTY_CONTACTS } from '@sr/dto';
import { FormikErrors } from 'formik';

export interface FormValues {
  name: string;
  companyType: string[],
  companyContacts: IContacts
}


export const initialValues: FormValues = {
  name: '',
  companyType: [],
  companyContacts: EMPTY_CONTACTS
}

export const validate = async (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  const requiredMsg = 'Поле обязательно';
  if (!values.name) errors.name = requiredMsg;

  if (values.companyType.length === 0)
    errors.companyType = 'Выберите хотя бы одно значение';

  return errors;
}


export const toCreateCompanyDto = (formData: FormValues) => {
  const newCompanyDto: CreateCompanyDto = {
    name: formData.name,
    type: formData.companyType.reduce((acc, x) => acc + Number(x), 0),
    contacts: {
      emails: formData.companyContacts.linkedEmails.map(x => ({ email: x.email })),
      phones: formData.companyContacts.linkedPhones.map(x => ({ phone: x.phone })),
      urls: formData.companyContacts.linkedUrls.map(x => ({ url: x.url.url, urlTypeId: x.url.urlType.id })),
    }
  };

  return newCompanyDto;
};