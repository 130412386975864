import { MoreVert } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Skeleton } from '@mui/material';
import { MoreMenu, useMoreMenu } from 'shared/ui/more-menu';
import { useCallback, useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { LinkToContractAppendix } from 'shared/ui/links/link-to-contract-appendix';
import { delReq, fetchReq, postReq } from 'shared/api/axios';
import {
  ContractAppendixDto,
  CreateContractAppendixDto,
  InvoiceContractAppendixDto
} from '@sr/dto/dist/src/contract-appendix.dto';

type Props = {
    invoiceId: number,
    contractId: number;
};

export const InvoiceAppendix = ({ invoiceId, contractId }: Props) => {
  const { item: invoiceAppendix, createItem, deleteItem, isFetching, isPosting } = useContractAppendix(invoiceId);
  const showConfirm = useConfirmDialog();
  const { showSuccess, showError } = useSnack();

  const handleCreate = async () => {
    const dto: CreateContractAppendixDto = {
      contractId,
      invoiceId,
      date: new Date(),

      extraConditionsText: '',
      promoText: '',
      promoFileId: null
    };

    return createItem(dto)
      .then(() => showSuccess('Приложение успешно создано'))
      .catch(e => showError(`Ошибка создания приложения: ${e}`));
  };

  const handleDelete = async () => {
    if (!invoiceAppendix) return;

    showConfirm({ content: 'Удалить приложение?' })
      .then(() => {
        deleteItem(invoiceAppendix)
          .then(() => showSuccess('Приложенин успешно удалено'))
          .catch(e => showError(`Ошибка удаления приложения: ${e}`));
      });
  };

  const { openMenu, menuData } = useMoreMenu([
    {
      name: 'Печать',
      icon: <PrintIcon/>,
    },
    // {
    //   name: 'Печать с подписью',
    //   icon: <PrintIcon />,
    //   onClick: () => { console.log('sdfsdfsdf') }
    // },
    { divider: true },
    {
      name: 'Удалить',
      icon: <DeleteIcon/>,
      onClick: handleDelete
    }
  ]);


  if (isFetching)
    return <Skeleton/>;

  if (isPosting)
    return <CircularProgress/>;

  if (!invoiceAppendix)
    return <Button variant='contained' size='small' onClick={handleCreate}>Создать</Button>;

  return (
    <>
      <LinkToContractAppendix appendix={invoiceAppendix}/>

      <IconButton onClick={(e) => {
        openMenu(e.currentTarget);
      }}>
        <MoreVert/>
      </IconButton>

      <MoreMenu {...menuData} />
    </>
  );
};

type Config<TItem, TCreateItem> = {
    fetchHandler: () => Promise<TItem | null>,
    createHandler: (createData: TCreateItem) => Promise<TItem>,
    deleteHandler: (itemToDelete: TItem) => Promise<void>;
};

export function useCreatableItem<TItem, TCreateItem>({
  fetchHandler,
  createHandler,
  deleteHandler
}: Config<TItem, TCreateItem>) {
  const [item, setItem] = useState<TItem | null>(null);

  const [isFetching, setFetching] = useState(false);
  const [isPosting, setPosting] = useState(false);

  useEffect(() => {
    setFetching(true);
    fetchHandler()
      .then(response => setItem(response))
      .finally(() => setFetching(false));
  }, [fetchHandler]);

  const createItem = async (data: TCreateItem) => {
    setPosting(true);
    return createHandler(data)
      .then(response => setItem(response))
      .finally(() => setPosting(false));
  };

  const deleteItem = async (item: TItem) => {
    setPosting(true);
    return deleteHandler(item)
      .then(() => setItem(null))
      .finally(() => setPosting(false));
  };

  return ({
    item,
    createItem,
    deleteItem,
    isFetching,
    isPosting
  });
}

const useContractAppendix = (invoiceId: number) => {
  const fetchAppendix = useCallback(() =>
    fetchReq(`contract-appendix/invoice/${invoiceId}`, InvoiceContractAppendixDto)
      .then(response => response.appendix)
  , [invoiceId]);

  const createAppendix = useCallback((dto: CreateContractAppendixDto) =>
    postReq('contract-appendix', dto, ContractAppendixDto)
  , []);

  const deleteAppendix = useCallback((dto: ContractAppendixDto) =>
    delReq('contract-appendix', dto.id)
  , []);

  return useCreatableItem<ContractAppendixDto, CreateContractAppendixDto>(
    {
      fetchHandler: fetchAppendix,
      createHandler: createAppendix,
      deleteHandler: deleteAppendix
    }
  );
};
