import { authorizedRequest } from 'shared/api/axios';
import { CashflowDto, CreateCashflowDto, UpdateCashflowDto } from '@sr/dto';

const CASHFLOW_URL = 'accounting/cashflows';

export const fetchCashflows = async () => {
  return authorizedRequest<void, CashflowDto[]>(
    CASHFLOW_URL,
    'GET');
};

export const fetchCashflowsDropDown = async () => {
  return authorizedRequest<void, (CashflowDto & { isDeleted: boolean; })[]>(
    `${CASHFLOW_URL}/dropdown`,
    'GET');
};


export const putCashflow = async (dto: UpdateCashflowDto) => {
  const updatedAccount = await authorizedRequest<UpdateCashflowDto, CashflowDto>(
    CASHFLOW_URL,
    'PUT',
    dto
  );
  return updatedAccount;
};


export const postCashflow = async (dto: CreateCashflowDto) => {
  const newAccount = await authorizedRequest<CreateCashflowDto, CashflowDto>(
    CASHFLOW_URL,
    'POST',
    dto
  );
  return newAccount;
};

export const deleteCashflow = async (id: number) => {
  return authorizedRequest<void, void>(
    `${CASHFLOW_URL}/${id}`,
    'DELETE'
  );
};