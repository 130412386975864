import { Type } from 'class-transformer';
import { IsNotEmpty, IsString, MaxLength, MinLength } from 'class-validator';

export class SearchQueryDto {
  @IsNotEmpty({ message: 'Поисковый запрос не может быть пустым' })
  @MinLength(2, { message: 'Минимально допустимая длинна поискового запроса $constraint1 символов' })
  @MaxLength(50, { message: 'Максимально допустимая длинна поискового запроса $constraint1 символов' })
  query: string;

  @Type(() => Boolean)
  searchCompanies?: boolean;

  @Type(() => Boolean)
  searchInvoices?: boolean;

  @Type(() => Boolean)
  searchLegalEntities?: boolean;

  @Type(() => Boolean)
  searchMedia?: boolean;

  @Type(() => Boolean)
  searchLeads?: boolean;

  @Type(() => Boolean)
  searchPurchases?: boolean;

}
