import { Box, Stack, Typography } from '@mui/material';
import { IPerson } from '@sr/dto'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { formatPerson } from 'utils/person-format-helpers';

type Props = {
  person: IPerson,
}

const ManagerSpan = (props: Props) => {
  const { person } = props;
  return (
    <Box sx={{ color: 'text.secondary' }}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <AccountCircleIcon />
        <Typography variant="subtitle2" >
          {formatPerson(person)}
        </Typography>
      </Stack>
    </Box>
  );
}

export default ManagerSpan;