export enum EntitiesWithContacts {
  company = 'company',
  lead = 'lead',
  person = 'person',
  user = 'user',
}

export enum ContactTypes {
  email = 'emails',
  phone = 'phones',
  url = 'urls',
}

export type ContactQuery = {
  relationId: number;
  relationType: EntitiesWithContacts;
};

export type AddContactQuery = ContactQuery & { contactType: ContactTypes };

export type DeleteContactQuery = ContactQuery & { contactId: number };
