import {
  CreateMediaTypeDto,
  UpdateMediaTypeBaseDataDto,
} from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { NotificationsConfig } from 'shared/ui/snack';
import { FormikErrors } from 'formik';


export type PopupFormData = {
  name: string,
};

export const mediaTypesNotificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'тип СМИ',
  itemTitleForm2: 'типа СМИ',
  gender: 'male'
};

export const formTools: FormTools<UpdateMediaTypeBaseDataDto, CreateMediaTypeDto, UpdateMediaTypeBaseDataDto, PopupFormData> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    name: formData.name,
  }),
  toCreateDto: formData => ({
    name: formData.name,
  }),
  fromDto: (dto) => ({
    name: dto.name,
  }),
  validate: (values: PopupFormData): FormikErrors<PopupFormData> => {
    let validationResult: FormikErrors<PopupFormData> = {};

    if (!values.name)
      validationResult.name = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },
  emptyValues: () => ({
    name: '',
  })
};
