import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { CombineCompaniesDialog } from 'features/company/combine-dialog';
import { CombineCompanyEntry } from 'features/company/combine/combine-company-entry';
import { useCombineCompaniesList } from 'features/company/combine/use-combine-companies-list';
import { useTitleHook } from 'hooks/page-title.hook';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { ErrorBanner } from 'shared/ui/error-banner';
import { Heading } from 'shared/ui/list-page-heading';
import { LoadingBanner } from 'shared/ui/loading-banner';

const CombineCompaniesPage = () => {
  useTitleHook('Объединение компаний');
  const [queryParams] = useSearchParams();
  const companiesComaSeparated = queryParams.get('companies');
  const companyIds = companiesComaSeparated?.split(',');

  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<number[]>([]);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const { companies, isLoading, error } = useCombineCompaniesList(companyIds?.map(Number));

  if (error) {
    return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />;
  }

  if (isLoading) {
    return <LoadingBanner />;
  }


  if (!companiesComaSeparated) {
    return <Alert color='info'>
      <AlertTitle>Объединение компаний</AlertTitle>
      Чтобы объединить компании, нажмите кнопку "Объединить" в диалоге отображения дубликатов</Alert>;
  }

  const handleCheck = (checked: boolean, id: number) => {
    if (checked) {
      setSelectedCompaniesIds([...selectedCompaniesIds, id]);
    } else {
      setSelectedCompaniesIds(selectedCompaniesIds.filter(x => x !== id));
    }
  }


  return <>
    <Heading
      sx={{ mb: 2 }}
      title="Объединение компаний"
    />

    <Stack gap={1}>
      {companies?.map((company) => (
        <CombineCompanyEntry company={company} key={company.id} onCheck={(checked) => handleCheck(checked, company.id)} />
      ))}
    </Stack>

    <Button variant='contained'
      disabled={!selectedCompaniesIds || selectedCompaniesIds.length < 2} sx={{ mt: 2 }}
      onClick={() => setConfirmOpen(true)}>
      Объединить
    </Button>

    <CombineCompaniesDialog
      open={confirmOpen}
      onClose={() => setConfirmOpen(false)}
      companies={companies?.filter(x => selectedCompaniesIds.includes(x.id)) ?? []} />
  </>;

}


export default CombineCompaniesPage;