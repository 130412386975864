import { companiesApi } from 'entities/company/api.slice';

export const useCombineCompaniesList = (ids?: number[]) => { 
  const { data, isLoading, error } = companiesApi.useListCombiningCompaniesQuery(ids?.join(',') ?? '', { skip: !ids || !ids.length });

  return {
    companies: data,
    isLoading,
    error
  };
};
