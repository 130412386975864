import { Outlet, Route, Routes, useParams, Link, useLocation, resolvePath } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Stack } from '@mui/material';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import StorageIcon from '@mui/icons-material/Storage';
import GradingIcon from '@mui/icons-material/Grading';

import { CompanyHome } from 'components/Companies/company-home';
import { CompanyLegalInfo } from 'components/Companies/LegalEntities/company-legal-info';
import { CompanyLeads } from 'components/Companies/LegalEntities/company-leads';
import { CompanyClientInvoices } from 'components/Companies/Invoices/company-client-invoices';
import { CompanySupplierInvoices } from 'components/Companies/Invoices/company-supplier-invoices';
import { useTitleHook } from 'hooks/page-title.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { useCompany } from 'hooks/companies.hook';
import { CompanyDto, UpdateCompanyDto } from '@sr/dto';
import { CompanyTypes } from 'components/Companies/company-type-span';
import { EditCompanyDialog } from 'components/Companies/CompanyDialog/company-dialog.edit';
import { useSnack } from 'shared/ui/snack';
import { useState } from 'react';
import { ActionsMenu } from 'shared/ui/actions-menu';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useIdParam } from 'utils/url-paramters.hook';
import EditIcon from '@mui/icons-material/Edit';

const tabInfos = {
  'home': {
    label: 'Контакты',
    icon: <StorageIcon />
  },
  'legal-entities': {
    label: 'Юрлица',
    icon: <AccountBalanceIcon />
  },
  'client-invoices': {
    label: 'Счета исходящие',
    icon: <ReceiptIcon />
  },
  'supplier-invoices': {
    label: 'Счета входящие',
    icon: <MoveToInboxIcon />
  },
  'leads': {
    label: 'Лиды',
    icon: <MoveToInboxIcon />
  },
  'audit': {
    label: 'Аудит',
    icon: <GradingIcon />
  }
};
const tabs = Object.entries(tabInfos);

export const CompanyDetailsPage = () => {
  const companyId = useIdParam('companyId');

  const { company, error, updateCompany } = useCompany(companyId);

  const [isAddCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
  const { showSuccess, showError } = useSnack();

  useTitleHook(company?.name);

  const handleUpdateCompany = (companyData: UpdateCompanyDto) => {
    return updateCompany(companyData)
      .then(() => showSuccess('Компания успешно обновлена'))
      .catch(error => showError('Ошибка редактирования компании: ' + error.message));
  };

  if (error)
    return <ErrorBanner errorMessage={error} />;

  if (!company)
    return <LoadingBanner />;

  return (
    <>
      <PageHeader
        title={
          <>
            <Typography variant="h5" color="primary">{company?.name}</Typography>
            <CompanyTypes company={company} />
          </>}
        actions={<ActionsMenu actions={[{
          callback: () => setAddCompanyDialogOpen(true),
          label: 'Редактировать',
          icon: <EditIcon/>
        }]} />}
        sx={{ mt: 1, mb: 0 }}
      />

      <CompanyTabs />

      <Routes>
        <Route element={<Outlet />}>
          <Route path='home' element={<CompanyHome company={company} />} />
          <Route path='legal-entities/*' >
            <Route index element={<CompanyLegalInfo companyId={companyId} />} />
          </Route>
          <Route path='client-invoices' element={<CompanyClientInvoices companyId={companyId} />} />
          <Route path='supplier-invoices' element={<CompanySupplierInvoices companyId={companyId} />} />
          <Route path='leads' element={<CompanyLeads companyId={companyId} />} />
          <Route path='audit' element={<> Аудит. TO DO</>} />
        </Route>
      </Routes>

      {company && <EditCompanyDialog
        open={isAddCompanyDialogOpen}
        onSubmit={handleUpdateCompany}
        onClose={() => setAddCompanyDialogOpen(false)}
        company={company}
      />}
    </>
  );
};


const CompanyTabs = () => {
  const location = useLocation();
  const pathSplit = location.pathname.split('/');
  pathSplit.pop();
  const companyRoot = pathSplit.join('/');

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
      <Tabs value={location.pathname}>
        {tabs.map(([route, info], i) => {
          const value = resolvePath(route, companyRoot).pathname;
          return <Tab
            key={i}
            label={info.label}
            value={value}
            iconPosition="start"
            icon={info.icon}
            to={route}
            component={Link} />;
        })}
      </Tabs>
    </Box>
  );
};

export default CompanyDetailsPage;


