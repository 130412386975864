import { emptyPagedCollection, InvoiceInfoDto, IPagingData } from '@sr/dto';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { wccBulkCreateApi } from 'features/wcc-bulk-create/api';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';

export const useInvoicesWithoutWcc = (paging: IPagingData, filterString?: string) => {

  const { data, isLoading, error } = wccBulkCreateApi.useGetInvoicesWithoutWccQuery({ paging, filter: filterString });

  const result: IRemotePagedCollection<InvoiceInfoDto> = {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };

  return result;
};
