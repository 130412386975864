import { useMemo } from 'react';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { AddMediaButton } from 'features/media/create';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorageIcon from '@mui/icons-material/Storage';
import { MediaListTab } from './tabs/media-list-tab';
import { NavTabInfo, NavTabs } from 'shared/ui/nav-tabs';
import { MediaPurchasesTab } from './tabs/media-purchases-tab';
import { LeadMediaPlansTab } from './tabs/lead-media-plans-tab';
import { useLocation } from 'react-router-dom';

const MediaListPage = () => {
  useTitleHook('СМИ и услуги');

  const location = useLocation();
  const tabInfos: NavTabInfo[] = useMemo(() =>
    [
      {
        path: 'list',
        label: 'СМИ',
        icon: <StorageIcon />,
        component: <MediaListTab />
      },
      {
        path: 'purchases',
        label: 'Отгрузки',
        icon: <AccountBalanceIcon />,
        component: <MediaPurchasesTab />,
      },
      {
        path: 'sent-mediaplans',
        label: 'Отправленные медиапланы',
        icon: <ReceiptIcon />,
        component: <LeadMediaPlansTab/>,
      },
    ],
  []);

  const currentTab = tabInfos.find(tab=>location.pathname.endsWith(tab.path));

  return (
    <>
      <Heading
        title="СМИ и услуги"
        actions={currentTab?.path === 'list' && <AddMediaButton />}
      />
      <NavTabs tabs={tabInfos} />
    </>
  );
};

export default MediaListPage;
