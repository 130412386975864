//import { IsNotEmpty, IsString } from "class-validator";

import { IsBoolean, IsNotEmpty, IsString } from "class-validator";
import { CountryDto, CountryInfoDto } from "../countries/country.dto";

export class CreateMacroRegionDto {
  @IsString()
  @IsNotEmpty()
  name: string = '';

  @IsString()
  @IsNotEmpty()
  slug: string = '';

  population: number = NaN;

  shortDescription: string = '';
  fullDescription: string = '';

  @IsBoolean()
  isPublished: boolean = false;
}

export class MacroRegionDto extends CreateMacroRegionDto {
  id: number = NaN;
}

export class MacroRegionInfoDto {
  id: number;
  name: string;
  country: CountryInfoDto;
  slug: string;
}