import { createApi, fetchBaseQuery }       from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, ROLES_BASE_URL }    from 'shared/api/api';
import { CollectionParams, setAuthHeader } from 'shared/api/rtk-query';
import { CreateRoleDto, RoleDto, UpdateRoleDto } from '@sr/dto';

const USER_ROLES_PATH = ROLES_BASE_URL;

export const userRolesApi = createApi({
  reducerPath: 'user-roles',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserRolesList: builder.query<RoleDto[], CollectionParams>({
      query: () => ({
        url: USER_ROLES_PATH,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    getUserRole: builder.query<RoleDto, number>({
      query: (id: number) => ({
        url: `${USER_ROLES_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: ['*'],
    }),
    deleteUserRole: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `${USER_ROLES_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['*'],
    }),
    updateUserRole: builder.mutation<RoleDto, UpdateRoleDto>({
      query: (role: UpdateRoleDto) => ({
        url: `${USER_ROLES_PATH}/${role.id}`,
        body: role,
        method: 'PATCH',
      }),
      invalidatesTags: ['*'],
    }),
    addUserRole: builder.mutation<RoleDto, CreateRoleDto>({
      query: (dto: CreateRoleDto) => ({
        url: USER_ROLES_PATH,
        body: dto,
        method: 'POST',
      }),
      invalidatesTags: ['*'],
    }),
  }),
  tagTypes: ['*'],
});

