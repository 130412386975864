// import { postCity } from 'entities/geo/cities/api';
import { FormValues, toCreateDto } from './form';
import { useActionCreators } from 'shared/store';
import { useSnack } from 'shared/ui/snack';
import { cityActions } from 'entities/geo/cities/cityUiSlice';
import { cityCreateApi } from './apiSlice';

export const useAddCityDialog = () => {
  const { openDialog, closeDialog } = useActionCreators(cityActions);
  return {
    openDialog: () => { openDialog() },
    closeDialog: () => { closeDialog() }
  }
}


export const useAddCity = () => {
  const { showSuccess, showError } = useSnack();

  const [addCity] = cityCreateApi.useAddCityMutation();

  return async (formValues: FormValues) => {
    const dto = toCreateDto(formValues);
    return addCity(dto)
      .unwrap()
      .then(() => showSuccess('Город успешно добавлен'))
      .catch(error => showError('Ошибка добавления города', error));
  };
}