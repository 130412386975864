import { authorizedRequest } from 'shared/api/axios';
import { LegalEntityCompanyDto } from '@sr/dto';

const BASE_URL = '/legal-entity';

export const fetchLinkedCompanies = async (legalEntityId: number) => {
  return authorizedRequest<void, LegalEntityCompanyDto[]>(
    `${BASE_URL}/${legalEntityId}/linked-companies`,
    'GET'
  );
};

export const postLinkedCompany = async (legalEntityId: number, companyId: number) => {
  return authorizedRequest<{ companyId: number; }, LegalEntityCompanyDto>(
    `${BASE_URL}/${legalEntityId}/linked-companies`,
    'POST',
    { companyId }
  );
};

export const deleteLinkedCompany = async (linkId: number) => {
  return authorizedRequest<void, void>(
    `${BASE_URL}/linked-companies/${linkId}`,
    'DELETE'
  );
};
