import { Box, Stack } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useSnack } from 'shared/ui/snack';
import { useActionCreators, useStateSelector } from 'shared/store';
import { FormDialog } from 'shared/ui/form-dialog';
import { changePasswordActions } from './slice';
import { changeUserPasswordApi } from './api';
import { FormValues, initialValues, validate } from './form';

export const ChangePasswordDialog = () => {
  const open = useStateSelector(state => state.ui.changePasswordDialog.dialogOpen);
  const userId = useStateSelector(state => state.user.id);

  const { closeDialog: cloaseDialog } = useActionCreators(changePasswordActions);
  const [submitChangePassword] = changeUserPasswordApi.useChangePasswordMutation();

  const { showSuccess, showError } = useSnack();

  const handleSubmit = async (formValues: FormValues) => {
    if (!userId) return Promise.reject();

    return submitChangePassword({
      userId,
      // currentPassword: formValues.currentPassword,
      newPassword: formValues.password1
    })
      .unwrap()
      .then(() => {
        showSuccess('Пароль успешно изменен');
        cloaseDialog();
      })
      .catch((e) => showError('Ошибка изменения пароля', e));
  };

  return (
    <FormDialog
      open={open}
      onClose={() => cloaseDialog()}
      renderTitle="Смена пароля"
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      renderForm={(formikProps) => (
        <Box minWidth={300} my={1} py={1}>
          <Stack spacing={2}>
            {/*  TODO: ВЕРНУТЬ, КОГДА РЕШИМ СДЕЛАТЬ ВОССТАНОВЛЕНИЕ ПАРОЛЯ ЧЕРЕЗ EMAIL И ТД (feature/707) */}
            {/*<Field*/}
            {/*  fullWidth*/}
            {/*  required*/}
            {/*  name='currentPassword'*/}
            {/*  type="password"*/}
            {/*  label="Текущий пароль"*/}
            {/*  component={TextField}*/}
            {/*/>*/}
            {/*<Divider/>*/}
            <Field
              fullWidth
              required
              name='password1'
              label="Новый пароль"
              type="password"
              component={TextField}
            />
            <Field
              fullWidth
              required
              name='password2'
              label="Новый пароль еще раз"
              type="password"
              component={TextField}
            />

          </Stack>
        </Box>
      )}

    />
  );
};

