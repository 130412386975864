import { Typography } from '@mui/material';
import { formatPrice } from 'utils/price-format-helpers';


const PriceSpan = ({ price, size }: { price: number, size?: 'small' | 'regular' }) => {
  const priceSplit = formatPrice(price).split('.');
  const rub = priceSplit[0];
  const kop = priceSplit[1];

  const formatter = new Intl.NumberFormat('ru-RU', {
    //currency: 'р',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const rubStr = formatter.format(price);
  const currencyColor = 'grey.500';
  return (
    <>
      <Typography variant={size === 'small' ? 'body1' : 'h6'} fontWeight={500} component='span'>
        {rubStr}
      </Typography>

      <Typography variant="caption" component='span' color={currencyColor}>
        р.
      </Typography>

      {(kop !== '00') &&
        <>
          <Typography variant="body2" component='span'>
            {kop}
          </Typography>
          {/* &nbsp; */}
          <Typography variant="caption" component='span' color={currencyColor}>
            к.
          </Typography>
        </>
      }

    </>
  );
}

export default PriceSpan;