import { leadMediaplanApi } from './api';
import { FormValues, fromDto, toDto } from './lead-mediaplan.form';
import { UpdateLeadMediaplanDto } from '@sr/dto';

export const useLeadMediaplan = (leadId: number) => {
  const { data: leadMediaplan } = leadMediaplanApi.useGetMediaPlanQuery({ leadId },
    { refetchOnMountOrArgChange: 120 });

  const [update] = leadMediaplanApi.useUpdateMediaPlanMutation();

  const updateFn = async (formValues: FormValues) => {
    if (!leadMediaplan) return;

    const dto = toDto(formValues);

    return update({ id: leadId, data: dto }).unwrap();
  };

  return {
    leadMediaplan,
    update: updateFn,
  };
};

export const isForbiddenToModifyFile = (leadStatusId: number) => {
  return [4, 5, 6].includes(leadStatusId);
};
