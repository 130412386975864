import { Period } from './types';

export const getCurrentPeriod = (): Period => {
  const now = new Date();
  now.setDate(1);
  now.setHours(0, 0, 0, 0);
  return { year: now.getFullYear(), month: now.getMonth(), };
};

export const getMonthNameFromMonthIdx = (monthIdx: number): string=> {
  const now = new Date();
  now.setMonth(monthIdx);
  return now.toLocaleString('ru-RU', { month: 'long' });
};
