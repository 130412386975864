import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { CompanyPickerField } from 'shared/ui/formik-fields/company-picker';

export interface FormData {
  companyId: number;
}

export const initialValues: FormData = {
  companyId: NaN
};

type ValidationData = Partial<Record<keyof FormData, string>>;

export const validate = (values: FormData) => {
  const errors: ValidationData = {};

  const requiredMsg = 'Поле обязательно';
  if (!values.companyId) errors.companyId = requiredMsg;

  return errors;
};

interface DialogProps {
  open: boolean;
  title: string;
  values?: FormData;
  submit: (formData: FormData) => Promise<void>;
  onClose: () => void;
}

export function LinkedCompanyDialog(props: DialogProps) {
  const { open, onClose, submit, title, values } = props;

  const handleSubmit = async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await submit(values);
    setSubmitting(false);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth='md'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={values ? values : initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm, isSubmitting }: any) => (
            <Form>
              <Box sx={{ mt: 1 }}>
                <Field
                  name='companyId'
                  label='Фирма'
                  component={CompanyPickerField}
                />
              </Box>
              <DialogActions>
                <Button variant="contained"
                  type='submit'
                  autoFocus
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'Подождите...' : 'Сохранить'}
                </Button>
                <Button variant="outlined"
                  onClick={onClose}
                  disabled={isSubmitting}>
                  Закрыть
                </Button>
              </DialogActions>
            </Form>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
