import { InvoiceInfoDto } from '@sr/dto';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';
import { fetchClientInvoicesDropdown, fetchInvoicesDropdown } from '../api';

// export const useInvoicesDropdown = (companyId?: number) => {
//   return useReadOnlyRemoteCollection<InvoiceInfoDto>({
//     fetchHandler: useCallback(() => fetchClientInvoicesDropdown(companyId), [companyId]),
//   });
// };

export const useInvoicesDropdown = () => {
  return useReadOnlyRemoteCollection<InvoiceInfoDto>({
    fetchHandler: fetchInvoicesDropdown
  });
};