import { Type } from "class-transformer";
import { PayOrderGroup } from "../main";
import { Identifiable } from "./identifiable";


export class PayOrderImportSessionDto extends Identifiable {
  @Type()
  importDate: Date;

  originalFileName: string;

  fileFormat: string;
  info: string;
  comment: string;
}

export class PayorderParseReportDto {
  totalSectionsCount: number;
  successfulParsedSectionsCount: number;
  failedParsedSectionsCount: number;
}

export class RunImportDto {
  sessionId: number;
  bankAccountId: number;
}

export class PayorderImportReportDto {
  totalPayordersCount: number;
  importedCount: number;
  skippedCount: number;
  failedCount: number;
  error: string;
}

export class CreatePayOrderImportSessionDto {
  importName: string;
  file: File;
}


export class CheckAllRowsDto {
  sessionId: number;
  checked: boolean;
}


export type InvalidReasons = {
  // дубликат (обычно возникает, потому что часть партянки уже проимпортировано)
  hasDuplicate?: boolean;

  // не найден подходящий счет
  invoiceNotFound?: boolean;

  // TODO ??:
  // не найдено юрлицо в базе (плательщика или получателя)
  // сумма платежа превышает сумму счета - предупреждение бы
}


export type ImportedPayorderAvailableOptions = {
  invoices: { id: number, title: string }[];
}

export type ImportedPayorderProps = {
  selected: boolean;
  selectedInvoiceId: number | null;
  selectedCashflowId: number | null;
  selectedGroup: PayOrderGroup | null;
}

export class UpdateImportedPayorderDto implements ImportedPayorderProps {
  id: number;
  selected: boolean;
  selectedInvoiceId: number | null;
  selectedCashflowId: number | null;
  selectedGroup: PayOrderGroup | null
}

export class ImportedPayorderDto {
  id: number;

  importProps: ImportedPayorderProps;

  availableOptions: ImportedPayorderAvailableOptions;

  invalidReasons?: InvalidReasons;

  /////////////////////////////
  paymentDescription: string;
  documentNumber: string;

  @Type()
  paymentDate: Date;

  @Type()
  withdrawDate?: Date;

  paymentAmount: number;
  payerBankAccount: string;
  payerName: string;
  payerInn: string;
  payerKpp: string;
  payerBankName: string;
  payerBankBik: string;
  payerCorrNumber: string;

  recieverBankAccount: string
  recieverName: string;
  recieverInn: string;
  receiverKpp: string;
  receiverBankName: string;
  receiverBankBik: string;
  receiverCorrNumber: string;
}



