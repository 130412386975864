import 'reflect-metadata';
import { CreateInvoiceDto, InvoiceInfo, InvoiceInfoDto, IPagingData } from '@sr/dto';
import { authorizedRequest, fetchCollectionReq, fetchPagedReq, postReq } from 'shared/api/axios';
import { INVOICES_BASE_URL, PRINT_FORMS_BASE_URL } from 'shared/api/api';


export const fetchClientInvoicesDropdown = async (companyId?: number) => {
  const response = await fetchCollectionReq<InvoiceInfoDto>(
    `${INVOICES_BASE_URL}/client` + (companyId ? `?company=${companyId}` : ''),
    InvoiceInfoDto
  );
  return response;
};

export const fetchClientInvoices = async (paging: IPagingData, filterString?: string, companyId?: number) => {
  const params = new URLSearchParams(filterString);
  companyId && params.append('companyId', companyId.toString());

  const response = await fetchPagedReq<void, InvoiceInfo>(
    `${INVOICES_BASE_URL}/client?${params.toString()}`,
    paging);
  return response;
};

export const fetchSupplierInvoices = async (paging: IPagingData, filterString?: string, companyId?: number) => {
  const params = new URLSearchParams(filterString);
  companyId && params.append('companyId', companyId.toString());

  const response = await fetchPagedReq(
    `${INVOICES_BASE_URL}/supplier?${params.toString()}`,
    paging,
    InvoiceInfoDto);
  return response;
};

export const fetchInvoicesDropdown = async () => {
  const response = await fetchCollectionReq<InvoiceInfoDto>(
    `${INVOICES_BASE_URL}/dropdown`,
    InvoiceInfoDto);
  return response;
};

export const deleteInvoice = async (id: number) => {
  return authorizedRequest<void, void>(
    `${INVOICES_BASE_URL}/${id}`,
    'DELETE'
  );
};

export const fetchInvoicePrintForm = async (invoiceId: number, withStamp = false) => {
  const response = await authorizedRequest<void, string>(
    `${PRINT_FORMS_BASE_URL}/invoice/${invoiceId}?stamp=${withStamp ? 'true' : ''}`,
    'GET');
  return response;
};
