import { createContext, ReactNode, useRef, useState } from 'react';
import ConfirmDialog from 'shared/ui/confirm-dialog';

type ConfirmDialogSetup = (config: ConfirmDialogConfig) => Promise<void>;
export const ConfirmDialogContext = createContext<ConfirmDialogSetup>(Promise.reject);

export type ConfirmDialogConfig = {
  title?: string;
  content: string;
  rejectOnCancel?: Boolean;
};

type Props = {
  children: ReactNode;
};

const ConfirmDialogProvider = (props: Props) => {
  const [config, setConfig] = useState<ConfirmDialogConfig | null>(null);

  const promiseRef = useRef({
    resolve: () => { },
    reject: () => { }
  });
  const titleRef = useRef('');
  const contentRef = useRef('');

  if (config) {
    titleRef.current = config.title ?? 'Вы уверены?';
    contentRef.current = config.content;
  }

  const confirmFunc = (config: ConfirmDialogConfig) => {
    setConfig(config);
    return new Promise<void>((resolve, reject) => {
      promiseRef.current = { resolve, reject };
    });
  };


  return (
    <ConfirmDialogContext.Provider value={confirmFunc}>
      {props.children}

      <ConfirmDialog
        open={!!config}
        onYesClick={() => {
          promiseRef.current.resolve();
          setConfig(null);
        }}
        onNoClick={() => {
          config?.rejectOnCancel && promiseRef.current.reject();
          setConfig(null);
        }}
        title={titleRef.current}
        content={contentRef.current}
      />
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;