import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { UpdateUserCompanyDataDto } from '@sr/dto';
import {userCompanyDataApi} from './api';

export const useUserCompanyDetails = (id: number) => {
  const { data, isLoading, isFetching, error } = userCompanyDataApi.useGetCompanyDataQuery(
    id,
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    userCompanyData: data ? data : undefined,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};


export const useUpdateUserCompanyData = (id: number) => {
  const [update, { isLoading, error }] = userCompanyDataApi.useUpdateCompanyDataMutation();
  return async (userCompanyData: UpdateUserCompanyDataDto) => {
    return update({ userId: id, data: userCompanyData }).unwrap();
  };
};
