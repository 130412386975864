import { WccDto } from '@sr/dto';
import { useState, useEffect } from 'react';
import { fetchInvoiceWcc, postInvoiceWcc, deleteInvoiceWcc } from './api';

export const useInvoiceWcc = (invoiceId: number) => {
  const [invoiceWcc, setInvoiceWcc] = useState<WccDto | null>(null);

  const [isFetchingWcc, setFetchingWcc] = useState(false);
  const [isPostingWcc, setPostingWcc] = useState(false);

  useEffect(() => {
    setFetchingWcc(true);
    fetchInvoiceWcc(invoiceId)
      .then(invoiceWcc => {
        setInvoiceWcc(invoiceWcc.wcc);
      })
      .finally(() => setFetchingWcc(false));
  }, [invoiceId]);

  const createWcc = async () => {
    setPostingWcc(true);
    return postInvoiceWcc({ invoiceId })
      .then(result => {
        setInvoiceWcc(result.wcc);
      })
      .finally(() => setPostingWcc(false));
  };

  const deleteWcc = async () => {
    setPostingWcc(true);
    return deleteInvoiceWcc(invoiceId)
      .then(() => {
        setInvoiceWcc(null);
      })
      .finally(() => setPostingWcc(false));
  };

  return ({
    invoiceWcc,
    createWcc,
    deleteWcc,
    isFetchingWcc,
    isPostingWcc
  })
}

