import { useEffect, useState } from 'react';
import { RegionInfoDto } from '@sr/dto';
import { fetchRegions } from 'shared/api/regions';
import { useSnack } from 'shared/ui/snack';


const useRegions = (load: boolean = true) => {
  const { showError } = useSnack();
  const [list, setList] = useState<RegionInfoDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!load)
      return;

    setLoading(true);
    fetchRegions()
      .then(response => setList(response))
      .catch(error => showError(error))
      .finally(() => {
        setLoading(false);
      });
  }, [load]);

  return ({
    list: list,
    loading
  });
};

export default useRegions;
