import { useMemo } from 'react';
import { InvoiceDto, PurchaseDto } from '@sr/dto';
import { useInvoicePurchases } from 'hooks/purchases-list.hook';

export const useRelatedPurchases = (invoiceId: number) => {
  const purchases = useInvoicePurchases(invoiceId);

  const purchasesProfit = useMemo(
    () => purchases.items.reduce(
      (prev, curr) => (prev + calcPurchaseProfit(curr)),
      0),
    [purchases]);

  const relatedInvoices = useMemo(
    () => {
      const allRelatedInvoices = purchases.items
        .filter(p => !!p.supplierInvoiceRow)
        .map(purchase => purchase.supplierInvoice);
      const uniqueIds = [...new Set(allRelatedInvoices.map(i => i.id))];
      const relatedInvoices = uniqueIds.map(id => allRelatedInvoices.find(i => i.id === id)) as InvoiceDto[];
      const totalCost = relatedInvoices.reduce((acc, current) => acc + current.totals.discountPriceTotal, 0);
      const totalPaied = relatedInvoices.reduce((acc, current) => acc + current.totals.partialPaidAmount, 0);
      return ({
        invoices: relatedInvoices,
        totalCost,
        totalPaied
      });
    },
    [purchases]);

  return ({
    purchases,
    purchasesProfit,
    relatedInvoices: relatedInvoices.invoices,
    totalInvoicesCost: relatedInvoices.totalCost,
    totalInvoicePaied: relatedInvoices.totalPaied
  })
}

export const calcPurchaseProfit = (purchase: PurchaseDto) => {
  return purchase.supplierInvoiceRow
    ? (purchase.clientInvoiceRow.priceDiscount - purchase.supplierInvoiceRow.priceDiscount)
    : 0;
}