import { MenuItem, TextField } from '@mui/material';
import { InvoiceStatus, INVOICE_STATUSES } from '@sr/dto';
import { FieldProps } from 'formik';

export const YearPickerField = ({ form, field }: FieldProps<number, any>) => {
  const { setFieldValue, errors, touched } = form;
  const { name, value } = field;
  const error = errors[name];
  const isTouched = touched[name];
  const errorText = isTouched
    ? typeof error === 'string'
      ? error
      : undefined
    : undefined;

  const handleChange = (event: any) => setFieldValue(name, event.target.value);

  let ArrYear : Array<number> = []
  const date = new Date()
  const year = date.getFullYear()
  ArrYear.push(year)
  ArrYear.push(year-1)
  ArrYear.push(year-2)
  ArrYear.push(year-3)
  ArrYear.push(year-4)
  return (
    <TextField
      label='Год'
      fullWidth
      value={value}
      onChange={handleChange}
      error={isTouched && error !== undefined}
      helperText={errorText}
      select
    >
      <MenuItem key={''} value={''}>
        &nbsp;
      </MenuItem>
      {ArrYear.map(key =>
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      )
      }
    </TextField>);
};