import { Box, Stack, Tab, Tabs } from '@mui/material';
import { useUser } from 'entities/user/profile/lib';
import { UserProfileCard } from 'features/user/user-profile/user-profile-card';
import { useTitleHook } from 'hooks/page-title.hook';
import { useState } from 'react';
import { PageHeader } from 'shared/ui/details-page-header';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { TabPanel } from 'shared/ui/tab-panel';
import { useIdParam } from 'utils/url-paramters.hook';
import { ChangePasswordDialog } from 'features/user/change-password-dialog';
import { UserPersonalData } from 'features/user/personal-data';
import { UserCompanyData } from 'features/user/user-company-data';
import { UserActiveStatus } from 'features/user/user-active-status';
import { UserFiredStatus } from 'features/user/user-fire-status';
import { ContactsPanel } from 'widgets/contacts/contact-panel';
import { EntitiesWithContacts } from 'features/contact/contacts.types';
import { CardPaperTitled } from 'shared/ui/card-paper';
import { UserRights } from '../../features/user/user-rights';

const UserDetailsPage = () => {
  const userId = useIdParam('userId');

  const { user, error } = useUser(userId);
  const fullName = user ? `${user.secondName} ${user.firstName} ${user.middleName}` : '';

  useTitleHook(fullName && `Пользователь ${fullName}`);

  const [tab, setTab] = useState(0);

  if (!user)
    if (error)
      return <ErrorBanner errorMessage={error} />;
    else
      return <LoadingBanner />;

  return (
    <>
      <PageHeader
        title={
          <PageHeader.Text>
            <PageHeader.Text.Stressed>{fullName}</PageHeader.Text.Stressed>
          </PageHeader.Text>
        }

        subHeader={
          <Box>
            <UserActiveStatus userId={userId} />
            <UserFiredStatus userId={userId} />
          </Box>
        }
      />

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)} >
          <Tab label="Основное" />
          <Tab label="Личные данные" />
          <Tab label="Компания" />
          <Tab label="Права" />
          <Tab label="Интеграция" disabled />
          <Tab label="Аудит" disabled />
        </Tabs>
      </Box>

      <Box>
        <TabPanel value={tab} index={0}>
          <Stack direction="row" spacing={2}>
            <Box maxWidth={500} flexGrow={1}>
              <UserProfileCard />
              <ChangePasswordDialog />
            </Box>
            <Box flexGrow={0} >
              <CardPaperTitled title="Контакты">
                <ContactsPanel relationId={userId} relationType={EntitiesWithContacts.user} />
              </CardPaperTitled>
            </Box>
          </Stack>
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <UserPersonalData userId={userId} />
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <UserCompanyData userId={userId} />
        </TabPanel>

        <TabPanel value={tab} index={3}>
          <UserRights userId={userId} />
        </TabPanel>

        <TabPanel value={tab} index={5}>
          Аудит (TODO)
        </TabPanel>

      </Box>
    </>
  );
};

export default UserDetailsPage;
