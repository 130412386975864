import { Link } from 'shared/ui/link-base';
import { UserDto } from '@sr/dto';
import { userLinkManager } from 'utils/link-manager';
import { formatPerson } from 'utils/person-format-helpers';

export const LinkToUser = ({ user }: { user: UserDto }) => {

  const link = userLinkManager.link(user.id);

  return (
    <Link to={link}>
      <span style={{ 'textDecoration': user.isFired ? 'line-through' : undefined }}>{formatPerson(user)}</span>
    </Link>
  );
};
