import { IPagedCollection, CityInfoDto, } from '@sr/dto';
import { cityApi } from 'entities/geo/cities/cityApiSlice';
import { CITIES_BASE_URL } from 'shared/api/api';
import { CollectionParams } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';


const injectedApi = cityApi.injectEndpoints({
  endpoints: (builder) => ({
    getCitiesList: builder.query<IPagedCollection<CityInfoDto>, CollectionParams>({
      query: ({ paging, filter }: CollectionParams) => ({
        url: `${CITIES_BASE_URL}?${filter}`,
        params: paging && pagingDataToParams(paging),
        method: 'GET'
      }),
    }),
    deleteCity: builder.mutation<void, number>({
      query: (cityId: number) => ({
        url: `${CITIES_BASE_URL}/${cityId}`,
        method: 'DELETE'
      }),
    })
  }),
  overrideExisting: true
});

export const cityListApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['*'],
  endpoints: {
    getCitiesList: {
      providesTags: ['*']
    },
    deleteCity: {
      invalidatesTags: ['*']
    }
  }
});
