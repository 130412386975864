import { useState, useEffect } from 'react';
import { MediaInfoDto } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { fetchMediaDropdown } from './api';

export const useMediaDropdown = (load: boolean = true) => {
  const { showError } = useSnack();
  const [list, setList] = useState<MediaInfoDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!load)
      return;

    setLoading(true);
    fetchMediaDropdown()
      .then(response => setList(response))
      .catch(error => showError(error))
      .finally(() => {
        setLoading(false);
      });
  }, [load, showError]);

  return ({
    list: list,
    loading
  });
};
