import { TextField, Autocomplete, AutocompleteProps } from '@mui/material';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import useCountries from 'entities/geo/countries/countries.hook';
import { ICountry } from 'interfaces/ICountry';

export const CountryPicker = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props;

  const [open, setOpen] = useState(false);
  const { list, loading: fetching } = useCountries(open || Boolean(value));
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: ICountry | null) => {
      setSelectedCountry(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name, setSelectedCountry]
  );

  useEffect(() => {
    setSelectedCountry(list.find(x => x.id === value) ?? null);
  }, [list, value]);


  const loading = open && list.length === 0 && fetching;


  return (
    // <CountryAutoselect name={name} value={selectedCountry} label={label} onChange={onChange} />
    <Autocomplete
      value={selectedCountry}
      autoComplete={false}
      options={list}
      //sx={{ mt: 2 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />
  );
};

type Props = {
  name: string;
  label: ReactNode;
  value: any;
  onChange: (e: any, v: any) => void;
}

export const CountryAutoselect = (props: Props) => {
  const { name, label, value, onChange } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<ICountry | null>(null);
  const { list, loading } = useCountries(open || Boolean(value));
  return (
    <Autocomplete
      value={selected}
      autoComplete={false}
      options={list}
      fullWidth
      //sx={{ mt: 2 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
}


