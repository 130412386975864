import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addNewCityDialogOpen: false
}

export const citySlice = createSlice({
  name: 'city',
  initialState: initialState,
  reducers: {
    openDialog(state) {
      state.addNewCityDialogOpen = true;
    },
    closeDialog(state) {
      state.addNewCityDialogOpen = false;
    }
  },
}
);

export const { reducer: cityReducer, actions: cityActions } = citySlice;