import { IsIn, IsNotEmpty, IsNumber, IsString } from "class-validator";
import { CashflowDirection, CFD_TYPES, CFD_TYPES_KEYS } from "./enums/cashflow-directions";

export class CashflowDto {
  id: number;
  title: string;

  @IsIn(CFD_TYPES_KEYS)
  @IsNumber()
  direction: CashflowDirection;

  isDeleted: boolean;
}

export class CreateCashflowDto {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsIn(CFD_TYPES_KEYS)
  @IsNumber()
  direction: CashflowDirection
}

export class UpdateCashflowDto {
  id: number;

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsIn(CFD_TYPES_KEYS)
  @IsNumber()
  direction: CashflowDirection;
}