import { Box, Stack, Typography } from '@mui/material';
import { IPerson } from '@sr/dto';
import { formatPerson } from 'utils/person-format-helpers';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

type Props = {
  person: IPerson,
}

const PersonSpan = (props: Props) => {
  const { person } = props;
  return (
    <Box sx={{ color: 'text.secondary' }}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <AccountBoxIcon />
        <Typography variant="caption" >
          {formatPerson(person)}
        </Typography>
      </Stack>
    </Box>
  );
}

export default PersonSpan;