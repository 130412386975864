import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { timeSheetApi, TimeSheetBaseMutation, TimeSheetMutation } from './time-sheet.api';
import { TimeSheetDataDto, TimeSheetDataRowDto, UserDto } from '@sr/dto';
import {
  GridAlignment,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';
import { RowCloseCell } from './row-close-cell';
import { HoursSumCell } from './hours-sum-cell';
import { DaysSumCell } from './days-sum-cell';

type TimeSheetGrid = {
  rows: GridRowsProp,
  columns: GridColDef[],
  isLoading: boolean,
  error: string
}

export const NULL_USER_ID = 'full_working_hours';

export const MANDATORY_COLUMNS = {
  NAME: { FIELD: 'name', LABEL: 'Имя сотрудника' },
  DAYS_SUM: { FIELD: 'daysSum', LABEL: 'Итого дней' },
  HOURS_SUM: { FIELD: 'hoursSum', LABEL: 'Итого часов' },
  CLOSE: { FIELD: 'closePeriodAction', LABEL: 'Закрытие' },
};

const mapUserRow = (row: TimeSheetDataRowDto) => {
  if (!row.user) throw new Error('Wrong mapUserRow usage');
  return { name: `${row.user.secondName} ${row.user.firstName}`, id: row.user.id , ...JSON.parse(row.payload), isClosed: row.closedWorkingHours !== null };
};

const mapWorkingScheduleRow = (row: TimeSheetDataRowDto) => {
  return { name: 'График работы: ', id: NULL_USER_ID, ...JSON.parse(row.payload), isClosed: row.closedWorkingHours !== null };
};

const timeSheetRowsMapper = (timeSheetDataDto: TimeSheetDataDto): GridRowsProp =>
  [
    ...timeSheetDataDto.rows
      .map((row: TimeSheetDataRowDto)=> {
        if (row.user) {
          return mapUserRow({ ...row, user: row.user as UserDto });
        }
        return mapWorkingScheduleRow(row);
      })
  ];

const timeSheetColMapper = (timeSheetDataDto: TimeSheetDataDto): GridColDef[] =>
  ([
    { field: MANDATORY_COLUMNS.NAME.FIELD, headerName: MANDATORY_COLUMNS.NAME.LABEL, width: 180, editable: false, sortable: false },
    ...timeSheetDataDto.dates.map((date: number) => ({
      field: String(date),
      headerName: String(date),
      width: 50, maxWidth: 100,
      editable: true, sortable: false, filterable: false,
      disableColumnMenu: true,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment
    })),
    { field: MANDATORY_COLUMNS.DAYS_SUM.FIELD, headerName: MANDATORY_COLUMNS.DAYS_SUM.LABEL,
      width: 150, maxWidth: 200,
      editable: false, sortable: false, filterable: false,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: DaysSumCell
    },
    { field: MANDATORY_COLUMNS.HOURS_SUM.FIELD, headerName: MANDATORY_COLUMNS.HOURS_SUM.LABEL,
      width: 150, maxWidth: 200,
      editable: false, sortable: false, filterable: false,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: HoursSumCell
    },
    { field: MANDATORY_COLUMNS.CLOSE.FIELD, headerName: MANDATORY_COLUMNS.CLOSE.LABEL,
      width: 150, maxWidth: 200,
      editable: false, sortable: false, filterable: false,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: RowCloseCell,
    },
  ]);

export const useTimeSheetData = (year: number, month: number): TimeSheetGrid => {
  const { data, isLoading, isFetching, error } = timeSheetApi.useGetTimeSheetDataQuery(
    { year, month },
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    rows: data ? timeSheetRowsMapper(data) : [],
    columns: data ? timeSheetColMapper(data) : [],
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching,
  };
};

export const useUpdateTimeSheetRow = () => {
  const [update] = timeSheetApi.usePutTimeSheetDataMutation();
  return async ({ year, month, userId, payload }: TimeSheetMutation) => {
    return update({ year, month, userId, payload }).unwrap();
  };
};

export const usePeriodActions = () => {
  const [closePeriod, { isLoading: isClosing }] = timeSheetApi.useCloseTimeSheetDataMutation();
  const [openPeriod, { isLoading: isOpening }] = timeSheetApi.useOpenTimeSheetDataMutation();

  return {
    closePeriod: async ({ year, month, userId, payload }: TimeSheetMutation)=> {
      return closePeriod({ year, month, userId, payload }).unwrap();
    },
    openPeriod: async ({ year, month, userId }: TimeSheetBaseMutation)=> {
      return openPeriod({ year, month, userId }).unwrap();
    },
    isLoading: isClosing || isOpening
  };
};
