import { GridRenderCellParams } from '@mui/x-data-grid';

const getTotalWorkingDays = (payload: any): number => {
  const { id, name, isClosed, ...values } = payload;
  return Object.values<number>(values).reduce((acc: number, curr)=> Number(curr) > 0 ? acc + 1: acc, 0);
};

export const DaysSumCell = (props: GridRenderCellParams) => {
  const totalWorkingDays = getTotalWorkingDays(props.row);
  return (<span>{totalWorkingDays}</span>);
};
