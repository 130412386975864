import {
  CompanyDto,
  CompanyQueryType,
  CreateCompanyDto,
  UpdateCompanyDto
} from '@sr/dto';
import { authorizedRequest, postReq, putReq } from 'shared/api/axios';
import { COMPANY_BASE_URL } from 'shared/api/api';

export const fetchCompanyDetails = async (companyId: number) => authorizedRequest<void, CompanyDto>(`${COMPANY_BASE_URL}/${companyId}`, 'GET');

export const postCompany = async (company: CreateCompanyDto) => postReq(COMPANY_BASE_URL, company, CompanyDto);

export const putCompany = async (company: UpdateCompanyDto) => putReq(COMPANY_BASE_URL, company, CompanyDto);

const BASE_URL = COMPANY_BASE_URL;
const COMPANIES_DROPDOWN_LIMIT = 25;

export const fetchCompaniesDropdown = async (name = '', legalEntityId?: number, limit = COMPANIES_DROPDOWN_LIMIT) => {
  const legalEntityIdQuery = legalEntityId ? '&legalEntityId=' + String(legalEntityId) : '';
  return authorizedRequest<void, CompanyDto[]>(`${BASE_URL}/?name=${name}${legalEntityIdQuery}&limit=${limit}`, 'GET');
};

export const fetchClientsIds = async (filter: CompanyQueryType) => {
  return authorizedRequest<void, number[]>(`${BASE_URL}/clients-ids?type=${filter}`, 'GET');
};

export const unlinkManagers = async (companiesIds: number[]) => {
  return authorizedRequest<void, any>(`${BASE_URL}/unlinkManagers?companiesIds=${companiesIds}`, 'DELETE');
};

export const fetchOurCompanyId = async () => {
  return authorizedRequest<void, number>(`${BASE_URL}/our-company`, 'GET');
};
