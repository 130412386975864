import { Stack, MenuItem, TextField, Typography } from '@mui/material';
import { checkOsnForm, CreateLegalEntityDto, LegalEntityDto, LegalEntityTypeDto, UpdateLegalEntityDto } from '@sr/dto';
import { TAX_FORMS } from '@sr/dto';
import { useLegalEntityTypesActiveList, useLegalEntityTypesList } from './legal-entities-type/legal-entities-type.hooks';

export interface FormData {
  name: string;
  legalEntityTypeId: number | '';
  inn: string;
  kpp: string;
  address: string;
  postAddress: string;
  taxForm: number | '';
  vat?: number | '';
}

export const fromLegalEntity = (entity: LegalEntityDto): FormData => {
  return {
    legalEntityTypeId: entity.legalEntityTypeId,
    name: entity.name,
    inn: entity.inn,
    kpp: entity.kpp,
    address: entity.address,
    postAddress: entity.postAddress,
    taxForm: entity.taxForm,
    vat: entity.vat ?? ''
  };
};

export const toLegalEntity = (id: number, formData: FormData): UpdateLegalEntityDto => {
  return {
    id,
    ...toNewLegalEntity(formData)
  };
};

export const toNewLegalEntity = (formData: FormData): CreateLegalEntityDto => {
  const taxForm = formData.taxForm as number;
  return {
    legalEntityTypeId: formData.legalEntityTypeId as number,
    name: formData.name,
    inn: formData.inn,
    kpp: formData.kpp,
    address: formData.address,
    postAddress: formData.postAddress,
    taxForm: taxForm,
    vat: checkOsnForm(taxForm) ? (formData.vat as number) : undefined
  };
};

export const emptyValues: FormData = {
  name: '',
  legalEntityTypeId: '',
  inn: '',
  kpp: '',
  taxForm: '',
  address: '',
  postAddress: '',
  vat: ''
};

export const validate = (values: FormData) => {
  let errors: Partial<{ [x in keyof FormData]: string }> = {};

  const requiredField = 'Обязательное поле';

  if (!values.legalEntityTypeId)
    errors.legalEntityTypeId = requiredField;

  if (!values.name)
    errors.name = requiredField;

  if (!values.inn)
    errors.inn = requiredField;
  else
    if (!(values.inn.length === 10 || values.inn.length === 12))
      errors.inn = 'ИНН должен состоять из 10 или 12 цифр';

  if (!values.kpp)
    errors.kpp = requiredField;
  else
    if (!(values.kpp.length === 9))
      errors.kpp = 'КПП должен состоять из 9 цифр';

  if (!values.address)
    errors.address = requiredField;

  if (!values.postAddress)
    errors.postAddress = requiredField;

  if (!values.taxForm)
    errors.taxForm = requiredField;

  if (checkOsnForm(values.taxForm as number))
    if (!values.vat)
      errors.vat = requiredField;

  return errors;
};

type Props = {
  formik: any;
}

export const LegalEntityBasicForm = (props: Props) => {
  const { formik } = props;
  const { collection, isLoading, error } = useLegalEntityTypesActiveList();
  let arr: any = []
  collection.map((el:any) => (el.active || formik.values.legalEntityTypeId==el.id) ? arr.push(el) : '')
  return (
    <>
      <form>
        <Stack direction="column" spacing={2}>
          <TextField
            name="name"
            label="Название"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.errors.name}
          />
          <TextField
            name="legalEntityTypeId"
            defaultValue={null}
            onChange={formik.handleChange}
            value={formik.values.legalEntityTypeId}
            select
            label="Форма собственности"
            error={formik.touched.legalEntityTypeId && !!formik.errors.legalEntityTypeId}
            helperText={formik.errors.legalEntityTypeId}
          >
            {
            arr.map((el:any) =>
              <MenuItem key={el.id} value={el.id} disabled={!el.active}> 
                {el.name}&nbsp;
                <Typography component="span" color="text.secondary">
                  ({el.description})
                </Typography>
              </MenuItem>
            )
            }
          </TextField>

          <Stack direction="row" spacing={2} >
            <TextField
              name="inn"
              label="ИНН"
              onChange={formik.handleChange}
              value={formik.values.inn}
              error={formik.touched.inn && !!formik.errors.inn}
              helperText={formik.errors.inn}
            />
            <TextField
              name="kpp"
              label="КПП"
              onChange={formik.handleChange}
              value={formik.values.kpp}
              error={formik.touched.kpp && !!formik.errors.kpp}
              helperText={formik.errors.kpp}
            />
          </Stack>
          <TextField
            name="address"
            label="Адрес"
            onChange={formik.handleChange}
            value={formik.values.address}
            error={formik.touched.address && !!formik.errors.address}
            helperText={formik.errors.address}
          />
          <TextField
            name="postAddress"
            label="Почтовый адрес"
            onChange={formik.handleChange}
            value={formik.values.postAddress}
            error={formik.touched.postAddress && !!formik.errors.postAddress}
            helperText={formik.errors.postAddress}
          />
          <TextField
            name="taxForm"
            defaultValue={null}
            onChange={formik.handleChange}
            value={formik.values.taxForm}
            error={formik.touched.taxForm && !!formik.errors.taxForm}
            helperText={formik.errors.taxForm}
            select
            label="Форма налогообложения"
          >
            {TAX_FORMS.map(el =>
              <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)
            }
          </TextField>
          <TextField
            name="vat"
            defaultValue={null}
            onChange={formik.handleChange}
            value={formik.values.vat}
            disabled={!checkOsnForm(formik.values.taxForm)}
            error={formik.touched.vat && !!formik.errors.vat}
            helperText={formik.errors.vat}
            select
            label="Ставка НДС"
          >
            <MenuItem value=''>-</MenuItem>
            <MenuItem value={10}>10 %</MenuItem>
            <MenuItem value={20}>20 %</MenuItem>
          </TextField>
        </Stack>
      </form>
    </>
  );
};
