import { MouseEvent } from 'react';
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link as RouterLink } from 'react-router-dom';
import MenuParams from 'shared/ui/menu-params';

import { CountryInfoDto } from '@sr/dto';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { LinkToCountry } from 'shared/ui/links/link-to-country';

type Props = {
  list: CountryInfoDto[],
  onDelete: (country: CountryInfoDto) => void;
};

const CountriesList = (props: Props) => {
  const { list, onDelete } = props;
  const { openMenu, menuData } = useMenu<CountryInfoDto>({ deleteHandler: onDelete });

  return (
    <>
      <TableContainer component={Card}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((country, i) => {
              return (
                <CountryRow
                  key={i}
                  country={country}
                  onMenuClick={openMenu} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <RowMenu menuData={menuData} />
    </>);
};



type RowProps = {
  country: CountryInfoDto,
  onMenuClick: (menuParams: MenuParams<CountryInfoDto>) => void;
};

const CountryRow = (props: RowProps) => {
  const { country, onMenuClick } = props;

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: country });
  };

  return (
    <>
      <TableRow
        key={country.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToCountry country={country} />
        </TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};


export default CountriesList;
