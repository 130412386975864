import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  value: '' as number | ''
};

const salaryUserSlice = createSlice({
  name: 'userSalary',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<number|''>) {
      state.value = action.payload;
    },
  },
});

export const { reducer: userSalaryReducer, actions: setUserSalary } = salaryUserSlice;

