import {
  Box,
  IconButton, TableCell, TableRow
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { ContractInfoDto, IPagingData } from '@sr/dto';
import MenuParams from 'shared/ui/menu-params';
import { GridLayout } from 'shared/ui/grid-layout';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToContract } from 'shared/ui/links/link-to-contract';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import ManagerSpan from 'shared/ui/manager-span';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { ContractFilter } from 'features/contract/contracts-filter';
import ContractStatus from 'entities/contract/contract-status';

type Props = {
  contracts: IRemotePagedCollection<ContractInfoDto>;
  paging: IPagingData,
  onChangePaging: (paging: IPagingData) => void,
  filterString?: string,
  onFilter?: (filterString: string) => void,
};

export const ContractsGrid = ({ contracts, paging, onChangePaging, filterString, onFilter }: Props) => {
  const { openMenu, menuData } = useMenu<ContractInfoDto>({
    deleteHandler: () => { }
  });


  return (
    <>
      {filterString !== undefined && onFilter &&
        <Box my={1}>
          <ContractFilter filterString={filterString} onFilter={onFilter} />
        </Box>
      }

      <GridLayout
        items={contracts.collection.items}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={contracts.collection.totalItemsCount}
        isLoading={contracts.isLoading}
        error={contracts.error}
        columns={['Номер', 'Действие', 'Дата', 'Юрлицо продавца', 'Юрлицо покупателя', 'Менеджер', 'Срок действия', '']}
        itemRender={(item) => <ContractRow
          key={item.id}
          contract={item}
          onMenu={openMenu} />}
        noItemsText='Список договоров пуст'
      />

      <RowMenu menuData={menuData} />
    </>);
};


type RowProps = {
  contract: ContractInfoDto;
  onMenu: (menuParam: MenuParams<ContractInfoDto>) => void;
};

const ContractRow = (props: RowProps) => {
  const { contract, onMenu } = props;

  return (
    <>
      <TableRow
        key={contract.id}
      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <LinkToContract contract={contract}>
            договор №{contract.id}
            {contract.name && <> ({contract.name})</>}
          </LinkToContract>
        </TableCell>
        <TableCell><ContractStatus contract={contract}/></TableCell>
        <TableCell>{formatDate(contract.contractStartDate)}</TableCell>
        <TableCell><LinkToLegalEntity legalEntity={contract.seller} /></TableCell>
        <TableCell><LinkToLegalEntity legalEntity={contract.buyer} /></TableCell>
        <TableCell><ManagerSpan person={contract.manager} /></TableCell>
        <TableCell>{contract.contractEndDate && formatDate(contract.contractEndDate)}</TableCell>
        <TableCell align="right">
          <IconButton
            edge="end"
            onClick={(e) => onMenu({ anchor: e.currentTarget, target: contract })}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
