import { Button, Paper, Stack, Typography } from '@mui/material';
import { Formik, FormikProps, Form } from 'formik';
import useCountry from 'entities/geo/countries/country.hook';
import { CountryInfoDto, UpdateCountryBasicDataDto } from '@sr/dto';

import { validate, FormValues, fromCountry, toCountry } from './country-form';
import { CountryFormFields } from './country-form-fields';
import { useTitleHook } from 'hooks/page-title.hook';

type Props = {
  countryId: number;
}
const CountryHome = (props: Props) => {
  const { countryId } = props;

  const { country, update } = useCountry(countryId);

  const handleSubmit = async (data: FormValues) => {
    const updateData: UpdateCountryBasicDataDto = {
      id: countryId,
      ...toCountry(data)
    };
    return update(updateData);
  }

  useTitleHook(country && `${country.name} | Страны`);

  return (
    <>
      <Stack direction='column' spacing={2}>
        {country && <>
          <Typography variant='h5' color='primary'>{country.name}</Typography>
          <Paper sx={{ p: 2 }}>
            <Formik
              initialValues={fromCountry(country)}
              enableReinitialize={true}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {(p: FormikProps<FormValues>) => (
                <>
                  <Form>
                    <CountryFormFields  {...p} />
                    <Button type='submit' variant='contained'>Сохранить</Button>
                  </Form>
                </>)}
            </Formik>
          </Paper>
        </>
        }

      </Stack>
    </>

  );
}

export default CountryHome;