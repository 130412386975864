import { ContractTemplateDto, ContractTemplateInfoDto, CreateContractTemplateDto, UpdateContractTemplateDto } from '@sr/dto';
import { useReadOnlyRemoteCollection, useRemoteCollection } from 'utils/remote-collection.hook';
import { useCallback } from 'react';
import { delReq, fetchCollectionReq, fetchReq, postReq, putReq } from 'shared/api/axios';
import { useRemoteItem } from 'utils/remote-item.hook';

const BASE_URL = '/contract-templates';

export const useContractsTemplatesDropDown = () => {
  return useReadOnlyRemoteCollection<ContractTemplateInfoDto>({
    fetchHandler: useCallback(async () => {
      const response = await fetchCollectionReq<ContractTemplateInfoDto>(
        `${BASE_URL}/dropdown`,
        ContractTemplateInfoDto
      );
      return response;
    }, []),
  });
};

export const useContractTemplatesList = () => {
  return useRemoteCollection<ContractTemplateInfoDto, CreateContractTemplateDto, UpdateContractTemplateDto>({
    fetchHandler: useCallback(async () => {
      return fetchCollectionReq(
        BASE_URL,
        ContractTemplateInfoDto
      );
    }, []),
    postHandler: useCallback(async (data: CreateContractTemplateDto) => {
      return postReq(
        BASE_URL,
        data,
        ContractTemplateDto
      );
    }, []),
    updateHandler: useCallback(async (data: UpdateContractTemplateDto) => {
      return putReq(
        BASE_URL,
        data,
        ContractTemplateDto
      );
    }, []),
    deleteHandler: async (id: number) => {
      return delReq(BASE_URL, id);
    }
  });
};

export const useContractTemplate = (id: number) => {
  return useRemoteItem<ContractTemplateDto, UpdateContractTemplateDto>({
    fetchHandler: useCallback(async () => {
      const response = await fetchReq(
        `${BASE_URL}/${id}`,
        ContractTemplateDto
      );
      return response;
    }, [id]),

    updateHandler: async (data) => {
      const response = await putReq<UpdateContractTemplateDto, ContractTemplateDto>(
        `${BASE_URL}`,
        data,
        ContractTemplateDto
      );
      return response;
    }
  });
};
