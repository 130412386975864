import { Checkbox, TableCell, TableRow } from '@mui/material';
import { InvoiceDto } from '@sr/dto';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import ManagerSpan from 'shared/ui/manager-span';
import PriceSpan from 'shared/ui/price-span';
import { InvoiceStatusSpan } from 'entities/invoice/status-span/status-span';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';

type Props = {
  invoice: InvoiceDto;
  checked: boolean;
  onCheckChange: (checked: boolean) => void;
};

export const InvoiceWccRow = ({ invoice, checked, onCheckChange }: Props) => {
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={(e, checked) => onCheckChange(checked)}
        />
      </TableCell>
      <TableCell>
        <LinkToInvoice invoice={invoice} />
      </TableCell>
      <TableCell>
        -
      </TableCell>
      <TableCell>
        {formatDate(invoice.endDate)}
      </TableCell>
      <TableCell>
        <LinkToLegalEntity legalEntity={invoice.seller} />
      </TableCell>
      <TableCell>
        <LinkToLegalEntity legalEntity={invoice.buyer} />
      </TableCell>
      <TableCell>
        <ManagerSpan person={invoice.manager} />
      </TableCell>
      <TableCell>
        -
      </TableCell>
      <TableCell align='right'>
        <PriceSpan price={invoice.totals.discountPriceTotal} size='small' />
      </TableCell>
      <TableCell align='right'>
        <InvoiceStatusSpan status={invoice.status} />
      </TableCell>

    </TableRow>
  );
};