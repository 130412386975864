import { LeadStatusDto } from '@sr/dto';
import { leadBaseApi } from 'entities/lead/api';
import { LEAD_BASE_URL } from 'shared/api/api';

const injectedApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateLeadStatus: builder.mutation<LeadStatusDto, { leadId: number; statusId: number }>({
      query: ({ leadId, statusId }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/status`,
        body: { statusId },
        method: 'PUT',
      }),
    }),
  }),
});

export const leadStatusApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['lead'],
  endpoints: {
    updateLeadStatus: {
      invalidatesTags: ['lead'],
    },
  },
});
