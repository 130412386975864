import { Field, FormikProps } from 'formik';
import { PopupFormData } from './media-types-create-edit.form';
import { Stack } from '@mui/material';
import { TextField } from 'formik-mui';

export const MediaTypeFromFields = (props: FormikProps<PopupFormData>) => {
  return (
    <Stack direction="column">
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Field
          name='name'
          component={TextField}
          label='Тип СМИ'
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
