import { Box, Button, MenuItem, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { Field, Formik } from 'formik';
import { CardPaper } from 'shared/ui/card-paper';
import { endOfDay, startOfDay } from 'date-fns';
import { PayorderFilter, FilterFormValues, fromDateString, fromNumberString, PayOrderStatus, POS_STATUSES, PO_GROUPS, PayOrderGroup } from '@sr/dto';
import { BankAccountPicker } from 'shared/ui/formik-fields/bank-account-picker';
import { TextField } from 'formik-mui';

type FormValues = FilterFormValues<PayorderFilter>;

const initial: FormValues = {
  ourLegalEntityId: '',
  fromDate: null,
  toDate: null,
  statusId: '',
};

const fromUrlSearchParams = (params: URLSearchParams) => {
  const formValues: FormValues = {
    bankAccountId: fromNumberString(params.get('bankAccountId')),
    group: fromNumberString(params.get('group')),
    ourLegalEntityId: fromNumberString(params.get('ourLegalEntityId')),
    fromDate: fromDateString(params.get('fromDate')),
    toDate: fromDateString(params.get('toDate')),
    statusId: fromNumberString(params.get('statusId')),
  };

  return formValues;
}

const toSearchString = (formValues: FormValues) => {
  const builder = new URLSearchParams();
  formValues.bankAccountId && builder.append('bankAccountId', formValues.bankAccountId.toString());
  formValues.fromDate && builder.append('fromDate', startOfDay(formValues.fromDate).toString());
  formValues.toDate && builder.append('toDate', endOfDay(formValues.toDate).toString());
  formValues.statusId && builder.append('statusId', formValues.statusId.toString());
  formValues.group && builder.append('group', formValues.group.toString());

  return builder.toString();
}

type Props = {
  filterString: string,
  onFilter: (searchParam: string) => void;
};

export const PayordersFilter = ({ filterString, onFilter }: Props) => {

  const handleSubmit = async (formValues: FormValues) => {
    const filterString = toSearchString(formValues);
    // console.log('Filter....' + filterString);
    return onFilter(filterString);
  };

  return (
    <CardPaper>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={
          filterString === ''
            ? initial
            : fromUrlSearchParams(new URLSearchParams(filterString))
        }
      >{
          ({ submitForm, resetForm, dirty }) => {
            return (
              <Stack direction="row" spacing={1} >

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="bankAccountId"
                    label="Расчетный счет"
                    component={BankAccountPicker}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="fromDate"
                    label="Дата платежа с"
                    component={DatePickerField}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="toDate"
                    label="Дата платежа по"
                    component={DatePickerField}
                  />
                </Box>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    name='statusId'
                    component={TextField}
                    select
                    fullWidth
                    label='Статус'
                  >
                    <MenuItem key="" value="">
                      &nbsp;
                    </MenuItem>
                    {Object.keys(POS_STATUSES).map(key =>
                      <MenuItem key={key} value={key}>
                        {POS_STATUSES[Number(key) as PayOrderStatus]}
                      </MenuItem>)}
                  </Field>
                </Box>


                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    name='group'
                    component={TextField}
                    select
                    fullWidth
                    label='Группа'
                  >
                    <MenuItem key="" value="">
                      &nbsp;
                    </MenuItem>
                    {Object.keys(PO_GROUPS).map(key =>
                      <MenuItem key={key} value={key}>
                        {PO_GROUPS[Number(key) as PayOrderGroup]}
                      </MenuItem>)}
                  </Field>
                </Box>

                <Button
                  onClick={submitForm}
                  variant="contained"
                  disabled={!dirty}
                  endIcon={<FilterListIcon />}
                >
                  Применить
                </Button>

                <Button
                  onClick={() => resetForm()}
                  variant="outlined"
                  endIcon={<HighlightOffIcon />}
                >
                  очистить
                </Button>
              </Stack>
            );
          }
        }
      </Formik>
    </CardPaper>
  );
};
