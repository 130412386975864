import { MediaInfoDto } from '@sr/dto';
import { ReactNode } from 'react';
import { Link } from 'shared/ui/link-base';
import { mediaLinkManager } from 'utils/link-manager';

export const LinkToMedia = (props: { media: MediaInfoDto, children?: ReactNode }) => {
  const { media: { id, name }, children } = props;

  const link = mediaLinkManager.link(id);

  if (children)
    return (<Link to={link} >
      {children}
    </Link>)

  return (
    <Link to={link} >
      {name}
    </Link>
  );
}