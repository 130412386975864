import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { InvoiceRowDto } from '@sr/dto';
import { PurchasePicker } from 'shared/ui/formik-fields/purchases-picker';
import { Field, Form, Formik } from 'formik';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  row: InvoiceRowDto,
  onSubmit: (purchaseId: number, rowId: number) => Promise<void>;
};

const LinkRowPurchaseDialog = ({ open, onClose, onSubmit, row }: DialogProps) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>
        Привяжите заявку
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ purchase: 0 }}
          onSubmit={(v) => onSubmit(v.purchase, row.id)}
        // validate={validate}
        >
          {({ submitForm, isSubmitting, dirty }) => <>
            <DialogContentText>
              <Form>
                <Field
                  name='purchase'
                  label='Заявка'
                  component={PurchasePicker}
                />
              </Form>
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                autoFocus
                disabled={isSubmitting || !dirty}
                onClick={submitForm}
              >
                Сохранить
              </Button>
              <Button
                variant="outlined"
                onClick={onClose}
              >
                Закрыть
              </Button>
            </DialogActions>
          </>
          }
        </Formik>
      </DialogContent>
    </Dialog>);
};

export default LinkRowPurchaseDialog;