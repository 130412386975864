import { contactsApi } from './contacts.api-slice';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import {
  AddContactQuery,
  ContactQuery, DeleteContactQuery,
} from './contacts.types';
import { AddContactDto, ILinkedEmail, ILinkedPhone, ILinkedUrl } from '@sr/dto';



export const useContacts = (query: ContactQuery) => {
  const { data, isLoading, error } = contactsApi.useGetContactsQuery(query, { skip: !query });
  return {
    contacts: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};

export const useAddContact = (query: AddContactQuery)=>{
  const [update, { isLoading }] = contactsApi.useAddContactMutation();
  const addContact = async <T extends ILinkedPhone|ILinkedEmail|ILinkedUrl> (
    data: AddContactDto
  ): Promise<T> => update({ query, data }).unwrap() as Promise<T>;
  return { addContact, isUpdating: isLoading };
};

export const useDeleteContact: (() => (query: DeleteContactQuery) => Promise<void>) = () => {
  const [deleteContact] = contactsApi.useDeleteContactMutation();
  return async (query: DeleteContactQuery)
    : Promise<void> =>
    deleteContact(query).unwrap();
};
