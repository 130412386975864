import { MediaBasicDataDto, UpdateMediaBasicDto } from '@sr/dto';
import { mediaApi } from 'entities/media/api-slice';
import { MEDIA_BASE_URL } from 'shared/api/api';


const injectedApi = mediaApi.injectEndpoints({
  endpoints: (builder) => ({
    getMediaBasicData: builder.query<MediaBasicDataDto, number>({
      query: (id: number) => ({
        url: `${MEDIA_BASE_URL}/${id}`,
        method: 'GET'
      }),
    }),
    updateMediaBasicData: builder.mutation<MediaBasicDataDto, UpdateMediaBasicDto>({
      query: (data: UpdateMediaBasicDto) => ({
        url: MEDIA_BASE_URL,
        body: data,
        method: 'PATCH'
      }),
    })
  }),
  overrideExisting: true
});

export const mediaBasicDataApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['media-basic-data'],
  endpoints: {
    getMediaBasicData: {
      providesTags: ['media-basic-data']
    },
    updateMediaBasicData: {
      invalidatesTags: ['media-basic-data']
    }
  }
})