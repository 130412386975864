import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ContractInfoDto } from '@sr/dto';
import { LinkToContract } from 'shared/ui/links/link-to-contract';

export const InvoiceContract = ({ contract }: { contract: ContractInfoDto }) => {

  return (
    <>
      <LinkToContract contract={contract} />
      <IconButton>
        <MoreVert />
      </IconButton>
    </>
  );
};