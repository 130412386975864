import { Stack, Box } from '@mui/material';
import { ContractAppendixDto } from '@sr/dto/dist/src/contract-appendix.dto';
import { LinkToContractAppendix } from 'shared/ui/links/link-to-contract-appendix';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';

export const AppendiciesList = ({ appendicies }: { appendicies: ContractAppendixDto[]; }) => {
  if (appendicies.length === 0)
    return <>Список приложений пуст</>;

  return (
    <Box m={1}>
      <Stack direction="column">
        {appendicies.map(a => (
          <Box key={a.id} sx={{ whiteSpace: 'pre-line' }}>
            <LinkToContractAppendix appendix={a} /> по счету <LinkToInvoice invoice={a.invoice} />
          </Box>
        ))}
      </Stack>
    </Box>
  );

};
