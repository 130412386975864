import { Box, Button, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PayOrderImportSessionDto } from '@sr/dto';
import { Link } from 'shared/ui/link-base';
import { CardPaper } from 'shared/ui/card-paper';
import { ReactNode } from 'react';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToImportSession } from 'shared/ui/links/link-to-import-session';

type Props = {
  collection: PayOrderImportSessionDto[],
  onDeleteClick: (session: PayOrderImportSessionDto) => void;
};

export const PayordersImportSessionsList = ({ collection, onDeleteClick }: Props) => {

  return (
    <>
      {collection.map(session => (
        <Box key={session.id} mb={1}>
          <CardPaper >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Box>
                <LinkToImportSession session={session}>{session.originalFileName}</LinkToImportSession> - {formatDate(session.importDate)} - {session.originalFileName}
              </Box>
              <Box>
                <IconButton onClick={() => onDeleteClick(session)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Stack>
          </CardPaper>
        </Box>
      ))}
    </>
  );
};


