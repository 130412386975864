import {
  IsString,
  MinLength,
  MaxLength,
  IsNumber,
  IsOptional
} from 'class-validator';
import { Duplicates } from './duplicates';

export type IUrlType = {
  id: number;
  name: string;
  icon: string;
};

export class CreateUrlDto {
  @IsString()
  @MinLength(5)
  @MaxLength(250)
  url: string;

  @IsOptional()
  @IsNumber()
  urlTypeId: number = DEFAULT_URL_TYPE;
}

export interface IUrl {
  url: string;
  urlType: IUrlType;
}

export interface ILinkedUrl extends Duplicates {
  id: number;
  url: IUrl;
}

export const DEFAULT_URL_TYPE = 1;
