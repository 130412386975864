import { leadManagerApi } from './api';

export const useUpdateLeadManager = () => {
  const [update, { isLoading }] = leadManagerApi.useUpdateLeadManagerMutation();

  return {
    update,
    isUpdating: isLoading,
  };
};
