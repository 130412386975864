import { ContractAppendixDto, UpdateContractAppendixDto } from '@sr/dto/dist/src/contract-appendix.dto';
import { plainToInstance } from 'class-transformer';
import { useCallback } from 'react';
import { fetchReq, authorizedFormDataRequest, toFormData } from 'shared/api/axios';
import { CONTRACTS_APPENDIX_BASE_URL } from 'shared/api/api';
import { useRemoteItem } from 'utils/remote-item.hook';

export const useContractAppendix = (appendixId: number) => {
  return useRemoteItem<ContractAppendixDto, UpdateContractAppendixDto>({
    fetchHandler: useCallback(async () => {
      const response = await fetchReq(
        `${CONTRACTS_APPENDIX_BASE_URL}/${appendixId}`,
        ContractAppendixDto
      );
      return response;
    }, [appendixId]),

    updateHandler: async (data) => {
      const response = await authorizedFormDataRequest<ContractAppendixDto>(
        `${CONTRACTS_APPENDIX_BASE_URL}`,
        'PUT',
        toFormData(data),

      );
      return plainToInstance(ContractAppendixDto, response, { enableImplicitConversion: true });
    }

  });
};