import { useCallback } from 'react';
import { fetchCollectionReq, fetchPagedReq, toFormData } from 'shared/api/axios';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';
import { ContractDto, ContractInfoDto, CreateContractDto, IPagingData, UpdateContractDto } from '@sr/dto';
import { plainToInstance } from 'class-transformer';
import { FormValues } from 'components/Contracts/contract.form';
import { useRemoteCollection } from 'utils/remote-collection.hook';
import { useRemoteItem } from 'utils/remote-item.hook';
import { authorizedFormDataRequest, authorizedRequest } from 'shared/api/axios';
import { CONTRACTS_BASE_URL } from 'shared/api/api';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const useContractsList = (paging: IPagingData, filterString?: string, companyId?: number) => {
  const collection = useReadOnlyRemotePagedCollection<ContractInfoDto>({
    fetchHandler: useCallback(async () => {
      const params = new URLSearchParams(filterString);
      companyId && params.append('companyId', companyId.toString());

      const response = await fetchPagedReq<void, ContractInfoDto>(`${CONTRACTS_BASE_URL}?${params.toString()}`, paging, ContractInfoDto, params);

      return response;
    }, [companyId, filterString, paging]),
  });

  const postHandler = useCallback(async (formData: FormValues) => {
    const dto = plainToInstance<CreateContractDto, FormValues>(CreateContractDto, formData);
    const response = await authorizedRequest<CreateContractDto, ContractDto>(CONTRACTS_BASE_URL, 'POST', dto);

    return plainToInstance(ContractInfoDto, response, { enableImplicitConversion: true });
  }, []);

  const deleteHandler = useCallback(async (id: number) => authorizedRequest<void, void>(`${CONTRACTS_BASE_URL}/${id}`, 'DELETE'), []);

  return {
    collection,
    addContract: postHandler,
    deleteContract: deleteHandler,
  };
};

export const useContract = (contractId: number) => {
  return useRemoteItem<ContractDto, UpdateContractDto>({
    fetchHandler: useCallback(async () => {
      const response = await authorizedRequest<void, ContractDto>(`${CONTRACTS_BASE_URL}/${contractId}`, 'GET');
      return plainToInstance(ContractDto, response, { enableImplicitConversion: true });
    }, [contractId]),

    updateHandler: async (data) => {
      const response = await authorizedFormDataRequest<ContractDto>(`${CONTRACTS_BASE_URL}`, 'PUT', toFormData(data));
      return plainToInstance(ContractDto, response, { enableImplicitConversion: true });
    },
  });
};
