import { ADDITION_TYPES, DEDUCTION_TYPES, SALARY_ENTRY_TYPES } from '@sr/dto';

const salaryTypeLabels: Record<SALARY_ENTRY_TYPES, string> = {
  [SALARY_ENTRY_TYPES.BONUS] : 'Премия',
  [SALARY_ENTRY_TYPES.SEEK_COMPENSATION] : 'Больничные',
  [SALARY_ENTRY_TYPES.VACATION_COMPENSATION] : 'Отпускные',
  [SALARY_ENTRY_TYPES.COMMISSION ]:'Проценты',
  [SALARY_ENTRY_TYPES.OTHER_ADDITIONS] : 'Прочие начисления',
  [SALARY_ENTRY_TYPES.BASE_SALARY] : 'Оклад',

  [SALARY_ENTRY_TYPES.PENALTY] : 'Штраф',
  [SALARY_ENTRY_TYPES.OTHER_DEDUCTIONS] : 'Прочие удержания',
  [SALARY_ENTRY_TYPES.PREPAYMENT] : 'Аванс',
  [SALARY_ENTRY_TYPES.TAXES] : 'Налоги',
};

export const manuallyAddedSalaryTypes = [
  SALARY_ENTRY_TYPES.BONUS,
  SALARY_ENTRY_TYPES.SEEK_COMPENSATION,
  SALARY_ENTRY_TYPES.VACATION_COMPENSATION,
  SALARY_ENTRY_TYPES.OTHER_ADDITIONS,
  SALARY_ENTRY_TYPES.PENALTY,
  SALARY_ENTRY_TYPES.OTHER_DEDUCTIONS,
  SALARY_ENTRY_TYPES.TAXES,
];

export const allSalaryTypes = Object.values(SALARY_ENTRY_TYPES).map(
  (value) => {
    const typeGroup = ADDITION_TYPES.includes(value)
      ? '+'
      : DEDUCTION_TYPES.includes(value)
        ? '-'
        : '';
    if (typeGroup === '') {
      throw new Error('Salary type cannot be displayed');
    }
    const groupColor = typeGroup === '+' ? 'green' : 'red';
    return {
      label: <span><span style={{ marginRight: '1rem', color: groupColor }}>{typeGroup}</span>{salaryTypeLabels[value]}</span>,
      value,
    };
  });

export const salaryTypesObj = Object.fromEntries(allSalaryTypes.map(x=>[x.value, x.label]));
