import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { NULL_USER_ID, usePeriodActions } from './time-sheet.hook';
import { useStateSelector } from 'shared/store';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';

export const RowCloseCell = (props: GridRenderCellParams) => {
  const { showError, showSuccess } = useSnack();
  const { closePeriod, openPeriod, isLoading } = usePeriodActions();
  const showConfirmDialog = useConfirmDialog();
  const selectedPeriod = useStateSelector((state) => state.salarySelectedPeriod.value);

  const { id: userId, name, isClosed, ...payload } = props.row;
  const onCloseClick = async (event: React.MouseEvent)=>{
    event.stopPropagation();
    await showConfirmDialog({
      title: 'Вы уверены?',
      content: 'Закрыть табель и начислить оклад?'
    });
    await closePeriod({
      year: selectedPeriod.year,
      month: selectedPeriod.month,
      userId: userId === NULL_USER_ID ? null : userId,
      payload: payload })
      .then(()=>{ showSuccess(`Табель ${name} закрыт`) })
      .catch((err)=>{
        console.error(err);
        showError(`Ошибка закрытия табеля ${name}: ${extractNestJsErrorMessage(err)}`);
      });
  };

  const onOpenClick = async (event: React.MouseEvent)=> {
    event.stopPropagation();
    await showConfirmDialog({
      title: 'Вы уверены?',
      content: 'Открыть табель и убрать начисление оклада?'
    });
    await openPeriod({
      year: selectedPeriod.year,
      month: selectedPeriod.month,
      userId: userId === NULL_USER_ID ? null : userId,
    })
      .then(()=>{ showSuccess(`Табель ${name} открыт`) })
      .catch((err)=>{
        console.error(err);
        showError(`Ошибка открытия табеля ${name}: ${extractNestJsErrorMessage(err)}`);
      });
    return Promise.resolve();
  };

  if (isClosed) {
    const allRows = props.api.getSortedRows();
    const isCanBeOpened = userId === NULL_USER_ID
      ? allRows.filter((row) => row.id !== NULL_USER_ID).every((row) => !row.isClosed)
      : true; // fixme: this should true, only when salary is not closed for period.
    // fixme: not possible to implement this now, because closing salary is not implemented yet
    return <Button onClick={onOpenClick} disabled={isLoading||!isCanBeOpened}> Открыть </Button>;
  }

  const isCanBeClosed = userId===NULL_USER_ID
    ? true
    : props.api.getRow(NULL_USER_ID).isClosed;
  return (
    <Tooltip title={isCanBeClosed ? 'Закрыть табель' : 'Закройте сначала график работы'}>
      <span>
        <Button onClick={onCloseClick} disabled={isLoading||!isCanBeClosed}> Закрыть </Button>
      </span>
    </Tooltip>);
};
