import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CardPaper } from 'shared/ui/card-paper';
import { useSearchRequest } from '../search-request.hook';


export const SearchRequestFilter = () => {
  const { navigateToResults } = useSearchRequest();
  const [params] = useSearchParams();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (values: FormValues) => {
    navigateToResults({
      query: values.query,
      searchCompanies: values.companies,
      searchInvoices: values.invoices,
      searchLegalEntities: values.legalEntities,
      searchMedia: values.media,
      searchLeads: values.leads,
      searchPurchases: values.purchases,
    });
  };

  const initialValues: FormValues = {
    query: params.get('query') ?? '',
    companies: params.get('searchCompanies') === 'true',
    invoices: params.get('searchInvoices') === 'true',
    legalEntities: params.get('searchLegalEntities') === 'true',
    media: params.get('searchMedia') === 'true',
    leads: params.get('searchLeads') === 'true',
    purchases: params.get('searchPurchases') === 'true',
  };


  return (
    <CardPaper elevation={6}>
      <Formik<FormValues>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        // validate={validate}
        validateOnChange
      >
        {({ submitForm, dirty, isValid }) => (
          <Form>
            <Stack direction="column" gap={1}>
              <Stack direction="row" gap={1} alignItems="center" >
                <Box flexGrow={1} >
                  <Field
                    name="query"
                    type="text"
                    component={TextField}
                    label="Поиск"
                    placeholder="введите запрос"
                    fullWidth
                    size="small"
                    inputRef={searchInputRef}
                  />
                </Box>
                {/* <Box> */}
                <Button
                  variant="contained"
                  type="submit"
                  onClick={submitForm}
                  endIcon={<SearchIcon />}
                  disabled={!dirty || !isValid}
                >
                  Поиск
                </Button>
                {/* </Box> */}
              </Stack>

              <Stack direction="row" gap={2} flexWrap="wrap">
                <Field
                  name="companies"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: 'искать в компаниях' }}
                />
                <Field
                  name="invoices"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: 'искать в счетах' }}
                />
                <Field
                  name="legalEntities"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: 'искать в юрлицах' }}
                />
                <Field
                  name="media"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: 'искать в СМИ' }}
                />
                <Field
                  name="leads"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: 'искать в лидах' }}
                />
                <Field
                  name="purchases"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: 'искать в заявках' }}
                />
              </Stack>

            </Stack>
          </Form>
        )}
      </Formik>
    </CardPaper>
  );
};

type FormValues = {
  query: string;
  companies: boolean;
  invoices: boolean;
  legalEntities: boolean;
  media: boolean;
  leads: boolean;
  purchases: boolean;
};