import { IContacts } from '@sr/dto';
import EmailAddress from '../email-address';
import { ContactListItem } from './contact-list-item';
import { List } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkIcon from '@mui/icons-material/Link';
import PhoneNumber from '../phone-number';
import Url from 'shared/ui/url';
import { DeleteRequestsHandlers } from './types';
import { DupesIndicator } from 'entities/contact-dupes/indicator';
import { DupesDisplayOptions } from 'entities/contact-dupes/types';

type Props = {
  contactsList: IContacts,
  promptDeleteHandlers: DeleteRequestsHandlers,
  dupesDisplayOptions?: DupesDisplayOptions
}

export const ContactsList = ({ contactsList, promptDeleteHandlers, dupesDisplayOptions }: Props) => {
  const { onDeleteEmailRequest, onDeletePhoneRequest, onDeleteUrlRequest } = promptDeleteHandlers;

  if (
    contactsList.linkedEmails.length === 0 &&
    contactsList.linkedPhones.length === 0 &&
    contactsList.linkedUrls.length === 0
  )
    return <>Список контактов пуст</>;

  return (
    <List dense={true}>
      {contactsList.linkedEmails.map((x) => (
        <ContactListItem
          key={x.id}
          icon={<EmailIcon />}
          contactText={<EmailAddress email={x.email} />}
          onDelete={() => onDeleteEmailRequest(x)}
          dupesIndicator={<DupesIndicator contactType='email' contactString={x.email} options={dupesDisplayOptions} />} />
      ))}

      {contactsList.linkedPhones.map((x) => (
        <ContactListItem
          key={x.id}
          icon={<PhoneIcon />}
          contactText={<PhoneNumber number={x.phone} />}
          onDelete={() => onDeletePhoneRequest(x)}
          dupesIndicator={<DupesIndicator contactType='phone' contactString={x.phone} options={dupesDisplayOptions} />}
        />
      ))}

      {contactsList.linkedUrls.map((x) => (
        <ContactListItem
          key={x.id}
          icon={<LinkIcon />}
          contactText={<Url url={x.url} />}
          onDelete={() => onDeleteUrlRequest(x)}
          dupesIndicator={<DupesIndicator contactType='url' contactString={x.url.url} options={dupesDisplayOptions} />}
        />
      ))}

    </List>
  )
}