import { useLocalStorageState } from 'shared/hooks/local-storage-state';

export const useDrawerToggle = () => {
  const [open, setOpen] = useLocalStorageState('side-menu-open', true);

  return {
    open,
    toggleDrawer: () => setOpen(!open),
  };
};
