import { extractNestJsErrorMessage }                    from 'shared/api/rtk-query';
import { UpdateUserRolesDto } from '@sr/dto';
import { userRoleApi }                                  from './api';

export const useGetUserRoles = (id: number) => {
  const { data, isLoading, isFetching, error } = userRoleApi.useGetRolesQuery(
    id,
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    userRoles: data ? data : undefined,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};


export const useUpdateUserRoles = (userId: number) => {
  const [update] = userRoleApi.useUpdateRolesMutation();
  return async (data: UpdateUserRolesDto) => {
    return update({ userId, data }).unwrap();
  };
};
