import { ContactDupesDto, ContactType } from '@sr/dto';
import { contactDupesApi } from './api.slice';
import { DupesExcludeLists } from './types';

export const useDupes = (contactType: ContactType, contactString: string, dupesExcludeList?: DupesExcludeLists) => {
  const [reload] = contactDupesApi.endpoints.getDupes.useLazyQuery();
  const { data, isLoading, error } = contactDupesApi.endpoints.getDupes.useQuery({ contactType, contactString });

  let dupes: ContactDupesDto | undefined = undefined;

  if (data) {
    if (dupesExcludeList)
      dupes = {
        dupesCompanies: data.dupesCompanies.filter((x) => !dupesExcludeList.company?.includes(x.id)),
        dupesEmployees: data.dupesEmployees.filter((x) => !dupesExcludeList.person?.includes(x.id)),
      };
    else dupes = data;
  }

  return {
    dupes,
    isLoading,
    error,
    reload,
  };
};
