import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';
import { ContactDupesDto, ContactType } from '@sr/dto';
import { dupesActions } from './store.slice';

const BASE_URL = 'contacts/dupes';

export const contactDupesApi = createApi({
  reducerPath: 'contact-dupes',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDupes: builder.query<
      ContactDupesDto,
      { contactType: ContactType; contactString: string }
    >({
      query: ({ contactType, contactString }) => ({
        url: `${BASE_URL}/${contactType}/${encodeURIComponent(contactString)}`,
        method: 'GET',
      }),
      providesTags: (result, error, query, meta) =>
        result ? [{ type: 'dupes', id: query.contactString }] : ['dupes'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        dispatch(dupesActions.startDupesCheck());
        try {
          await queryFulfilled;
        } finally {
          dispatch(dupesActions.finishDupesCheck());
        }
      },
    }),
  }),
  tagTypes: ['dupes'],
});
