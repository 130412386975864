import { ContractDto } from '@sr/dto';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { CardPaper } from 'shared/ui/card-paper';

export const PartiesCard = ({ contract }: { contract: ContractDto; }) => {
  return (
    <CardPaper>
      <LegendList>
        <LegendItem title="Юрлицо клиента:" value={<LinkToLegalEntity legalEntity={contract.buyer} />} />
        <LegendItem title="Наше юрлицо:" value={<LinkToLegalEntity legalEntity={contract.seller} />} />
      </LegendList>
    </CardPaper>
  );
};
