import { Box, Button, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { Field, Formik } from 'formik';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { endOfDay, startOfDay } from 'date-fns';
import { InvoiceStatusPickerField } from 'shared/ui/formik-fields//invoice-status-picker-field';
import { useSearchParams } from 'react-router-dom';
import { BulkCreateFilter, FilterFormValues, fromDateString, fromNumberString } from '@sr/dto';

type FormValues = FilterFormValues<BulkCreateFilter>;

const initial: FormValues = {
  ourLegalEntityId: '',
  fromDate: null,
  toDate: null,
  statusId: '',
};


const fromUrlSearchParams = (params: URLSearchParams) => {
  const formValues: FormValues = {
    ourLegalEntityId: fromNumberString(params.get('ourLegalEntityId')),
    fromDate: fromDateString(params.get('fromDate')),
    toDate: fromDateString(params.get('toDate')),
    statusId: fromNumberString(params.get('statusId')),
  };

  return formValues;
}

const toSearchString = (formValues: FormValues) => {
  const builder = new URLSearchParams();
  formValues.ourLegalEntityId && builder.append('ourLegalEntityId', formValues.ourLegalEntityId.toString());
  formValues.fromDate && builder.append('fromDate', startOfDay(formValues.fromDate).toString());
  formValues.toDate && builder.append('toDate', endOfDay(formValues.toDate).toString());
  formValues.statusId && builder.append('statusId', formValues.statusId.toString());

  return builder.toString();
}

type Props = {
  filterString: string,
  onFilter: (searchParam: string) => void;
};

export const WccBulkCreateFilter = ({ filterString, onFilter }: Props) => {
  const ourCompanyId = useOurCompany();

  const handleSubmit = async (formValues: FormValues) => {
    const filterString = toSearchString(formValues);
    // console.log('Filter....' + filterString);
    return onFilter(filterString);
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={
          filterString === ''
            ? initial
            : fromUrlSearchParams(new URLSearchParams(filterString))
        }
      >{
          ({ submitForm, resetForm, dirty }) => {
            return (
              <Stack direction="row" spacing={1} >

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="ourLegalEntityId"
                    label="Наше юрлицо"
                    component={LegalEntityPicker}
                    companyIdFilter={ourCompanyId}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="fromDate"
                    label="Дата окончания счета с..."
                    component={DatePickerField}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="toDate"
                    label="Дата окончания счета по..."
                    component={DatePickerField}
                  />
                </Box>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="statusId"
                    label="Статус"
                    component={InvoiceStatusPickerField}
                  />
                </Box>

                <Button
                  onClick={submitForm}
                  variant="contained"
                  disabled={!dirty}
                  endIcon={<FilterListIcon />}
                >
                  Применить
                </Button>

                <Button
                  onClick={() => resetForm()}
                  variant="outlined"
                  endIcon={<HighlightOffIcon />}
                >
                  очистить
                </Button>
              </Stack>
            );
          }
        }
      </Formik>
    </Box>
  );
};

export const useInvoicesFilterSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return fromUrlSearchParams(searchParams);
}