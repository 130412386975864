import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, PURCHASE_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';
import { PurchaseDto } from '@sr/dto';

export const purchaseApi = createApi({
  reducerPath: 'purchases',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_BASE_URL}${PURCHASE_BASE_URL}`,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPurchase: builder.query<PurchaseDto, number>({
      query: (id) => ({
        url: `${id}`,
        method: 'GET',
      }),
      providesTags:(result, error, query) =>([{ type: 'purchases', id: query }]),
    }),
  }),
  tagTypes: ['purchases'],
});
