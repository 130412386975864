import { ContractDto, ContractStatus, DownloadFileDto, isFile, UpdateContractDto } from '@sr/dto';
import { FormDataFileField, FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { isBefore } from 'date-fns';
import { FormikErrors } from 'formik';
import { validateDateField } from 'utils/date-validation';


export type FormData = {
  contractTemplateId: number | '',
  name: string;
  startDate: Date | undefined,
  endDate: Date | undefined,
  unlimited: boolean;
  autoprolong: boolean;
  status: ContractStatus | '',
  managerId: number | '',
  scanFile: FormDataFileField;
};

export const formTools: FormTools<ContractDto, void, UpdateContractDto, FormData> = {
  toCreateDto: formData => null,

  toUpdateDto: (id, formData): UpdateContractDto => ({
    id: id,
    contractTemplateId: Number(formData.contractTemplateId),
    status: formData.status as ContractStatus,
    name: formData.name,
    contractStartDate: formData.startDate ? new Date(formData.startDate): new Date(),
    contractEndDate: formData.unlimited || !formData.endDate ? undefined : new Date(formData.endDate),
    autoprolong: formData.autoprolong,
    managerId: Number(formData.managerId),
    scanFile: formData.scanFile === null
      ? null
      : isFile(formData.scanFile)
        ? formData.scanFile
        : undefined
  }),

  fromDto: (dto): FormData => ({
    status: dto.status,
    contractTemplateId: dto.template.id,
    name: dto.name,
    startDate: dto.contractStartDate,
    endDate: dto.contractEndDate,
    unlimited: !dto.contractEndDate,
    autoprolong: dto.autoprolong,
    managerId: dto.manager.id,
    scanFile: dto.scanFile
  }),

  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (!values.managerId)
      validationResult.managerId = VALIDATION_REQUIRED_FIELD;


    if (!values.startDate)
      validationResult.startDate = VALIDATION_REQUIRED_FIELD;
    else
      validateDateField('startDate', values, validationResult);

    if (!values.unlimited) {
      if (values.endDate === undefined)
        validationResult.endDate = VALIDATION_REQUIRED_FIELD;
      else
        validateDateField('endDate', values, validationResult);
    }

    if (values.startDate && values.endDate)
      if (isBefore(values.endDate, values.startDate)) {
        validationResult.startDate = 'Дата начала не может быть больше конца';
        validationResult.endDate = 'Дата начала не может быть больше конца';
      }

    return validationResult;
  },
  emptyValues: () => ({
    contractTemplateId: '',
    name: '',
    startDate: undefined,
    endDate: undefined,
    unlimited: false,
    autoprolong: false,
    status: '',
    managerId: '',
    scanFile: null
  })
};

