import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { SearchSectionHeader } from './search-section-header'
import { IPagedCollection } from '@sr/dto'
import { SectionFoundCounters } from './found-counter'

type Props = {
  title: string,
  content: ReactNode,
  items: IPagedCollection<unknown>,
  emptyResultText: string
}

export const SearchResultSection = ({ title, content, items, emptyResultText }: Props) => {
  return (
    <Box>
      <SearchSectionHeader title={title} />
      {content}
      <SectionFoundCounters list={items} noItems={emptyResultText} />
    </Box>
  )
}