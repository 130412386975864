import { userApi } from 'entities/user/api';

export const changeUserPasswordApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation<void, {
            userId: number,
            // currentPassword: string,
            newPassword: string
        }>({
          query: ({
            userId
            // currentPassword
            , newPassword
          }) => ({
            url: `users/${userId}/change-password`,
            body: {
              // currentPassword
              newPassword
            },
            method: 'PUT'
          }),
        })
  }),
  overrideExisting: true
});
