import { ReactNode, useState } from 'react';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';


type MenuItemHandler = {
  divider?: boolean,
  name?: string,
  element?: ReactNode
  icon?: ReactNode,
  onClick?: () => void,
};


export const useMoreMenu = (items: MenuItemHandler[]) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (anchorEl: HTMLElement) => {
    setAnchorEl(anchorEl);
  };

  const menuData: MenuData = {
    anchorEl: anchorEl,
    open: !!anchorEl,
    onClose: () => {
      setAnchorEl(null);
    },
    menuItems: items
  };

  return {
    openMenu,
    menuData
  };
};


export type MenuData = {
  anchorEl: HTMLElement | null,
  open: boolean,
  onClose: () => void,
  menuItems: MenuItemHandler[];
};

export const MoreMenu = ({ anchorEl, open, onClose, menuItems }: MenuData) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {menuItems.map((item, idx) =>
        item.divider
          ? <Divider key={idx} sx={{ my: 0.5 }} />
          :
          <MenuItem
            key={idx}
            onClick={() => {
              item.onClick?.();
              onClose();
            }}>
            {item.element ? item.element :
              <>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  {item.name}
                </ListItemText>
              </>}

          </MenuItem>
      )
      }
    </Menu>);
};
