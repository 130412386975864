import { FormValues, toCreateMedia } from './form-data';
import { mediaActions } from 'entities/media/media-ui-slice';
import { useActionCreators } from 'shared/store';
import { useSnack } from 'shared/ui/snack';
import { mediaCreateApi } from './api-slice';

export const useAddMediaDialog = () => {
  const { openDialog, closeDialog } = useActionCreators(mediaActions);
  return {
    openDialog: () => { openDialog() },
    closeDialog: () => { closeDialog() }
  }
}


export const useAddNewMedia = () => {
  const { showSuccess, showError } = useSnack();

  const [addMedia] = mediaCreateApi.useAddMediaMutation();

  return async (formValues: FormValues) => {
    const dto = toCreateMedia(formValues);
    return addMedia(dto)
      .unwrap()
      .then(() => showSuccess('СМИ успешно добавлено'))
      .catch(error => showError('Ошибка добавления СМИ', error));
  };
}