import { searchApi } from './api.slice';

export const useSearchResults = (params: URLSearchParams) => {
  const { data, isLoading, error } = searchApi.useGetSearchResultsQuery(params.toString(), {
    skip: !params.get('query'),
    refetchOnMountOrArgChange: true
  });

  return {
    data,
    isLoading,
    error,
  };
};
