import { MenuItem, Stack } from '@mui/material';
import { CashflowDirection, CFD_TYPES } from '@sr/dto';
import { FormData } from './cashflows.form-tools';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';



export const CashflowFormFelds = (props: FormikProps<FormData>) => {
  return (
    <Stack direction="column">
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Field
          name='title'
          component={TextField}
          label='Название'
          fullWidth
        />
        <Field
          name='direction'
          component={TextField}
          select
          fullWidth
          label='Направление'
          sx={{ width: 250 }}
        >
          {/* <MenuItem value=''>Выберите тип...</MenuItem> */}
          {Object.keys(CFD_TYPES).map(key =>
            <MenuItem key={key} value={key}>
              {CFD_TYPES[Number(key) as CashflowDirection]}
            </MenuItem>)}
        </Field>
      </Stack>
    </Stack>
  )
}

