import { FormControlLabel } from '@mui/material';
import { Switch, SwitchProps } from 'formik-mui';

export const SwitchField = (props: SwitchProps & { label: string }) => {

  return (
    <FormControlLabel
      control={<Switch {...props} />}
      label={props.label} />
  )
}