import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ReactNode, useState } from 'react';
import { SnackContext, SnackInfo } from 'shared/ui/snack';
import { SnackbarCloseReason } from '@mui/base';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export const SnackProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);

  const infoRef = React.useRef<SnackInfo>();

  const setNewInfo = (info: SnackInfo) => {
    infoRef.current = { ...info };
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClosed = () => {
    infoRef.current = undefined;
  }

  return (
    <SnackContext.Provider value={setNewInfo}>

      {children}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => handleClose()}
        TransitionProps={{ onExited: () => handleClosed() }}
      >
        <Alert onClose={handleClose} severity={infoRef.current?.severity} sx={{ width: '100%' }}>
          {infoRef.current?.msg}
        </Alert>
      </Snackbar>

    </SnackContext.Provider>
  );
};
