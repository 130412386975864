import { MouseEvent } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { IPagingData, MediaInfoDto } from '@sr/dto';
import MenuParams from 'shared/ui/menu-params';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';


type Props = {
  collection: IRemotePagedCollection<MediaInfoDto>,
  paging?: IPagingData;
  onChangePaging?: (paging: IPagingData) => void;
  onMediaDeleteClick: (media: MediaInfoDto) => void,
};

export const MediaList = ({ collection, paging, onChangePaging, onMediaDeleteClick }: Props) => {
  const { openMenu, menuData } = useMenu<MediaInfoDto>({
    deleteHandler: onMediaDeleteClick
  });

  return (
    <>
      <GridLayout<MediaInfoDto>
        columns={['Название', 'Город', 'Тип', '']}
        items={collection.collection.items}
        totalItemsCount={collection.collection.totalItemsCount}
        isLoading={collection.isLoading}
        error={collection.error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        itemRender={(item) =>
          <MediaList.Row
            key={item.id}
            mediaData={item}
            onMenuClick={openMenu} />}
        noItemsText='Список СМИ пуст'
      />

      <RowMenu menuData={menuData} />
    </>);
};


type RowProps = {
  mediaData: MediaInfoDto,
  onMenuClick: (menuParams: MenuParams<MediaInfoDto>) => void;
};

MediaList.Row = (props: RowProps) => {
  const { mediaData, onMenuClick } = props;

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: mediaData });
  };

  return (
    <>
      <TableRow
        key={mediaData.id}
      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToMedia media={mediaData} >
            {mediaData.name}
          </LinkToMedia>
        </TableCell>
        <TableCell>{mediaData.city.name}</TableCell>
        <TableCell>{mediaData.mediaType.name}</TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};