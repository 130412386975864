import { useEffect, useState } from 'react';
import { useSnack } from 'shared/ui/snack/snack.hook';
import { ICountry } from 'interfaces/ICountry';
import { fetchCountries } from 'shared/api/countries';


const useCountries = (load: boolean = true) => {
  const { showError } = useSnack();
  const [list, setList] = useState<ICountry[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!load)
      return;

    setLoading(true);
    fetchCountries()
      .then(response => setList(response))
      .catch(error => showError(error))
      .finally(() => {
        setLoading(false);
      });
  }, [load]);

  return ({
    list: list,
    loading
  });
};

export default useCountries;
