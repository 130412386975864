import { invoiceDataApi } from './details/api';
import { REFETCH_TIME_SPAN, extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { CreateInvoiceDto } from '@sr/dto';

export const useInvoiceDetails = (invoiceId: number) => {
  const { data: invoice, isLoading, error } = invoiceDataApi.useGetInvoiceQuery(invoiceId, { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });

  return {
    invoice: invoice,
    loading: isLoading,
    error: extractNestJsErrorMessage(error)
  };
};

export const useAddInvoice = () => {
  const [addInvoice] = invoiceDataApi.useAddInvoiceMutation();
  return async (dto : CreateInvoiceDto, purchaseId?: number)=> {
    return addInvoice({ dto, purchaseId }).unwrap();
  };
};



