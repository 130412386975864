import { IPagedCollection, LeadInfoWithContactsDto, OptionalContacts, SalaryEntryDto } from '@sr/dto';
import { TextWithHighlight } from 'shared/ui/text-with-highlight';
import { SearchResultSection } from './search-result-section';
import { LinkToLead } from 'shared/ui/links/link-to-lead';
import { extractContact } from '../extract-contacts';
import { ContactSpan } from './contact-span';

type Props = {
  highlight: string;
  list?: IPagedCollection<LeadInfoWithContactsDto>;
}

export const FoundLeads = ({ list, highlight }: Props) => {
  if (!list)
    return null;

  return (
    <SearchResultSection
      title="Лиды"
      items={list}
      content={list.items.map(entity => {

        return (
          <div key={entity.id}>
            <LinkToLead lead={entity}>
              <TextWithHighlight component='span' variant='body1' hlText={highlight}>Лид №{entity.id}: {entity.subject} </TextWithHighlight>
            </LinkToLead>
            &nbsp;
            {entity.contacts && renderContacts(entity.contacts)}


          </div>);
      }
      )}
      emptyResultText="Лиды не найдены"
    />
  );
};


const renderContacts = (contacts: OptionalContacts) => {
  return (
    <>
      {contacts.emails && <div>{contacts.emails.map(x => <>{x}</>)}</div>}
      {contacts.phones && <div>{contacts.phones.map(x => <>{x}</>)}</div>}
      {contacts.urls && <div>{contacts.urls.map(x => <>{x}</>)}</div>}
    </>
  )
}

// (<ContactSpan contactType={contact[0]}>
//   {contact[1].map((contact) => (
//     <TextWithHighlight key={contact} component='span' variant='body1' hlText={highlight}>{contact}</TextWithHighlight>
//   ))
//   }
// </ContactSpan>)
