import { IsNotEmpty, IsString } from 'class-validator';
import { CompanyDto } from './company.dto';

export interface IPerson {
  id: number;
  firstName: string;
  secondName?: string;
  middleName?: string;
}

export interface ICreatePerson extends Omit<IPerson, 'id'> {}

export class EmployeeDto implements IPerson {
  id: number;
  firstName: string;
  secondName?: string | undefined;
  middleName?: string | undefined;
  companies: CompanyDto[];
}

export class PersonCompanyDto {
  id: number;
  person: PersonDto;
  position?: string;
}

export interface ICreatePersonCompany {
  person: ICreatePerson;
  position?: string;
}

// in general I don't see any benefits of using both interface and classes
// so, feel free to refactor this, removing interfaces
export class CreatePersonDto {
  @IsString()
  @IsNotEmpty()
  firstName: string;

  @IsString()
  secondName?: string;

  @IsString()
  middleName?: string;

  dob?: Date;
}

export class PersonDto extends CreatePersonDto implements IPerson {
  id: number;
}
