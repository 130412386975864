import { useInvoiceRelatedPayOrders } from 'hooks/payorders.hook';
import { useMemo } from 'react';

export const useRelatedPayOrders = (invoiceId: number) => {
  const payOrders = useInvoiceRelatedPayOrders(invoiceId);

  const payOrdersTotal = useMemo(
    () => payOrders.items.reduce((prev, curr) => (
      prev + curr.rows.reduce((acc, row) => acc + row.amount, 0)),
      0),
    [payOrders]);


  return ({
    payOrders,
    payOrdersTotal
  })
}
