import { ILinkedEmail, ILinkedPhone, ILinkedUrl } from '@sr/dto';
import useAddContactsDialogs from 'hooks/add-contacts-dialog.hook';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { AddContactHandlers, MenuClickHandlers, DeleteRequestsHandlers } from 'shared/ui/contact-list/types';
import { useCallback } from 'react';
import { ContactQuery, DeleteContactQuery } from './contacts.types';

export const useAddHandlers = ({ onAddEmail, onAddPhone, onAddUrl }: AddContactHandlers) => {
  const { showSuccess, showError } = useSnack();
  const { showEmailDialog, showPhoneDialog, showUrlDialog } = useAddContactsDialogs();

  const handleSubmitEmail = async (email: string) => {
    return onAddEmail(email)
      .then(() => showSuccess('Email успешно добавлен'))
      .catch((e) => showError(`Ошбика добавления email: ${e.data.message}`));
  };

  const handleSubmitPhone = async (phone: string) => {
    return onAddPhone(phone)
      .then(() => showSuccess('Телефон успешно добавлен'))
      .catch((e) => showError(`Ошибка добавления телефона : ${e.data.message}`));
  };

  const handleSubmitUrl = async (url: string) => {
    return onAddUrl(url)
      .then(() => showSuccess('Сайт успешно добавлен'))
      .catch((e) => showError(`Ошибка добавления адреса сайта: ${e.data.message}`));
  };

  const menuHandlers: MenuClickHandlers = {
    onAddEmailClick: () => showEmailDialog(async (email: string) => handleSubmitEmail(email)),
    onAddPhoneClick: () => showPhoneDialog(async (phone: string) => handleSubmitPhone(phone)),
    onAddUrlClick: () => showUrlDialog(async (url: string) => handleSubmitUrl(url)),
  };

  return menuHandlers;
};

export const usePromptedDeleteHandlers = (query: ContactQuery, onDeleteContact: (query: DeleteContactQuery) => Promise<void>) => {
  const showConfirmDialog = useConfirmDialog();
  const { showSuccess, showError } = useSnack();

  const handleDeleteEmail = useCallback(
    async (emailToDelete: ILinkedEmail) => {
      showConfirmDialog({
        content: `Удалить email ${emailToDelete.email}?`,
      }).then(() => {
        onDeleteContact({ relationId: query.relationId, relationType: query.relationType, contactId: emailToDelete.id })
          .then(() => showSuccess('Email успешно удален'))
          .catch((e) => showError(`Ошибка при удалении email: ${e.message}`));
      });
    },
    [query, onDeleteContact, showConfirmDialog, showError, showSuccess]
  );

  const handleDeletePhone = useCallback(
    async (phoneToDelete: ILinkedPhone) => {
      showConfirmDialog({
        content: `Удалить телефон ${phoneToDelete.phone}?`,
      }).then(() => {
        onDeleteContact({ relationId: query.relationId, relationType: query.relationType, contactId: phoneToDelete.id })
          .then(() => showSuccess('Телефон успешно удален'))
          .catch((e: Error) => showError(`Ошибка при удалении телефона: ${e.message}`));
      });
    },
    [query, onDeleteContact, showConfirmDialog, showError, showSuccess]
  );

  const handleDeleteUrl = useCallback(
    async (url: ILinkedUrl) => {
      showConfirmDialog({
        content: 'Удалить адрес сайта?',
      }).then(() => {
        onDeleteContact({ relationId: query.relationId, relationType: query.relationType, contactId: url.id })
          .then(() => showSuccess('Адрес сайта успешно удален'))
          .catch((e: Error) => showError(`Ошибка при удалении адреса сайта: ${e.message}`));
      });
    },
    [query, onDeleteContact, showConfirmDialog, showError, showSuccess]
  );

  const confirmDeleteHandlers: DeleteRequestsHandlers = {
    onDeleteEmailRequest: handleDeleteEmail,
    onDeletePhoneRequest: handleDeletePhone,
    onDeleteUrlRequest: handleDeleteUrl,
  };

  return confirmDeleteHandlers;
};
