import { useRef } from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { SwitchField } from 'shared/ui/formik-fields/switch-field';
import { formTools, PublishingDataFormData } from './publishing-data.form-tools';
import {
  PublishingDataRelations,
  useAddPublishingData,
  usePublishingData,
  useUpdatePublishingData
} from 'entities/publishing-data/publishing-data.hooks';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ContentProviderRef, RichEditor } from 'shared/ui/rich-editor/rich-editor';
import FilePickerField from 'shared/ui/formik-fields/file-picker';
import { useSnack } from 'shared/ui/snack';

type Props = {
    id: number | null,
    relatedType: PublishingDataRelations,
    relatedId: number
}

export const PublishingDataForm = ({ id, relatedType, relatedId }: Props) => {
  const shortDescription = useRef<ContentProviderRef>(null);
  const fullDescription = useRef<ContentProviderRef>(null);
  const benefits = useRef<ContentProviderRef>(null);
  const specificConditions = useRef<ContentProviderRef>(null);

  const { addPublishingData, isUpdating } = useAddPublishingData(relatedType, relatedId);
  const updatePublishingData = useUpdatePublishingData();
  const { showSuccessUpdate, showFailedUpdate } = useSnack({
    itemTitleForm1: 'Данные сайта', itemTitleForm2: 'Данных сайта', gender: 'plural'
  });

  const handleSubmit = async (formData: PublishingDataFormData) => {
    formData.shortDescription = shortDescription.current?.() ?? '';
    formData.fullDescription = fullDescription.current?.() ?? '';
    formData.benefits = benefits.current?.() ?? '';
    formData.specificConditions = specificConditions.current?.() ?? '';

    if (serverPublishingData) {
      return await updatePublishingData(formTools.toUpdateDto(serverPublishingData.id, formData))
        .then(() => {
          showSuccessUpdate();
        })
        .catch((error) => {
          showFailedUpdate(error);
        });
    }
    return await addPublishingData(formTools.toCreateDto(formData))
      .then(() => {
        showSuccessUpdate();
      })
      .catch((error) => {
        showFailedUpdate(error);
      });

  };
  const { item: serverPublishingData, isLoading, error } = usePublishingData(id);
  let initialData = formTools.emptyValues();
  if (serverPublishingData) {
    initialData = formTools.fromDto(serverPublishingData);
  }
  return (
    <>
      {error?.length
        ? error
        : isLoading
          ? <LoadingBanner/>
          : <Formik initialValues={initialData} onSubmit={handleSubmit} validate={formTools.validate}>
            {({ submitForm, isSubmitting, isValid, dirty, values }: FormikProps<PublishingDataFormData>) => (
              <Paper sx={{ p: 1, pt: 2 }}>
                <Box
                  sx={{ py: 1, mb: 1, justifyContent: 'space-between', display: 'flex', gap: 1 }}>
                  <Field sx={{ width: 1 }} name='slug' label='Символьный код'
                    component={TextField}/>
                </Box>
                <Stack direction="row" justifyContent="space-between">
                  <Box sx={{ width: 1 / 2 }}>
                    <Field name='isPublished' label='Опубликовано на сайте'
                      type="checkbox"
                      component={SwitchField}/>
                  </Box>
                  <Box sx={{ width: 1 / 2 }}>
                    <Field name='isSubdomain' label='Как субдомен'
                      type="checkbox"
                      component={SwitchField}/>
                  </Box>
                </Stack>
                <Box>
                  <Typography sx={{ mb: 1 }} variant="h6">Краткое описание</Typography>
                  <Field name='shortDescription'>
                    {({ field, form, meta }: FieldProps) => (
                      <RichEditor content={field.value} disabled={isSubmitting}
                        ref={shortDescription}
                        onChange={() => {
                          if (!dirty)
                            form.setFieldValue('shortDescription', shortDescription.current?.());
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Typography sx={{ mb: 1 }} variant="h6">Подробное описание</Typography>
                  <Field name='fullDescription'>
                    {({ field, form, meta }: FieldProps) => (
                      <RichEditor content={field.value} disabled={isSubmitting}
                        ref={fullDescription}
                        onChange={() => {
                          if (!dirty)
                            form.setFieldValue('fullDescription', fullDescription.current?.());
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Typography sx={{ mb: 1 }} variant="h6">Преимущества типа СМИ</Typography>
                  <Field name='benefits'>
                    {({ field, form, meta }: FieldProps) => (
                      <RichEditor content={field.value} disabled={isSubmitting} ref={benefits}
                        onChange={() => {
                          if (!dirty)
                            form.setFieldValue('benefits', benefits.current?.());
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Typography sx={{ mb: 1 }} variant="h6">Особые условия размещения</Typography>
                  <Field name='specificConditions'>
                    {({ field, form, meta }: FieldProps) => (
                      <RichEditor content={field.value} disabled={isSubmitting}
                        ref={specificConditions}
                        onChange={() => {
                          if (!dirty)
                            form.setFieldValue('specificConditions', specificConditions.current?.());
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box sx={{ p: 1, pt: 3, display: 'flex', flexDirection: 'row', gap: 5 }}>
                  <Field
                    sx={{ width: 1 / 2 }}
                    name="smallImage"
                    label="Маленький логотип"
                    component={FilePickerField}
                  />
                  <Field
                    sx={{ width: 1 / 2 }}
                    name="detailedImage"
                    label="Логотип"
                    component={FilePickerField}
                  />
                </Box>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid || !dirty || isUpdating}
                >
                                    сохранить
                </Button>
              </Paper>
            )}
          </Formik>
      }
    </>
  );
};
