import { UserPersonalDataDto } from '@sr/dto';

export type FormValues = {
  passportData: string;
  residenceAddress: string;
  dob: Date | null;
}
export const initialValues = (dto: UserPersonalDataDto|undefined) : FormValues =>({
  passportData: dto?.passportData ?? '',
  residenceAddress: dto?.residenceAddress ?? '',
  dob: dto?.dob ? new Date(dto.dob) : null,
});
