import { emptyPagedCollection, EventDto, IPagingData } from '@sr/dto';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { eventsApi } from '../api';

const EVENTS_PER_PAGE = 10;
const EVENT_LIST_SUPPORTED_TABS = ['all', 'my', 'overdue'];

export const useEventsList = (tab :string, currentPage: number, filter?: URLSearchParams,): IRemotePagedCollection<EventDto> => {
  if (!EVENT_LIST_SUPPORTED_TABS.includes(tab)){
    throw new Error('Tab name is not recognised');
  }
  const paging: IPagingData = { page: currentPage, itemsPerPage: EVENTS_PER_PAGE };

  const { data, error, isLoading, isFetching }
    = eventsApi.useGetTabEventsQuery(
      { tab, paging, filter: filter?.toString().length ? `&${filter.toString()}` : '' },
      { refetchOnMountOrArgChange: 60 });

  return  {
    collection: data ?? emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};

export const useCompanyEvents = (companyId: number, currentPage: number, filter?: URLSearchParams): IRemotePagedCollection<EventDto>=> {
  const paging: IPagingData = { page: currentPage, itemsPerPage: EVENTS_PER_PAGE };

  const { data, isLoading, error , isFetching }
    = eventsApi.useGetCompanyEventsQuery(
      { companyId, paging, filter: filter?.toString().length ? `${filter.toString()}` : '' },
      { refetchOnMountOrArgChange: 60 });

  return  {
    collection: data ?? emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};
