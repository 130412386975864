import { emptyPagedCollection, IPagingData, WccDto } from '@sr/dto';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { wccListApi } from './api';

export const useWccList = (paging: IPagingData) => {
  const { data, isLoading, error } = wccListApi.useGetClientWccListQuery({ paging }, { refetchOnMountOrArgChange: true });

  const result: IRemotePagedCollection<WccDto> = {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };

  return result;
};

