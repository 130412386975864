import { useState } from 'react';

import { ContractsGrid } from 'components/Contracts/contracts.grid';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { useContractsList } from 'entities/contract/lib';
import { usePagingParams } from 'shared/hooks/paging-params';

const title = 'Договоры';

const ContractsListPage = () => {
  useTitleHook(title);

  const [paging, setPaging] = usePagingParams();
  const [filterString, setFilterString] = useState('');
  const contracts = useContractsList(paging, filterString);

  return (
    <>
      <Heading
        sx={{ mb: 1 }}
        title={title} />

      <ContractsGrid
        contracts={contracts.collection}
        paging={paging}
        onChangePaging={setPaging}
        filterString={filterString}
        onFilter={setFilterString}
      />
    </>
  );
};

export default ContractsListPage;
