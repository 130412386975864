import { useEffect, useState } from 'react';
import { useSnack } from 'shared/ui/snack/snack.hook';
import { CityInfoDto } from '@sr/dto';
import { authorizedRequest } from 'shared/api/axios';
import { CITIES_BASE_URL } from 'shared/api/api';


export const useCitiesDropdown = (load: boolean = true) => {
  const { showError } = useSnack();
  const [list, setList] = useState<CityInfoDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!load)
      return;

    setLoading(true);

    authorizedRequest<void, CityInfoDto[]>(`${CITIES_BASE_URL}/dropdown`, 'GET')
      .then(list => setList(list))
      .catch(error => showError(error))
      .finally(() => {
        setLoading(false);
      });
  }, [load, showError]);

  return ({
    list: list,
    loading
  });
};

