// libraries imports
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// app level imports
import { CreateMediaTypeDto, UpdateMediaTypeBaseDataDto } from '@sr/dto';
import { useIdParam } from 'utils/url-paramters.hook';
import { mediaTypeLinkManager } from 'utils/link-manager';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { useTitleHook } from 'hooks/page-title.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { ActionsMenu } from 'shared/ui/actions-menu';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { PageBreadCrumbs } from 'shared/ui/page-breadcrumbs';
import { FormDialog } from 'shared/ui/form-dialog';
import { useSnack } from 'shared/ui/snack';

// media-type specific imports
import {
  formTools,
  MediaTypeFromFields,
  mediaTypesNotificationsConfig,
  PopupFormData,
  useDeleteMediaType,
  useMediaType,
  useRenameMediaType
} from 'entities/media/media-types';
import { PublishingDataRelations } from 'entities/publishing-data/publishing-data.hooks';
import { PublishingDataForm } from 'features/publishing-data/publishing-data.form';

const MediaTypeDetailed = ()=>{
  const links = [
    { text: 'Настройки', to: '/settings/home' },
    { text: 'Типы СМИ', to: mediaTypeLinkManager.baseUrl }
  ];

  const mediaTypeId = useIdParam();
  const { item: mediaType, isLoading, error } = useMediaType(mediaTypeId);

  useTitleHook('Тим СМИ: ' + mediaType?.name ?? '');

  const removeItem  = useDeleteMediaType();
  const updateItem = useRenameMediaType();
  const navigate = useNavigate();

  const { showSuccessDelete, showFailedDelete } = useSnack(mediaTypesNotificationsConfig);

  const { dialogProps, openToEdit } = useCreateOrEditDialog<UpdateMediaTypeBaseDataDto, CreateMediaTypeDto, UpdateMediaTypeBaseDataDto, PopupFormData>({
    addHandler: async () => {},
    updateHandler: async (dto) => { await updateItem(dto) },
    formTools: formTools,
    notificationsConfig: mediaTypesNotificationsConfig
  });
  const handleRename = async ()=>{ openToEdit({ id: mediaTypeId, name: mediaType?.name ?? '' }) };
  const handleDelete = async () => {
    const mediaTypeName = mediaType?.name ?? '';
    await removeItem({ id: mediaTypeId, name:  mediaTypeName })
      .then(()=>{
        showSuccessDelete();
        navigate(mediaTypeLinkManager.baseUrl);
      })
      .catch(e=>{
        showFailedDelete(e);
      });
  };

  return (
    <>
      <PageBreadCrumbs links={links}/>
      {error?.length
        ? error
        : isLoading
          ? <LoadingBanner/>
          : mediaType
            ?
            <>
              <PageHeader
                title={
                  <Typography variant="h5" color="primary">{mediaType.name}</Typography>
                }
                actions={
                  <ActionsMenu actions={[
                    { callback: handleRename, label: 'Переименовать', icon: <EditIcon/> },
                    { callback: handleDelete, label: 'Удалить', icon: <DeleteIcon/> }
                  ]}
                  />}
              />
              <FormDialog<PopupFormData>
                {...dialogProps}
                renderTitle={'Изменить тип СМИ'}
                renderForm={(props) => <MediaTypeFromFields {...props} />}
              />
              <PublishingDataForm
                id={mediaType ? mediaType.publishingDataId : null}
                relatedType={PublishingDataRelations.mediaType}
                relatedId={mediaTypeId}
              />
            </>
            :
            <>Нет типа СМИ с id: {mediaTypeId}</>
      }
    </>
  );
};

export default MediaTypeDetailed;
