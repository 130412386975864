import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridLayout } from 'shared/ui/grid-layout';
import { NotificationsConfig } from 'shared/ui/snack';
import { PayOrderDto, BA_TYPES, PayOrderGroup, PO_GROUPS, PayOrderStatus, POS_STATUSES, InvoiceDto, BankAccountDto, IPagingData } from '@sr/dto';
import { formatDate } from 'utils/date-format-helpers';
import PriceSpan from 'shared/ui/price-span';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { PayordersFilter } from 'features/payorders/payorders-filter';

const columns = [
  'Номер ПП',
  'Дата',
  'Тип',
  'Группа',
  'Статус',
  'Сумма',
  'Счет',
  'Подробности',
  ''
];

export const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'платежное поручение',
  itemTitleForm2: 'платежного поручения',
  gender: 'neutral'
};

type Props = {
  collection: IRemotePagedCollection<PayOrderDto>,
  paging: IPagingData,
  onChangePaging: (paging: IPagingData) => void,
  filterString?: string,
  onFilter?: (filterString: string) => void,
  onEditClick: (payOrder: PayOrderDto) => void,
  onDeleteClick: (payOrder: PayOrderDto) => void,
};

export const PayOrdersList = ({ collection, paging, onChangePaging, filterString, onFilter, onEditClick, onDeleteClick }: Props) => {

  return (
    <>
      {filterString !== undefined && onFilter &&
        <Box my={1}>
          <PayordersFilter filterString={filterString} onFilter={onFilter} />
        </Box>
      }
      <GridLayout
        columns={columns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        itemRender={payOrder => <Row
          key={payOrder.id}
          payOrder={payOrder}
          onEditClick={() => onEditClick(payOrder)}
          onDeleteClick={() => onDeleteClick(payOrder)}
        />}
        noItemsText="Список платежных поручений пуст" />
    </>
  );
};



type RowProps = {
  payOrder: PayOrderDto,
  onEditClick: () => void,
  onDeleteClick: () => void;
};

const Row = ({ payOrder, onEditClick, onDeleteClick }: RowProps) => {
  return (
    <TableRow>
      <TableCell >
        {payOrder.documentNumber}
      </TableCell>
      <TableCell>
        {formatDate(payOrder.paymentDate)}
      </TableCell>
      <TableCell>
        {BA_TYPES[payOrder.bankAccount.type]}
      </TableCell>
      <TableCell>
        {PO_GROUPS[payOrder.group as PayOrderGroup]}
      </TableCell>
      <TableCell>
        {POS_STATUSES[payOrder.status as PayOrderStatus]}
      </TableCell>
      <TableCell>
        <PriceSpan price={payOrder.rows.reduce((acc, row) => acc + row.amount, 0)} size='small' />
      </TableCell>
      <TableCell>
        <BankAccountSpan bankAccount={payOrder.bankAccount} />
      </TableCell>
      <TableCell>
        <InvoicePayment payorder={payOrder} />
      </TableCell>
      <TableCell align="right" width={100}>
        <IconButton edge="end" onClick={onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow >
  );
};


export default PayOrdersList;


const InvoicePayment = ({ payorder }: { payorder: PayOrderDto; }) => {
  const invoice = payorder.rows[0]?.invoice;

  return (
    <>
      <Box>
        {invoice && <InvoiceInfo invoice={invoice} />}
      </Box>
      <Box>

      </Box>
      <Box>
        {/* Назначение платежа: &nbsp; */}
        <Typography component="span" variant='inherit' fontStyle='italic'>
          {payorder.description}
        </Typography>
      </Box>
    </>
  );
};


const BankAccountSpan = ({ bankAccount }: { bankAccount: BankAccountDto; }) => (
  <Typography variant='inherit' color={bankAccount.isActive ? 'inherit' : 'grey.500'}>
    {bankAccount.title}
  </Typography>
);

const InvoiceInfo = ({ invoice }: { invoice: InvoiceDto; }) => {
  return (
    <>
      <LinkToInvoice invoice={invoice} />&nbsp;
      для <LinkToLegalEntity legalEntity={invoice.buyer} /> (<LinkToCompany company={invoice.buyerCompany} />)
      от <LinkToLegalEntity legalEntity={invoice.seller} /> (<LinkToCompany company={invoice.sellerCompany} />)
    </>
  );
};