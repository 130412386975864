import { Box } from '@mui/material';

import { MediaListFilter } from 'features/media/media-filter';
import { usePagingParams } from 'shared/hooks/paging-params';
import { MediaPurchasesList } from 'entities/media/media-purchases';
import { useMediaPurchases } from 'entities/media/media-purchases/api';
import { useSearchParams } from 'react-router-dom';



export const MediaPurchasesTab = () => {
  const [paging, setPaging] = usePagingParams();
  const [searchParams] = useSearchParams();


  const collection = useMediaPurchases(paging, searchParams);


  return (
    <Box mt={2}>
      <Box mb={1}>
        <MediaListFilter  />
      </Box>

      <MediaPurchasesList
        collection={collection}
        paging={paging}
        onChangePage={setPaging}
      />
    </Box>

  );
};
