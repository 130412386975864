import { Container, List, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { IRemoteCollection } from 'utils/remote-collection.hook';
import { ErrorBanner } from './error-banner';
import { LoadingBanner } from './loading-banner';


type Props<T> = {
  items: IRemoteCollection<T>;
  itemRender: (arg: T) => ReactNode;
  noItemsText: string;
}

export function ListLayout<T>(props: Props<T>) {
  const {
    items: { items, isLoading, error },
    itemRender,
    noItemsText } = props;

  if (error)
    return <ErrorBanner errorMessage={error} />;


  if (isLoading)
    return <LoadingBanner />;

  if (!items.length)
    return (
      <>
        {noItemsText}
      </>);

  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      {items.map(itemRender)}
    </Stack>
  );
};

