import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IPagingData, LegalEntityBaseInfoDto } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { ContractsGrid } from 'components/Contracts/contracts.grid';
import { AddContractDialog } from 'widgets/contracts/add-dialog/add-contract-dialog';
import { FormValues } from 'components/Contracts/contract.form';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { useContractsList } from 'entities/contract/lib';
import { DEFAULT_PAGINATION } from 'shared/ui/grid-layout';

type Props = {
  companyId: number;
}

export const CompanyContracts = ({ companyId }: Props) => {
  const [paging, setPaging] = useState<IPagingData>(DEFAULT_PAGINATION);
  const { collection, addContract } = useContractsList(paging, undefined, companyId);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const ourCompanyId = useOurCompany();

  const { showSuccess, showError } = useSnack();

  const handleAddContract = async (data: FormValues) => {
    return addContract(data)
      .then(() => {
        setCreateDialogOpen(false);
        showSuccess('Договор успешно добавлен');
        setPaging({ ...paging });
      })
      .catch((e) => showError(`Ошибка добавления договора: ${e.message}`));
  };

  const handleDelete = async (entity: LegalEntityBaseInfoDto) => { };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" color="grey.700">Договоры</Typography>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => { setCreateDialogOpen(true) }}
        >
          Добавить
        </Button>
      </Stack>

      <ContractsGrid
        contracts={collection}
        paging={paging}
        onChangePaging={setPaging}
      />

      <AddContractDialog
        buyerCompanyId={companyId}
        ourCompanyId={ourCompanyId}
        open={createDialogOpen}
        title='Новый договор'
        onClose={() => setCreateDialogOpen(false)}
        submit={handleAddContract} />

    </>);
};
