import { Button, MenuItem, Select, Stack, Switch } from '@mui/material';
import { fetchInvoicePrintForm } from 'entities/client-invoices-list/api';
import { useCallback, useState } from 'react';
import { useActionCreators, useStateSelector } from 'shared/store';
import { Dialog } from 'shared/ui/dialog';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { useSnack } from 'shared/ui/snack';
import { usePrintPopup } from 'utils/print-popup.hook';
import { printInvoiceActions } from './store-slice';

type Props = {
    invoiceId: number;
}

export const InvoicePrintFormDialog = ({ invoiceId }: Props) => {
  const { showError } = useSnack();

  const open = useStateSelector(state => state.ui.printInvoice.dialogOpen);

  const { closeDialog } = useActionCreators(printInvoiceActions);

  const { openWindow, loading } = usePrintPopup(
    useCallback(() => {
      return fetchInvoicePrintForm(invoiceId)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [invoiceId, showError])
  );

  return (
    <Dialog
      open={open}
    >
      <Dialog.Title>
                Печать
        <Dialog.CloseButton onClick={() => closeDialog()}/>
      </Dialog.Title>
      <Dialog.Content>
        <LegendList>
          <LegendItem title="Выводить ссылку на СМИ в печатных формах: " value={<Switch/>}></LegendItem>
          <LegendItem title="Даты оказания услуг:" value={<Select size='small' value={1}>
            <MenuItem value={1}>Не выводить</MenuItem>
            <MenuItem value={2}>Выводить полностью</MenuItem>
            <MenuItem value={3}>Выводить месяцы</MenuItem>
          </Select>}/>
        </LegendList>

      </Dialog.Content>
      <Dialog.Actions>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button variant="outlined" onClick={openWindow} disabled={loading}>Печать</Button>
          <Button variant="outlined" disabled={loading}>Печать с подписью</Button>
        </Stack>

      </Dialog.Actions>
    </Dialog>);
};


export const useInvoicePrintDialog = (invoiceId: number) => {
  const [open, setOpen] = useState(false);
  return {
    open,
    setOpen
  };
};
