import { Divider, Stack, Typography } from "@mui/material"

type Props = {
  title: string
}

export const SearchSectionHeader = ({ title }: Props) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography component="h3" variant="h6">{title}</Typography>
      <Divider sx={{ flexGrow: 1 }} />
    </Stack>
  )
}