import { createSlice } from '@reduxjs/toolkit';
import { ActionPrefix, initialState } from './state';
import { buildExtraReducers } from './thunks';



const slice = createSlice({
  name: ActionPrefix,
  initialState: initialState,
  reducers: {},
  extraReducers: buildExtraReducers
});


export const { reducer: invoiceMutableDataReducer, actions: invoiceMutableDataActions } = slice;

