import { BankAccountDto, BankAccountType, CreateBankAccountDto } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';


export type FormData = {
  title: string,
  type: number | '',
  isActive: boolean | '',
};

export const formTools: FormTools<BankAccountDto, CreateBankAccountDto, BankAccountDto, FormData> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    title: formData.title,
    type: formData.type as BankAccountType,
    isActive: !!formData.isActive
  }),
  toCreateDto: formData => ({
    title: formData.title,
    type: formData.type as BankAccountType,
  }),
  fromDto: (dto) => ({
    title: dto.title,
    isActive: dto.isActive,
    type: dto.type
  }),
  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (!values.title)
      validationResult.title = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },
  emptyValues: () => ({
    isActive: '',
    type: 1,
    title: ''
  })
};