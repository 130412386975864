import { useState } from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MenuParams from './menu-params';

type MenuHandlers<T> = {
  deleteHandler: (target: T) => void,
  editHandler?: (target: T) => void
}

export const useMenu = function <T>(handlers: MenuHandlers<T>) {
  const { editHandler, deleteHandler } = handlers;

  const [currentMenu, setCurrentMenu] =
    useState<MenuParams<T> | null>(null);

  const openMenu = (menuParams: MenuParams<T>) => {
    setCurrentMenu(menuParams);
  };

  return {
    openMenu,
    menuData: {
      anchorEl: currentMenu?.anchor,
      open: !!currentMenu,
      onClose: () => { setCurrentMenu(null) },
      onDeleteClick: async () => {
        currentMenu && await deleteHandler(currentMenu.target);
        setCurrentMenu(null);
      },
      onEditClick: editHandler ? (async () => {
        currentMenu && await editHandler(currentMenu.target);
        setCurrentMenu(null);
      }) : undefined
    }
  };

};

type MenuData = {
  anchorEl?: HTMLElement,
  open: boolean,
  onClose: () => void,
  onEditClick?: () => void,
  onDeleteClick: () => void
}

export const RowMenu = (props: { menuData: MenuData }) => {
  const { anchorEl, open, onClose, onEditClick, onDeleteClick } = props.menuData;
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {
        onEditClick && (
          <MenuItem onClick={onEditClick}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Редактировать</ListItemText>
          </MenuItem>
        )
      }
      <MenuItem onClick={onDeleteClick}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Удалить</ListItemText>
      </MenuItem>

    </Menu>);
};
