import {CompanyDto} from './company.dto';
import {IContacts} from './contacts';
import {IPerson} from './person.dto';
import {DownloadFileDto} from "./download-file.dto";

//   isActive: boolean;
// }

// export class PriceDto extends CreatePriceDto {
//   id: number;

//   constructor(src: Price) {
//     super();
//     this.id = src.id;
//     this.name = src.name;
//     this.isActive = src.isActive;
//   }
// }

export class PriceInfoDto {
    id: number;
    companyInfo: CompanyDto;
    companyContacts: IContacts;
    updatedBy: IPerson;
    createdAt: Date;
    files: { main: DownloadFileDto; extra: DownloadFileDto[] };
}

// export class UpdatePriceDto extends CreatePriceDto { }

export class CreatePriceDto {
    companies: string;
    media: string;
    userId: number
}
