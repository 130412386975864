import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { invoiceRowsApi } from './invoice-rows.api';
import { CreateInvoiceRowDto, UpdateInvoiceRowDto } from '@sr/dto';

export const useInvoiceRows = (invoiceId: number, invoiceRowId?: number) => {
  const { data, isLoading, error } = invoiceRowsApi.useGetInvoiceRowsQuery({ invoiceId, invoiceRowId });
  return {
    rows: data ?? [],
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};

export const  useUpdateInvoiceRow = (purchaseId?: number)=>{
  const [update, { isLoading, error }] = invoiceRowsApi.useUpdateInvoiceRowMutation();
  return async (invoiceRow: UpdateInvoiceRowDto) => {
    return update({ ...invoiceRow, purchaseId }).unwrap();
  };
};

export const useAddInvoiceRow = () => {
  const [addInvoiceRow] = invoiceRowsApi.useAddInvoiceRowMutation();
  return async (dto : CreateInvoiceRowDto)=> {
    return addInvoiceRow(dto).unwrap();
  };
};

export const useDeleteInvoiceRow = (invoiceId: number) => {
  const [callDelete] =  invoiceRowsApi.useDeleteInvoiceRowMutation();
  return (invoiceRowId: number)=>{
    return callDelete({ invoiceId, invoiceRowId }).unwrap();
  };
};

