import { SelectChangeEvent, MenuItem, Select } from '@mui/material';
import { ImportedPayorderDto } from '@sr/dto';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';


export const InvoiceCellContent = ({ importedPayorder, onChange }: {
  importedPayorder: ImportedPayorderDto,
  onChange: (newValue: number | null) => void
}) => {
  const availableInvoices = importedPayorder.availableOptions.invoices;

  if (availableInvoices.length === 0)
    return (<>-</>);

  if (availableInvoices.length === 1)
    return (
      <LinkToInvoice invoice={{
        id: availableInvoices[0].id,
        invoiceNumber: availableInvoices[0].title
      }} />
    )

  const value = importedPayorder.importProps.selectedInvoiceId || '';

  return (
    <Select
      size='small'
      value={value.toString()}
      onChange={(e: SelectChangeEvent) => onChange(e.target.value === '' ? null : Number(e.target.value))}
    >
      {importedPayorder.availableOptions.invoices.map(inv =>
        <MenuItem key={inv.id} value={inv.id}>{inv.title}</MenuItem>
      )}
    </Select>
  );
}
