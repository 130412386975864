import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTitleHook } from 'hooks/page-title.hook';

const Error404 = () => {
  useTitleHook('Ошибка 404');
  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h4">
        404
      </Typography>

      Страница не найдена
    </Container>);
}

export default Error404;