import { Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

const TRANSITION_TIMEOUT = 400;

const EmailAddress = (props: {email: string}) => {
  const [title, setTitle] = useState('Нажми, чтобы скопировать');

  const handleEmailClick = () => {
    setTitle('Скопировано!');
    navigator.clipboard.writeText(props.email);
  };

  const handleTooltipClose = () => {
    setTimeout(() => {
      setTitle('Нажми, чтобы скопировать');
    }, TRANSITION_TIMEOUT);
  };

  return (
    <Tooltip TransitionProps={{ timeout: TRANSITION_TIMEOUT }} onClose={handleTooltipClose} placement={'top'} title={title}>
      <Typography sx={{ cursor: 'pointer' }} onClick={handleEmailClick} variant='body1'>
        {props.email}
      </Typography>
    </Tooltip>
  )
  ;
};

export default EmailAddress;
