import { MediaTypeBaseDataDto } from '@sr/dto';
import { withRemoteCollectionDropDownPicker } from 'shared/ui/form-fields/with-remote-collection-dropdown';
import { useMediaTypesDropdown } from './dropdown.hook';


export const MediaTypePicker = withRemoteCollectionDropDownPicker<MediaTypeBaseDataDto, number>({
  collectionProvider: useMediaTypesDropdown,
  renderDropdownLabel: (item: MediaTypeBaseDataDto | '') => typeof item === 'string' ? item : item.name,
  renderEmptyValue: <>&nbsp;</>
});
