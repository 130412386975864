type SplitPart = { text: string; hl: boolean };

export function splitHighlight(inputText: string, hlText: string): SplitPart[] {
  const result: SplitPart[] = [];

  while (inputText !== '') {
    const [begin, highlighted, end] = splitString(inputText, hlText);

    if (begin !== '') result.push({ text: begin, hl: false });

    if (highlighted) {
      result.push({ text: highlighted, hl: true });
    } else {
      result.push({ text: end, hl: false });
      return result;
    }

    inputText = end;
  }

  return result;
}

function splitString(inputStr: string, hl: string) {
  const found = inputStr.toLowerCase().indexOf(hl.toLowerCase());
  if (found === -1) return ['', '', inputStr];
  return [inputStr.substring(0, found), inputStr.substring(found, found + hl.length), inputStr.substring(found + hl.length)];
}
