import { LegalEntitiesGrid } from '../legal-entities-grid';
import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { LegalEntityCreateDialog } from '../legal-entity-create-dialog';
import { useAddCompanyLegalEntity, useLegalEntities } from '../legal-entities.hook';
import { useSnack } from 'shared/ui/snack';
import { CreateLegalEntityDto } from '@sr/dto';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { extractNestJsErrorMessage } from '../../../shared/api/rtk-query';

export const OurLegalEntitiesList = () => {
  const [createEntityOpen, setCreateEntityOpen] = useState(false);
  const ourCompanyId = useOurCompany();
  const ourLegalEntities = useLegalEntities({ companyId: ourCompanyId });
  const addLegalEntity = useAddCompanyLegalEntity(ourCompanyId);

  const { showSuccess, showError } = useSnack();

  const handleAddLegalEntity = async (legalEntity: CreateLegalEntityDto) => {
    return addLegalEntity(legalEntity)
      .then(() => {
        setCreateEntityOpen(false);
        showSuccess('Наше Юридическое лицо успешно добавлено');
      })
      .catch(e => {
        showError(`Ошибка создания юридического лица: ${extractNestJsErrorMessage(e)}`);
      });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" color="grey.700">Список юридических лий компании</Typography>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setCreateEntityOpen(true)}
        >
        Добавить
        </Button>
      </Stack>
      <LegalEntitiesGrid {...ourLegalEntities} />
      <LegalEntityCreateDialog
        open={createEntityOpen}
        onClose={() => setCreateEntityOpen(false)}
        onSubmit={handleAddLegalEntity} />
    </>
  );
};
