import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPagingData } from '@sr/dto';
import { fetchClientInvoices, fetchSupplierInvoices } from '../api';

type FetchClientInvoicesParams = {
  paging: IPagingData, filter?: string, companyId?: number
}

export const fetchClientInvoicesThunk = createAsyncThunk(
  'client-invoices/fetch',
  async ({ paging, filter, companyId }: FetchClientInvoicesParams) => fetchClientInvoices(paging, filter, companyId)
);

export const fetchSupplierInvoicesThunk = createAsyncThunk(
  'supplier-invoices/fetch',
  async ({ paging, filter, companyId }: FetchClientInvoicesParams) => fetchSupplierInvoices(paging, filter, companyId)
);


export const clientInvoicesThunks = {
  fetchClientInvoicesThunk,
  fetchSupplierInvoicesThunk
};
