import { Typography } from '@mui/material';
import { ContactType } from '@sr/dto';
import { ReactNode } from 'react';

export const ContactSpan = ({ contactType, children }: { contactType: ContactType, children: ReactNode }) => {
  const title = contactType === 'email'
    ? 'email'
    : contactType === 'phone'
      ? 'Телефон'
      : 'Сайт';
  return (
    <>
      <Typography variant='caption' color='grey.500' component='span'>{title}:</Typography>
      &nbsp;{children}
    </>
  );
};