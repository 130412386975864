import { CreateInvoiceDto, InvoiceDto, INVOICE_TYPE_SUPPLIER, UpdateInvoiceResource } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { sharedEmptyValues, sharedFromDto, sharedToDto, sharedValidate, SharedFormData as BaseFormData } from 'entities/invoice/shared-invoice.form';


export type FormData = BaseFormData & {
  sellerSignerId: number | '',
  sellerBankDetailsId: number | '';
};

export const formTools: FormTools<InvoiceDto, CreateInvoiceDto, UpdateInvoiceResource, FormData> = {
  toUpdateDto: function (id: number, formData: FormData) {
    throw new Error('Function not implemented.');
  },

  toCreateDto: (formData: FormData): CreateInvoiceDto => {
    const dto = sharedToDto(formData);
    dto.invoiceNumber = formData.invoiceNumber;
    dto.sellerSignerId = formData.sellerSignerId as number;
    dto.sellerBankDetailsId = formData.sellerBankDetailsId as number;
    dto.type = INVOICE_TYPE_SUPPLIER;
    if (formData.sellerCompanyId){
      dto.sellerCompanyId = formData.sellerCompanyId;
    }
    return dto;
  },

  fromDto: (dto: InvoiceDto): FormData => ({
    ...sharedFromDto(dto),
    sellerSignerId: dto.sellerSignerId,
    sellerBankDetailsId: dto.sellerBankDetailsId,
  }),

  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult = sharedValidate(values) as FormikErrors<FormData>;
    if (!values.invoiceNumber)
      validationResult.invoiceNumber = VALIDATION_REQUIRED_FIELD;

    if (!values.sellerBankDetailsId)
      validationResult.sellerBankDetailsId = VALIDATION_REQUIRED_FIELD;

    if (!values.sellerSignerId)
      validationResult.sellerSignerId = VALIDATION_REQUIRED_FIELD;

    if (!values.managerId)
      validationResult.managerId = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },

  emptyValues: () => ({
    ...sharedEmptyValues(),
    sellerSignerId: '',
    sellerBankDetailsId: '',
  })
};
