import { MenuItem, TextField } from '@mui/material';
import { Identifiable } from '@sr/dto';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import { useState, useEffect, ReactNode, ChangeEvent } from 'react';
import { IRemoteCollection } from 'utils/remote-collection.hook';

type Config<TItem> = {
  itemsProvider: () => IRemoteCollection<TItem>,
  isActive: (item: TItem) => boolean,
  selectedItemRender: (item: TItem) => string | ReactNode,
  dropDownRenderValue: (item: TItem) => string | ReactNode
}

export const withGenericSelectPicker = <TItem extends Identifiable>(config: Config<TItem>) => {
  const { itemsProvider, isActive, selectedItemRender, dropDownRenderValue } = config;

  const GenericPicker = (props: TextFieldProps) => {
    const {
      form: { setFieldValue },
      field: { name, value },
      label,
      disabled,
    } = props;

    const { items } = itemsProvider();

    // Поле позволяет отображать "неактивные" варианты, например, удаленный расчетный счет
    // т.е. варианты, которые есть в базе, но выбирать их из дропдауна нельзя
    // isEditable - в каком режиме отображать комбобокс: если изначально выбран "неактивный" вариант
    // то отключаем весь комбобокс и пользователь выбирать ничего не может
    const [isEditable, setEditable] = useState(true);
    const [selected, setSelected] = useState<TItem | null>(null);
    const [availableItems, setAvaliableItems] = useState<TItem[]>([]);

    useEffect(() => {
      if (items.length > 0) {
        setAvaliableItems(items.filter(i => isActive(i)));
        if (value === '') {
          setSelected(null);
          setEditable(true);
          setFieldValue(name, '');
        }
        else {
          const initialSelected = items.find(x => x.id === value);
          if (initialSelected) {
            setSelected(initialSelected);
            setEditable(isActive(initialSelected));
            setFieldValue(name, initialSelected.id);
            // если изначально выбран "неактивный" вариант, то дропдаун должен содержать все варианты,
            // включая "неактивный"
            if (!isActive(initialSelected))
              setAvaliableItems(items);
          }
          else {
            setSelected(null);
            setEditable(false);
            setFieldValue(name, '');
          }
        }
      }
      // else {
      //   setSelected(null);
      //   setEditable(false);
      //   setFieldValue(name, '');
      // }

    }, [items, value, setFieldValue, name]);


    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, e.target.value);
      const foundItem = availableItems.find(x => x.id === Number(e.target.value));
      setSelected(foundItem ? foundItem : null);
    };

    const selectProps = selectedItemRender ? {
      renderValue: (value: any) => {
        const foundItem = availableItems.find(x => x.id === Number(value));
        return foundItem && selectedItemRender(foundItem);
      }
    } : undefined;

    const val = items.length === 0
      ? ''
      : selected
        ? selected.id
        : '';

    return (
      <TextField
        {...fieldToTextField(props)}
        value={val}
        onChange={onChange}
        select
        disabled={disabled || !isEditable}
        label={label}
        SelectProps={selectProps}
      >
        <MenuItem key="" value="">
          &nbsp;
        </MenuItem>
        {availableItems.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {dropDownRenderValue(item)}
          </MenuItem>
        ))}
      </TextField >
    )
  };

  return GenericPicker;
}



