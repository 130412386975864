import { RegionInfoDto, CreateRegionDto } from '@sr/dto';
import { FormikErrors } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

export interface FormValues {
  name: string;
  countryId: number | '';
  // digitalCode: string;
  // population: number;
}


export const fromRegion = (region: RegionInfoDto): FormValues => {
  return {
    name: region.name,
    countryId: region.country.id,
    // digitalCode: region.digitalCode,
    // population: region.population
  };
};

export const toRegion = (formData: FormValues): CreateRegionDto => {
  return {
    name: formData.name,
    countryId: Number(formData.countryId),
    // digitalCode: formData.digitalCode,
    // population: formData.population
  };
};


export const initialValues: FormValues = {
  name: '',
  countryId: '',
  // digitalCode: '',
  // population: 0
};

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.name) errors.name = VALIDATION_REQUIRED_FIELD;

  if (!values.countryId) errors.countryId = VALIDATION_REQUIRED_FIELD;

  return errors;
};
