import { CashflowDirection, CashflowDto, CFD_INBOUND, CreateCashflowDto, UpdateCashflowDto } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';


export type FormData = {
  title: string,
  direction: number | '',
};

export const formTools: FormTools<CashflowDto, CreateCashflowDto, UpdateCashflowDto, FormData> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    title: formData.title,
    direction: formData.direction as CashflowDirection,
  }),
  toCreateDto: formData => ({
    title: formData.title,
    direction: formData.direction as CashflowDirection,
  }),
  fromDto: (dto) => ({
    title: dto.title,
    direction: dto.direction,

  }),
  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (!values.title)
      validationResult.title = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },
  emptyValues: () => ({
    direction: CFD_INBOUND,
    title: ''
  })
};