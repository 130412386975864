import { SyntheticEvent } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { CompanyEmployeeDto } from '@sr/dto';
import { ContactsPanel } from 'widgets/contacts/contact-panel';
import { EntitiesWithContacts } from 'features/contact/contacts.types';
import { formatPerson } from 'utils/person-format-helpers';

interface Props {
  personLink: CompanyEmployeeDto;
  onDelete: (link: CompanyEmployeeDto) => Promise<void>;
  companyId: number;
}

export const PersonItem = ({ personLink, companyId, onDelete }: Props) => {
  const { person, position } = personLink;

  const personName = formatPerson(person);

  const handleDeletePersonClick = (e: SyntheticEvent) => {
    onDelete(personLink);
  };

  return (
    <Accordion >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <AccountBoxIcon color='disabled' sx={{ mr: 2 }} />
        <Typography sx={{ width: '50%' }}>{personName}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{position}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <ContactsPanel
          relationType={EntitiesWithContacts.person}
          relationId={person.id}
          dupesDisplayOptions={{ excludeLists: { company: [companyId] } }} />
      </AccordionDetails>

      <AccordionActions>
        <Button
          variant='text'
          color='error'
          size='small'
          onClick={handleDeletePersonClick}
        >
          Удалить сотрудника
        </Button>
      </AccordionActions>
    </Accordion>
  );
};


