import { CitiesList } from 'entities/geo/cities/cities-list';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useCitiesList } from 'entities/geo/cities/cities-list/cities-list.hook';
import { AddCityButton, AddCityDialog, useAddCityDialog, useAddCity, FormValues } from 'features/geo/city/add';
import { useDeleteCity } from 'features/geo/city/delete';


const CitiesListPage = () => {
  useTitleHook('Города');

  const [paging, setPaging] = usePagingParams();

  const collection = useCitiesList(paging);
  const { closeDialog } = useAddCityDialog();

  const addCity = useAddCity();

  const handleAddCity = async (formValues: FormValues) =>
    addCity(formValues).then(closeDialog);

  const handleDeleteCity = useDeleteCity();


  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Города"
        actions={<AddCityButton />}
      />

      <CitiesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onDelete={handleDeleteCity}
      />

      <AddCityDialog onSubmit={handleAddCity} />
    </>
  );
};

export default CitiesListPage;