import { authorizedRequest } from 'shared/api/axios';
import { CountryInfoDto, CreateCountryDto } from '@sr/dto';

const BASE_URL = 'countries';

export const fetchCountries = async () => {
  return authorizedRequest<void, CountryInfoDto[]>(BASE_URL, 'GET');
};

export const fetchCountryDetails = async (countryId: number) => {
  return authorizedRequest<void, CountryInfoDto>(
    `/${BASE_URL}/${countryId}`,
    'GET'
  );
};

export const postCountry = async (country: CreateCountryDto) => {
  return authorizedRequest<CreateCountryDto, CountryInfoDto>(
    BASE_URL,
    'POST',
    country
  );
};

export const putCountry = async (countryId: number, country: CreateCountryDto) => {
  return authorizedRequest<CreateCountryDto, CountryInfoDto>(
    `/${BASE_URL}/${countryId}`,
    'PUT',
    country
  );
};

export const deleteCountry = async (countryId: number) => {
  return authorizedRequest<void, void>(
    `/${BASE_URL}/${countryId}`,
    'DELETE'
  );
};
