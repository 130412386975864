import { LegalEntityDto, LegalEntityBaseInfoDto, LegalEntityTypeBaseInfoDto, LegalEntityTypeDto } from '@sr/dto';
import { formatLegalEntity } from 'utils/legal-entity-format-helpers';
import { Link } from 'shared/ui/link-base';
import { legalEntityManager, legalEntityTypeManager } from 'utils/link-manager';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode,
  legalEntityType: LegalEntityTypeBaseInfoDto | LegalEntityTypeDto
}

export const LinkToLegalEntityType = ({ legalEntityType, children }: Props) => {
  const link = legalEntityTypeManager.link(legalEntityType.id);
  return (
    <Link to={link} >
      {legalEntityType.name}
    </Link>
  );
};
