import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, IconButton, Divider, Theme, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CardPaper } from 'shared/ui/card-paper';
import { SxProps } from '@mui/system';
import { HeaderText } from 'shared/ui/header-text';

type Props = {
  title: ReactNode,
  actions?: ReactNode,
  subHeader?: ReactNode,
  sx?: SxProps<Theme>;
};

export const PageHeader = ({ title, actions, subHeader, sx }: Props) => {
  const navigate = useNavigate();

  return (
    <Box sx={sx || { mb: 2, mt: 1 }}>
      <CardPaper>
        <Stack direction="row" alignItems="center">
          <Box>
            <IconButton color='primary' onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
            </IconButton>
          </Box>

          <Box>
            {title}
          </Box>

          {actions &&
            <Box sx={{ ml: 'auto', mr: 1 }}>
              {actions}
            </Box>
          }
        </Stack>

        {subHeader && (
          <>
            <Divider sx={{ my: 1 }} />
            {subHeader}
          </>
        )}
      </CardPaper>
    </Box>);
};

PageHeader.Text = HeaderText;