import { Link } from 'shared/ui/link-base';
import { CountryInfoDto } from '@sr/dto';
import { geoLinkManager } from 'utils/link-manager';

export const LinkToCountry = ({ country: { id, name } }: { country: CountryInfoDto }) => {
  const link = geoLinkManager.linkToCountry(id);
  return (
    <Link to={link} >
      {name}
    </Link>
  );
}