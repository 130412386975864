import { Stack, Button, Card, CardContent, CardActions } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useSnack } from 'shared/ui/snack';
import { useUpdateUserRoles, useGetUserRoles } from './user-company-data.hook';
import { formTools, FormValues } from './user-rights.form-tools';
import { UserRolePicker } from '../user-role-picker/user-role-picker';
import { useStateSelector } from '../../../shared/store';

export const UserRights = ({ userId }: { userId: number }) => {
  const { userRoles, isLoading, error } = useGetUserRoles(userId);
  const updateData = useUpdateUserRoles(userId);
  const user = useStateSelector(state => state.user.profile);
  const { showSuccess, showError } = useSnack();

  const roles = (userRoles || []).map(item => item.id);
  if (isLoading) {
    return <LoadingBanner />;
  }

  if (error) {
    return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />;
  }

  if (!(user && userId)) {
    return null;
  }

  const initialValues: FormValues = {
    roles: roles
  };

  const handelSubmit = async (formValues: FormValues) => {
    return updateData(formTools.toUpdateDto(userId, formValues))
      .then(() => showSuccess('Данные успешно обновлены'))
      .catch(e => showError('Ошибка обновления данных', e));
  };
  return (
    <Card>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handelSubmit}
        validate={formTools.validate}
      >
        {({ submitForm, isSubmitting, isValid, dirty }: FormikProps<FormValues>) => (
          <>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Field
                  name='roles'
                  label='Роли пользователя'
                  required
                  fullWidth
                  component={UserRolePicker}
                />
              </Stack>

            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 1 }}
                onClick={submitForm}
                disabled={!dirty || isSubmitting || !isValid}
              >
                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card>
  );
};
