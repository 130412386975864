import { Box, Button, IconButton, Stack } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { PayOrderImportSessionDto, SettingsImportCashflowsDto } from '@sr/dto';
import { Heading } from 'shared/ui/list-page-heading';
import { NewImportDialog, useNewImportDialog } from 'components/PayOrdersImport/new-import-dialog';
import { PayordersImportSessionsList } from 'components/PayOrdersImport/payorders-import-sessions-list';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePayOrdersImportSessions } from 'hooks/payorders-import.hook';
import { useSnack } from 'shared/ui/snack';
import { PayorderImportSettingsDialog, usePayorderImportSettingsDialog } from 'components/Settings/payorder-import-settings-dialog';

const PayOrdersImportPage = () => {
  const { showSuccessDelete, showFailedDelete } = useSnack();
  const showConfirm = useConfirmDialog();

  useTitleHook('Импорт платежных поруечений');

  const { collection, removeItem } = usePayOrdersImportSessions();

  const handleDelete = (session: PayOrderImportSessionDto) => {
    showConfirm({
      content: 'Удалить импорт?'
    })
      .then(() => removeItem(session.id))
      .then(() => showSuccessDelete())
      .catch(e => showFailedDelete(e));
  };

  const { dialogProps: settingsDialogProps, openDialog } = usePayorderImportSettingsDialog();

  const { props: newUploadDialogProps, openNewImportDialog } = useNewImportDialog({
    onUploadComplete: collection.reload
  });

  return (
    <>
      <Heading title='Импорт платежных поручений' />
      <Stack direction="row" justifyContent="space-between">
        <Button variant='contained' onClick={openNewImportDialog}>
          Новый импорт...
        </Button>

        <Button variant='outlined' onClick={openDialog}>
          <SettingsIcon />
        </Button>
      </Stack>


      <Box mt={2}>
        <PayordersImportSessionsList collection={collection.items} onDeleteClick={handleDelete} />
      </Box>

      <NewImportDialog {...newUploadDialogProps} />

      <PayorderImportSettingsDialog {...settingsDialogProps} />
    </>
  );
};

export default PayOrdersImportPage;