import { PurchaseDto } from '@sr/dto';
import { deletePurchase, patchPurchase, UpdatePurchaseDto } from 'shared/api/purchases';
import { useRemoteItem } from 'utils/remote-item.hook';

export const usePurchase = (id: number) => {
  const { loading, error, updateItem } = useRemoteItem<PurchaseDto, UpdatePurchaseDto>({
    updateHandler: patchPurchase,
  });

  return {
    error,
    loading,
    updatePurchase: updateItem,
    deletePurchase
  };
};
