import { InvoiceTotals } from '@sr/dto';

import { INVOICES_BASE_URL } from 'shared/api/api';
import { invoiceApi } from '../api-slice';

const injectedApi = invoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceTotals: builder.query<InvoiceTotals, number>({
      query: (id: number) => ({
        url: `${INVOICES_BASE_URL}/${id}/totals`,
        method: 'GET'
      }),
    }),

  }),
  overrideExisting: true
});

export const invoiceTotalsApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['invoice-totals'],
  endpoints: {
    getInvoiceTotals: {
      providesTags: ['invoice-totals']
    },

  }
});


// type State = {
//   totals: InvoiceTotals | null
// }

// const initialState: State = {
//   totals: null
// }

// const slice = createSlice({
//   name: 'invoiceTotals',
//   initialState: initialState,
//   reducers: {
//     set: (state, action) => {
//       state.totals = action.payload;
//     }
//   },

// });


// export const { reducer: invoiceTotalsReducer, actions: invoiceTotalsActions } = slice;

