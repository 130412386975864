import {IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";
import { Identifiable } from "./identifiable";

export class CreateMediaTypeDto {
  @IsString()
  @IsNotEmpty()
  name: string;
}

export class UpdateMediaTypeBaseDataDto extends CreateMediaTypeDto implements Identifiable{
  @IsNumber()
  id: number;
}

export class MediaTypeBaseDataDto extends UpdateMediaTypeBaseDataDto{
  @IsOptional()
  @IsNumber()
  publishingDataId: number | null;
}
