import { CircularProgress } from '@mui/material';
import { PersonItem } from './person-item';
import { CompanyEmployeeDto } from '@sr/dto';

interface Props {
  list: CompanyEmployeeDto[] | null;
  companyId: number;
  onLinkDelete: (linkId: CompanyEmployeeDto) => Promise<void>;
}

export const PersonsList = ({ list, onLinkDelete, companyId }: Props) => {
  return (
    <>
      {!list ? (
        <CircularProgress sx={{ m: 2 }} size={24} />
      )
        : (
          <>
            {list.map((link, i) =>
              (<PersonItem key={i} personLink={link} companyId={companyId} onDelete={onLinkDelete} />))
            }
          </>
        )}
    </>);
};