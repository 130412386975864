import { Stack } from '@mui/material';
import BankAccountsList from './Accounting/bank-accounts-list';
import CashflowsList from './Accounting/cashflows-list';

const AccountingTab = () => {
  return (
    <>
      <Stack direction='row' spacing={2}>
        <BankAccountsList />
        <CashflowsList />
      </Stack>
    </>
  )
}

export default AccountingTab;