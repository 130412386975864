import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addNewMediaDialogOpen: false
}

const slice = createSlice({
  name: 'media',
  initialState: initialState,
  reducers: {
    openDialog(state) {
      state.addNewMediaDialogOpen = true;
    },
    closeDialog(state) {
      state.addNewMediaDialogOpen = false;
    }
  },
}
);

export const { reducer: mediaReducer, actions: mediaActions } = slice;