import { Box, Button, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { Field, Formik } from 'formik';
import { CardPaper } from 'shared/ui/card-paper';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { endOfDay, startOfDay } from 'date-fns';
import { UserPickerField } from 'features/user/user-picker/ui';
import { useSearchParams } from 'react-router-dom';
import { ContractsFilter, FilterFormValues, fromDateString, fromNumberString } from '@sr/dto';

type FormValues = FilterFormValues<ContractsFilter>;

const initial: FormValues = {
  ourLegalEntityId: '',
  fromDate: null,
  toDate: null,
  managerId: ''
};

const fromUrlSearchParams = (params: URLSearchParams) => {
  const formValues: FormValues = {
    ourLegalEntityId: fromNumberString(params.get('ourLegalEntityId')),
    fromDate: fromDateString(params.get('fromDate')),
    toDate: fromDateString(params.get('toDate')),
    managerId: fromNumberString(params.get('managerId')),
  };

  return formValues;
};

const toSearchString = (formValues: FormValues) => {
  const builder = new URLSearchParams();
  formValues.ourLegalEntityId && builder.append('ourLegalEntityId', formValues.ourLegalEntityId.toString());
  formValues.fromDate && builder.append('fromDate', startOfDay(formValues.fromDate).toString());
  formValues.toDate && builder.append('toDate', endOfDay(formValues.toDate).toString());
  formValues.managerId && builder.append('managerId', formValues.managerId.toString());

  return builder.toString();
};

type Props = {
    filterString: string,
    onFilter: (searchParam: string) => void;
};

export const ContractFilter = ({ filterString, onFilter }: Props) => {
  // todo: can be refactored using url-params filters
  const ourCompanyId = useOurCompany();

  const handleSubmit = async (formValues: FormValues) => {
    const filterString = toSearchString(formValues);
    return onFilter(filterString);
  };

  return (
    <CardPaper>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={
          filterString === ''
            ? initial
            : fromUrlSearchParams(new URLSearchParams(filterString))
        }
      >{
          ({ submitForm, resetForm, dirty }) => {
            return (
              <Stack direction="row" spacing={1}>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="ourLegalEntityId"
                    label="Наше юрлицо"
                    component={LegalEntityPicker}
                    companyIdFilter={ourCompanyId}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="fromDate"
                    label="Дата окончания с"
                    component={DatePickerField}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="toDate"
                    label="Дата окончания до"
                    component={DatePickerField}
                  />
                </Box>


                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="managerId"
                    component={UserPickerField}
                  />
                </Box>

                <Button
                  onClick={submitForm}
                  variant="contained"
                  disabled={!dirty}
                  endIcon={<FilterListIcon/>}
                >
                                Применить
                </Button>

                <Button
                  onClick={() => resetForm()}
                  variant="outlined"
                  endIcon={<HighlightOffIcon/>}
                >
                                очистить
                </Button>
              </Stack>
            );
          }
        }
      </Formik>
    </CardPaper>
  );
};

export const useInvoicesFilterSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return fromUrlSearchParams(searchParams);
};
