import { UserPersonalDataDto } from '@sr/dto';

import { userApi } from 'entities/user/api';

const injectedUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPersonalData: builder.query<UserPersonalDataDto, number>({
      query: (userId: number) => `users/${userId}/personal-data`,
    }),

    updateUserPersonalData: builder.mutation<UserPersonalDataDto, { userId: number, data: UserPersonalDataDto }>({
      query: ({ userId, data }) => ({
        url: `users/${userId}/personal-data`,
        body: data,
        method: 'PUT'
      }),
    })
  }),
  overrideExisting: true
});

export const userPersonalDataApi = injectedUserApi.enhanceEndpoints({
  addTagTypes: ['data'],
  endpoints: {
    getUserPersonalData: {
      providesTags: ['data']
    },
    updateUserPersonalData: {
      invalidatesTags: ['data']
    }
  }
});

