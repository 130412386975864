import { EventDto, IPagedCollection } from '@sr/dto';
import { Box, debounce, LinearProgress, Stack } from '@mui/material';
import EventItem from 'components/Companies/Feed/event-item';
import { useCallback, useEffect } from 'react';

type EventsListProps = {
  collection: IPagedCollection<EventDto>,
  isLoading: boolean,
  showCompany: boolean,
  loadMore: () => void,
}

const COMPONENT_ID = 'events_list';
const SCROLL_DEBOUNCE_DELAY = 1000;
export const EventsList = ({ collection, isLoading, loadMore, showCompany }: EventsListProps)=> {

  const handleScroll = useCallback(() => {
    const eventsList = document.querySelector(`#${COMPONENT_ID}`);
    if (!eventsList) return;
    if (
      !isLoading &&
      eventsList.scrollTop * 1.1 + eventsList.clientHeight >= eventsList.scrollHeight
    ) {
      loadMore();
    }
  }, [isLoading, loadMore]);

  const debouncedHandleScroll = debounce(()=>{ handleScroll() }, SCROLL_DEBOUNCE_DELAY);

  useEffect(() => {
    return () => {
      debouncedHandleScroll.clear();
    };
  }, [debouncedHandleScroll]);

  useEffect(() => {
    const eventsList = document.querySelector(`#${COMPONENT_ID}`);

    if (eventsList && collection.items.length < collection.totalItemsCount){
      eventsList.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => {
      const eventsList = document.querySelector(`#${COMPONENT_ID}`);
      if (eventsList)
        eventsList.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [collection, debouncedHandleScroll]);

  return (
    <>
      {!isLoading && collection.items.length === 0 &&
        <Box sx={{ py: 2, display: 'flex', justifyContent: 'center', }}>
          <p>Нет событий </p>
        </Box>
      }
      <Box sx={{ overflowY: 'auto', height: '70vh' }} id={COMPONENT_ID}>
        <Stack direction='column' spacing={1} sx={{ mt: 2 }}>
          {collection.items.map((event, idx)=>
            <EventItem
              showCompany={showCompany}
              key={event.id}
              item={event}
            />
          )}
        </Stack>
        {isLoading &&
          <LinearProgress/>
        }
        {(!isLoading && collection.items.length > 0) && <Box sx={{ display: 'flex', justifyContent: 'center', }}>
          {
            <p>Показано {collection.items.length} событий из {collection.totalItemsCount}</p>
          }
        </Box>
        }
      </Box>
    </>
  );
};
