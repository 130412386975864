import {
  Stack,
} from '@mui/material';


import { Field } from 'formik';
import { TextField } from 'formik-mui';


export const BankDetailsFormFields = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Field
        label='Банк'
        component={TextField}
        name='bankName' />
      <Field
        label='БИК'
        component={TextField}
        name='bik' />
      <Field
        label='Корр. счет'
        component={TextField}
        name='corrAccountNumber' />
      <Field
        label='Счет'
        component={TextField}
        name='accountNumber' />
    </Stack>
  );
}
