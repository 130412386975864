import { useAppDispatch, useStateSelector } from 'shared/store';
import { logoutThunk } from './auth-slice';

export const useUserAuth = () => {
  const dispatch = useAppDispatch();
  const user = useStateSelector((store) => store.auth.user);
  const logout = () => {
    if (user) dispatch(logoutThunk(user));
  };

  return {
    user,
    logout,
  };
};
