import { MediaTypeBaseDataDto } from '@sr/dto';
import { useCallback } from 'react';
import { authorizedRequest } from 'shared/api/axios';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';


export const useMediaTypesDropdown = () => {
  return useReadOnlyRemoteCollection<MediaTypeBaseDataDto>({
    fetchHandler: useCallback(() => { return authorizedRequest<void, MediaTypeBaseDataDto[]>('/media-types', 'GET') }, [])
  });
};
