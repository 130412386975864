// import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
// import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
// import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircle';
// import OutboundIcon from '@mui/icons-material/Outbound';
import SwapVerticalCircleOutlinedIcon from '@mui/icons-material/SwapVerticalCircleOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { CashflowDirection, CFD_TYPES } from '@sr/dto';
import { Chip, ListItemIcon } from '@mui/material';

const flowDirectionProps: { [key in CashflowDirection]: { color: "success" | "error" | "primary", icon: JSX.Element } } = {
  1: { color: 'success', icon: <ArrowCircleDownIcon /> },
  2: { color: 'error', icon: <ArrowCircleUpIcon /> },
  3: { color: 'primary', icon: <SwapVerticalCircleOutlinedIcon /> },
}

export const CashflowChip = ({ direction }: { direction: CashflowDirection }) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      color={flowDirectionProps[direction].color}
      icon={flowDirectionProps[direction].icon}
      label={CFD_TYPES[direction]} />
  )
}

export const CashflowDirectionIcon = ({ direction }: { direction: CashflowDirection }) => {
  return (
    <>
      {flowDirectionProps[direction].icon}
    </>
  )
}

export const CashflowDirectionListIcon = ({ direction }: { direction: CashflowDirection }) => {
  return (
    // 
    <ListItemIcon
      // color={flowDirectionProps[direction].color}
      sx={{ color: `${flowDirectionProps[direction].color}.main` }}
    >
      {flowDirectionProps[direction].icon}
    </ListItemIcon >
  )
}