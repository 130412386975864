import { Box, Stack, Typography } from '@mui/material';
import { CompanyWithManagersDto } from '@sr/dto';
import { LinkedManagerChip } from 'entities/user/linked-manager-chip';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { formatPerson } from 'utils/person-format-helpers';


export const CompanyAnnounce = ({ company }: { company: CompanyWithManagersDto }) => {
  const { viaPerson, managers } = company;
  return (
    <Box sx={{ px: 1 }}>
      {viaPerson && <> {formatPerson(viaPerson)} @ </>}
      <LinkToCompany company={company} />
      <Stack direction='row' gap={1}>
        {managers.length === 0
          ? <Typography variant='caption'>Нет закрепленных менеджеров</Typography>
          : managers.map((manager, idx) => (
            <LinkedManagerChip key={idx} manager={manager} />
          ))}
      </Stack>
    </Box >
  );
};
