import { fromDateString, UpdateWccDto, WccDto, WccStatus } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';


export type FormValues = {
  wccCost: number | '',
  finishDate: Date | null,
  status: WccStatus | '',
};

export const formTools: FormTools<WccDto, void, UpdateWccDto, FormValues> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    status: formData.status as WccStatus,
    endDate: formData.finishDate!,
    amount: Number(formData.wccCost),
  }),

  toCreateDto: formData => { },

  fromDto: (dto) => ({
    wccCost: dto.amount,
    status: dto.status,
    finishDate: fromDateString(dto.endDate),
  }),

  validate: (values: FormValues): FormikErrors<FormValues> => {
    let validationResult: FormikErrors<FormValues> = {};

    if (!values.finishDate)
      validationResult.finishDate = VALIDATION_REQUIRED_FIELD;

    if (values.wccCost === '')
      validationResult.wccCost = VALIDATION_REQUIRED_FIELD;
    if (isNaN(Number(values.wccCost)))
      validationResult.wccCost = 'Допустимы только числовые значения';

    return validationResult;
  },

  emptyValues: () => ({
    status: '',
    wccCost: '',
    finishDate: null,
  })
};