import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { RegionInfoDto } from '@sr/dto';
import { MouseEvent } from 'react';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import MenuParams from 'shared/ui/menu-params';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { LinkToRegion } from 'shared/ui/links/link-to-region';
import { useTitleHook } from 'hooks/page-title.hook';

type Props = {
  list: RegionInfoDto[],
  onDelete: (regionToDelete: RegionInfoDto) => void,
};

const RegionsList = (props: Props) => {
  useTitleHook('Регионы');
  const { list, onDelete } = props;
  const { openMenu, menuData } = useMenu<RegionInfoDto>({ deleteHandler: onDelete });

  return (
    <>
      <TableContainer component={Card}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, i) => {
              return (
                <RegionRow
                  key={i}
                  region={item}
                  onMenuClick={openMenu} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <RowMenu menuData={menuData} />
    </>);
};

type RowProps = {
  region: RegionInfoDto,
  onMenuClick: (menuParams: MenuParams<RegionInfoDto>) => void;
};

export const RegionRow = (props: RowProps) => {
  const { region, onMenuClick } = props;

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: region });
  };

  return (
    <>
      <TableRow
        key={region.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToRegion region={region} />
        </TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};


export default RegionsList;
