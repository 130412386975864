import { leadBaseApi } from 'entities/lead/api';
import { LEAD_BASE_URL } from 'shared/api/api';
import { CompanyWithManagersDto } from '@sr/dto';

const injectedApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeadContact: builder.query<CompanyWithManagersDto[], { leadId: number }>({
      query: ({ leadId }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/companies`,
        method: 'GET',
      }),
    }),
  }),
});

export const leadConnectionsApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['leadContacts'],
  endpoints: {
    getLeadContact: {
      providesTags: (result, error, arg, meta) => [{ type: 'leadContacts', id: arg.leadId }],
    },
  },
});
