import { TableCell, TableRow } from '@mui/material';
import { IPagingData, LeadMediaplanDto } from '@sr/dto';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import ManagerSpan from 'shared/ui/manager-span';
import { LinkToFile } from 'shared/ui/links/link-to-file';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { formatDate } from 'utils/date-format-helpers';

const columns: HeadCellsCollection = ['Тип СМИ', 'Город', 'Дата добавления', 'Менеджер', 'Файл'];
const emptyCell = '- пусто -';


type Props = {
  collection: IRemotePagedCollection<LeadMediaplanDto>,
  paging: IPagingData,
  onChangePage: (paging: IPagingData) => void
}

export const SentMediaPlans = ({ collection, paging, onChangePage }: Props) => {
  return (
    <>
      <GridLayout<LeadMediaplanDto>
        columns={columns}
        items={collection.collection.items}
        pagingData={paging}
        onChangePaging={onChangePage}
        totalItemsCount={collection.collection.totalItemsCount}
        isLoading={collection.isLoading}
        error={collection.error}
        itemRender={mediaplan => (<SentMediaPlanRow key={mediaplan.id} mediaplan={mediaplan} />)}
        noItemsText='Нет заявок'
      />
    </>
  );
};

const SentMediaPlanRow = ({ mediaplan }: { mediaplan: LeadMediaplanDto; }) => {
  return (
    <TableRow>
      <TableCell>
        {mediaplan.mediaType?.name ?? emptyCell}
      </TableCell>
      <TableCell>
        {mediaplan.city?.name ?? emptyCell}
      </TableCell>
      <TableCell>
        {mediaplan.createdAt && formatDate(mediaplan.createdAt)}
      </TableCell>
      <TableCell sx={{ minWidth: 200 }}>
        {mediaplan.createdBy !==null ? <ManagerSpan person={ mediaplan.createdBy}/>: '--Неизвестно--'}
      </TableCell>
      <TableCell>
        {/*<SingleFilePicker label={} value={} onChange={}/>*/}
        {mediaplan.offerFile ? <LinkToFile file={mediaplan.offerFile}/> : '-- нет файла --'}
      </TableCell>
    </TableRow>);
};


