import { FormikErrors } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

export type FormValues = {
    // currentPassword: string;
    password1: string;
    password2: string;
}

export const initialValues: FormValues = {
  // currentPassword: '',
  password1: '',
  password2: ''
};


export function validate(values: FormValues): FormikErrors<FormValues> {
  const result: FormikErrors<FormValues> = {};

  // if (values.currentPassword === '')
  //   result.currentPassword = VALIDATION_REQUIRED_FIELD;

  if (values.password1 === '')
    result.password1 = VALIDATION_REQUIRED_FIELD;

  if (values.password2 === '')
    result.password2 = VALIDATION_REQUIRED_FIELD;

  if (values.password1 !== '' && values.password2 !== '' && values.password1 !== values.password2) {
    result.password1 = 'Пароли не совпадают';
    result.password2 = 'Пароли не совпадают';
  }

  return result;
}
