import { Box, Typography } from "@mui/material";

type Props = {
  query: string | null
}

export const SearchResultTitle = ({ query }: Props) => {
  return (
    <Box component="h2">
      {query &&
        <>
          <Typography component='span' variant='h6'>Результаты поиска для: </Typography>
          <Typography component='span' variant='h6' bgcolor='yellow'>{query}</Typography>
        </>
      }
    </Box>
  );
}
