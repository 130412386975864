import { Button, Paper, Stack, Typography } from '@mui/material';
import { Formik, FormikProps, Form } from 'formik';
import useRegion from 'hooks/region.hook';
import { validate, FormValues, fromRegion, toRegion } from './region-form';
import { RegionFormFields } from './region-form-fields';
import { UpdateRegionDto } from '@sr/dto';
import { useTitleHook } from 'hooks/page-title.hook';

type Props = {
  regionId: number;
}
const RegionHome = (props: Props) => {
  const { regionId } = props;

  const { region, update } = useRegion(regionId);
  useTitleHook(region && `${region.name} | Регионы`);

  const handleSubmit = async (data: FormValues) => {
    const updateData: UpdateRegionDto = {
      id: regionId,
      ...toRegion(data)
    };
    return update(updateData);
  };

  return (
    <>
      <Stack direction='column' spacing={2}>
        {region && <>
          <Typography variant='h5' color='primary'>{region.name}</Typography>
          <Paper sx={{ p: 2 }}>
            <Formik
              initialValues={fromRegion(region)}
              enableReinitialize={true}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {(p: FormikProps<FormValues>) => (
                <>
                  <Form>
                    <RegionFormFields  {...p} />
                    <Button type='submit' variant='contained'>Сохранить</Button>
                  </Form>
                </>)}
            </Formik>
          </Paper>
        </>
        }
      </Stack>
    </>

  );
};

export default RegionHome;
