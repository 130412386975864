import { Alert, AlertTitle, Box, Paper } from '@mui/material';

export const InfoBanner = ({ message }: { message: string }) => {
  return (
    <>
      <Paper sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <Alert severity="info">
            {/* <AlertTitle>Ошибка</AlertTitle> */}
            {message}
          </Alert>
        </Box>
      </Paper>
    </>);
}