import { TableCell, TableRow } from '@mui/material';
import { BankAccountType, BA_TYPES, PayOrderDto, PayOrderStatus, POS_STATUSES } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import { LinkToPayOrder } from 'shared/ui/links/link-to-payorder';
import PriceSpan from 'shared/ui/price-span';
import { formatDate, formatDatesAndPeriod } from 'utils/date-format-helpers';
import { IRemoteCollection } from 'utils/remote-collection.hook';

type Props = {
  payOrders: IRemoteCollection<PayOrderDto>;
};

export const RelatedPayorders = ({ payOrders }: Props) => {
  return (
    <>
      <GridLayout<PayOrderDto>
        columns={['Дата', 'Номер', 'Тип', 'Группа', 'Сумма', 'Статус']}
        items={payOrders.items}
        isLoading={payOrders.isLoading}
        error={payOrders.error}

        itemRender={payOrder => (<PayOrderRow key={payOrder.id} payOrder={payOrder} />)}
        noItemsText='Нет платежных поручений'
      />
    </>
  );
};

const PayOrderRow = ({ payOrder }: { payOrder: PayOrderDto; }) => {

  const totalPaymentAmount = payOrder.rows.reduce((prev, curr) => prev + curr.amount, 0);

  return (
    <TableRow>
      <TableCell>
        <LinkToPayOrder payorder={payOrder}>
          {formatDate(payOrder.paymentDate)}
        </LinkToPayOrder>
      </TableCell>
      <TableCell>
        <LinkToPayOrder payorder={payOrder}>
          {payOrder.documentNumber}
        </LinkToPayOrder>
      </TableCell>
      <TableCell>
        {BA_TYPES[payOrder.bankAccount.type as BankAccountType]}
      </TableCell>
      <TableCell>
        {payOrder.cashflow.title}
      </TableCell>

      <TableCell>
        <PriceSpan size='small' price={totalPaymentAmount} />
      </TableCell>

      <TableCell>
        {POS_STATUSES[payOrder.status as PayOrderStatus]}
      </TableCell>

    </TableRow>);
};
