import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DraggablePaper } from 'shared/ui/draggable-paper';

type DialogProps = {
  open: boolean;
  title: string;
  content: string;
  onYesClick: () => void;
  onNoClick: () => void;
};

const ConfirmDialog = (props: DialogProps) => {
  const {
    open,
    title,
    content,
    onYesClick,
    onNoClick } = props;

  return (
    <Dialog
      onClose={() => onNoClick()}
      open={open}
      fullWidth
      PaperComponent={DraggablePaper}
    >
      <DialogTitle >
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          autoFocus
          onClick={onYesClick}
        >
          Да
        </Button>
        <Button
          variant="outlined"
          onClick={onNoClick}
        >
          Нет
        </Button>
      </DialogActions>
    </Dialog>);
};


export default ConfirmDialog;
