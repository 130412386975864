import { DEFAULT_URL_TYPE, IContacts, ILinkedEmail, ILinkedPhone, ILinkedUrl } from '@sr/dto';
import { parsePhoneNumber } from 'libphonenumber-js';

let nextId = -1;

export const useContactsActions = (value: IContacts, setNewValue: (newValue: IContacts) => void) => {
  const handleAddEmail = async (newEmail: string) => {
    const newEmailEntry: ILinkedEmail = {
      id: nextId--,
      email: newEmail,
    };

    setNewValue({
      ...value,
      linkedEmails: [...value.linkedEmails, newEmailEntry],
    });
  };

  const handleRemoveEmail = (linkedEmail: ILinkedEmail) => {
    setNewValue({
      ...value,
      linkedEmails: value.linkedEmails.filter((x) => x.id !== linkedEmail.id),
    });
  };

  const handleAddPhone = (newPhone: string) => {
    const normalizedPhone = parsePhoneNumber(newPhone).number.toString();

    const newPhoneEntry: ILinkedPhone = {
      id: nextId--,
      phone: normalizedPhone,
    };

    setNewValue({
      ...value,
      linkedPhones: [...value.linkedPhones, newPhoneEntry],
    });
  };

  const handleRemovePhone = (linkedPhone: ILinkedPhone) => {
    setNewValue({
      ...value,
      linkedPhones: value.linkedPhones.filter((x) => x.id !== linkedPhone.id),
    });
  };

  const handleAddUrl = (newUrl: string) => {
    const newUrlEntry = { id: nextId--, url: { url: newUrl, urlType: { id: DEFAULT_URL_TYPE, name: '', icon: '' } } };

    setNewValue({
      ...value,
      linkedUrls: [...value.linkedUrls, newUrlEntry],
    });
  };

  const handleRemoveUrl = (linkedUrl: ILinkedUrl) => {
    setNewValue({
      ...value,
      linkedUrls: value.linkedUrls.filter((x) => x.id !== linkedUrl.id),
    });
  };

  return {
    currentValue: value,
    addEmail: handleAddEmail,
    removeEmail: handleRemoveEmail,
    addPhone: handleAddPhone,
    removePhone: handleRemovePhone,
    addUrl: handleAddUrl,
    removeUrl: handleRemoveUrl,
  };
};
