import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Paper, TextField } from '@mui/material';
import { ContentProviderRef, RichEditor } from 'shared/ui/rich-editor/rich-editor';


export type TemplateDataRef = {
  name?: string,
  templateContent?: string
};

type Props = {
  content: string;
  name: string;
  disabled: boolean;
}

export const ContractTemplateFields = forwardRef(({ name, content, disabled }: Props, ref: ForwardedRef<() => TemplateDataRef>) => {

  const nameRef = useRef<HTMLInputElement>();
  const editorRef = useRef<ContentProviderRef>(null);

  useImperativeHandle(ref,
    () => () => {
      return {
        name: nameRef.current?.value,
        templateContent: editorRef.current?.()
      };
    },
    []);

  return (
    <Paper sx={{ p: 1, pt: 2 }}>
      <Box mb={2}>
        <TextField label="Название" fullWidth defaultValue={name} inputRef={nameRef} />
      </Box>
      <Box>
        <RichEditor disabled={disabled} content={content} ref={editorRef} />
      </Box>
    </Paper>
  );
});

