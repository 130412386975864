import { PurchaseInfoDto, PurchaseDto } from '@sr/dto';
import { ReactNode } from 'react';
import { Link } from '../link-base';
import { purchaseLinkManager } from 'utils/link-manager';

export const LinkToPurchase = (props: { purchase: PurchaseInfoDto | PurchaseDto, children?: ReactNode; }) => {
  const { purchase: { id }, children } = props;

  const link = purchaseLinkManager.link(id);

  if (children)
    return (
      <Link to={link} >
        {children}
      </Link>);

  return (
    <Link to={link} >
      {id}
    </Link>
  );
};