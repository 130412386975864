import { Stack, Box } from '@mui/material';
import { Formik, Field, FormikProps } from 'formik';
import { LeadDto } from '@sr/dto';
import { LeadBudgetPickerField } from 'shared/ui/formik-fields//lead-budget-picker-field';
import { useSnack } from 'shared/ui/snack';
import { FormValues, toUpdateDto, validate, fromDto } from './lead-props.form';
import { FormCardLayout } from 'shared/ui/form/form-card-layout';
import { LeadQualityPickerField } from 'shared/ui/formik-fields/lead-quality-picker-field';
import { useLeadProps } from './lib';


type Props = {
  lead: LeadDto;
};

export const LeadPropsForm = ({ lead }: Props) => {
  const { showSuccess, showError } = useSnack();

  const { update, isLoading } = useLeadProps(lead.id);

  const handleSubmit = async (values: FormValues) => {
    const dto = toUpdateDto(lead.id, values);
    return update(dto)
      .then(() => showSuccess('Данные лида успешно обновлены'))
      .catch(e => showError(`Ошибка обновления данных лида ${e.message}`));
  };


  return (
    <Box sx={{ p: 1, pt: 2 }}>
      <Formik
        initialValues={fromDto(lead)}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formikProps: FormikProps<FormValues>) => (
          <FormCardLayout
            formikProps={formikProps}
            renderForm={() => (
              <>
                <Stack direction="column" spacing={2}>
                  <Field
                    component={LeadBudgetPickerField}
                    name='budgetId'
                  />

                  <Field
                    component={LeadQualityPickerField}
                    name='qualityId'
                  />

                </Stack>
              </>)}
          />)}
      </Formik >
    </Box>
  );
};