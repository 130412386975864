import { ReactNode } from 'react'
import { Box, Button, Paper, Stack } from '@mui/material'
import { Form, Formik, FormikErrors, FormikProps, FormikValues } from 'formik'

type FormCardProps<TFormValues extends FormikValues> = {
  renderForm: (props: FormikProps<TFormValues>) => ReactNode,
  initialValues: TFormValues;
  validate: (values: TFormValues) => FormikErrors<TFormValues>;
  onSubmit: (values: TFormValues) => Promise<void>
}

export function FormCard<TFormValues extends FormikValues>({ renderForm, initialValues, validate, onSubmit }: FormCardProps<TFormValues>) {
  return (
    <Paper sx={{ p: 2 }}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(formikProps: FormikProps<TFormValues>) => (
          <Form>
            <Stack direction="column" spacing={2}>
              <Box>
                {renderForm(formikProps)}
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  type='submit'
                  variant='contained'
                  disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                >
                  Сохранить
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Paper>)
}