import { Box, Button } from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { BulkCreateProgressDialog, BulkCreateList, wccBulkCreateActions } from 'features/wcc-bulk-create';
import { useActionCreators, useStateSelector } from 'shared/store';

export const BulkCreateTab = () => {

  const checkedItems = useStateSelector(state => state.wcc.bulkCreate.checkedItems);
  const { openDialog } = useActionCreators(wccBulkCreateActions);

  return (
    <Box mt={1}>
      <Button
        sx={{ mb: 0 }}
        variant="contained"
        startIcon={<PlaylistAddIcon />}
        onClick={() => openDialog()}
        disabled={checkedItems.length === 0}
      >
        создать
      </Button>

      <BulkCreateList />

      <BulkCreateProgressDialog
        onSuccess={() => {
          // reload ....
          // setPaging({ ...paging });
        }}
      />
    </Box >
  );
}

