import { FormikErrors } from 'formik';
import { isFile, PublishingDataDto, PublishingDataCreateFormDto, StringifiedBoolean, PublishingDataFormDto } from '@sr/dto';
import { validateSlug } from 'utils/slug-validation';
import { FormTools } from 'utils/form-tools';

export type PublishingDataFormData = {
  slug: string,
  shortDescription: string,
  fullDescription: string,
  benefits: string,
  specificConditions: string,
  smallImage: File | null | undefined,
  detailedImage: File | null | undefined
  isPublished: boolean,
  isSubdomain: boolean
}

const toCreateDto = (formData:PublishingDataFormData): PublishingDataCreateFormDto => ({
  ...formData,
  isPublished: formData.isPublished ? StringifiedBoolean.TRUE : StringifiedBoolean.FALSE,
  isSubdomain:formData.isSubdomain ? StringifiedBoolean.TRUE : StringifiedBoolean.FALSE,
  smallImage: formData.smallImage === null
    ? null
    : formData.smallImage && isFile(formData.smallImage)
      ? formData.smallImage
      : undefined,
  detailedImage: formData.detailedImage === null
    ? null
    : formData.detailedImage && isFile(formData.detailedImage)
      ? formData.detailedImage
      : undefined
});

export const formTools: FormTools<PublishingDataDto, PublishingDataCreateFormDto, PublishingDataFormDto, PublishingDataFormData> = {
  fromDto: (dto: PublishingDataDto): PublishingDataFormData => ({
    slug: dto.slug,
    shortDescription: dto.shortDescription,
    fullDescription: dto.fullDescription,
    benefits: dto.benefits,
    smallImage: dto.smallImage ?? null,
    detailedImage: dto.detailedImage ?? null,
    specificConditions: dto.specificConditions,
    isPublished: dto.isPublished,
    isSubdomain: dto.isSubdomain,
  }),
  toCreateDto,
  toUpdateDto: (id, formData): PublishingDataFormDto=>({
    ...toCreateDto(formData),
    id,
  }),
  validate: (values):FormikErrors<PublishingDataFormData>=>{
    let validationResult: FormikErrors<PublishingDataFormData> = {};
    const slugValidation = validateSlug(values.slug);
    slugValidation && (validationResult.slug = slugValidation);
    return validationResult;
  },
  emptyValues: (): PublishingDataFormData => ({
    slug: '',
    shortDescription: '',
    fullDescription: '',
    benefits: '',
    specificConditions: '',
    isPublished: false,
    isSubdomain: false,
    smallImage: null,
    detailedImage: null,
  })
};
