import { TextField } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import { FormikErrors, FormikProps } from 'formik';
import { FormDialog } from './form-dialog';
import { useEffect, useRef } from 'react';

type FormValues = {
  email: string;
}

const initialValues: FormValues = {
  email: ''
}

type DialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (email: string) => Promise<void>;
}

const validate = (values: FormValues): FormikErrors<FormValues> => {
  if (!values.email)
    return { email: 'Обязательное поле' };

  if (!isEmail(values.email))
    return { email: 'Неверный формат email' };

  return {};
};


export const EmailDialog = ({ open, onSubmit, onClose }: DialogProps) => {
  const handleSubmit = (values: FormValues) => onSubmit(values.email);

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      renderTitle="Добавление нового Email"
      renderForm={(props) => <FormInput {...props} />}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    />
  );
}

const FormInput = (props: FormikProps<FormValues>) => {
  const inputRef = useRef<HTMLElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <TextField
      fullWidth
      id="email"
      name="email"
      label="Email"
      inputRef={inputRef}
      value={props.values.email}
      onChange={props.handleChange}
      error={Boolean(props.errors.email)}
      helperText={props.errors.email}
    />)
}
