import { Box, Button } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { IContacts, ILinkedEmail, ILinkedPhone, ILinkedUrl } from "@sr/dto"
import { FieldProps } from "formik"
import { MouseEvent, useState } from "react"
import { AddContactMenu, ContactsList } from "shared/ui/contact-list"
import useAddContactsDialogs from "hooks/add-contacts-dialog.hook";
import { useContactsActions } from "./lib";
import { DeleteRequestsHandlers } from "shared/ui/contact-list/types";
import { useSnack } from "shared/ui/snack";
import { DupesDisplayOptions } from "entities/contact-dupes/types";

type Props = FieldProps<IContacts> & { dupesDisplayOptions?: DupesDisplayOptions };

export const ContactsListField = ({ field, form, dupesDisplayOptions }: Props) => {
  const { setFieldValue } = form;
  const { name, value } = field;

  const { showError } = useSnack();
  const { showEmailDialog, showPhoneDialog, showUrlDialog } = useAddContactsDialogs();

  const { addEmail, removeEmail, addPhone, removePhone, addUrl, removeUrl } = useContactsActions(value, (newValue) => setFieldValue(name, newValue));

  const deleteHandlers: DeleteRequestsHandlers = {
    onDeleteEmailRequest: removeEmail,
    onDeletePhoneRequest: removePhone,
    onDeleteUrlRequest: removeUrl,
  }

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const menuClickHandlers = {
    onAddEmailClick: () => showEmailDialog(async (newEmail) =>
      addEmail(newEmail)
        .then(() => console.log('ok'))
        .catch(e => {
          console.log('error');
          showError('Ошибка при поиске дублей:', e)
        })
        .catch(e => console.error(e))
    ),
    onAddPhoneClick: () => showPhoneDialog(async (newPhone) => addPhone(newPhone)),
    onAddUrlClick: () => showUrlDialog(async (newUrl) => addUrl(newUrl))
  }

  const handleAddContactClick = (el: MouseEvent<HTMLElement>) => {
    setMenuAnchor(el.currentTarget);
  }

  return (
    <>
      <ContactsList contactsList={value} promptDeleteHandlers={deleteHandlers} dupesDisplayOptions={dupesDisplayOptions} />

      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size='small'
          startIcon={<AddIcon />}
          onClick={handleAddContactClick}
        // disabled={isFetching}
        >
          Добавить контакт
        </Button>
      </Box>

      <AddContactMenu
        onClose={() => setMenuAnchor(null)}
        menuAnchor={menuAnchor}
        menuClickHandlers={menuClickHandlers} />
    </>
  )
}