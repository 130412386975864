import { Box, Divider, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { ReactElement, ReactNode, SyntheticEvent, useState } from 'react';
import { TabPanel } from './tab-panel';

export type TabInfo = {
  label: string;
  icon?: ReactElement;
  component: ReactNode;
  disabled?: boolean;
}


export const Tabs = ({ tabs }: { tabs: TabInfo[] }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <MuiTabs value={tabIndex} onChange={handleChange}>
        {tabs.map(({ label, icon, disabled }, i) => {
          return <MuiTab
            key={i}
            label={label}
            value={i}
            iconPosition="start"
            icon={icon}
            disabled={disabled}
          />;
        })}
      </MuiTabs>
      <Divider />
      <Box>
        {tabs.map(({ component }, idx) =>
          <TabPanel key={idx} value={tabIndex} index={idx}>{component}</TabPanel>
        )}
      </Box>
    </>
  )
}