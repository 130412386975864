import { MenuItem, TextField } from '@mui/material'
import { ImportedPayorderDto, PayOrderGroup, PO_GROUPS } from '@sr/dto'

export const GroupCellContent = ({ importedPayorder, onChange }:
  {
    importedPayorder: ImportedPayorderDto,
    onChange: (newValue: PayOrderGroup | null) => void
  }) => {

  return (
    <TextField
      select
      value={importedPayorder.importProps.selectedGroup ? Number(importedPayorder.importProps.selectedGroup) : ''}
      onChange={(e) => onChange(e.target.value === '' ? null : Number(e.target.value) as PayOrderGroup)}
      size="small"
    >
      {Object.keys(PO_GROUPS).map(key =>
        <MenuItem key={key} value={Number(key)}>
          {PO_GROUPS[Number(key) as PayOrderGroup]}
        </MenuItem>)}
    </TextField>
  )
}