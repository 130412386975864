export const BA_CASH = 1;
export const BA_NON_CASH = 2;
export const BA_VIRTUAL = 3;

export type BankAccountType = 1 | 2 | 3;

export const BA_TYPES: { [x in BankAccountType]: string } = {
  [BA_CASH]: 'Наличный',
  [BA_NON_CASH]: 'Безналичный',
  [BA_VIRTUAL]: 'Виртуальный',
}

