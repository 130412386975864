import { CompanyWithManagersDto, IPagedCollection, } from '@sr/dto';
import { CollectionParams } from '../../shared/api/rtk-query';
import { COMPANY_BASE_URL } from '../../shared/api/api';
import { pagingDataToParams } from '../../utils/remote-paged-collection.hook';
import { companiesApi } from '../../entities/company/api.slice';

export enum BackendSuffixes {
  SUPPLIERS_ALL = 'suppliers',
  CLIENTS_ALL = 'clients',
  MY_CLIENTS = 'my-clients',
  CLIENTS_FREE = 'free-clients',
  CLIENTS_ON_FIRED_MANAGERS = 'fired-clients',
  CLIENTS_TO_DETACH = 'detachment-clients',
  CLIENTS_WITH_LEADS = 'lids-clients',
  CLIENTS_FREE_WITH_INVOICES = 'checks-clients'
}

const injectedCompaniesListApi = companiesApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuppliersList: builder.query<IPagedCollection<CompanyWithManagersDto>, CollectionParams & { suffix: BackendSuffixes }>({
      query: ({ paging, filter, suffix }) => ({
        url: `${COMPANY_BASE_URL}/${suffix}?${filter}`,
        params: paging && pagingDataToParams(paging)
      }),
    }),
  }),
});

export const companiesListApi = injectedCompaniesListApi.enhanceEndpoints({
  addTagTypes: ['companies-list'],
  endpoints: {
    getSuppliersList: {
      providesTags: (result, error, query) => (
        result ?
          [{
            type: 'companies-list',
            // id: `${query.suffix}_${String(query.filter)}_${String(query.paging?.page)}_${String(query.paging?.itemsPerPage)}`,
            id: query.suffix
          }]
          : ['companies-list']
      ),
    },
  }
});

