import {
  AddContactQuery,
  ContactQuery,
  ContactTypes,
  EntitiesWithContacts
} from './contacts.types';

const contactRelationUrls = new Map<EntitiesWithContacts, string>([
  [EntitiesWithContacts.company, 'company'],
  [EntitiesWithContacts.person, 'person'],
  [EntitiesWithContacts.user, 'user'],
  [EntitiesWithContacts.lead, 'lead']
]);

const contactTypeUrls = new Map<ContactTypes, string>([
  [ContactTypes.email, 'emails'],
  [ContactTypes.phone, 'phones'],
  [ContactTypes.url, 'urls'],
]);

export const buildAddContactUrl = (addParams: AddContactQuery): string => {
  const relationUrl = contactRelationUrls.get(addParams.relationType);
  if (!relationUrl)
    throw Error('Contact relation is not known');
  const contactTypeUrl = contactTypeUrls.get(addParams.contactType);
  if (!contactTypeUrl)
    throw Error('Contact type is not known');
  return `${relationUrl}/${addParams.relationId}/${contactTypeUrl}`;
};

export const buildGetContactsUrl = (getParams: ContactQuery)=>{
  return `${contactRelationUrls.get(getParams.relationType)}/${getParams.relationId}/contacts`;
};
