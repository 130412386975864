import {Type} from "class-transformer";

export class LeadFilter {
    statusId?: number;
    budgetId?: number;
    companyId?: number
    qualityId?: number;
    managerId?: number;
    @Type()
    fromDate?: Date;
    @Type()
    toDate?: Date;
}