import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useAddMediaDialog } from './lib';

export const AddMediaButton = () => {
  const { openDialog } = useAddMediaDialog();
  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={openDialog}
    >
      Добавить
    </Button>)
}