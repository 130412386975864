import { useCallback } from 'react';
import { WccDto, UpdateWccDto } from '@sr/dto';
import { useRemoteItem } from 'utils/remote-item.hook';
import { plainToInstance } from 'class-transformer';
import { WCC_BASE_URL } from 'shared/api/api';
import { authorizedRequest } from 'shared/api/axios';

export const useWcc = (id: number) => {
  const wcc = useRemoteItem<WccDto, UpdateWccDto>({
    fetchHandler: useCallback(() => fetchWcc(id), [id]),
    updateHandler: putWcc
  });


  return wcc;
}



const fetchWcc = async (id: number) => {
  const result = await authorizedRequest<void, WccDto>(
    `${WCC_BASE_URL}/${id}`,
    'GET');
  // return WccDto.fromPlain(result);
  return result;
};

const putWcc = async (dto: UpdateWccDto) => {
  const result = await authorizedRequest<UpdateWccDto, WccDto>(
    `${WCC_BASE_URL}`,
    'PUT',
    dto
  );
  return plainToInstance(WccDto, result, { enableImplicitConversion: true });
};
