import { ReactNode } from 'react';
import { Link } from 'shared/ui/link-base';

import { contractLinkManager } from 'utils/link-manager';
import { ContractAppendixDto } from '@sr/dto/dist/src/contract-appendix.dto';


export const LinkToContractAppendix = (props: { appendix: ContractAppendixDto, children?: ReactNode }) => {
  const { appendix: { id, appendixNumber }, children } = props;
  const number = `№ ${appendixNumber}`;

  const link = contractLinkManager.appendixLink(id);

  if (children)
    return (
      <Link to={link} >
        {children}
      </Link>);

  return (
    <Link to={link} >
      Приложение {number}
    </Link>
  );
}