import { Navigate } from 'react-router-dom';
import { refreshThunk } from 'features/auth/auth-slice';
import { Container } from '@mui/material';
import { getRefreshToken } from 'shared/api/token';
import { useAppDispatch, useStateSelector } from 'shared/store';

type Props = {
  children: React.ReactNode,
  [x: string]: any;
};

const ProtectedRoute = (props: Props) => {
  const { children } = props;
  const { user, pendingRequest } = useStateSelector(store => store.auth);
  const dispatch = useAppDispatch();

  const refreshToken = getRefreshToken();

  if (!user) {
    if (!refreshToken) {
      return <Navigate to='/login/' />;
    }

    if (pendingRequest)
      return (
        <Container>
          Релогин...
        </Container>
      );

    dispatch(refreshThunk({ refreshToken }));
  }

  return <>{children} </>;
};

export default ProtectedRoute;
