import { Box, Button, DialogActions, Stack } from '@mui/material';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { Dialog } from 'shared/ui/dialog';
import { useBulkCreate } from 'features/wcc-bulk-create/lib';
import { useActionCreators, useStateSelector } from 'shared/store';
import { wccBulkCreateActions } from './slice';

type Props = {
  onSuccess: () => void;
};

export const BulkCreateProgressDialog = ({ onSuccess }: Props) => {
  const open = useStateSelector(state => state.wcc.bulkCreate.dialogOpen);
  const idList = useStateSelector(state => state.wcc.bulkCreate.checkedItems);
  const { closeDialog, uncheckAll } = useActionCreators(wccBulkCreateActions);

  const { create, report, loading, error, reset } = useBulkCreate();

  const handleClose = () => {
    reset();
    closeDialog();
  }

  const handleCreate = () => create(idList).then(() => {
    uncheckAll();
    onSuccess();
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
    >
      <Dialog.Title>
        Создание актов
        {/* <Dialog.CloseButton onClick={() => props.onClose?.({}, 'escapeKeyDown')} /> */}
      </Dialog.Title>

      <Dialog.Content>
        {loading && <LoadingBanner />}

        {report
          ?
          <Box>
            <LegendList>
              <LegendItem title="Успешно создано актов:" value={report.createdNumber} />
            </LegendList>
          </Box>
          :
          error
            ? <ErrorBanner errorMessage='error' />
            :
            <Box>
              <Box>
                {`Создать акты для ${idList.length} счетов?`}
              </Box>
            </Box>
        }
      </Dialog.Content>

      <DialogActions sx={{ flex: 1, flexDirection: 'row' }}>
        <Stack direction="row" justifyContent="flex-start" spacing={1}>
          <Button
            variant="contained"
            disabled={loading || !!report}
            onClick={handleCreate}
          >
            создать
          </Button>

          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleClose}
          >
            закрыть
          </Button>
        </Stack>
      </DialogActions>

    </Dialog >);
};
