import { UpdateUserProfileBasicDto, UserDto } from '@sr/dto';
import { USERS_BASE_URL } from 'shared/api/api';
import { fetchReq, putReq } from 'shared/api/axios';

export const fetchUser = async (id: number) => {
  return fetchReq<UserDto>(
    `${USERS_BASE_URL}/${id}`
  );
};

export const putUser = async (data: UpdateUserProfileBasicDto) => {
  return putReq<UpdateUserProfileBasicDto, UserDto>(
    USERS_BASE_URL,
    data
  );
};