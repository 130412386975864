import { Alert, AlertTitle, Box, Button, DialogActions, DialogProps, DialogTitle, Divider } from '@mui/material';
import { CreatePayOrderImportSessionDto, PayorderParseReportDto } from '@sr/dto';
import { SingleFilePicker } from 'shared/ui/formik-fields/file-picker';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { Dialog } from 'shared/ui/dialog';
import { useCallback, useState } from 'react';
import { toFormData } from 'shared/api/axios';
import { useImport } from './uploadImportFile.hook';

type Props = {
  dialogProps: DialogProps,
  onUploadComplete: () => void;
};

export const NewImportDialog = ({ dialogProps, onUploadComplete }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [importReport, setImportReport] = useState<PayorderParseReportDto | null>(null);
  const [importError, setImportError] = useState('');
  const { uploadAndImport, loading: importInProgress } = useImport();

  const handleChangeFile = (file: File | null) => {
    setFile(file);
  };

  const handleImport = useCallback(async () => {
    if (!file) return;

    setImportError('');
    setImportReport(null);

    const data: CreatePayOrderImportSessionDto = {
      importName: 'без названия',
      file: file
    };

    return uploadAndImport(toFormData(data))
      .then(res => {
        setImportReport(res);
        onUploadComplete();
      })
      .catch(e => setImportError(e.message));
  }, [file, uploadAndImport]);


  const cleanUpAndClose = () => {
    setImportError('');
    setImportReport(null);
    setFile(null);
    dialogProps.onClose?.({}, 'escapeKeyDown');
  };

  return (
    <Dialog
      {...dialogProps}
      maxWidth='lg'
    >
      <DialogTitle>
        Новый импорт
        <Dialog.CloseButton onClick={cleanUpAndClose} />
      </DialogTitle>

      <Dialog.Content>
        <SingleFilePicker
          value={file}
          onChange={handleChangeFile}
          label='Файл'
          disabled={importInProgress || importReport != null || importError.length !== 0} />
        <Divider />
        <Box>
          {importInProgress && <LoadingBanner />}
          {importReport && <ImportResult report={importReport} />}
          {importError && <ImportError error={importError} />}
        </Box>
      </Dialog.Content>

      <DialogActions>
        <Button
          variant="contained"
          onClick={handleImport}
          disabled={importInProgress || !file || importReport != null || importError.length !== 0}
        >
          Загрузить
        </Button>
      </DialogActions>

    </Dialog >);
};


export const useNewImportDialog = ({ onUploadComplete }: { onUploadComplete: () => void; }) => {
  const [open, setOpen] = useState(false);

  const openNewImportDialog = () => {
    setOpen(true);
  };

  const props: Props = {
    dialogProps: {
      open,
      maxWidth: 'md',
      onClose: (e: any, reason: string) => {
        if (reason !== 'backdropClick')
          setOpen(false);
      },
    },
    onUploadComplete: onUploadComplete
  };

  return {
    openNewImportDialog,
    props: props
  };
};

function ImportResult({ report }: { report: PayorderParseReportDto; }) {
  return (
    <>
      <Alert color='success'>
        <AlertTitle>Успешно</AlertTitle>
        <LegendList>
          <LegendItem title='Всего платежных поручений:' value={report.totalSectionsCount} />
          <LegendItem title='Успешно распознанных:' value={report.successfulParsedSectionsCount} />
          <LegendItem title='Неуспешно распознанных:' value={report.failedParsedSectionsCount} />
        </LegendList>
      </Alert>
    </>
  );
}

function ImportError({ error }: { error: string; }) {
  return (
    <>
      <Alert color='error'>
        <AlertTitle>Ошибка</AlertTitle>
        {error}
      </Alert>
    </>
  );
}