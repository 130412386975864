import { Box, Button, Divider, IconButton, Popover, Stack, Typography } from '@mui/material';
import { ContactDupesDto } from '@sr/dto';
import { ReactNode } from 'react';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { formatPerson } from 'utils/person-format-helpers';
import { DupesDisplayOptions } from './types';
import { CompanyAnnounce } from 'entities/company/announce/company-announce';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { extractCompaniesFromDupes, useNavigateToCombinePage } from 'features/company/combine/use-navigate-to-combine';


type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void,
  dupes: ContactDupesDto,
  options?: DupesDisplayOptions
}

export const DupesPopover = ({ anchorEl, dupes, open, onClose, options }: Props) => {
  const companiesToCombine = extractCompaniesFromDupes(dupes, options);
  const navigateToCombinePage = useNavigateToCombinePage(companiesToCombine);

  return (
    <Popover
      // sx={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={onClose}
    // disableRestoreFocus
    >
      <Box p={2}>
        <Stack divider={<Divider />}>
          <DupesSection
            title="Компании"
            list={dupes.dupesCompanies}
            renderItem={(item) => (
              <Box component="div" key={item.id} sx={{ mb: 1 }}>
                <CompanyAnnounce company={item} />
              </Box>)}
          />

          <DupesSection
            title="Сотрудники"
            list={dupes.dupesEmployees}
            renderItem={(item) => (
              <Box key={item.id} display="flex" alignContent="center">
                <AccountBoxIcon color='disabled' sx={{ mr: 1 }} />
                <Typography component="span" variant="body1" >{formatPerson(item)}</Typography>
                &nbsp;(<LinkToCompany company={item.companies[0]} />)
              </Box>)}
          />

          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button variant='outlined' size="small" color='warning' startIcon={<JoinFullIcon />} onClick={navigateToCombinePage}>
              объединить
            </Button>
          </Box>
        </Stack>
      </Box >
    </Popover >
  );
};

function DupesSection<T>({ title, list, renderItem }: { title: string, list: T[], renderItem: (item: T) => ReactNode }) {
  if (list.length === 0) return null;

  return (
    <Box mb={2}>
      <Typography component="p" variant="subtitle1" sx={{ backgroundColor: 'grey.200', pl: 1 }}>{title}: </Typography>
      {list.map(renderItem)}
    </Box>
  );
}