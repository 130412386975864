import { Field, FieldProps } from 'formik';
import { COMPANY_TYPE_INFOS, CompanyTypesEnum } from '@sr/dto';
import { FormControl, FormLabel, FormGroup, FormHelperText } from '@mui/material';
import { CheckboxWithLabel } from 'formik-mui';

export const CompanyTypesCheckboxes = ({ form, field, meta }: FieldProps) => {
  const error = form.errors[field.name] as string;

  return (
    <FormControl component="fieldset" >
      <FormLabel component="legend">Тип</FormLabel>
      <FormGroup >
        {Object.keys(COMPANY_TYPE_INFOS).map(key =>
          <Field
            name="companyType"
            type="checkbox"
            key={key}
            value={COMPANY_TYPE_INFOS[key as CompanyTypesEnum].value.toString()}
            component={CheckboxWithLabel}
            Label={{ label: COMPANY_TYPE_INFOS[key as CompanyTypesEnum].label }}
          ></Field>)
        }
      </FormGroup>
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormControl>
  );
};
