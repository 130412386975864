import { Stack } from '@mui/material';
import { Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { RegionPicker } from 'shared/ui/formik-fields/region-picker';


export const CityBasicDataFormFields = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Field
        name="name"
        label="Название"
        component={TextField}
      />
      <Field
        component={RegionPicker}
        name="regionId"
        label="Регион"
      />

      <Field
        name="timeZone"
        label="Часовой пояс"
        component={TextField}
      />
      <Field
        name="phoneCode"
        label="Телефонный код"
        component={TextField}
      />
      <Field
        name="isCapital"
        type="checkbox"
        component={CheckboxWithLabel}
        Label={{ label: 'Столица региона' }}
      />
    </Stack >
  );
};