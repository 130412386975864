import { leadStatusApi } from './api';

export const useUpdateLeadStatus = () => {
  const [update, { isLoading }] = leadStatusApi.useUpdateLeadStatusMutation();

  return {
    update,
    isUpdating: isLoading,
  };
};
