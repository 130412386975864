import { CreateCityDto } from '@sr/dto';
import { FormikErrors } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

export interface FormValues {
  name: string;
  regionId: number | '';
}

export const toCreateDto = (formData: FormValues): CreateCityDto => ({
  name: formData.name,
  regionId: Number(formData.regionId),
})


export const initialValues: FormValues = {
  name: '',
  regionId: '',
}

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.name) errors.name = VALIDATION_REQUIRED_FIELD;

  if (!values.regionId) errors.regionId = VALIDATION_REQUIRED_FIELD;

  return errors;
}
