import { DatePickerProps } from 'formik-mui-x-date-pickers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { useEffect } from 'react';
import { isAfter } from 'date-fns';
import { FormControl } from '@mui/material';

export const DatePickerField = (props: DatePickerProps) => {
  const {
    field: { name, value },
    form: { setFieldValue, errors, touched },
    label,
    disabled,
    clearable = false,
    size = undefined,
    minDate = undefined,
  } = props;

  useEffect(() => {
    if (minDate && value && isAfter(minDate, value)) {
      setFieldValue(name, minDate);
    }
  }, [minDate, value, setFieldValue, name]);

  const isTouched = touched[name];
  const error = errors[name];
  const errorText = isTouched
    ? typeof error === 'string'
      ? error
      : undefined
    : undefined;

  const handleChange = async (newValue: any) => {
    await setFieldValue(name, newValue);
  };

  const handleClear = async ()=>{
    await setFieldValue(name, null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <FormControl fullWidth>
        <DatePicker
          minDate={minDate}
          label={label}
          value={value}
          onChange={handleChange}
          // format="dd.MM.yyyy"
          slotProps={{
            textField: {
              size,
              helperText: errorText,
              error: !!error
            },
            field: { clearable, onClear: handleClear }
          }}
          disabled={disabled}
          // mask="__.__.____"
        />
      </FormControl>
    </LocalizationProvider>
  );
};

