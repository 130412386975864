import { COMPANY_TYPE_INFOS, CompanyTypesEnum, CompanyTypesList } from '@sr/dto';

type TypeInfo = {
  value: number;
  label: string;
};

export const getCompanyTypeArray = (companyType: number): TypeInfo[] => {
  let result: TypeInfo[] = [];

  for (const key of CompanyTypesList) {
    const { value, label } = COMPANY_TYPE_INFOS[key];
    if ((companyType & value) === value) result.push({ label, value });
  }
  return result;
};

export const typeFromCheckBoxes = (checkboxes: string[]) => checkboxes.reduce((acc, x) => acc + Number(x), 0);
