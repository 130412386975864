import { DownloadFileDto, PurchaseDto, PURCHASE_STATUSES } from '@sr/dto';
import { UpdatePurchaseDto } from 'shared/api/purchases';

export type FormData = {
  paymentAmount: number | '',
  paymentDate: Date | '',
  status: number | '',

  // File - прикреплен новый файл для загрузки
  // DownloadFileDto - только при редактировании полученного с сервера объекта. 
  //          для отображения имеющегося на сервере файла
  // null - удалить файл
  // undefined - начальное состояние формы, когда объект новый и файл не выбран
  invoiceScan?: File | DownloadFileDto | null,
  bankDetailsScan?: File | DownloadFileDto | null,
  wccScan?: File | DownloadFileDto | null,
};


export const baseEmptyValues: FormData = {
  paymentAmount: '',
  paymentDate: new Date(),
  status: PURCHASE_STATUSES[0].id,
};

type ValidationData = Partial<{ [x in keyof FormData]: string }>;

export const validate = (values: FormData): ValidationData => {
  let validationResult: ValidationData = {};

  return validationResult;
};

export const toDto = (purchaseId: number, formData: FormData) => {

  const fromFormDataField = (value: File | DownloadFileDto | null | undefined): File | null | undefined => {
    // File - прикрепить новый файл
    // null - удалить прикрепленный файл
    // undefined - ничего не делать

    if (value instanceof DownloadFileDto)
      return undefined;

    return value;
  };

  const dto: UpdatePurchaseDto =
  {
    id: Number(purchaseId),
    statusId: Number(formData.status),
    paymentAmount: Number(formData.paymentAmount),
    paymentDate: new Date(formData.paymentDate),
    invoiceScan: fromFormDataField(formData.invoiceScan),
    bankDetailsScan: fromFormDataField(formData.bankDetailsScan),
    wccScan: fromFormDataField(formData.wccScan),
  };

  return dto;
};

export function fromDto(dto: PurchaseDto): FormData {
  const formData: FormData = {
    paymentAmount: dto.paymentAmount,
    paymentDate: dto.paymentDate,
    status: dto.status.id,
    invoiceScan: dto.invoiceScan,
    bankDetailsScan: dto.bankDetailsScan,
    wccScan: dto.wccScan,
  };
  return formData;
}