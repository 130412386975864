import { Stack } from '@mui/material';
import { Field } from 'formik';
import { ContractPicker } from 'entities/contract/contract-picker';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { FormData, formTools } from './client-invoice.form-tools';
import { UserPickerField } from 'features/user/user-picker/ui';
import { FormDialog } from 'shared/ui/form-dialog';
import { ContractInfoDto } from '@sr/dto';
import { EntityRoles } from 'entities/invoice/types';
import { useStateSelector } from 'shared/store';


type Props = {
    open: boolean;
    clientCompanyId: number;
    ourCompanyId: number;
    onSubmit: (data: FormData) => Promise<void>;
    onClose: () => void;
};

export const AddClientInvoiceDialog = (props: Props) => {
  const {
    open,
    onSubmit,
    onClose,
    clientCompanyId,
    ourCompanyId
  } = props;
  const currentUser = useStateSelector(store => store.auth.user);

  return (
    <FormDialog
      renderForm={(form) => <FormFields form={form} clientCompanyId={clientCompanyId} ourCompanyId={ourCompanyId}/>}
      renderTitle="Новый счет"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      validate={formTools.validate}
      initialValues={{ ...formTools.emptyValues(), managerId: currentUser?.id ?? null }}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      validateOnChange={true}
    />
  );
};

const FormFields = ({ clientCompanyId, ourCompanyId, form }: { clientCompanyId: number, ourCompanyId: number; form: any; }) => {
  const { setFieldValue } = form;
  const handleContractChange = (newContract: ContractInfoDto)=> {
    if (!newContract?.buyer || !newContract?.seller) return;
    setFieldValue('buyerLegalEntityId', newContract.buyer.id);
    setFieldValue('sellerLegalEntityId', newContract.seller.id);
  };

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
      <Stack direction="row" spacing={2}>
        <Field
          name='startDate'
          fullWidth
          label='Дата выставления'
          component={DatePickerField}
          required
        />
        <Field
          name='endDate'
          fullWidth
          label='Дата окончания'
          component={DatePickerField}
          required
        />
      </Stack>
      <Field
        name='contractId'
        label='Договор'
        component={ContractPicker}
        clientCompanyId={clientCompanyId}
        onChange={handleContractChange}
        required
      />
      <Field
        disabled
        label='Наше юрлицо'
        component={LegalEntityPicker}
        name='sellerLegalEntityId'
        companyIdFilter={ourCompanyId}
        entityRole={EntityRoles.INVOICE_ISSUER}
        required
      />
      <Field
        disabled
        label='Юрлицо клиента'
        component={LegalEntityPicker}
        name='buyerLegalEntityId'
        companyIdFilter={clientCompanyId}
        required
      />
      <Field
        isLinkedToCompany
        includeUser
        name='managerId'
        label='Менеджер'
        component={UserPickerField}
        required
      />
    </Stack>
  );
};
