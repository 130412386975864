import { emptyPagedCollection, IPagingData, MediaInfoDto } from '@sr/dto';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { mediaListApi } from './api-slice';


export const useMediaList = (pagination: IPagingData, filter?: URLSearchParams,) => {

  const { data, isLoading, error } = mediaListApi.useGetMediaListQuery(
    { paging: pagination, filter: filter?.toString() || '' },
    { refetchOnMountOrArgChange: true });

  const result: IRemotePagedCollection<MediaInfoDto> = {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };

  return result;

  // return useReadOnlyRemotePagedCollection<MediaInfoDto>({
  //   fetchHandler: useCallback(async () => {
  //     const params = new URLSearchParams(filter);

  //     const response = await fetchPagedReq<void, MediaInfoDto>(
  //       `${MEDIA_BASE_URL}?${params.toString()}`,
  //       pagination,
  //       MediaInfoDto
  //     );
  //     return response;
  //   }, [filter, pagination]),
  // }
  // )
};
