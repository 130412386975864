import { UpdateInvoiceResource, InvoiceBasicResource } from '@sr/dto';
import { authorizedRequest } from 'shared/api/axios';
import { INVOICES_BASE_URL } from 'shared/api/api';

export const fetchInvoiceBasics = async (invoiceId: number) => {
  const response = await authorizedRequest<void, InvoiceBasicResource>(
    `${INVOICES_BASE_URL}/basic/${invoiceId}`,
    'GET');
  return response;
};

export const putInvoiceBasics = async (invoice: UpdateInvoiceResource) => {
  const response = await authorizedRequest<UpdateInvoiceResource, InvoiceBasicResource>(
    `${INVOICES_BASE_URL}/basic`,
    'PUT',
    invoice
  );
  return response;
};

