import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { MediaListFilter } from 'features/media/media-filter';
import { usePagingParams } from 'shared/hooks/paging-params';
import { SentMediaPlans } from 'features/media-plans/sent-media-plans/ui';
import { useLeadMediaPlans } from 'features/lead/lead-mediaplan/lead-mediaplans.hook';

export const LeadMediaPlansTab = () => {
  const [paging, setPaging] = usePagingParams();
  const [searchParams] = useSearchParams();

  const collection = useLeadMediaPlans(paging, searchParams);

  return (
    <Box mt={2}>
      <Box mb={1}>
        <MediaListFilter useTitle={false}/>
      </Box>

      <SentMediaPlans
        collection={collection}
        paging={paging}
        onChangePage={setPaging}
      />
    </Box>

  );
};
