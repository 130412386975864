import { FormikErrors } from 'formik';
import {
  isFile,
  StringifiedBoolean,
  LegalEntityOurDataDto,
  LegalEntityOurDataMultipartFormCreateDto, LegalEntityOurDataMultipartFormUpdateDto
} from '@sr/dto';
import { FormTools } from 'utils/form-tools';

export type LegalEntityOurDataFormikFormData = {
  isClientInvoicesEnabled: boolean;
  isSupplierInvoicesEnabled: boolean;
  invoicePrefix: string,
  stamp: File | null | undefined,
}

const toCreateDto = (formData:LegalEntityOurDataFormikFormData): LegalEntityOurDataMultipartFormCreateDto => ({
  isClientInvoicesEnabled: formData.isClientInvoicesEnabled ? StringifiedBoolean.TRUE: StringifiedBoolean.FALSE,
  isSupplierInvoicesEnabled: formData.isSupplierInvoicesEnabled ? StringifiedBoolean.TRUE: StringifiedBoolean.FALSE,
  invoicePrefix: formData.invoicePrefix,
  stamp: formData.stamp === null
    ? null
    : formData.stamp && isFile(formData.stamp)
      ? formData.stamp
      : undefined
});

export const formTools: FormTools<LegalEntityOurDataDto, LegalEntityOurDataMultipartFormCreateDto, LegalEntityOurDataMultipartFormUpdateDto, LegalEntityOurDataFormikFormData> = {
  fromDto: (dto: LegalEntityOurDataDto): LegalEntityOurDataFormikFormData => ({
    isClientInvoicesEnabled: dto.isClientInvoicesEnabled,
    isSupplierInvoicesEnabled: dto.isSupplierInvoicesEnabled,
    invoicePrefix: dto.invoicePrefix,
    stamp: dto.stamp ?? null,
  }),
  toCreateDto,
  toUpdateDto: (id, formData) => ({
    ...toCreateDto(formData),
    id,
  }),
  validate: (values):FormikErrors<LegalEntityOurDataFormikFormData>=>{
    let validationResult: FormikErrors<LegalEntityOurDataFormikFormData> = {};
    if (values.invoicePrefix.length > 8) {
      validationResult.invoicePrefix = 'Максимум 8 символов';
    }
    if (values.invoicePrefix.length < 1) {
      validationResult.invoicePrefix = 'Префикс не может быть пустым';
    }
    return validationResult;
  },
  emptyValues: (): LegalEntityOurDataFormikFormData => ({
    isClientInvoicesEnabled: true,
    isSupplierInvoicesEnabled: true,
    invoicePrefix: '',
    stamp: null,
  })
};
