import { SelectChangeEvent, Stack, Box } from '@mui/material';
import { ImportedPayorderDto, CashflowDto } from '@sr/dto';
import { CashflowDirectionListIcon } from 'shared/ui/cashflow-chip';
import { GenericSelectPicker } from 'shared/ui/form-fields/generic-select-picker';
import { IRemoteCollection } from 'utils/remote-collection.hook';


export const CashflowCellContent = ({ importedPayorder, cashflowsDropDown, onChange }:
  {
    importedPayorder: ImportedPayorderDto,
    cashflowsDropDown: IRemoteCollection<CashflowDto>,
    onChange: (newValue: number | null) => void;
  }) => {

  return (
    <GenericSelectPicker
      value={importedPayorder.importProps.selectedCashflowId || ''}
      onChange={(e: SelectChangeEvent) => onChange(e.target.value === '' ? null : Number(e.target.value))}
      textFieldProps={{ size: 'small' }}
      collection={cashflowsDropDown}
      isActive={(item) => !item.isDeleted}
      menuItemRender={cashflow => (
        <Stack direction="row" >
          <CashflowDirectionListIcon direction={cashflow.direction} />
          <Box>
            {cashflow.title}
          </Box>
        </Stack>)}
      selectedItemRender={cashflow => (
        <Stack direction="row" >
          <CashflowDirectionListIcon direction={cashflow.direction} />
          <Box>
            {cashflow.title}
          </Box>
        </Stack>)}
    />
  );
};