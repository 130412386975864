import { ReactNode } from 'react';
import { ContractDto, ContractInfoDto } from '@sr/dto';
import { Link } from 'shared/ui/link-base';
import { contractLinkManager } from 'utils/link-manager';

export const LinkToContract = (props: { contract: ContractDto | ContractInfoDto, children?: ReactNode }) => {
  const { contract: { id, name }, children } = props;

  const link = contractLinkManager.link(id);

  if (children)
    return (
      <Link to={link} >
        {children}
      </Link>);

  return (
    <Link to={link} >
      договор №{name}
    </Link>
  );
}