import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

export const LinkManagerButton = ({ onClick }: { onClick: () => void; }) => {
  return (
    <Button
      onClick={onClick}
      // sx={{ ml: 'auto' }}
      size='small'
      startIcon={<AddIcon />}>
      Добавить
    </Button>);
};