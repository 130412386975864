import { TextField, Autocomplete } from '@mui/material';
import { ContractTemplateInfoDto } from '@sr/dto';
import { useContractsTemplatesDropDown } from 'entities/contract-template/contract-templates.hook';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

export const ContractTemplatePickerField = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props;

  const [open, setOpen] = useState(false);
  const collection = useContractsTemplatesDropDown();
  const { items, isLoading: fetching } = collection;
  const [selectedEntity, setSelectedEntity] = useState<ContractTemplateInfoDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: ContractTemplateInfoDto | null) => {
      setSelectedEntity(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name, setSelectedEntity]
  );

  useEffect(() => {
    setSelectedEntity(items.find(x => x.id === value) ?? null);
  }, [items, value]);


  const loading = open && items.length === 0 && fetching;

  return (
    <Autocomplete
      value={selectedEntity}
      autoComplete={false}
      options={items}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};


