import { Link } from 'shared/ui/link-base';
import { CityInfoDto } from '@sr/dto';
import { geoLinkManager } from 'utils/link-manager';

export const LinkToCity = ({ city: { id, name } }: { city: CityInfoDto }) => {

  const link = geoLinkManager.linkToCity(id);
  return (
    <Link to={link} >
      {name}
    </Link>
  );
}