import {
  CreateRoleDto, RoleDto,
  UpdateRoleDto,
} from '@sr/dto';
import { userRolesApi } from '.';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';

export const useUserRolesList = ()=> {
  const { data: userRoles, isLoading, error } = userRolesApi.useGetUserRolesListQuery({});
  return  {
    collection: userRoles ?? [],
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading
  };
};

export const useUserRole = (id: number) => {
  const { data, isLoading, error } = userRolesApi.useGetUserRoleQuery(id);
  return {
    item: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};

export const useDeleteUserRole: (()=>(userRole: RoleDto) => Promise<void>) = () => {
  const showConfirmDialog = useConfirmDialog();
  const [deleteUserRole] = userRolesApi.useDeleteUserRoleMutation();
  return async (userRole: RoleDto): Promise<void> => {
    return showConfirmDialog({
      content: `Удалить роль: ${userRole.title}?`,
    })
      .then(() => deleteUserRole(userRole.id).unwrap());
  };
};

export const useUpdateUserRole = () => {
  const [update] = userRolesApi.useUpdateUserRoleMutation();
  return async (data: UpdateRoleDto) => {
    return update(data).unwrap();
  };
};

export const useAddUserRole = () => {
  const [addUserRole] = userRolesApi.useAddUserRoleMutation();
  return async (userRole: CreateRoleDto) => {
    return addUserRole(userRole).unwrap();
  };
};
