import { CreateMediaDto } from '@sr/dto';
import { mediaApi } from 'entities/media/api-slice';
import { MEDIA_BASE_URL } from 'shared/api/api';


const injectedApi = mediaApi.injectEndpoints({
  endpoints: (builder) => ({
    addMedia: builder.mutation<void, CreateMediaDto>({
      query: (dto: CreateMediaDto) => ({
        url: MEDIA_BASE_URL,
        body: dto,
        method: 'POST'
      }),
    })
  }),
  overrideExisting: true
});

export const mediaCreateApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['*'],
  endpoints: {
    addMedia: {
      invalidatesTags: ['*']
    }
  }
})