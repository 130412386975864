import { UserActiveStatusDto } from '@sr/dto';
import { userApi } from 'entities/user/api';


const injectedApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserActiveStatus: builder.query<UserActiveStatusDto, number>({
      query: (userId: number) => `users/${userId}/active-status`,
    }),
    updateUserActiveStatus: builder.mutation<UserActiveStatusDto, { userId: number, data: UserActiveStatusDto }>({
      query: ({ userId, data }) => ({
        url: `users/${userId}/active-status`,
        body: data,
        method: 'PUT'
      }),
    }),
  }),
});

export const userActiveStatusApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['userActiveStatus', 'userList'],
  endpoints: {
    getUserActiveStatus: {
      providesTags: (result, _, userId) => (
        result ? [{ type: 'userActiveStatus', id: userId }] : ['userActiveStatus']
      ),
    },
    updateUserActiveStatus: {
      invalidatesTags: (res, error, query) => (
        res && !error ? [{ type: 'userActiveStatus', id: query.userId }, 'userList'] : ['userActiveStatus']
      ),
    },
  },
});
