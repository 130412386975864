import { useEffect, useState } from 'react';
import { useSnack } from 'shared/ui/snack';
import { companyQueryTypeToBackendSuffix, useCompanies } from 'hooks/companies.hook';
import { CompanyQueryType, IPagingData } from '@sr/dto';
import { useSearchParams } from 'react-router-dom';
import { usePagingParams } from 'shared/hooks/paging-params';
import { CompaniesList } from './companies-list';
import Box from '@mui/material/Box';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { fetchClientsIds, unlinkManagers } from 'entities/company/details/api';
import { useDispatch } from 'react-redux';
import { companiesListApi } from './company-list.api';

export enum ManagersDetachmentVariant {
  ALL = 'all',
  PAGE = 'page',
  REMOVE_ALL = 'remove-all',
  REMOVE_PAGE = 'remove-page',
  CUSTOM = 'custom'
}

export const ClientsListTab = ({ queryType }: { queryType: CompanyQueryType }) => {
  useEffect(() => {
    setSelectedCompaniesIds([]);
  }, [queryType]);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [paging, setPaging] = usePagingParams();
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<number[]>([]);
  const shouldShowDetachment = queryType === CompanyQueryType.CLIENTS_TO_DETACH || queryType === CompanyQueryType.CLIENTS_ON_FIRED_MANAGERS;

  const [managersDetachmentVariant, setManagersDetachmentVariant] = useState<ManagersDetachmentVariant>(ManagersDetachmentVariant.CUSTOM);

  const collection = useCompanies(paging, queryType, searchParams);

  const { showError } = useSnack();

  const onCheckboxChange = (id: number) => {
    setManagersDetachmentVariant(ManagersDetachmentVariant.CUSTOM);
    setSelectedCompaniesIds(selectedCompaniesIds.includes(id) ?
      selectedCompaniesIds.filter((companyId) => companyId !== id) :
      [...selectedCompaniesIds, id]);
  };

  const handleDetachmentVariantChange = async (value: ManagersDetachmentVariant) => {
    setManagersDetachmentVariant(value);

    switch (value) {
    case ManagersDetachmentVariant.ALL:
      setSelectedCompaniesIds(await fetchClientsIds(queryType));
      break;
    case ManagersDetachmentVariant.PAGE:
      setSelectedCompaniesIds(collection.collection.items.map((company) => company.id));
      break;
    case ManagersDetachmentVariant.REMOVE_PAGE:
      setSelectedCompaniesIds(
        selectedCompaniesIds
          .filter((id) => {
            return !collection.collection.items.map((company) => company.id).includes(id);
          })
      );

      break;
    case ManagersDetachmentVariant.REMOVE_ALL:
      setSelectedCompaniesIds([]);
    }
  };

  const handleDetachBtnClick = () => {
    unlinkManagers(selectedCompaniesIds).then(() => {
      setManagersDetachmentVariant(ManagersDetachmentVariant.CUSTOM);
      setSelectedCompaniesIds([]);
      if (companyQueryTypeToBackendSuffix.get(queryType))
        dispatch(companiesListApi.util.invalidateTags([{ type: 'companies-list', id: companyQueryTypeToBackendSuffix.get(queryType) }]));
    })
      .catch(e => showError('Не удалось открепить менеджеров'));
  };

  return (
    <>
      {
        shouldShowDetachment
          ?
          <Box gap={2} my={2} display={'flex'} alignItems={'center'}>
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel>Открепление менеджеров</InputLabel>
              <Select
                placeholder='Выделить строки'
                value={managersDetachmentVariant}
                label="Открепление менеджеров"
                size='small'
                onChange={(evt) => handleDetachmentVariantChange(evt.target.value as ManagersDetachmentVariant)}
              >
                <MenuItem value={ManagersDetachmentVariant.ALL}>Выбрать все</MenuItem>
                <MenuItem value={ManagersDetachmentVariant.REMOVE_ALL}>Снять все</MenuItem>
                <MenuItem value={ManagersDetachmentVariant.PAGE}>Выбрать на этой странице</MenuItem>
                <MenuItem value={ManagersDetachmentVariant.REMOVE_PAGE}>Снять на этой странице</MenuItem>
                <MenuItem value={ManagersDetachmentVariant.CUSTOM}>Свободное выделение</MenuItem>
              </Select>
            </FormControl>
            <Button color={'warning'} variant="contained"
              onClick={handleDetachBtnClick}
              type='submit'
              autoFocus
              disabled={!selectedCompaniesIds.length}>
                  Открепить выбранные
            </Button>
          </Box>
          : null
      }
      <CompaniesList
        companiesCollection={collection}
        paging={paging}
        onChangePaging={(pagingData: IPagingData) => setPaging(pagingData)}
        type={queryType}
        selectedCompaniesIds={selectedCompaniesIds}
        onCheckboxChange={onCheckboxChange}
      />
    </>
  );
};
