import { Typography } from '@mui/material';
import { parsePhoneNumber } from 'libphonenumber-js';

const PhoneNumber = ({ number }: { number: string }) => {
  const phone = parsePhoneNumber(number);
  const parts = phone.format('NATIONAL').split(' ');
  if (parts.length !== 3)
    return (
      <Typography color="text.primary" component='span'>
        {number}
      </Typography>
    )

  return (
    <>
      <Typography color="text.secondary" component='span'>
        {parts[0]} {parts[1] || ''}
      </Typography>
      <Typography color="text.primary" component='span'>
        {` ${parts[2] || ''}`}
      </Typography>
    </>
  );
};

export default PhoneNumber;
