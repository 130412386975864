import { ReactNode, useId } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IRemoteCollection } from 'utils/remote-collection.hook';
import { Identifiable } from '@sr/dto';


export type Config<TItem> = {
  collectionProvider: () => IRemoteCollection<TItem>;
  renderDropdownLabel: (item: TItem) => ReactNode;
  renderEmptyValue?: ReactNode;
};

export type Props<TValue> = {
  value: TValue | '';
  onChange: (value: TValue | string) => void;
  label: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium' | undefined;
  backgroundColor?: string;
};

export function withRemoteCollectionDropDownPicker<TItem extends Identifiable, TValue = any>(config: Config<TItem>) {

  return ({ label, value, onChange, disabled, error, helperText, size = undefined, backgroundColor = 'white' }: Props<TValue>) => {
    const { collectionProvider, renderDropdownLabel, renderEmptyValue } = config;
    const { items, isLoading: fetching } = collectionProvider();

    const handleChange = (event: SelectChangeEvent<'' | TValue>) => {
      onChange(event.target.value);
    };

    const id = useId();
    const loading = items.length === 0 || fetching;

    return (
      <FormControl error={error} fullWidth size={size}>
        <InputLabel id={`select-label-${id}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${id}`}
          id={`select-${id}`}
          label={label}
          value={loading ? '' : value}
          onChange={handleChange}
          disabled={disabled || loading}
          size={size}
          sx={{ backgroundColor }}
        >
          {renderEmptyValue &&
            <MenuItem
              key=""
              value=""
            >
              {renderEmptyValue}
            </MenuItem>
          }
          {items.map(s => (
            <MenuItem
              key={s.id}
              value={s.id}
              sx={{ backgroundColor }}
            >
              {renderDropdownLabel(s)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  };
}
