export class FormCaptionsVariant {
    id: number;
    name: string;
    contractHeadLineTemplate: string;
    contractBottomDetailsTemplate: string;
}

export const FORM_CAPTIONS_VARIANT: FormCaptionsVariant[] = [
    { 
      'id': 1, 'name': 'Юр. лицо', 
      'contractHeadLineTemplate': '{ФОРМА_СОБСТВЕННОСТИ} "{НАЗВАНИЕ}", именуемое в дальнейшем {ЗАКАЗЧИК_ИСПОЛНИТЕЛЬ} в лице {ПОДПИСАНТА_ДОЛЖНОСТЬ} {ПОДПИСАНТА}, действующего', 
      'contractBottomDetailsTemplate': '{ФОРМА_СОБСТВЕННОСТИ} "{НАЗВАНИЕ}"' 
    },
    { 
      'id': 2, 'name': 'Физ. лицо', 
      'contractHeadLineTemplate': '{НАЗВАНИЕ} в дальнейшем {ЗАКАЗЧИК_ИСПОЛНИТЕЛЬ} действует', 
      'contractBottomDetailsTemplate': '{НАЗВАНИЕ}' 
    },
    { 
      'id': 3, 'name': 'ИП', 
      'contractHeadLineTemplate': '{ФОРМА_СОБСТВЕННОСТИ} {НАЗВАНИЕ} в дальнейшем {ЗАКАЗЧИК_ИСПОЛНИТЕЛЬ} действует', 
      'contractBottomDetailsTemplate': '{ФОРМА_СОБСТВЕННОСТИ} {НАЗВАНИЕ}' 
    },
  ];