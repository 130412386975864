import { useStateSelector } from 'shared/store';
import { FormDialog } from 'shared/ui/form-dialog';
import { validate, initialValues, FormValues } from './form';
import { Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import { RegionPicker } from 'shared/ui/formik-fields/region-picker';
import { useAddCityDialog } from './lib';

interface Props {
  onSubmit: (values: FormValues) => Promise<void>;
}

export const AddCityDialog = ({ onSubmit }: Props) => {
  const { closeDialog } = useAddCityDialog();
  const isOpen = useStateSelector(state => state.ui.city.addNewCityDialogOpen);

  return (
    <FormDialog
      onClose={closeDialog}
      open={isOpen}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      renderTitle="Новый город"
      renderForm={(props) => (
        <Stack direction="column" spacing={2}>
          <Field
            name="name"
            label="Название"
            component={TextField}
          />
          <Field
            component={RegionPicker}
            name="regionId"
            label="Регион"
          />
        </Stack >
      )}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues} />
  );
}

