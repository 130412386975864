import { Box, Breadcrumbs, Tab, Tabs, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import StorageIcon from '@mui/icons-material/Storage';
import GradingIcon from '@mui/icons-material/Grading';

import { useCityBasicData } from 'entities/geo/cities/city-basic-data';
import { CityHomeTab } from 'pages/geo/city/tabs/city-home-tab';
import { Outlet, Route, Routes, useLocation, Link } from 'react-router-dom';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { PageHeader } from 'shared/ui/details-page-header';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useIdParam } from 'utils/url-paramters.hook';
import { useTitleHook } from 'hooks/page-title.hook';
import { CityPageBreadCrumbs } from 'entities/geo/cities/bread-crumbs';
import { PublishingDataForm } from 'features/publishing-data/publishing-data.form';
import { PublishingDataRelations } from 'entities/publishing-data/publishing-data.hooks';

const tabInfos = {
  'home': {
    label: 'Основное',
    icon: <StorageIcon />,
    disabled: false
  },
  'publishing': {
    label: 'Публикация на сайте',
    icon: <AccountBalanceIcon />,
    disabled: false
  },
  'stats': {
    label: 'Статистические данные',
    icon: <ReceiptIcon />,
    disabled: true
  },
  'metrics': {
    label: 'Метрика',
    icon: <MoveToInboxIcon />,
    disabled: true
  },
  'audit': {
    label: 'Аудит',
    icon: <GradingIcon />,
    disabled: true
  }
};


export const CityDetailsPage = () => {
  const cityId = useIdParam('cityId');

  const { cityBasicData, error } = useCityBasicData(cityId);

  useTitleHook(cityBasicData && `${cityBasicData.name} | Города`);

  if (!cityBasicData)
    if (error)
      return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />;
    else
      return <LoadingBanner />;

  return (
    <>
      <CityPageBreadCrumbs city={cityBasicData} />

      <PageHeader title={<PageHeader.Text>{cityBasicData.name}</PageHeader.Text>} sx={{ mt: 1, mb: 0 }} />

      <CityTabs />
      <Routes>
        <Route element={<Outlet />}>
          <Route path='home' element={<CityHomeTab cityId={cityId} />} />
          <Route path='publishing' element={
            <PublishingDataForm id={cityBasicData.publishingDataId} relatedType={PublishingDataRelations.city} relatedId={cityId}/>
          }/>
          <Route path='stats' element={<> Стат. данные </>} />
          <Route path='audit' element={<> Аудит. TO DO</>} />
        </Route>
      </Routes>

    </>
  );
};


export default CityDetailsPage;



const CityTabs = () => {
  const tabs = Object.entries(tabInfos);
  const location = useLocation();
  const tabName = location.pathname.split('/').at(-1);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
      <Tabs value={tabName}>
        {tabs.map(([route, info], i) => {
          return <Tab
            key={i}
            label={info.label}
            value={route}
            iconPosition="start"
            icon={info.icon}
            to={route}
            component={Link}
            disabled={info.disabled} />;
        })}
      </Tabs>
    </Box>
  );
};


