import { Divider, MenuItem, TextField } from '@mui/material';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import PriceSpan from 'shared/ui/price-span';
import { PaiedProgress } from 'shared/ui/progress-bar';
import { CardPaper } from 'shared/ui/card-paper';

type ProfitProps = {
  fullCost: number;
  purchasesProfit: number;
  payOrdersTotal: number;
  targetProfit: number;
  currentProfit: number;
};

export const ProfitCard = ({ fullCost, purchasesProfit, payOrdersTotal, targetProfit, currentProfit }: ProfitProps) => {
  const ramaining = fullCost - payOrdersTotal;
  return (
    <CardPaper>
      <LegendList>
        <PaiedProgress variant="determinate" value={100 * (payOrdersTotal / fullCost)} />
        <LegendItem title="Сумма:" value={<PriceSpan price={fullCost} />} />
        <LegendItem title="Оплачено:" value={<PriceSpan price={payOrdersTotal} />} />
        <LegendItem title="Еще:" value={<PriceSpan price={ramaining} />} />
        <Divider sx={{ p: 1 }} />
        <LegendItem title="Фактическая прибыль:" value={<PriceSpan price={currentProfit} />} />
        <LegendItem title="Расчетная прибыль:" value={<PriceSpan price={targetProfit} />} />
        <LegendItem title="Прибыль по заявкам:" value={<PriceSpan price={purchasesProfit} />} />
        <Divider sx={{ p: 1 }} />
        <LegendItem title="Ставка:" value={
          <TextField size='small' value={1} select fullWidth>
            <MenuItem value={1}>10%</MenuItem>
            <MenuItem value={2}>20%</MenuItem>
          </TextField>} />
        <LegendItem title="ЗП менеджера:" value={<PriceSpan size='small' price={4500} />} />
      </LegendList >
    </CardPaper>
  );
};