import { Link, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Box, Tab, Tabs } from '@mui/material';
import { PublishingDataForm } from 'features/publishing-data/publishing-data.form';
import { PublishingDataRelations } from 'entities/publishing-data/publishing-data.hooks';
import RegionHome from 'entities/geo/regions/region-home';
import useRegion from 'hooks/region.hook';
import { PageHeader } from 'shared/ui/details-page-header';

const tabInfos = {
  'home': {
    label: 'Основное',
    icon: <StorageIcon />,
    disabled: false
  },
  'publishing': {
    label: 'Публикация на сайте',
    icon: <AccountBalanceIcon />,
    disabled: false
  },
};

export const RegionDetailsPage = () => {
  const { regionId: regionIdParam } = useParams<{ regionId: string }>();
  const regionId = Number(regionIdParam);
  const { region } = useRegion(regionId);
  if (!region) {
    return <>Нет города</>;
  }

  return(
    <>
      <PageHeader title={<PageHeader.Text>{region?.name ?? ''}</PageHeader.Text>} sx={{ mt: 1, mb: 0 }} />

      <RegionTabs/>
      <Routes>
        <Route element={<Outlet />}>
          <Route path='home' element={<RegionHome regionId={regionId} />} />
          <Route path='publishing' element={
            <PublishingDataForm id={region?.publishingDataId ? region.publishingDataId : null}
              relatedType={PublishingDataRelations.region}
              relatedId={regionId}/>
          }/>
        </Route>
      </Routes>

    </>
  );
};

const RegionTabs = () => {
  const tabs = Object.entries(tabInfos);
  const location = useLocation();
  const tabName = location.pathname.split('/').at(-1);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
      <Tabs value={tabName}>
        {tabs.map(([route, info], i) => {
          return <Tab
            key={i}
            label={info.label}
            value={route}
            iconPosition="start"
            icon={info.icon}
            to={route}
            component={Link}
            disabled={info.disabled} />;
        })}
      </Tabs>
    </Box>
  );
};


export default RegionDetailsPage;
