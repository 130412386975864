import { Field, FormikProvider, useFormik } from 'formik';
import { UserPickerField } from 'features/user/user-picker/ui';
import { useSearchParams } from 'react-router-dom';
import {
  formValuesFromUrlSearchParams,
  mergeFormValuesVsCurrentSearchParams,
} from 'utils/url-search-params.helper';
import { useEffect } from 'react';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';

type FormValues = Record<string, string | null>

type Props = {
  queryParamName: string,
  size?: 'small' | 'medium'
}

/**
 * Когда используете данный фильтр по менеджеру он просто обновляет URLSearchParams а адресной строке браузера.
 * Компонент списка должен подписаться на URLSearchParams и обновлять состояние соответственно
 */
export const ManagerUrlParamsFilter = ({ queryParamName, size = 'medium' }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues: FormValues = {
    [queryParamName]: null,
  };

  const formik = useFormik<FormValues>({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultValues),
    onSubmit: () => {},
    validateOnChange: true,
  });

  const formValue: string | null = formik.values[queryParamName] ?? null;

  useEffect(() => {
    if (!formik.isValid) return ;
    setSearchParams(prev => {
      const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultValues);
      if (Object.keys(formik.touched).length || formik.dirty) {
        // раз пользователь обновил фильтр, сбрасываем страницу на 0
        params.delete(PAGE_FIELD_NAME);
      }
      return params;
    });
  }, [formValue]);

  return (
    <FormikProvider value={formik}>
      <Field
        fullWidth
        name={queryParamName}
        label="Менеджер"
        clearable={true}
        size={size}
        component={UserPickerField}
      />
    </FormikProvider>
  );
};
