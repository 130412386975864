import { ReactNode } from 'react';
import { Typography } from '@mui/material';

export const HeaderText = ({ children }: { children: ReactNode }) =>
  <Typography variant='h5' component='span'>
    {children}
  </Typography>;


const WithPrimaryColor = ({ children }: { children: ReactNode }) =>
  <Typography variant='inherit' component='span' color='primary'>
    {children}
  </Typography>;


HeaderText.Stressed = WithPrimaryColor;