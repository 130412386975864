import { PayOrderDto, CreatePayOrderDto, PayOrderGroup, PayOrderStatus, UpdatePayOrderDto, POS_DRAFT, CreateUpdatePayOrderRowDto } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';

export type RowData = {
  id: number | '';
  invoiceId: number | '';
  amount: number | '';
};

export type FormData = {
  status: PayOrderStatus;
  description: string;
  documentNumber: string;
  paymentDate: Date;
  group: PayOrderGroup | '';
  accountId: number | '';
  cashflowId: number | '';
  rows: RowData[];
};

export const formTools: FormTools<PayOrderDto, CreatePayOrderDto, UpdatePayOrderDto, FormData> = {
  toUpdateDto: (id, formData): UpdatePayOrderDto => ({
    id: id,
    status: formData.status,
    description: formData.description,
    documentNumber: formData.documentNumber,
    paymentDate: formData.paymentDate,
    group: formData.group as PayOrderGroup,
    accountId: formData.accountId as number,
    cashflowId: formData.cashflowId as number,
    rows: formData.rows.map<CreateUpdatePayOrderRowDto>(row => ({
      id: row.id as number,
      invoiceId: row.invoiceId as number,
      amount: row.amount as number
    }))
  }),

  toCreateDto: formData => ({
    status: formData.status as PayOrderStatus,
    description: formData.description,
    documentNumber: formData.documentNumber,
    paymentDate: formData.paymentDate,
    group: formData.group as PayOrderGroup,
    accountId: formData.accountId as number,
    cashflowId: formData.cashflowId as number,
    rows: formData.rows.map<CreateUpdatePayOrderRowDto>(row => ({
      id: row.id as number,
      invoiceId: row.invoiceId as number,
      amount: row.amount as number
    }))
  }),

  fromDto: (dto) => ({
    status: dto.status,
    description: dto.description,
    documentNumber: dto.documentNumber,
    paymentDate: dto.paymentDate,
    group: dto.group,
    accountId: dto.bankAccount.id,
    cashflowId: dto.cashflow.id,
    rows: dto.rows.map(row => ({
      id: row.id,
      invoiceId: row.invoiceId,
      amount: row.amount
    }))
  }),

  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (!values.documentNumber)
      validationResult.documentNumber = VALIDATION_REQUIRED_FIELD;

    if (!values.description)
      validationResult.description = VALIDATION_REQUIRED_FIELD;

    if (!values.group)
      validationResult.group = VALIDATION_REQUIRED_FIELD;

    if (!values.cashflowId)
      validationResult.cashflowId = VALIDATION_REQUIRED_FIELD;

    if (!values.accountId)
      validationResult.accountId = VALIDATION_REQUIRED_FIELD;
    if (values.rows.length === 0)
      validationResult.rows = 'Нужна хотя бы одна сумма по счету';
    else {
      // validationResult.rows = [];
      // values.rows.forEach((row, idx) => {
      //   const result: { [key in keyof RowData]?: string } = {};

      //   if (row.invoiceId === '')
      //     result.invoiceId = requiredMsg;

      //   if (row.amount === '')
      //     result.amount = requiredMsg;
      //   else if (Number(row.amount) === 0)
      //     result.amount = 'Сумма должна быть отличной от нуля';

      //   if (Object.keys(result).length > 0)
      //     validationResult.rows.push(result)
      // });

      const rowsValidation = values.rows.map(row => {
        const result: FormikErrors<RowData> = {};

        if (row.invoiceId === '')
          result.invoiceId = VALIDATION_REQUIRED_FIELD;

        if (row.amount === '')
          result.amount = VALIDATION_REQUIRED_FIELD;
        else if (Number(row.amount) === 0)
          result.amount = 'Сумма должна быть отличной от нуля';

        // return (Object.keys(result).length > 0) ? result : undefined;
        return result;
      });

      if (!rowsValidation.every(x => Object.keys(x).length === 0))
        validationResult.rows = rowsValidation;

    }

    return validationResult;
  },

  emptyValues: () => ({
    status: POS_DRAFT,
    description: '',
    documentNumber: '',
    paymentDate: new Date(),
    group: '',
    accountId: '',
    cashflowId: '',
    rows: [{ id: -1, amount: '', invoiceId: '' }]
  })
};
