import { Box, Stack, Typography } from '@mui/material';
import { CompanyDto } from '@sr/dto';
import { Fragment } from 'react';
import { getCompanyTypeArray } from 'entities/company/company-type-field/company-type-helpers';

const Separator = <Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />;

export const CompanyTypes = ({ company }: { company: CompanyDto | null }) => {
  if (!company) return <></>;

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        {getCompanyTypeArray(company.type).map((x, idx, arr) =>
          <Fragment key={idx}>
            <Typography component="span" variant='subtitle2' color="grey.500">
              {x.label}
            </Typography>

            {idx !== (arr.length - 1) && Separator}
          </Fragment>
        )}
      </Stack>
    </>
  )
}