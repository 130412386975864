import { Box, Checkbox, Stack, TableCell, TableRow } from '@mui/material';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { GridLayout } from 'shared/ui/grid-layout';
import { CompanyQueryType, CompanyWithManagersDto, IPagingData } from '@sr/dto';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { ManagerUrlParamsFilter } from 'entities/filters/manager-url-params-filter';
import { LinkedManagerChip } from 'entities/user/linked-manager-chip';
import { YearUrlParamsFilter } from 'entities/filters/year-url-params-filter';
import { CardPaper } from 'shared/ui/card-paper';

const columns = [
  'Название',
  'Менеджеры',
  'Города',
];

export type BaseProps = {
    companiesCollection: IRemotePagedCollection<CompanyWithManagersDto>,
    paging?: IPagingData,
    onChangePaging?: (paging: IPagingData) => void,
    type: CompanyQueryType,
};

type SelectableListOptions = {
  // both fields are defined or both are undefined
  onCheckboxChange: (id: number) => void,
  selectedCompaniesIds: number[]
} | {
  onCheckboxChange?: undefined,
  selectedCompaniesIds?: undefined
}

type Props = BaseProps & SelectableListOptions

const ManagerFilterLayout = () => (
  <CardPaper>
    <Stack direction="row" spacing={1} >
      <Box sx={{ minWidth: 350 }}>
        <ManagerUrlParamsFilter queryParamName='managerId' />
      </Box>
      <Box sx={{ minWidth: 200, flexGrow: 2 }}/>
    </Stack>
  </CardPaper>
);

const YearFilterLayout = () => (
  <CardPaper>
    <Stack direction="row" spacing={1} >
      <Box sx={{ minWidth: 350 }}>
        <YearUrlParamsFilter queryParamName={'year'}/>
      </Box>
      <Box sx={{ minWidth: 200, flexGrow: 2 }}/>
    </Stack>
  </CardPaper>

);

export const CompaniesList = ({
  companiesCollection,
  paging,
  onChangePaging,
  type,
  onCheckboxChange,
  selectedCompaniesIds
}: Props) => {
  const rowExtraProps: SelectableListOptions = onCheckboxChange
    ? { onCheckboxChange, selectedCompaniesIds }
    : { onCheckboxChange: undefined, selectedCompaniesIds: undefined };

  return (
    <>
      <Box mb={1}>
        {
          (type === CompanyQueryType.CLIENTS_ALL || type === CompanyQueryType.SUPPLIERS_ALL) &&
          <ManagerFilterLayout/>
        }
        {
          (type === CompanyQueryType.CLIENTS_FREE_WITH_INVOICES || type === CompanyQueryType.CLIENTS_WITH_LEADS) &&
          <YearFilterLayout />
        }
      </Box>
      <GridLayout<CompanyWithManagersDto>
        columns={onCheckboxChange ? ['', ...columns] : columns}
        items={companiesCollection.collection.items}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={companiesCollection.collection.totalItemsCount}
        isLoading={companiesCollection.isLoading}
        error={companiesCollection.error}
        itemRender={(item) => <CompanyRow
          key={item.id}
          {...rowExtraProps}
          companyData={item}
        />}
        noItemsText='Список фирм пуст'/>
    </>);
};

type RowProps = {
    companyData: CompanyWithManagersDto,
};

const CompanyRow = ({ companyData, onCheckboxChange, selectedCompaniesIds }: RowProps & SelectableListOptions) => {

  return (
    <>
      <TableRow
        key={companyData.id}
      >
        {onCheckboxChange ? <TableCell width={50} component="th" scope="row">
          <Checkbox checked={selectedCompaniesIds.includes(companyData.id)}
            onChange={() => onCheckboxChange(companyData.id)}/>
        </TableCell> : null}
        <TableCell component="th" scope="row">
          <LinkToCompany company={companyData}/>
        </TableCell>
        <TableCell>
          {companyData.managers.length === 0
            ? <>-</>
            : companyData.managers.map(manager => <LinkedManagerChip key={manager.id} manager={manager}/>)
          }
        </TableCell>
        <TableCell>-</TableCell>
      </TableRow>
    </>
  );
};

