import {
  TableRow,
  TableCell
} from '@mui/material';
import { MouseEvent } from 'react';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuParams from 'shared/ui/menu-params';
import { CityInfoDto, IPagingData } from '@sr/dto';
import { useMenu, RowMenu } from 'shared/ui/row-menu';
import { GridLayout } from 'shared/ui/grid-layout';
import { LinkToCity } from 'shared/ui/links/link-to-city';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';


type Props = {
  collection: IRemotePagedCollection<CityInfoDto>,
  paging: IPagingData,
  onChangePaging: (paging: IPagingData) => void,
  onDelete: (city: CityInfoDto) => void,
};

export const CitiesList = ({ collection, paging, onChangePaging, onDelete }: Props) => {
  const { openMenu, menuData } = useMenu<CityInfoDto>({ deleteHandler: onDelete });

  return (
    <>
      <GridLayout<CityInfoDto>
        columns={['Название', '']}
        items={collection.collection.items}
        totalItemsCount={collection.collection.totalItemsCount}
        pagingData={paging}
        onChangePaging={onChangePaging}
        isLoading={collection.isLoading}
        error={collection.error}
        noItemsText='Список городов пуст'
        itemRender={(i) =>
          <CitiesList.CityRow
            key={i.id}
            city={i}
            onMenuClick={openMenu} />}
      />

      <RowMenu menuData={menuData} />
    </>);
};

type RowProps = {
  city: CityInfoDto,
  onMenuClick: (menuParams: MenuParams<CityInfoDto>) => void;
};

CitiesList.CityRow = (props: RowProps) => {
  const { city, onMenuClick } = props;

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: city });
  };

  return (
    <>
      <TableRow
        key={city.id}
      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToCity city={city} />
        </TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

