import { Box, Checkbox, Paper, Stack, Typography } from "@mui/material";
import { CompanyToCombineDto } from "@sr/dto";
import { LinkedManagerChip } from "entities/user/linked-manager-chip";
import { LinkToCompany } from "shared/ui/links/link-to-company";

export const CombineCompanyEntry = ({ company, onCheck }: { company: CompanyToCombineDto, onCheck: (checked: boolean) => void }) => {
  return (
    <Paper sx={{ p: 1, }} >

      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Checkbox onChange={(e) => onCheck(e.target.checked)} />
          <Box>
            <LinkToCompany company={company} />
          </Box>
        </Stack>
        <Box flexGrow={1} sx={{}}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={3}>
            <Box mb={1} sx={{ maxWidth: 800, }}>
              {/* <Typography variant="body2" mb={1} sx={{ textAlign: "right" }}>Менеджеры:</Typography> */}

              {company.managers.length > 0
                ? <Stack direction="row" flexWrap="wrap">
                  {company.managers.map((manager) => (<LinkedManagerChip key={manager.id} manager={manager.manager} />))}
                </Stack>
                : <Typography variant="caption" color="GrayText">Нет закрепленных менеджеров</Typography>
              }

            </Box>
            <Box justifySelf="flex-end">
              <Stack direction="row" gap={1}>
                {/* <Stack direction="column" alignItems="stretch" gap={1}> */}
                <Counter title="Клиентские счета:" value={company.clientInvoicesCount ? company.clientInvoicesCount.toString() : "-"} />
                <Counter title="Юрлица:" value={company.legalEntitiesCount ? company.legalEntitiesCount.toString() : "-"} />
                {/* </Stack> */}
                {/* <Stack direction="column" alignItems="stretch" gap={1}> */}
                <Counter title="Входящие счета:" value={company.supplierInvoicesCount ? company.supplierInvoicesCount.toString() : "-"} />
                <Counter title="Лиды:" value={company.leadsCount ? company.leadsCount.toString() : "-"} />
                {/* </Stack> */}
              </Stack>

            </Box>
          </Stack>
        </Box>
      </Stack>

    </Paper >
  );
}


const Counter = ({ title, value }: { title: string, value: string }) => {
  return (
    <Paper elevation={0} variant="outlined" sx={{ minWidth: 100, backgroundColor: "grey.200" }} >
      <Stack direction="column" alignItems="center" mx={1} >
        <Box>
          <Typography variant="caption">{title}</Typography>
        </Box>
        <Box>
          <Typography variant="h6">{value}</Typography>
        </Box>
      </Stack>
    </Paper>

  )
}