import { Button, DialogActions, DialogTitle, Paper, Stack, Typography } from '@mui/material';
import { SettingsImportCashflowsDto } from '@sr/dto';
import { plainToInstance } from 'class-transformer';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { CashflowPicker } from 'shared/ui/formik-fields/cashflow-picker';
import { Dialog, DialogProps } from 'shared/ui/dialog';
import { Field, Formik, FormikErrors, FormikProps } from 'formik';
import { useSnack } from 'shared/ui/snack';
import { useState } from 'react';
import { authorizedRequest, authReq, fetchReq } from 'shared/api/axios';

type FormValues = {
  defaultInboundCashflowId: number | '',
  defaultOutboundCashflowId: number | '';
};

const validate = (values: FormValues) => {
  const result: FormikErrors<FormValues> = {};
  if (!values.defaultInboundCashflowId)
    result.defaultInboundCashflowId = VALIDATION_REQUIRED_FIELD;
  if (!values.defaultOutboundCashflowId)
    result.defaultOutboundCashflowId = VALIDATION_REQUIRED_FIELD;
  return result;
};

const initials: FormValues = {
  defaultInboundCashflowId: '',
  defaultOutboundCashflowId: ''
};

type Props = {
  dialogProps: DialogProps,
  currentSettings: SettingsImportCashflowsDto | null,
  onSubmit: (newSettings: FormValues) => Promise<void>;
};


export function PayorderImportSettingsDialog({ dialogProps, currentSettings, onSubmit }: Props) {

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={currentSettings || initials}
      validate={validate}
      enableReinitialize
    >{
        ({ submitForm, isSubmitting, isValid, dirty }: FormikProps<FormValues>) => (
          <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Настройки импорта
              <Dialog.CloseButton onClick={() => dialogProps.onClose?.({}, 'backdropClick')} />
            </DialogTitle>
            <Dialog.Content>
              <Stack direction="column" spacing={2}>
                <Paper sx={{ bgcolor: 'grey.100', p: 2 }} elevation={0}>
                  <Typography variant="body2">
                    Значения поля "Статья движения денежных средств" при импорте платежных поручений из файла
                  </Typography>
                </Paper>
                <Field
                  name='defaultInboundCashflowId'
                  label="Значение по-умолчанию для входящих платежек"
                  component={CashflowPicker}
                />
                <Field
                  name='defaultOutboundCashflowId'
                  label="Значение по-умолчанию для исходящих платежек"
                  component={CashflowPicker}
                />
              </Stack>
            </Dialog.Content>
            <DialogActions>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Сохранить
              </Button>
              <Button
                onClick={() => dialogProps.onClose?.({}, 'backdropClick')}
                disabled={isSubmitting}
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>)}
    </Formik>
  );
}


export const usePayorderImportSettingsDialog = () => {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState<SettingsImportCashflowsDto | null>(null);
  const { showError, showSuccess } = useSnack();

  const openDialog = async () => {
    await loadSettings();
    setOpen(true);
  };

  const loadSettings = async () => {
    return fetchReq(
      '/settings/payorders-import',
      SettingsImportCashflowsDto
    )
      .then(response => setSettings(response))
      .catch(e => showError('Ошибка получения текщих настроек импорта: ' + e.message));
  };

  const handleSubmit = async (values: FormValues) => {
    const data = new SettingsImportCashflowsDto();
    data.defaultInboundCashflowId = Number(values.defaultInboundCashflowId);
    data.defaultOutboundCashflowId = Number(values.defaultOutboundCashflowId);

    const response = await authorizedRequest<SettingsImportCashflowsDto, SettingsImportCashflowsDto>(
      '/settings/payorders-import',
      'PUT',
      data
    );

    setSettings(plainToInstance(SettingsImportCashflowsDto, response, { enableImplicitConversion: true }));

    showSuccess('Настройки импорта успешно обновлены');
  };

  const dialogProps: Props = {
    dialogProps: {
      open: open,
      onClose: () => setOpen(false)
    },
    currentSettings: settings,
    onSubmit: handleSubmit
  };

  return {
    openDialog,
    dialogProps
  };

}

