import petrovich from 'petrovich';
import { InputType } from 'petrovich';

export function morpher(word: string, morph: string, type: InputType) {
  let wordMorph;
  switch (morph) {
    case 'именительный':
      morph = 'nominative';
      break;
    case 'родительный':
      morph = 'genitive';
      break;
    case 'дательный':
      morph = 'dative';
      break;
    case 'винительный':
      morph = 'accusative';
      break;
    case 'творительный':
      morph = 'instrumental';
      break;
    case 'предложный':
      morph = 'prepositional';
      break;
  }
  if (type === 'ФИО') {
    let person = {
      middle: '',
      first: '',
      last: '',
    };
    const arr = word.trim().split(' ');
    if (arr.length == 3) {
      person['middle'] = arr[2];
    }
    if (arr.length >= 2) {
      person['first'] = arr[1];
    }
    if (arr.length >= 1) {
      person['last'] = arr[0];
    }
    const personMorph = petrovich(person, morph);

    wordMorph =
      personMorph['last'] +
      ' ' +
      personMorph['first'] +
      ' ' +
      personMorph['middle'];
  } else if (type === 'Должность') {
    let position = {
      gender: 'male',
      first: word.trim(),
    };
    wordMorph = petrovich(position, morph)['first'];
  }
  return wordMorph;
}
