import { REFETCH_TIME_SPAN } from 'shared/api/rtk-query';
import { cityBasicDataApi } from './apiSlice';

export const useCityBasicData = (id: number) => {
  const { data: cityBasicData, error } = cityBasicDataApi.useGetCityBasicDataQuery(id, { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });

  return {
    cityBasicData,
    error
  };
};
