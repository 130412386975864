import { Type } from "class-transformer";

export class PayorderFilter {
  ourLegalEntityId?: number;
  bankAccountId?: number;
  group?: number;
  statusId?: number;

  @Type()
  fromDate?: Date;
  @Type()
  toDate?: Date;
}