export const CFD_INBOUND = 1;
export const CFD_OUTBOUND = 2;
export const CFD_CROSS = 3;

export type CashflowDirection = 1 | 2 | 3;
// export type CashflowDirection = CFD_INBOUND | CFD_OUTBOUND | CFD_CROSS;

export const CFD_TYPES: { [x in CashflowDirection]: string } = {
  1: 'Входящие',
  2: 'Исходящие',
  3: 'Совмещенные',
}

export const CFD_TYPES_KEYS = Object.keys(CFD_TYPES).map(Number);