import { useState } from 'react';
import { IPagingData } from '@sr/dto';
import { DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { LeadsList } from 'entities/lead/lead-list';

type Props = {
    companyId: number;
};

export const CompanyLeads = (props: Props) => {
  const { companyId } = props;
  const [paging, setPaging] = useState<IPagingData>(DEFAULT_PAGINATION);

  return (
    <>
      <LeadsList companyId={companyId} paging={paging}
        onChangePaging={(pagingData: IPagingData) => setPaging(pagingData)}/>
    </>);
};
