
export const companyLinkManager = {
  baseUrl: 'company',
  clientsUrl: 'clients',
  link: function (companyId: number) { return `/${this.baseUrl}/${companyId}/home` }
};

export const legalEntityManager = {
  baseUrl: 'legal-entity',
  link: function (legalEntityId: number) { return `/${this.baseUrl}/${legalEntityId}` }
};

export const legalEntityTypeManager = {
  baseUrl: 'legal-entity-type',
  link: function (legalEntityTypeId: number) { return `/${this.baseUrl}/${legalEntityTypeId}` }
};

export const invoiceLinkManager = {
  baseUrl: 'invoice',
  link: function (id: number) { return `/${this.baseUrl}/${id}` },
  reportLink: function (id: number) { return `/${this.baseUrl}/${id}/report` }
};

export const wccLinkManager = {
  baseUrl: 'wcc',
  link: function (id: number) { return `/${this.baseUrl}/${id}` },
};


export const contractLinkManager = {
  baseUrl: 'contracts',
  appendixBaseUrl: 'contract-appendix',
  templateBaseUrl: 'contract-templates',
  link: function (id: number) { return `/${this.baseUrl}/${id}` },
  appendixLink: function (id: number) { return `/${this.appendixBaseUrl}/${id}` },
  templateLink: function (id: number) { return `/${this.templateBaseUrl}/${id}` }
};


export const purchaseLinkManager = {
  baseUrl: 'purchases',
  link: function (id: number) { return `/${this.baseUrl}/${id}` }
};

export const mediaLinkManager = {
  baseUrl: 'media',
  link: function (id: number) { return `/${this.baseUrl}/${id}/home` }
};

export const mediaTypeLinkManager = {
  baseUrl: '/settings/media-types',
  link: function (id: number){ return `${this.baseUrl}/${id}` }
};

export const leadLinkManager = {
  baseUrl: 'leads',
  link: function (id: number) { return `/${this.baseUrl}/${id}` }
};

export const salaryLinkManager = {
  baseUrl: 'salary',
  tabs: {
    home: 'home',
    reserveFund: 'reserve-fund',
    timeSheet: 'time-sheet'
  }
};

export const userLinkManager = {
  baseUrl: 'users',
  link: function (id: number) { return `/${this.baseUrl}/${id}` }
};

export const reportsLinkManager = {
  baseUrl: 'reports',
  tabs: {
    cashBalance: 'cash-balance'
  }
};

export const fileLinkManager = {
  baseUrl: 'download/file',
  link: function (id: number) { return `/api/${this.baseUrl}/${id}` }
  //link: function (id: number) { return `http://localhost:5001/api/files/${id}`; }
};

export const payOrderLinkManager = {
  baseUrl: 'payorder',
  link: function (id: number) { return `/${this.baseUrl}/${id}` }
};

export const geoLinkManager = {
  cityBaseUrl: 'cities',
  countryBaseUrl: 'countries',
  regionsBaseUrl: 'regions',
  linkToCity: function (id: number) { return `/${this.cityBaseUrl}/${id}/home` },
  linkToCountry: function (id: number) { return `/${this.countryBaseUrl}/${id}/home` },
  linkToRegion: function (id: number) { return `/${this.regionsBaseUrl}/${id}/home` }
};

export const roleLinkManager = {
  baseUrl: 'settings/user-roles',
  link: function (id: number) { return `/${this.baseUrl}/${id}` }
};
