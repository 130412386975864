import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { legalEntitiesTypeApi } from './legal-entities-type.api';
import { CreateLegalEntityTypeDto, IPagingData } from '@sr/dto';
import { usePagingParams } from 'shared/hooks/paging-params';

  export const useLegalEntityTypesList = ()=> {
    const [paging, setPaging] = usePagingParams();
    const { data: LegalEntityType, isLoading, error } = legalEntitiesTypeApi.useGetLegalEntitiesTypeQuery(paging)
    return  {
      collection: LegalEntityType ?? [],
      error: extractNestJsErrorMessage(error),
      isLoading: isLoading,
      paging: paging,
      onChangePaging: (pagingData: IPagingData) => setPaging(pagingData)
    };
  };

  export const useLegalEntityTypesActiveList = ()=> {
    const { data: LegalEntityType, isLoading, error } = legalEntitiesTypeApi.useGetLegalEntitiesTypeActiveQuery({})
    return  {
      collection: LegalEntityType ?? [],
      error: extractNestJsErrorMessage(error),
      isLoading: isLoading
    };
  };
