import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { TextField, Autocomplete, Box, useTheme, Theme } from '@mui/material';
import { ContractInfoDto, ContractsDropdownFilter } from '@sr/dto';
import { useContractsDropdown } from './lib';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { formatDate } from 'utils/date-format-helpers';
import { formatLegalEntity } from 'utils/legal-entity-format-helpers';
import Typography from '@mui/material/Typography';

export type ContractPickerFilterProviders = {
  clientCompanyId: number,
  onChange: (newValue: ContractInfoDto) => void;
}

export const ContractPicker = (props: TextFieldProps & ContractPickerFilterProviders) => {
  const {
    form: { setFieldValue, values },
    field: { name, value },
    label,
    clientCompanyId,
    onChange
  } = props;
  const theme = useTheme();

  const filter = useMemo<ContractsDropdownFilter | undefined>(() => {
    if (!clientCompanyId) return undefined;

    return {
      clientCompanyId
    };

  }, [clientCompanyId]);

  const collection = useContractsDropdown(filter);

  const [open, setOpen] = useState(false);
  const { items, isLoading: fetching } = collection;

  const handleChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: any) => {
      onChange(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name]
  );


  const loading = open && items.length === 0 && fetching;

  const { clientCompanyId: _, ...textFieldProps } = props;

  const selectedEntity = items.find(x => x.id === value) ?? null;

  return (
    <Autocomplete
      disabled={!clientCompanyId}
      value={selectedEntity}
      autoComplete={false}
      options={items}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name} от ${formatDate(option.contractStartDate)}`}
      getOptionDisabled={option => !option.isActive}
      renderOption={(props, option)=> RenderContractOption(props, option, theme)}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};

const RenderContractOption = (props: React.HTMLAttributes<HTMLElement>, option: ContractInfoDto, theme: Theme)=> {
  if (!option) return;
  return (
    <li {...props}>
      <Box display="flex" flexDirection="column">
        <Typography
          style={{ color: option.isActive ? theme.palette.text.primary : theme.palette.text.disabled }}
        >
          {`${option.name} от ${formatDate(option.contractStartDate)}`}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: option.isActive ? theme.palette.text.primary : theme.palette.text.disabled }}
        >
          { formatLegalEntity(option.seller) } / { formatLegalEntity(option.buyer) }
        </Typography>
      </Box>
    </li>
  );
};



