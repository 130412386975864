import { useState } from 'react';
import { PurchasesList } from 'components/Purchases/purchases-list';
import { useSnack } from 'shared/ui/snack';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
// import { FormData, toDto } from 'components/Purchases/client-invoice.form';
import { PurchaseInfoDto } from '@sr/dto';
import { useNavigate } from 'react-router-dom';
import { Heading } from 'shared/ui/list-page-heading';
import { usePurchases } from 'hooks/purchases-list.hook';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePagingParams } from 'shared/hooks/paging-params';
import { deletePurchase } from 'shared/api/purchases';


const PurchasesListPage = () => {
  useTitleHook('Заявки на размещение');
  const [paging, setPaging] = usePagingParams();
  const [filterString, setFilterString] = useState('');
  const collection = usePurchases(paging, filterString);

  const navigate = useNavigate();

  const { showSuccess, showError } = useSnack();
  const showConfirmDialog = useConfirmDialog();


  const handleDeletePurchase = (purchase: PurchaseInfoDto) => {
    showConfirmDialog({
      content: 'Удалить заявку?',
      rejectOnCancel: false
    })
      .then(() => {
        deletePurchase(purchase.id)
          .then(() => {
            showSuccess('Заявка успешно удалена');
            collection.reload && collection.reload();
          })
          .catch(e => showError(`Ошибка удаления заявки: ${e}`));
      });
  };

  const handleAddPurchase = (leadData: FormData) => {
    // const purchase = toDto(leadData);
    // return addPurchase(purchase)
    //   .then(response => {
    //     showSuccess('Счет успешно добавлен');
    //     navigate(`/client-invoices/${response.id}`);
    //   })
    //   .catch(error => {
    //     showError('Ошибка добавления счета: ' + error.message);
    //   });
  };


  return (
    <>
      <Heading title="Заявки на размещение"/>

      {/* <Button
        sx={{ mb: 2 }}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setAddCompanyDialogOpen(true)}
        disabled
      >
        Добавить
      </Button> */}
      <PurchasesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        filterString={filterString}
        onFilter={setFilterString}
        onDelete={handleDeletePurchase}
      />
    </>
  );
};

export default PurchasesListPage;
