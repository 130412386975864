import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from './theme';
import { ruRU } from '@mui/material/locale';
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import ErrorBoundary from 'app/error-boundary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import PortalSettingsProvider from 'shared/providers/portal-settings.provider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

import { SnackProvider } from 'shared/ui/snack';
import ConfirmDialogProvider from 'shared/providers/confirm-dialog.provider';

import ProtectedRoute from 'app/protected-route';
import MainLayout from 'shared/layout/main-layout';

import LoginPage from 'pages/user/login';
import Error404 from 'pages/404';
import SettingsPage from 'pages/settings/settings-page';
import SuppliersListPage from 'pages/company/suppliers-list-page';
import ClientsListPage from 'pages/company/clients-list-page';
import CompanyDetailsPage from 'pages/company/company-details-page';
import ContactsPage from 'pages/contacts/contacts-page';
import LegalEntityDetailsPage from 'pages/company/legal-entity-details-page';
import LeadsListPage from 'pages/leads/leads-list-page';
import LeadDetailsPage from 'pages/leads/lead-details-page';
import MediaDetailsPage from 'pages/media/media-details-page';
import MediaListPage from 'pages/media-list/media-list-page';
import CountriesListPage from 'pages/geo/country/countries-list-page';
import CitiesListPage from 'pages/geo/city/cities-list-page';
import RegionsListPage from 'pages/geo/region/regions-list-page';
import RegionDetailsPage from 'pages/geo/region/region-details-page';
import CityDetailsPage from 'pages/geo/city/city-details-page';
import CountryDetailsPage from 'pages/geo/country/country-details-page';
import ContractsListPage from 'pages/contracts/contracts-list-page';
import InvoiceDetailsPage from 'pages/invoices/invoice-details-page';
import ClientInvoicesListPage from 'pages/invoices/client-invoces-list-page';
import SuppliersInvoicesListPage from 'pages/invoices/suppliers-invoces-list-page';
import PurchasesListPage from 'pages/purchases/purchases-list-page';
import PurchaseDetailsPage from 'pages/purchases/purchase-details-page';
import PayOrdersListPage from 'pages/payorders/payorders-list-page';
import PayOrderDetailsPage from 'pages/payorders/payorder-details-page';
import InvoiceReportPage from 'pages/invoices/invoice-report-page';
import WccListPage from 'pages/wcc/wcc-list-page';
import WccDetailsPage from 'pages/wcc/wcc-details-page';
import ContractDetailsPage from 'pages/contracts/contract-details-page';
import PayOrdersImportPage from 'pages/payorders/import-sessions-list-page';
import PayOrdersImportSessionPage from 'pages/payorders/import-session-page';
import ContractAppendixDetailsPage from 'pages/contract-appendix/contract-appendix-details-page';
import ContractTemplatePage from 'pages/settings/contract-template-page';
import UsersListPage from 'pages/users/users-list-page';
import UserDetailsPage from 'pages/users/user-details-page';
import EventsListPage from 'pages/events/events-list';
import CombineCompaniesPage from 'pages/combine-companies/combine-companies-page';
import { MediaPlanningPage } from 'pages/media-planning/media-planning-page';
import MediaTypeDetailed from 'pages/settings/media-type-detailed';
import { AddContactsDialogProvider } from 'shared/providers/add-contacts-dialog.provider';
import SearchResultPage from 'pages/search/search-result-page';
import SalaryPage from 'pages/salary/salary';
import { reportsLinkManager, salaryLinkManager } from 'utils/link-manager';
import ReportsPage from 'pages/reports/reports';
import LegalEntityTypePage from 'pages/legal-entity-type/legal-entity-type-page';
import RoleDetailsPage from '../pages/roles/role-detail-page';

export default function App() {
  const theme = createTheme(themeOptions, ruRU);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <SnackProvider>
            <ConfirmDialogProvider>
              <AddContactsDialogProvider>
                <Routes>
                  <Route path='/login' element={<LoginPage />} />
                  <Route element={
                    <ProtectedRoute >
                      <PortalSettingsProvider>
                        <MainLayout>
                          <ErrorBoundary>
                            <Outlet />
                          </ErrorBoundary>
                        </MainLayout>
                      </PortalSettingsProvider>
                    </ProtectedRoute>
                  }
                  >
                    <Route path='company'>
                      <Route path=':companyId/*' element={<CompanyDetailsPage />} />
                    </Route>
                    <Route path='clients/*' element={<ClientsListPage />} />
                    <Route path='suppliers' element={<SuppliersListPage />} />

                    <Route path='accounting' >
                      <Route index element={<PayOrdersListPage />} />
                      <Route path=':payOrderId' element={<PayOrderDetailsPage />} />
                      <Route path='import'>
                        <Route index element={<PayOrdersImportPage />} />
                        <Route path=':sessionId' element={<PayOrdersImportSessionPage />} />
                      </Route>
                    </Route>
                    <Route path='legal-entity/:legalEntityId' element={<LegalEntityDetailsPage />} />
                    <Route path='contacts' element={<ContactsPage />} />
                    <Route path='purchases'  >
                      <Route index element={<PurchasesListPage />} />
                      <Route path=':id' element={<PurchaseDetailsPage />} />
                    </Route>
                    <Route path='leads' >
                      <Route index element={<LeadsListPage />} />
                      <Route path=':leadId' element={<LeadDetailsPage />} />
                    </Route>
                    <Route path='contracts' >
                      <Route index element={<ContractsListPage />} />
                      <Route path=':contractId' element={<ContractDetailsPage />} />
                    </Route>
                    <Route path='contract-appendix/:appendixId' element={<ContractAppendixDetailsPage />} />
                    <Route path='invoice' >
                      <Route path=':invoiceId' element={<InvoiceDetailsPage />} />
                      <Route path=':invoiceId/report' element={<InvoiceReportPage />} />
                    </Route>
                    <Route path='accounting' >
                      <Route index element={<PayOrdersListPage />} />
                      <Route path=':payOrderId' element={<PayOrderDetailsPage />} />
                      <Route path='import'>
                        <Route index element={<PayOrdersImportPage />} />
                        <Route path=':sessionId' element={<PayOrdersImportSessionPage />} />
                      </Route>
                    </Route>
                    <Route path={`${salaryLinkManager.baseUrl}/*`} element={<SalaryPage />} />
                    <Route path='wccs/*' element={<WccListPage />} />
                    <Route path='wcc/:wccId/*' element={<WccDetailsPage />} />
                    <Route path='client-invoices' element={<ClientInvoicesListPage />} />
                    <Route path='supplier-invoices' >
                      <Route index element={<SuppliersInvoicesListPage />} />
                      <Route path=':invoiceId' element={<InvoiceDetailsPage />} />
                    </Route>
                    <Route path='events/*' element={<EventsListPage />} />
                    <Route path={`${reportsLinkManager.baseUrl}/*`} element={<ReportsPage/>}/>
                    <Route path='media-planning/*' element={<MediaPlanningPage />} />
                    <Route path='media-list/*' element={<MediaListPage />} />
                    <Route path='media' >
                      <Route path=':mediaId/*' element={<MediaDetailsPage />} />
                    </Route>
                    <Route path='countries' >
                      <Route index element={<CountriesListPage />} />
                      <Route path=':countryId/*' element={<CountryDetailsPage />} />
                    </Route>
                    <Route path='regions' >
                      <Route index element={<RegionsListPage />} />
                      <Route path=':regionId/*' element={<RegionDetailsPage />} />
                    </Route>
                    <Route path='cities' >
                      <Route index element={<CitiesListPage />} />
                      <Route path=':cityId/*' element={<CityDetailsPage />} />
                    </Route>
                    <Route path='users' >
                      <Route index element={<UsersListPage />} />
                      <Route path=':userId' element={<UserDetailsPage />} />
                    </Route>
                    <Route path='legal-entity-type/:id' element={<LegalEntityTypePage />} />
                    <Route path='settings/*' element={<SettingsPage />} />
                    <Route path='contract-templates/:id' element={<ContractTemplatePage />} />
                    <Route path='settings/media-types/:id' element={<MediaTypeDetailed />} />
                    <Route path='settings/user-roles/:id' element={<RoleDetailsPage/>}/>
                    <Route path='search' element={<SearchResultPage />} />
                    <Route path='combine-companies' element={<CombineCompaniesPage />} />
                    <Route path='*' element={<Error404 />} />
                  </Route>
                  <Route path='*' element={<Error404 />} />
                </Routes>
              </AddContactsDialogProvider>
            </ConfirmDialogProvider>
          </SnackProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router >);

}



