type NOT_SHOW = 1
type SHOW_MONTH  = 2
type SHOW_ALL = 3

export const INVOICE_PRINT_NOT_SHOW_DATE:NOT_SHOW = 1
export const INVOICE_PRINT_SHOW_MONTH: SHOW_MONTH = 2
export const INVOICE_PRINT_SHOW_ALL_DATE: SHOW_ALL = 3

export type PrintFormDateView = NOT_SHOW | SHOW_MONTH | SHOW_ALL

export const INVOICE_PRINT_FORM_DATE_VIEW_STATUSES: {[key in PrintFormDateView]: string} = {
  [INVOICE_PRINT_NOT_SHOW_DATE]: 'Не выводить',
  [INVOICE_PRINT_SHOW_MONTH]: 'Выводить месяцы',
  [INVOICE_PRINT_SHOW_ALL_DATE]: 'Выводить полностью',
}

export const INVOICE_PRINT_FORM_DATE_KEYS = Object.keys(INVOICE_PRINT_FORM_DATE_VIEW_STATUSES).map(k=> Number(k) as PrintFormDateView)
