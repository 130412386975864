import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { purchaseApi } from './purchase.api';

export const usePurchaseRTK = (id: number) => {
  const { data, isLoading, error } = purchaseApi.useGetPurchaseQuery(id);
  return {
    purchase: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};
