import { Link, Typography } from '@mui/material';
import { IUrl } from '@sr/dto';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  url: IUrl
}

const Url = ({ url }: Props) => {
  return (
    <Typography variant='body1'>
      <Link href={'//' + url} target="_blank" rel="noreferrer">
        {url.url}
      </Link>
    </Typography>
  );
};

export default Url;