import { useEffect } from 'react';
import { useActionCreators, useStateSelector } from 'shared/store';
import { userThunks } from './thunks';

export const useUser = (userId: number) => {
  const { fetchUserThunk } = useActionCreators(userThunks);

  useEffect(() => {
    fetchUserThunk(userId);
  }, [fetchUserThunk, userId]);

  return {
    user: useStateSelector(state => state.user.profile),
    error: useStateSelector(state => state.user.meta.error),

  }

  // return useRemoteItem<UserDto>({
  //   fetchHandler: useCallback(async () => {

  //     const response = await fetchReq<UserDto>(`${BASE_URL}/${userId}`, UserDto);

  //     return response;
  //   }, [userId]),
  // });
};