import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { ourLegalEntitiesApi } from './our-legal-entities.api';
import {
  LegalEntityOurDataMultipartFormCreateDto,
  LegalEntityOurDataMultipartFormUpdateDto,
} from '@sr/dto';

export const useOurEntityData = (id: number) => {
  const { data, isLoading, isFetching, error } = ourLegalEntitiesApi.useGetOurLegalEntityDataQuery(id, {
    refetchOnMountOrArgChange: 120
  });

  return {
    legalEntityOurData: data,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};

export const useAddOurEntityData = (legalEntityId: number) => {
  const [update] = ourLegalEntitiesApi.useAddOurLegalEntityDataMutation();
  return (dto: LegalEntityOurDataMultipartFormCreateDto)=> update({ ...dto, legalEntityId }).unwrap();
};

export const useUpdateOurEntityData = (legalEntityId: number) => {
  const [update] = ourLegalEntitiesApi.useUpdateOurLegalEntityDataMutation();
  return (dto: LegalEntityOurDataMultipartFormUpdateDto)=> update({ ...dto, legalEntityId }).unwrap();
};
