import { CreateUserDto, UserDto } from '@sr/dto';
import { USERS_BASE_URL } from 'shared/api/api';
import { postReq } from 'shared/api/axios';

export const postUser = async (dto: CreateUserDto) => {
  return postReq<CreateUserDto, UserDto>(
    USERS_BASE_URL,
    dto,
    UserDto
  );
};