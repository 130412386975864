import { CompanyDto, CompanyWithManagersDto } from '../company.dto';
import { InvoiceInfoDto } from '../invoice.dto';
import { LeadInfoDto } from '../lead.dto';
import { LegalEntityBaseInfoDto } from '../legal-entity.dto';
import { MediaInfoDto } from '../media-basic-data.dto';
import { IPagedCollection, emptyPagedCollection } from '../pagination';
import { PersonDto } from '../person.dto';
import { PurchaseDto } from '../purchase.dto';

export class SearchResultsDto {
  foundInvoices?: IPagedCollection<InvoiceInfoDto>;
  foundLegalEntities?: IPagedCollection<LegalEntityBaseInfoDto>;
  foundCompanies?: IPagedCollection<CompanySearchResultEntryDto>;
  foundMedia?: IPagedCollection<MediaInfoDto>;
  foundLeads?: IPagedCollection<LeadInfoWithContactsDto>;
  foundPurchases?: IPagedCollection<PurchaseDto>;
}

export type OptionalContacts = {
  emails?: string[];
  phones?: string[];
  urls?: string[];
};

export class CompanySearchResultEntryDto {
  company: CompanyDto;
  directContacts?: OptionalContacts;
  viaPeople?: {
    person: PersonDto;
    personContact: OptionalContacts;
  }[];
}

export class LeadInfoWithContactsDto extends LeadInfoDto {
  contacts: OptionalContacts;
}
