import {
  Divider,
  Stack,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { BankDetailsPicker } from 'shared/ui/formik-fields/bank-details-picker';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { SignerPicker } from 'shared/ui/formik-fields/signer-picker';
import { UserPickerField } from 'features/user/user-picker/ui';
import { ContractTemplatePickerField } from 'shared/ui/formik-fields//contract-template-picker-field';
import { FormValues } from 'components/Contracts/contract.form';
import { EntityRoles } from 'entities/invoice/types';

type Props = {
  ourCompanyId?: number;
  buyerCompanyId?: number;
};

export const ContractFormFields = ({ ourCompanyId, buyerCompanyId }: Props) => {
  const { values } = useFormikContext<FormValues>();

  return (
    <Stack direction='column' spacing={2}>
      <Field
        label='Название'
        fullWidth
        component={TextField}
        name='name' />
      <Stack direction='row' spacing={2}>
        <Field
          required
          label='Дата начала'
          component={DatePickerField}
          name='contractStartDate' />
        <Field
          required
          label='Дата окончания'
          component={DatePickerField}
          name='contractEndDate'
          minDate={values.contractStartDate}
        />
      </Stack>
      <Field
        required
        label='Тип договора'
        component={ContractTemplatePickerField}
        name='contractTemplateId' />

      <Field
        required
        label='Ответственный менеджер'
        component={UserPickerField}
        name='managerId'
        isLinkedToCompany

      />

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2} minWidth={400}>
          <Field
            required
            label='Наше юрлицо'
            fullWidth
            component={LegalEntityPicker}
            disableInactive={true}
            companyIdFilter={ourCompanyId}
            entityRole={EntityRoles.INVOICE_ISSUER}
            name='sellerLegalEntityId' />
          <Field
            required
            label='Наши банковские реквизиты'
            component={BankDetailsPicker}
            name='sellerBankDetailsId'
            legalEntityId={values.sellerLegalEntityId} />
          <Field
            required
            label='Наш подписант'
            component={SignerPicker}
            legalEntityId={values.sellerLegalEntityId}
            name='sellerSignerId' />
        </Stack>
        <Stack direction="column" spacing={2} minWidth={400}>
          <Field
            required
            label='Юрлицо клиента'
            component={LegalEntityPicker}
            disableInactive={true}
            name='buyerLegalEntityId'
            companyIdFilter={buyerCompanyId} />
          <Field
            required
            label='Банковские реквизиты клиента'
            component={BankDetailsPicker}
            name='buyerBankDetailsId'
            legalEntityId={values.buyerLegalEntityId} />
          <Field
            required
            label='Подписант клиента'
            component={SignerPicker}
            legalEntityId={values.buyerLegalEntityId}
            name='buyerSignerId' />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction='column' sx={{ mt: 2 }}>

      </Stack>
    </Stack>
  );
};
