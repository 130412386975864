import { TextField, Autocomplete } from '@mui/material';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import useRegions from 'hooks/regions.hook';
import { RegionInfoDto } from '@sr/dto';

export const RegionPicker = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props;

  const [open, setOpen] = useState(false);
  const { list, loading: fetching } = useRegions(open || Boolean(value));
  const [selectedRegion, setSelectedRegion] = useState<RegionInfoDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: RegionInfoDto | null) => {
      setSelectedRegion(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name, setSelectedRegion]
  );

  useEffect(() => {
    setSelectedRegion(list.find(x => x.id === value) ?? null);
  }, [list, value]);


  const loading = open && list.length === 0 && fetching;


  return (
    <Autocomplete
      value={selectedRegion}
      autoComplete={false}
      options={list}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />
  );
};




