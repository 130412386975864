import { useActionCreators, useStateSelector } from 'shared/store/hooks';
import { toCreateDto, FormData } from './form';
import { linkedManagersThunks } from 'entities/company/linked-managers/linked-managers.slice';

export const useAddManagerLink = () => {
  const { postNewLinkThunk } = useActionCreators(linkedManagersThunks);
  const companyId = useStateSelector(state => state.companyDetails.company?.id);

  const handleSubmit = async (formData: FormData) => {
    if (!companyId) {
      console.error('companyId is undefined');
      return;
    }

    const dto = toCreateDto(companyId, formData);

    return postNewLinkThunk(dto)
      .unwrap();
  };

  return {
    submit: handleSubmit
  };
};