import { CreateMediaDto } from '@sr/dto';
import { FormikErrors } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

export interface FormValues {
  name: string;
  mediaTypeId: number | '';
  cityId: number | '';
}


export const toCreateMedia = (formData: FormValues): CreateMediaDto => ({
  name: formData.name,
  typeId: Number(formData.mediaTypeId),
  cityId: Number(formData.cityId),
});

export const emptyValues: FormValues = {
  name: '',
  mediaTypeId: '',
  cityId: '',
};


export const validate = (values: FormValues) => {
  let errors: FormikErrors<FormValues> = {};


  if (!values.name)
    errors.name = VALIDATION_REQUIRED_FIELD;
  else if (values.name.length < 3)
    errors.name = 'Минимум 3 символа';
  else if (values.name.length >= 256)
    errors.name = 'Максимум 256 символов';

  if (!values.mediaTypeId)
    errors.mediaTypeId = VALIDATION_REQUIRED_FIELD;

  if (!values.cityId)
    errors.cityId = VALIDATION_REQUIRED_FIELD;

  return errors;
};
