import { IPagingData } from '@sr/dto';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

const IPP_FIELD_NAME = 'itemsPerPage';
export const PAGE_FIELD_NAME = 'page';

/**
 * Хук для управлениея пагинацией из searchParams из URL браузера.
 * Достает текущие параметры пагинации и дает функцию, для их изменения
 *
 */
export const usePagingParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get(PAGE_FIELD_NAME);
  const itemsPerPageParam = searchParams.get(IPP_FIELD_NAME);


  const paging: IPagingData = useMemo(() => ({
    page: pageParam !== null ? Number(pageParam) : DEFAULT_PAGINATION.page,
    itemsPerPage: itemsPerPageParam !== null ? Number(itemsPerPageParam) : DEFAULT_ITEMS_PER_PAGE,
  }), [itemsPerPageParam, pageParam]);

  const setPaging = useCallback((paging: IPagingData) => {
    setSearchParams(prev => {
      // если изменили количество строк на странице, то сбросим страницу на 0,
      // иначе, можно выскочить за диапазон
      const newPage = paging.itemsPerPage !== Number(prev.get(IPP_FIELD_NAME)) ? 0 : paging.page;
      // сохраним не тронутыми любые остальные параметры
      const notPagingSearchParams = [...prev.entries()]
        .filter(([key, _]) => key !== PAGE_FIELD_NAME && key !==IPP_FIELD_NAME);
      return [
        ...notPagingSearchParams,
        [PAGE_FIELD_NAME, newPage.toString()],
        [IPP_FIELD_NAME, paging.itemsPerPage.toString()]
      ];
    });
  }, [setSearchParams]);

  return [paging, setPaging] as const;
};
