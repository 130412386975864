import { ReactNode } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Form, FormikProps, FormikValues } from 'formik';

type FormCardProps<TFormValues extends FormikValues> = {
  renderForm: (props: FormikProps<TFormValues>) => ReactNode,
  formikProps: FormikProps<TFormValues>
}

export function FormCardLayout<TFormValues extends FormikValues>({ renderForm, formikProps }: FormCardProps<TFormValues>) {
  return (
    <Form>
      <Stack direction="column" spacing={2}>
        <Box>
          {renderForm(formikProps)}
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button
            type='submit'
            variant='contained'
            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Stack>
    </Form>
  );
}