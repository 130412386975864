import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Form, Formik, FormikHelpers } from 'formik';
import { validate, FormData, initialValues } from './bank-details.form';
import { BankDetailsFormFields } from './bank-details.form-fields';


interface DialogProps {
  open: boolean;
  title: string;
  values?: FormData;
  submit: (formData: FormData) => Promise<void>;
  onClose: () => void;
}

export function BankDetailsDialog(props: DialogProps) {
  const { open, onClose, submit, title, values } = props;

  const handleSubmit = async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await submit(values);
    setSubmitting(false);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={values ? values : initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm, isSubmitting }: any) => (
            <Form>
              <Box sx={{ mt: 1 }}>

                <BankDetailsFormFields />
              </Box>
              <DialogActions>
                <Button variant="contained"
                  type='submit'
                  autoFocus
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'Подождите...' : 'Сохранить'}
                </Button>
                <Button variant="outlined"
                  onClick={onClose}
                  disabled={isSubmitting}>
                  Закрыть
                </Button>
              </DialogActions>
            </Form>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
