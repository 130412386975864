import { Switch, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { useSnack } from 'shared/ui/snack';
import { userFiredStatusApi } from './api';

export const UserFiredStatus = ({ userId }: { userId: number }) => {
  const { data, isFetching, isError } = userFiredStatusApi.useGetUserFiredStatusQuery(userId, { refetchOnMountOrArgChange: 60 });
  const [updateData, { isLoading: isUpdating }] = userFiredStatusApi.useUpdateUserFiredStatusMutation();

  const { showSuccess, showError } = useSnack();

  const isFired = data?.isFired !== undefined
    ? data.isFired
    : true;

  if (isError) {
    showError('Ошибка получения статуса работы пользователя');
  }

  const handleChange = (e: ChangeEvent, checked: boolean) => {
    e.preventDefault();
    updateData({ userId, data: { isFired: !checked } })
      .unwrap()
      .then(({ isFired }) => showSuccess(`Пользователь успешно ${isFired ? 'уволен' : 'принят на работу'}`))
      .catch(e => showError('Ошибка изменения статуса пользователя', e));
  };

  return (
    <FormControlLabel
      disabled={isFetching || isUpdating || isError}
      control={
        <Switch checked={!isFired} onChange={handleChange} />
      }
      label="пользователь работает"
    />
  );
};
