import { CAPPENDIX_STATUS_READY, ContractAppendixStatus, isFile } from '@sr/dto';
import { ContractAppendixDto, UpdateContractAppendixDto } from '@sr/dto/dist/src/contract-appendix.dto';
import { FormDataFileField, FormTools } from 'utils/form-tools';
import { FormikErrors } from 'formik';


export type FormValues = {
  status: ContractAppendixStatus | '',
  date: Date | '',
  scanFile: FormDataFileField,
  extraConditionsText: string,
  promoText: string,
  // promoFileId: 'promoFileId'
};

// type Fields = keyof FormValues;

export const formTools: FormTools<ContractAppendixDto, void, UpdateContractAppendixDto, FormValues> = {
  toCreateDto: formData => null,

  toUpdateDto: (id, formData) => ({
    id: id,
    status: formData.status as ContractAppendixStatus,
    date: new Date(formData.date),
    extraConditionsText: formData.extraConditionsText,
    promoText: formData.promoText,
    scanFile: formData.scanFile === null
      ? null
      : isFile(formData.scanFile)
        ? formData.scanFile
        : undefined
  }),

  fromDto: (dto) => ({
    status: dto.status,
    date: dto.date,
    extraConditionsText: dto.extraConditionsText,
    promoText: dto.promoText,
    scanFile: dto.scanFile
  }),

  validate: (values: FormValues): FormikErrors<FormValues> => {
    let validationResult: FormikErrors<FormValues> = {};

    // if (!values.managerId)
    //   validationResult.managerId = VALIDATION_REQUIRED_FIELD;


    // if (!values.startDate)
    //   validationResult.startDate = VALIDATION_REQUIRED_FIELD;
    // else
    //   validateDateField('startDate', values, validationResult);

    // if (!values.unlimited) {
    //   if (values.endDate === '')
    //     validationResult.endDate = VALIDATION_REQUIRED_FIELD;
    //   else
    //     validateDateField('endDate', values, validationResult);
    // }

    // if (values.startDate && values.endDate)
    //   if (isBefore(values.endDate, values.startDate)) {
    //     validationResult.startDate = 'Дата начала не может быть больше конца';
    //     validationResult.endDate = 'Дата начала не может быть больше конца';
    //   }


    // if (isNaN(Number(values.wccCost)))
    //   validationResult.wccCost = 'Допустимы только числовые значения';

    return validationResult;
  },
  emptyValues: () => ({
    date: new Date(),
    extraConditionsText: '',
    promoText: '',
    status: CAPPENDIX_STATUS_READY,
    scanFile: null
  })
}

