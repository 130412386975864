import { useState, useEffect } from 'react';
import { FormValues, toRegion } from 'entities/geo/regions/region-form';
import { RegionInfoDto } from '@sr/dto';
import { deleteRegion, fetchRegions, postRegion } from 'shared/api/regions';

export const useRegionsList = () => {
  const [regionsList, setList] = useState<RegionInfoDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    fetchRegions()
      .then(response => {
        setList(response);
      })
      .catch(error => {
        setLoading(false);
        setError(error.message);
        setList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const addRegion = async (data: FormValues): Promise<void> => {
    return postRegion(toRegion(data))
      .then(region => {
        setList([...regionsList, region]);
      });
  };

  const removeRegion = (id: number) => {
    return deleteRegion(id)
      .then(() => {
        setList(regionsList.filter(x => x.id !== id));
      });
  };

  return {
    loading,
    error,
    regionsList,
    addRegion,
    removeRegion
  };
};

export default useRegionsList;