import {  useEffect } from 'react';
import { CompanyQueryType,  CreateCompanyDto, IPagingData, UpdateCompanyDto } from '@sr/dto';
import { postCompany } from 'entities/company/details/api';
import { useAppDispatch, useStateSelector } from 'shared/store';
import { companyThunks } from 'entities/company/details/company.slice';
import { BackendSuffixes, companiesListApi } from '../features/company/company-list.api';
import { extractNestJsErrorMessage } from '../shared/api/rtk-query';

export const companyQueryTypeToBackendSuffix = new Map<CompanyQueryType, BackendSuffixes>([
  [CompanyQueryType.SUPPLIERS_ALL, BackendSuffixes.SUPPLIERS_ALL],
  [CompanyQueryType.CLIENTS_ALL,BackendSuffixes.CLIENTS_ALL],
  [CompanyQueryType.CLIENTS_MY, BackendSuffixes.MY_CLIENTS],
  [CompanyQueryType.CLIENTS_FREE_WITH_INVOICES, BackendSuffixes.CLIENTS_FREE_WITH_INVOICES],
  [CompanyQueryType.CLIENTS_WITH_LEADS,BackendSuffixes.CLIENTS_WITH_LEADS],
  [CompanyQueryType.CLIENTS_FREE,BackendSuffixes.CLIENTS_FREE],
  [CompanyQueryType.CLIENTS_ON_FIRED_MANAGERS,BackendSuffixes.CLIENTS_ON_FIRED_MANAGERS],
  [CompanyQueryType.CLIENTS_TO_DETACH,BackendSuffixes.CLIENTS_TO_DETACH],
]);

export const useCompanies = (paging: IPagingData, companyQueryType: CompanyQueryType, params?: URLSearchParams, ) => {
  const urlSuffix = companyQueryTypeToBackendSuffix.get(companyQueryType);
  if (!urlSuffix) {
    throw new Error(`companyQueryType ${companyQueryType} has no corresponding backend url`);
  }
  const { data, isLoading, error } = companiesListApi.useGetSuppliersListQuery({ paging, filter: params?.toString() ?? '', suffix: urlSuffix });
  return {
    collection: { items: data?.items ?? [], totalItemsCount: data?.totalItemsCount ?? 0 },
    isLoading,
    error: extractNestJsErrorMessage(error),
  };
};

export const addCompany = (dto: CreateCompanyDto) => {
  return postCompany(dto);
};

export const useCompany = (companyId: number) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(companyThunks.fetchCompanyThunk(companyId));
  }, [companyId, dispatch]);

  const updateItem = (data: UpdateCompanyDto) => dispatch(companyThunks.updateCompanyThunk(data)).unwrap();

  const company = useStateSelector((state) => state.companyDetails.company);
  const loading = useStateSelector((state) => state.companyDetails.meta.status === 'loading');
  const error = useStateSelector((state) => state.companyDetails.meta.error);

  return {
    company,
    updateCompany: updateItem,
    loading,
    error,
  };
};
