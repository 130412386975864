import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useSnack } from 'shared/ui/snack';
import { addCompany, useCompanies } from 'hooks/companies.hook';
import { CompanyQueryType, CreateCompanyDto, IPagingData } from '@sr/dto';
import { Button } from '@mui/material';
import { AddCompanyDialog } from 'components/Companies/CompanyDialog/company-dialog.add';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePagingParams } from 'shared/hooks/paging-params';
import { companyLinkManager } from 'utils/link-manager';
import { CompaniesList } from 'features/company/companies-list';

const SuppliersListPage = () => {
  const [searchParams] = useSearchParams();

  const heading = 'Поставщики';
  useTitleHook(heading);
  const [paging, setPaging] = usePagingParams();
  const [isAddCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
  const collection = useCompanies(paging, CompanyQueryType.SUPPLIERS_ALL, searchParams);

  const { showSuccess, showError } = useSnack();
  const navigate = useNavigate();

  const handleAddClick = () => {
    setAddCompanyDialogOpen(true);
  };

  const handleAddCompany = async (companyData: CreateCompanyDto) => {
    return addCompany(companyData)
      .then((newCompany) => {
        showSuccess('Компания успешно добавлена');
        navigate(companyLinkManager.link(newCompany.id));
      })
      .catch(error => showError('Ошибка добавления компании: ' + error.message));
  };

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title={heading}
        actions={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
          >
            Добавить
          </Button>}
      />

      <CompaniesList
        companiesCollection={collection}
        paging={paging}
        type={CompanyQueryType.SUPPLIERS_ALL}
        onChangePaging={(pagingData: IPagingData) => setPaging(pagingData)}
      />

      <AddCompanyDialog
        open={isAddCompanyDialogOpen}
        onSubmit={handleAddCompany}
        onClose={() => setAddCompanyDialogOpen(false)}
      />
    </>
  );

};

export default SuppliersListPage;
