import { IPagingData, emptyPagedCollection, WccDto } from '@sr/dto';
import { extractNestJsErrorMessage, REFETCH_TIME_SPAN } from 'shared/api/rtk-query';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { wccExportApi } from '../api';

export const useWccListToExport = (paging: IPagingData) => {
  const { data, error, isLoading } = wccExportApi.useGetWccExportListQuery({ paging }, { refetchOnMountOrArgChange: REFETCH_TIME_SPAN });

  return {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading
  } as IRemotePagedCollection<WccDto>;
};
