import { Typography } from '@mui/material';
import { useSalaryPeriods } from './salary-period.hook';
import { ErrorBanner } from 'shared/ui/error-banner';
import { setPeriodActions } from './salary-period.slice';
import { PeriodPicker } from 'entities/period-picker/period-picker';
import { useActionCreators, useStateSelector } from 'shared/store';


export function SalaryPeriodPicker() {
  const selectedPeriod = useStateSelector((state) => state.salarySelectedPeriod.value);
  const { salaryPeriods, isLoading, error } = useSalaryPeriods();

  const { setPeriod } = useActionCreators(setPeriodActions);

  if (error){
    return <ErrorBanner errorMessage={error}/>;
  }

  return (
    <>
      <Typography variant='subtitle1' sx={{ my:1 }}>Период закрытия</Typography>
      <PeriodPicker periods={salaryPeriods} onSelect={setPeriod} selectedPeriod={selectedPeriod} isLoading={isLoading} />
    </>
  );
}
