import { Type } from "class-transformer";

export class ContractsFilter {
  ourLegalEntityId?: number;
  managerId?: number;

  @Type()
  fromDate?: Date;

  @Type()
  toDate?: Date;
}

export class ContractsDropdownFilter {
  clientCompanyId: number;
}
