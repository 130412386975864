import { FieldProps } from 'formik';
import { MediaTypePicker } from './media-type-picker';

export const MediaTypePickerField = ({ field, form }: FieldProps) => {
  const { setFieldValue, errors, touched } = form;
  const { name, value } = field;
  const error = errors[name];
  const isTouched = touched[name];
  const errorText = isTouched
    ? typeof error === 'string'
      ? error
      : undefined
    : undefined;

  const handleChange = (value: any) => setFieldValue(name, value);

  return (
    <MediaTypePicker
      value={value}
      onChange={handleChange}
      label='Тип'
      error={isTouched && error !== undefined}
      helperText={errorText}
    />);
};
