import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Chip,
  MenuItem,
  TextField,
  Paper
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { CardPaper, CardPaperHeader, CardPaperTitled } from 'shared/ui/card-paper';
import { EventsListFilter } from 'features/events/events-filter/events-filter';

const names = [
  'Дело: звонок вх.',
  'Дело: звонок исх.',
  'Звонок вх.',
  'Звонок исх.',
  'Звонок пропущ.'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  onAddClick: () => void;
};

const CompanyFeedHeader = (props: Props) => {
  const { onAddClick } = props;

  return (
    <Paper>
      <Stack direction="column"  spacing={1}>
        <Stack direction="row" sx={{ p: 2 }}>
          <CardPaperHeader title="Лента событий" />
          <Box display="flex" sx={{ justifyContent: 'flex-end', flexGrow: 1 }}>
            <Button
              onClick={onAddClick}
              variant='contained'
              startIcon={<AddIcon />}
              size='small'
            >
                Добавить
            </Button>
          </Box>
        </Stack>
        <Box sx={{ px:1, pb:1 }}>
          <EventsListFilter />
        </Box>
      </Stack>
    </Paper>
  );
};

export default CompanyFeedHeader;


const EventTypeDropdown = () => {
  const [typeFilter, setTypeFilter] = useState<string[]>([]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setTypeFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  return (
    <TextField
      id="outlined-select-currency-native"
      size='small'
      select
      label='Тип'
      value={typeFilter}
      sx={{ minWidth: 200 }}
      onChange={handleChange}

      SelectProps={{
        multiple: true,
        size: 'small',
        renderValue: (selected: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: string) => (
              <Chip key={value} label={value} size='small' />
            ))}
          </Box>
        )
      }}
    >
      {names.map((name) => (
        <MenuItem
          key={name}
          value={name}
        >
          {name}
        </MenuItem>
      ))}
    </TextField>);
};
