import { Box, Button, DialogActions } from '@mui/material';
import { PayorderImportReportDto } from '@sr/dto';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { Dialog } from 'shared/ui/dialog';

type Props = {
  open: boolean,
  onClose: () => void,
  loading: boolean,
  report: PayorderImportReportDto | null,
  error: string,
};

export const ImportProgressDialog = ({ open, onClose, loading, report, error }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth='lg'
    >
      <Dialog.Title>
        Импорт
        {/* <Dialog.CloseButton onClick={() => props.onClose?.({}, 'escapeKeyDown')} /> */}
      </Dialog.Title>

      <Dialog.Content>
        {report
          ?
          report.error === '' ?
            <Box>
              <LegendList>
                <LegendItem title="Всего обработано" value={report.totalPayordersCount} />
                <LegendItem title="Успешно импортировано" value={report.importedCount} />
                <LegendItem title="Ошибки" value={report.failedCount} />
                <LegendItem title="Пропущено" value={report.skippedCount} />
              </LegendList>
            </Box>
            :
            <ErrorBanner errorMessage={report.error} />
          :
          <Box>
            {error ? <ErrorBanner errorMessage={error} /> : <LoadingBanner />}
          </Box>
        }
      </Dialog.Content>

      <DialogActions sx={{ flex: 1, flexDirection: 'row' }}>
        <Box flexDirection="row" justifyContent="flex-start">
          <Button
            variant="contained"
            disabled={loading}
            onClick={onClose}
          >
            закрыть
          </Button>
        </Box>
      </DialogActions>

    </Dialog >);

};