import { Button, IconButton, ListItem, ListItemText, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCallback, useState } from 'react';
import { fetchLinkedCompanies, postLinkedCompany, deleteLinkedCompany } from 'shared/api/legal-entity';
import { CreateCompanyLegalEntityDto, LegalEntityCompanyDto } from '@sr/dto';
import { useLinkRemoteCollection } from 'utils/remote-collection.hook';
import { ListLayout } from 'shared/ui/list-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import MenuParams from 'shared/ui/menu-params';
import { LinkedCompanyDialog, FormData } from './linked-company-dialog';
import { LinkToCompany } from 'shared/ui/links/link-to-company';


const useLinkedCompanies = (legalEntityId: number) => {
  const { collection, addItem, removeItem } = useLinkRemoteCollection<LegalEntityCompanyDto, CreateCompanyLegalEntityDto>({
    fetchHandler: useCallback(() => fetchLinkedCompanies(legalEntityId), [legalEntityId]),
    postHandler: (linkData) => postLinkedCompany(linkData.legalEntityId, linkData.companyId),
    deleteHandler: deleteLinkedCompany
  });

  return {
    linkedCompanies: collection,
    linkCompany: addItem,
    unlinkCompany: removeItem,
    reload: () => { }
  };
};

export const LegalEntityLinkedCompanies = (props: { legalEntityId: number; }) => {
  const { legalEntityId } = props;

  const { linkedCompanies, linkCompany, unlinkCompany } = useLinkedCompanies(legalEntityId);

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { showError, showSuccess } = useSnack();
  const showConfirm = useConfirmDialog();

  const { openMenu, menuData } = useMenu<LegalEntityCompanyDto>({
    deleteHandler: (target: LegalEntityCompanyDto) => {
      showConfirm({
        content: 'Удалить связь с фирмой?'
      })
        .then(() => unlinkCompany(target.id))
        .then(() => showSuccess('Связь с фирмой успешно удалена'))
        .catch(e => showError(`Ошибка удаления связи с фирмой: ${e.message}`));
    }
  });

  const handleAddCompany = async (formData: FormData) => {
    return linkCompany({
      legalEntityId,
      companyId: formData.companyId
    })
      .then(() => {
        showSuccess('Компания успешно привязана');
        setAddDialogOpen(false);
      });
  };

  return (
    <>
      <ListLayout<LegalEntityCompanyDto>
        items={linkedCompanies}
        itemRender={(item) => <LinkedCompanyItem key={item.id} item={item} onMenuClick={openMenu} />}
        noItemsText='Список привязанных фирм пуст'
      />

      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          autoFocus
          onClick={() => setAddDialogOpen(true)}
        >
          Добавить
        </Button>
      </Box>

      <RowMenu menuData={menuData} />

      <LinkedCompanyDialog
        title="Привязать фирму"
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        submit={handleAddCompany}
      />
    </>
  );
};

type ItemProps = {
  item: LegalEntityCompanyDto,
  onMenuClick: (menuParams: MenuParams<LegalEntityCompanyDto>) => void;
};

const LinkedCompanyItem = ({ onMenuClick, item }: ItemProps) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" onClick={(e) => onMenuClick({ anchor: e.currentTarget, target: item })}>
          <MoreVertIcon />
        </IconButton>}
    >
      <ListItemText
        primary={<LinkToCompany company={item.company} />}
      />
    </ListItem>
  );
};