import { createSlice } from '@reduxjs/toolkit';


type State = {
  dialogOpen: boolean;
  invoiceId: number | null;

}

const initialState: State = {
  dialogOpen: false,
  invoiceId: null
};

const slice = createSlice({
  name: 'ui/print/invoice',
  initialState: initialState,
  reducers: {
    openDialog: (state) => {
      state.dialogOpen = true;
    },
    closeDialog: (state) => {
      state.dialogOpen = false;
    }
  }
})

export const { reducer: printInvoiceReducer, actions: printInvoiceActions } = slice;

