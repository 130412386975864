import { LeadStatusDto } from '@sr/dto';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authorizedRequest } from 'shared/api/axios';
import { LeadDto, CreateLeadDto, LeadInfoDto } from '@sr/dto';
import { postReq, putReq } from 'shared/api/axios';
import { setAuthHeader } from 'shared/api/rtk-query';
import { APP_BASE_URL, LEAD_BASE_URL } from 'shared/api/api';

const LEAD_STATUS_BASE_URL = '/lead-props';
const LEAD_BUDGET_BASE_URL = '/lead-props';
const LEAD_QUALITY_BASE_URL = '/lead-props';

export const leadBaseApi = createApi({
  reducerPath: 'lead',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export const fetchLeadBudgets = async () => {
  const response = await authorizedRequest<void, LeadStatusDto[]>(`${LEAD_BUDGET_BASE_URL}/budget`, 'GET');
  return response;
};

export const fetchLeadQualityDropdown = async () => {
  const response = await authorizedRequest<void, LeadStatusDto[]>(`${LEAD_QUALITY_BASE_URL}/quality-dropdown`, 'GET');
  return response;
};

export const postLead = async (lead: CreateLeadDto) => postReq(LEAD_BASE_URL, lead, LeadInfoDto);

// export const putLead = async (data: UpdateLeadPropsDto) => putReq(`${LEAD_BASE_URL}/${data.id}`, data, LeadDto);

export const putLeadCompany = async (leadId: number, companyId: number) => putReq(`${LEAD_BASE_URL}/${leadId}/company`, { companyId }, LeadDto);
