import {Identifiable} from "./identifiable";
import {IsString, IsBoolean, IsEnum} from "class-validator";


export type StringifiedNull = 'null';
export type StringifiedUndefined = 'undefined';
export enum StringifiedBoolean {
  TRUE = 'true',
  FALSE = 'false',
}

export class PublishingDataDto extends Identifiable
{
  @IsString()
  slug: string;
  @IsString()
  shortDescription: string;
  @IsString()
  fullDescription: string;
  @IsString()
  benefits: string;
  @IsString()
  specificConditions: string;
  smallImage?: File | null | undefined;
  detailedImage?: File | null | undefined;
  @IsBoolean()
  isPublished: boolean;
  @IsBoolean()
  isSubdomain: boolean;
}

export class PublishingDataFormDto extends Identifiable {
  @IsString()
  slug: string;
  @IsString()
  shortDescription: string;
  @IsString()
  fullDescription: string;
  @IsString()
  benefits: string;
  @IsString()
  specificConditions: string;
  smallImage?: File | null | undefined;
  detailedImage?: File | null | undefined;

  @IsEnum(StringifiedBoolean)
  @IsString()
  isPublished: StringifiedBoolean;

  @IsEnum(StringifiedBoolean)
  @IsString()
  isSubdomain: StringifiedBoolean;
}

export type PublishingDataCreateFormDto = Omit<PublishingDataFormDto, 'id'>
