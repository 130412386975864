import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateUserProfileBasicDto } from '@sr/dto';
import { metaState } from 'shared/api/async-meta';
import { fetchUser, putUser } from './api';
import { State } from './user-profile.slice';

const THUNK_PREFIX = 'user/profile';

const fetchUserThunk = createAsyncThunk(
  `${THUNK_PREFIX}/fetch`,
  async (id: number) => fetchUser(id)
);

const updateUserThunk = createAsyncThunk(
  `${THUNK_PREFIX}/update`,
  async (data: UpdateUserProfileBasicDto) => putUser(data)
);


export const buildExtraReducers = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(fetchUserThunk.pending, (state, action) => {
    state.profile = null;
    state.id = action.meta.arg;
    metaState.pending(state.meta);
  });
  builder.addCase(fetchUserThunk.fulfilled, (state, { payload }) => {
    state.profile = payload;
    metaState.done(state.meta);
  });
  builder.addCase(fetchUserThunk.rejected, (state, action) => {
    state.profile = null;
    metaState.error(state.meta, action.error.message);
  });


  builder.addCase(updateUserThunk.fulfilled, (state, { payload }) => {
    state.profile = payload;
  });

}

export const userThunks = {
  fetchUserThunk,
  updateUserThunk
};
