import { Divider } from '@mui/material';
import { CompanyLegalEntities } from './company-legal-entities';
import { CompanyContracts } from './company-contracts-list';


type Props = {
  companyId: number;
}

export const CompanyLegalInfo = (props: Props) => {
  const { companyId } = props;

  return (
    <>
      <CompanyLegalEntities companyId={companyId} />

      <Divider sx={{ mt: 4, mb: 2 }} />

      <CompanyContracts companyId={companyId} />
    </>);
};
