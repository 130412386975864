import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogProps as MuiDialogProps, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';
import { DraggablePaper } from './draggable-paper';

export type DialogProps = MuiDialogProps & {
  onClosed?: () => void,
};

export const Dialog = ({ children, onClosed, ...dialogProps }: DialogProps) => {
  return (
    <MuiDialog
      {...dialogProps}
      // fullWidth
      // maxWidth='lg'
      TransitionProps={{ onExited: onClosed }}
      PaperComponent={DraggablePaper}
    >
      {children}
      {/* <DialogTitle>
        {title}
      </DialogTitle>
      <Dialog.Content>
        {children}
      </Dialog.Content>
      <DialogActions>
        {actions}
      </DialogActions> */}
    </MuiDialog>);
};

Dialog.Content = ({ children }: { children: ReactNode; }) =>
  <DialogContent dividers>
    {children}
  </DialogContent>;

Dialog.Title = ({ children }: { children: ReactNode; }) => (<DialogTitle>{children}</DialogTitle>);

Dialog.Actions = ({ children }: { children: ReactNode; }) => (<DialogActions>{children}</DialogActions>);

Dialog.CloseButton = ({ onClick, disabled }: { disabled?: boolean, onClick: () => void; }) =>
  <IconButton
    onClick={onClick}
    disabled={disabled}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>;

export type DialogButtonProps = {
  onClick: () => void;
  disabled: boolean;
  caption: string;
};

type ButtonsProps = {
  btnConfirmProps: DialogButtonProps;
  btnCancleProps: DialogButtonProps;
};

Dialog.Buttons = ({ btnConfirmProps, btnCancleProps }: ButtonsProps) =>
  <>
    <Button
      variant="contained"
      autoFocus
      onClick={btnConfirmProps.onClick}
      disabled={btnConfirmProps.disabled}
    >
      {btnConfirmProps.caption}
    </Button>
    <Button
      variant="outlined"
      autoFocus
      onClick={btnCancleProps.onClick}
      disabled={btnCancleProps.disabled}
    >
      {btnCancleProps.caption}
    </Button>
  </>; 
