import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import StorageIcon from '@mui/icons-material/Storage';
import GradingIcon from '@mui/icons-material/Grading';
import LanguageIcon from '@mui/icons-material/Language';

import { MediaHomeTab } from 'pages/media/tabs/media-home-tab';
import { useIdParam } from 'utils/url-paramters.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { useTitleHook } from 'hooks/page-title.hook';
import { LoadingBanner } from 'shared/ui/loading-banner';
// import { useMediaBasicData } from 'entities/media/media-basic-data';
import { HorisontalListSubtitle } from 'shared/ui/horizontal-list';
import { ErrorBanner } from 'shared/ui/error-banner';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { useMediaBasicData } from 'entities/media/media-basic-data';
import { NavTabInfo, NavTabs } from 'shared/ui/nav-tabs';
import { useMemo } from 'react';
import { PublishingDataForm } from 'features/publishing-data/publishing-data.form';
import { PublishingDataRelations } from 'entities/publishing-data/publishing-data.hooks';

const MediaDetailsPage = () => {
  const mediaId = useIdParam('mediaId');
  const { media, error } = useMediaBasicData(mediaId);

  useTitleHook(media && `${media.name}`);

  const tabInfos: NavTabInfo[] = useMemo(() =>
    [
      {
        path: 'home',
        label: 'Основное',
        icon: <StorageIcon />,
        component: <MediaHomeTab mediaId={mediaId} />
      },
      {
        path: 'publishing-data',
        label: 'Данные сайта',
        icon: <LanguageIcon/>,
        component:
          <PublishingDataForm
            id={media?media.publishingDataId:null} relatedType={PublishingDataRelations.media} relatedId={mediaId}
          />
      },
      {
        path: 'prices',
        label: 'Цены',
        icon: <AccountBalanceIcon />,
        component: <></>,
        disabled: true
      },
      {
        path: 'cities',
        label: 'Города',
        icon: <ReceiptIcon />,
        component: <></>,
        disabled: true
      },
      {
        path: 'metrics',
        label: 'Метрика',
        icon: <MoveToInboxIcon />,
        component: <></>,
        disabled: true
      },
      {
        path: 'audit',
        label: 'Аудит',
        icon: <GradingIcon />,
        component: <></>,
        disabled: true
      }
    ]
  , [mediaId, media?.publishingDataId]);

  if (error)
    return <ErrorBanner errorMessage={extractNestJsErrorMessage(error)} />;

  if (!media)
    return <LoadingBanner />;


  return (
    <>
      <PageHeader
        title={<PageHeader.Text>{media.name}</PageHeader.Text>}
        subHeader={<HorisontalListSubtitle items={[media.mediaType.name]} />}
        sx={{ mt: 1, mb: 0 }}
      />

      <NavTabs tabs={tabInfos} />
    </>
  );
};


export default MediaDetailsPage;
