import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkedItems: [] as number[],
  dialogOpen: false
}

export const slice = createSlice({
  name: 'wcc/bulk-create/selected-items',
  initialState: initialState,
  reducers: {
    toggleChecked(state, { payload: { id, checked } }) {
      state.checkedItems = checked ? [...state.checkedItems, id] : state.checkedItems.filter(x => x !== id);
    },

    checkAll(state, { payload }) {
      state.checkedItems = payload;
    },

    uncheckAll(state) {
      state.checkedItems = [];
    },

    openDialog(state) {
      state.dialogOpen = true;
    },

    closeDialog(state) {
      state.dialogOpen = false;
    }
  },
}
);

export const { reducer: wccBulkCreateReducer, actions: wccBulkCreateActions } = slice;