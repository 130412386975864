import { PageHeader } from 'shared/ui/details-page-header';
import { LoadingBanner } from 'shared/ui/loading-banner';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useImportedPayorders, usePayOrderImportSession } from 'hooks/payorders-import.hook';
import { useSnack } from 'shared/ui/snack';
import { useParams } from 'react-router-dom';
import { ImportedPayordersList } from 'components/PayOrdersImport/imported-rows';
import { PayorderImportReportDto, RunImportDto, UpdateImportedPayorderDto } from '@sr/dto';
import { useTitleHook } from 'hooks/page-title.hook';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Counters, ImportActions } from 'components/PayOrdersImport/import-actions';
import { postReq } from 'shared/api/axios';
import { ImportProgressDialog } from 'components/PayOrdersImport/import-progress-dialog';


const PayOrdersImportSessionPage = () => {
  const { sessionId: sessionIdStr } = useParams<{ sessionId: string; }>();
  const sessionId = Number(sessionIdStr);
  const [openProgress, setOpenProgress] = useState(false);

  const { item: session, loading } = usePayOrderImportSession(sessionId);

  useTitleHook(`Импорт платежных поручений ${session?.originalFileName}`);

  const { collection, updateItem } = useImportedPayorders(sessionId);

  const { runImport, report, error, loading: importInProgress } = useRunImport();

  const { showSuccessDelete, showFailedDelete } = useSnack();
  const showConfirm = useConfirmDialog();

  const handleUpdateRow = async (data: UpdateImportedPayorderDto) => {
    await updateItem(data.id, data);
  };



  const handleRunImport = async (bankAccountId: number) => {
    setOpenProgress(true);
    runImport(sessionId, bankAccountId);
  };

  if (loading) return <LoadingBanner />;

  const counters: Counters = {
    selectedCount: collection.items.reduce((acc, item) => acc += Number(item.importProps.selected), 0),
    totalCount: collection.items.length,
    invalidCount: collection.items.reduce((acc, item) => acc += Number(!!item.invalidReasons), 0)
  };

  return (
    <>
      <PageHeader title={session?.originalFileName} />

      <Box mb={2}>
        <ImportActions counters={counters} onImportClick={handleRunImport} />
      </Box>

      <Box mb={2}>
        <ImportedPayordersList
          rows={collection.items}
          sessionId={sessionId}
          reload={collection.reload}
          onUpdateRow={handleUpdateRow} />
      </Box>

      <ImportProgressDialog
        open={openProgress}
        onClose={() => setOpenProgress(false)}
        loading={importInProgress}
        report={report}
        error={error} />
    </>
  );
};

export default PayOrdersImportSessionPage;


export const useRunImport = () => {
  const [report, setReport] = useState<PayorderImportReportDto | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const runImport = async (sessionId: number, bankAccountId: number) => {
    setLoading(true);
    return postReq<RunImportDto, PayorderImportReportDto>(
      'accounting/payorders/import/run-import',
      {
        sessionId: sessionId,
        bankAccountId: bankAccountId
      },
      PayorderImportReportDto
    )
      .then(report => setReport(report))
      .catch(e => setError(e.message))
      .finally(() => setLoading(false));
  };

  return {
    loading,
    runImport,
    report,
    error
  };
};