// import { Link } from 'shared/ui/Links/link-base';
import { Link as MaterialLink } from '@mui/material';
import { DownloadFileDto } from '@sr/dto';
import { fileLinkManager } from 'utils/link-manager';
// import { saveAs } from 'file-saver';

export const LinkToFile = ({ file: { id, fileName } }: { file: DownloadFileDto; }) => {
  const link = fileLinkManager.link(id);

  return (
    <MaterialLink href={link} target="_self" download={fileName}>
      {fileName}
    </MaterialLink>
  );
};
