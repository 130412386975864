/* eslint-disable indent */
import { Box, IconButton, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IMediaPriceInfo } from 'interfaces/IMediaPriceInfo';
import { ILinkedEmail, ILinkedPhone } from '@sr/dto/dist/src/contacts';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToFile } from 'shared/ui/links/link-to-file';
import { MoreMenu, useMoreMenu } from 'shared/ui/more-menu';
import { formatDateTime } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';


type Props = {
  priceInfo: IMediaPriceInfo;
}

const MediaPriceInfo = (props: Props) => {
  const { priceInfo: { id, companyInfo, companyContacts, updatedBy, comments, files, createdAt } } = props;
  const comments1 = '';
  const { openMenu, menuData } = useMoreMenu(files.extra.map((file) => ({
    element: <LinkToFile file={file} />
  })));
  return (
    <Box>
      <Stack direction='row' alignItems={'center'} spacing={1}>
        <Box width={300} sx={{ p: 1 }}>
          <LinkToCompany company={companyInfo} />
        </Box>
        <Box width={200} sx={{ p: 1 }}>
          <LinkToFile file={files.main} />
        </Box>
        <Box width={200} sx={{ p: 1 }}>
          <ul>
            {companyContacts.linkedEmails.map((x: ILinkedEmail) => <li key={x.id}>{x.email}</li>)}
          </ul>
          <ul>
            {companyContacts.linkedPhones.map((x: ILinkedPhone) => <li key={x.id}>{x.phone}</li>)}
          </ul>
        </Box>
        <Box width={200} sx={{ p: 1 }}>
          {formatPerson(updatedBy)}
        </Box>
        <Box>
          {comments1}
        </Box>
        <Box>
          {formatDateTime(createdAt)}
        </Box>
        <Box width={100}>
          {files.extra.length
            ? <>
              <IconButton
                onClick={(e) => {
                  openMenu(e.currentTarget);
                }}
                edge="end"><MoreVertIcon />
              </IconButton><MoreMenu {...menuData} />
            </>
            : null
          }
        </Box>
      </Stack>
    </Box>
  );
};

export default MediaPriceInfo;
