import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { FilterFormValues, MediaFilter } from '@sr/dto';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Field, Formik } from 'formik';
import { CardPaper } from 'shared/ui/card-paper';
import { TextField } from 'formik-mui';
import { CityPicker } from 'entities/geo/cities/city-picker';
import { MediaTypePickerField } from 'entities/media/media-type-picker';
import { isNumber, isString } from 'class-validator';
import {
  formValuesFromUrlSearchParams,
  getRestSearchParams,
  mergeFormValuesVsCurrentSearchParams,
} from 'utils/url-search-params.helper';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';

type FormValues = FilterFormValues<MediaFilter>;

const defaultValues: FormValues = {
  title: '',
  cityId: '',
  mediaTypeId: ''
};

export const MediaListFilter = ({ useTitle = true }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback((formValues: FormValues) => {
    setSearchParams(prev => {
      const params = mergeFormValuesVsCurrentSearchParams(prev, formValues, defaultValues);
      // раз новые условия фильтра, сбросим страницу на 0
      params.set(PAGE_FIELD_NAME, '0');
      return params;
    });
  }, [setSearchParams]);

  const handleReset = useCallback(async ()=>{
    setSearchParams(prev=> {
      const rest = getRestSearchParams(prev, defaultValues);
      // раз фильтра больше нет, сбросим страницу на 0
      rest.set(PAGE_FIELD_NAME, '0');
      return rest;
    });
  }, [setSearchParams]);

  const cityIdValidator = (value: string|number): string =>
    (isString(value) && value.length === 0) || isNumber(value)
      ? ''
      : 'Выберите город из списка';
  return (
    <CardPaper>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={formValuesFromUrlSearchParams(searchParams, defaultValues)}
      >{
          ({ submitForm, resetForm, isValid, dirty }) => {
            return (
              <Stack direction="row" spacing={1} >
                {useTitle &&
                  <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                    <Field
                      fullWidth
                      name="title"
                      label="Название"
                      component={TextField}
                    />
                  </Box>}
                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="cityId"
                    label="Город"
                    validate={cityIdValidator}
                    component={CityPicker}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: useTitle ? 0 : 1 }}>
                  <Field
                    fullWidth
                    name="mediaTypeId"
                    label="Тип СМИ"
                    component={MediaTypePickerField}
                  />
                </Box>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  disabled={!dirty || !isValid}
                  endIcon={<FilterListIcon />}
                >
                  Применить
                </Button>
                <Button
                  onClick={() => { resetForm(); handleReset() }}
                  variant="outlined"
                  endIcon={<HighlightOffIcon />}
                >
                  очистить
                </Button>
              </Stack>
            );
          }
        }
      </Formik>
    </CardPaper >
  );
};
