import { createSlice } from '@reduxjs/toolkit';


type State = {
  dialogOpen: boolean;
}

const initialState: State = {
  dialogOpen: false
};

const slice = createSlice({
  name: 'ui/change-password',
  initialState: initialState,
  reducers: {
    openDialog: (state) => {
      state.dialogOpen = true;
    },
    closeDialog: (state) => {
      state.dialogOpen = false;
    }
  }
})

export const { reducer: changePasswordReducer, actions: changePasswordActions } = slice;


