import { Fragment } from 'react';
import { Box, Stack, Typography } from '@mui/material';

const Separator = <Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />;

export const HorisontalListSubtitle = ({ items }: { items: string[] }) => {
  if (items.length === 0) return <></>;

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        {items.map((item, idx, arr) =>
          <Fragment key={`${idx}-${item}`}>
            <Typography component="span" variant='subtitle2' color="grey.500">
              {item}
            </Typography>

            {idx !== (arr.length - 1) && Separator}
          </Fragment>
        )}
      </Stack>
    </>
  )
}