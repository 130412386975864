import { UserDto, IPagedCollection } from '@sr/dto';
import { CollectionParams } from 'shared/api/rtk-query';

import { userApi } from 'entities/user/api';
import { USERS_BASE_URL } from 'shared/api/api';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';

const injectedUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query<IPagedCollection<UserDto>, CollectionParams>({
      query: ({ paging, filter }: CollectionParams) => ({
        url: `${USERS_BASE_URL}/?${filter}`,
        params: paging && pagingDataToParams(paging)
      }),
    }),
  }),
});

export const userListApi = injectedUserApi.enhanceEndpoints({
  addTagTypes: ['userList'],
  endpoints: {
    getUserList: {
      providesTags: (result, error, query) => (
        result ?
          [{
            type: 'userList',
            id: `${String(query.filter)}_${String(query.paging?.page)}_${String(query.paging?.itemsPerPage)}`
          }]
          : ['userList']
      ),
    },
  }
});

