import { useRef } from 'react';
import { PaperProps, Paper } from '@mui/material';
import Draggable from 'react-draggable';

export const DraggablePaper = (props: PaperProps) => {
  const nodeRef = useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      cancel={'[class*="MuiDialogContent-root"], [class*="MuiDialogActions-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}