import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { wccExportApi } from './api';


export const useWccExport = () => {
  const [postExport, { data, isLoading, error, reset }] = wccExportApi.useRunExportMutation();

  const runExport = async (idList: number[]) => {
    if (idList.length === 0) return Promise.reject();

    return postExport(idList).unwrap();
  }


  return {
    runExport,
    report: data || null,
    loading: isLoading,
    error: extractNestJsErrorMessage(error),
    reset
  }
}