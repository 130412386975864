import { IsNotEmpty, IsNumber, IsNumberString, IsString, Length } from "class-validator";

export class CreateBankDetailsDto {
  @IsString()
  @IsNotEmpty()
  bankName: string = '';

  @IsNumberString({ no_symbols: true })
  @Length(9)
  bik: string = '';

  @IsNumberString({ no_symbols: true })
  @Length(20)
  accountNumber: string = '';

  @IsNumberString({ no_symbols: true })
  @Length(20)
  corrAccountNumber: string = '';

  @IsNumber()
  legalEntityId: number = NaN;
}

export class BankDetailsDto extends CreateBankDetailsDto {
  id: number = NaN;
}

export class UpdateBankDetailsDto extends CreateBankDetailsDto {
  id: number;
}
