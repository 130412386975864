import { IPagedCollection, LegalEntityBaseInfoDto } from '@sr/dto';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { TextWithHighlight } from 'shared/ui/text-with-highlight';
import { formatLegalEntity } from 'utils/legal-entity-format-helpers';
import { SearchResultSection } from './search-result-section';

type Props = {
  highlight: string;
  list?: IPagedCollection<LegalEntityBaseInfoDto>;
}

export const FoundLegalEntities = ({ list, highlight }: Props) => {
  if (!list)
    return null;

  return (
    <SearchResultSection
      title="Юрлица"
      items={list}
      content={list.items.map(entity =>
        <div key={entity.id}>
          <LinkToLegalEntity legalEntity={entity}>
            <TextWithHighlight component='span' variant='body1' hlText={highlight}>{formatLegalEntity(entity)}</TextWithHighlight>
          </LinkToLegalEntity>
          &nbsp;ИНН: <TextWithHighlight component='span' variant='body1' hlText={highlight}>{entity.inn}</TextWithHighlight>
        </div>)}
      emptyResultText="Юрлица не найдены"
    />
  );
};

