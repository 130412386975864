import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { FilterFormValues } from '@sr/dto';
import { UserPicker } from 'features/user/user-picker/user-picker';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { formValuesFromUrlSearchParams, urlSearchParamsFromFormValues } from 'utils/url-search-params.helper';

type EventsFilter = {
  userId: number | '';
  fromDate: Date | null,
  toDate: Date | null,
  fromDueDate: Date | null,
  toDueDate: Date | null,
  isImportantOnly: boolean;
  isCompletedHidden: boolean;
}

export const defaultFormValues: EventsFilter = {
  userId: '',
  fromDate: null,
  toDate: null,
  fromDueDate: null,
  toDueDate: null,
  isImportantOnly: false,
  isCompletedHidden: false
};

export type FormValues = FilterFormValues<EventsFilter>;

export const EventsListFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultFormValues),
    onSubmit: () => {},
    validateOnChange: true,
  });

  useEffect(() => {
    if (formik.isValid) {
      setSearchParams(urlSearchParamsFromFormValues(formik.values, defaultFormValues));
    }
  }, [formik.values, formik.isValid, setSearchParams]);

  const onUserChange = (value: any) => {
    formik.setFieldValue('userId', String(value));
  };

  return (
    <FormikProvider value={formik}>
      <Stack spacing={3} direction="row">
        <Box sx={{
          minWidth: 300, flexGrow:2
        }}>
          <UserPicker value={formik.values.userId} onChange={onUserChange} label="Менеджер" size="small"
          />
          <Stack direction="row">
            <Box>
              <Field
                type="checkbox"
                name="isImportantOnly"
                Label={{ label: 'Только важные' }}
                component={CheckboxWithLabel}
              />
            </Box>
            <Box>
              <Field
                type="checkbox"
                name="isCompletedHidden"
                Label={{ label: 'Скрыть выполненные' }}
                component={CheckboxWithLabel}
              />
            </Box>
          </Stack>
        </Box>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Field
              fullWidth
              name="fromDate"
              label="Дата события с"
              size="small"
              clearable
              component={DatePickerField}
            />
            <Field
              fullWidth
              name="toDate"
              label="Дата события по"
              size="small"
              clearable
              component={DatePickerField}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Field
              fullWidth
              name="fromDueDate"
              label="Назначено дело с"
              size="small"
              clearable
              component={DatePickerField}
            />
            <Field
              fullWidth
              name="toDueDate"
              label="Назначено дело по"
              size="small"
              clearable
              component={DatePickerField}
            />
          </Stack>
        </Stack>
      </Stack>
    </FormikProvider>
  );
};
