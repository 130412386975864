import { useCallback, useEffect, useState } from 'react';
import { CompanyDto } from '@sr/dto';
import { fetchCompaniesDropdown } from '../entities/company/details/api';
import { debounce } from '@mui/material';
import { useSnack } from 'shared/ui/snack';

const DEBOUNCE_DELAY = 300;

export const useCompaniesDropdown = (name: string = '', legalEntityId?: number) => {
  const [items, setItems] = useState<CompanyDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showError } = useSnack();

  const fetchCompanies = async () => {
    setIsLoading(true);
    try {
      const response = await fetchCompaniesDropdown(name, legalEntityId);
      setItems(response);
    } catch (error) {
      showError('Не удалось получить список фирм');
      setItems([]);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchCompanies = useCallback(debounce(() => {
    fetchCompanies();
  }, DEBOUNCE_DELAY), [name, legalEntityId]);


  useEffect(() => {
    if (!name && !legalEntityId) {
      setItems([]);
      return;
    }

    debouncedFetchCompanies();
  }, [name, legalEntityId]);

  useEffect(() => {
    return () => {
      debouncedFetchCompanies.clear();
    };
  }, [debouncedFetchCompanies]);

  return { items, isLoading };
};
