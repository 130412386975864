import { MultipartData, PayorderParseReportDto } from '@sr/dto';
import { plainToInstance } from 'class-transformer';
import { useState } from 'react';
import { authorizedFormDataRequest } from 'shared/api/axios';

export const useImport = () => {
  const [loading, setLoading] = useState(false);

  async function uploadAndParse(data: MultipartData) {
    setLoading(true);

    return authorizedFormDataRequest<PayorderParseReportDto>(
      'accounting/payorders/import',
      'POST',
      data
    )
      .then(response => plainToInstance(PayorderParseReportDto, response, { enableImplicitConversion: true }))
      .finally(() => setLoading(false));
  }

  return {
    uploadAndImport: uploadAndParse,
    loading
  };
};