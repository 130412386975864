import { IRemoteCollection } from 'utils/remote-collection.hook';
import { leadStatusApi } from './api';
import { LeadStatusDto } from '@sr/dto';

export const useLeadStatuses = (): IRemoteCollection<LeadStatusDto> => {
  const { data, isLoading, refetch } = leadStatusApi.useGetStatusesListQuery();

  return {
    items: data ?? [],
    isLoading: isLoading,
    error: '',
    reload: () => refetch(),
  };
};
