import { useState, useEffect } from 'react';
import { toCountry, FormValues } from 'entities/geo/countries/country-form';
import { deleteCountry, fetchCountries, postCountry } from 'shared/api/countries';
import { CountryInfoDto } from '@sr/dto';

export const useCountriesList = () => {

  const [list, setList] = useState<CountryInfoDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    fetchCountries()
      .then((response) => {
        setList(response);
      })
      .catch(error => {
        setError(error.message);
        setList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const addCountry = async (data: FormValues): Promise<void> => {
    return postCountry(toCountry(data))
      .then(region => {
        setList([...list, region]);
      });
  };

  const removeCountry = (id: number) => {
    return deleteCountry(id)
      .then(() => {
        setList(list.filter(x => x.id !== id));
      });
  };



  return ({
    isLoading: loading,
    error: error,
    list: list,
    addCountry,
    removeCountry
  });
};