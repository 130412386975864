import { SyntheticEvent, useState } from 'react';
import { Paper, Box, Tabs, Tab } from '@mui/material';
import { ContractDto } from '@sr/dto';
import { TabPanel } from 'shared/ui/tab-panel';
import { AppendiciesList } from './contract-appendicies-list';

export const ContractTabs = ({ contract }: { contract: ContractDto; }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ p: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
        <Tabs value={value} onChange={handleChange} >
          <Tab label="Приложения" />
          <Tab label="Счета" />
          <Tab label="Аудит" />
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <AppendiciesList appendicies={contract.appendicies} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Счета (TODO)
        </TabPanel>
        <TabPanel value={value} index={2}>
          Аудит (TODO)
        </TabPanel>
      </Box>
    </Paper>);
};