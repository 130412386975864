import { LeadStatusDto } from '@sr/dto';
import { leadBaseApi } from 'entities/lead/api';
import { LEAD_BASE_URL } from 'shared/api/api';

const injectedApi = leadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatusesList: builder.query<LeadStatusDto[], void>({
      query: () => ({
        url: `lead-props/status-dropdown`,
        method: 'GET',
      }),
    }),
  }),
});

export const leadStatusApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['*'],
  endpoints: {
    getStatusesList: {
      providesTags: ['*'],
    },
  },
});
