import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'shared/ui/link-base';

type Props = {
  links: {
    text: string,
    to?: string,
  }[]
}

export const PageBreadCrumbs = ({ links }: Props) => (
  <Breadcrumbs >
    {links.map(({ to, text }) => (
      to ?
        (<Link key={`${to}-${text}`} to={to}>
          {text}
        </Link>)
        :
        (<Typography key={`${text}`} color="text.primary">{text}</Typography>)
    ))}
  </Breadcrumbs>
);