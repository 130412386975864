import { IsNotEmpty, IsNumber, IsString } from "class-validator";

export class CreateAddressDto {
    @IsString()
    @IsNotEmpty()
    custom: string;
}

export class UpdateAddressDto extends CreateAddressDto { }

export class AddressDto extends CreateAddressDto {
    @IsNumber()
    id: number;
}