import { Stack, Box, Button } from '@mui/material';
import { CardPaper } from 'shared/ui/card-paper';
import { useBankAccountsDropdown } from 'hooks/bank-accounts.hook';
import { useState } from 'react';
import { BankAccountPicker } from './bank-account-picker';

export type Counters = {
  selectedCount: number,
  totalCount: number,
  invalidCount: number;
};

type Props = {
  counters: Counters,
  onImportClick: (bankAccountId: number) => void;
};


export const ImportActions = ({ counters, onImportClick }: Props) => {
  const [selectedBankAccount, setBankAccount] = useState<number | undefined>(undefined);

  const accounts = useBankAccountsDropdown();

  return (
    <CardPaper>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box>
          {`Выбрано : ${counters.selectedCount} из ${counters.totalCount}`} &nbsp;&nbsp;&nbsp;
          {`Доступно для импорта: ${counters.totalCount - counters.invalidCount}`}&nbsp;&nbsp;&nbsp;
          {`Недоступно для импорта: ${counters.invalidCount}`}
        </Box>
        <Box width={500}>
          <Stack direction="row" spacing={2}>
            <Box flexGrow={1}>
              <BankAccountPicker
                value={selectedBankAccount}
                collection={accounts}
                onChange={(value) => setBankAccount(value)} />
            </Box>

            <Box>
              <Button
                variant='contained'
                disabled={counters.selectedCount === 0 || selectedBankAccount === undefined}
                onClick={() => selectedBankAccount && onImportClick(selectedBankAccount)}
              >
                Импортировать
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </CardPaper>
  );
};
