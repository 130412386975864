import { Chip } from '@mui/material';
import { UserDto } from '@sr/dto';
import { formatPerson } from 'utils/person-format-helpers';
import PersonIcon from '@mui/icons-material/Person';

export const LinkedManagerChip = ({ manager }: { manager: UserDto }) => {
  return <Chip
    sx={{ mr: 1 }}
    avatar={<PersonIcon />}
    label={formatPerson(manager)}
    size="small" />;
}