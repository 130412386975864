import { TableCell, Button } from '@mui/material';
import { InvoiceRowDto } from '@sr/dto';

type PlacementButtonProps = {
  showButton: boolean;
  text: string;
  variant: 'text' | 'outlined' | 'contained';
}

export const PlacementCell = ({ showButton, text, variant, onPlaceClick }: PlacementButtonProps & { onPlaceClick: () => void }) => {
  return (
    <TableCell>
      {showButton && <Button
        size='small'
        variant={variant}
        onClick={onPlaceClick}>
        {text}
      </Button>}
    </TableCell >);
};

export const usePlacementCell = (isNewRow: boolean, row: InvoiceRowDto, isClientInvoice: boolean) => {
  const buttonProps: PlacementButtonProps = {
    text: '',
    variant: 'text',
    showButton: !isNewRow
  }

  let disableDelete = false;

  if (isClientInvoice) {
    buttonProps.text = row.linkedClientPurchase ? `Заявка № ${row.linkedClientPurchase.id}` : 'Разместить';
    buttonProps.variant = row.linkedClientPurchase ? 'text' : 'outlined';
    disableDelete = !!row.linkedClientPurchase;
  }
  else {
    buttonProps.text = row.linkedSupplierPurchase ? 'Отвязать' : 'Привязать';
    buttonProps.variant = row.linkedSupplierPurchase ? 'outlined' : 'contained';
    disableDelete = !!row.linkedSupplierPurchase;
  }

  return {
    buttonProps,
    disableDelete,
  }
}
