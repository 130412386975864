import { PublishingDataCreateFormDto, PublishingDataFormDto, } from '@sr/dto';
import { publishingDataApi } from './api-slice';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';

export const usePublishingData = (id: number|null) => {
  if (!id) {
    return { item: undefined, isLoading: false };
  }
  const { data, isLoading, error } = publishingDataApi.useGetPublishingDataQuery(id);
  return {
    item: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};
export enum PublishingDataRelations {
  mediaType,
  media,
  city,
  region,
  country
}

const publishingDataEndpointsByType: Map<PublishingDataRelations, string> = new Map<PublishingDataRelations, string>([
  [PublishingDataRelations.mediaType, 'media-type'],
  [PublishingDataRelations.media, 'media'],
  [PublishingDataRelations.city, 'city'],
  [PublishingDataRelations.region, 'region'],
  [PublishingDataRelations.country, 'country'],
]);
export const useAddPublishingData = (relation: PublishingDataRelations, relationId: number)=>{
  const relationUrl = publishingDataEndpointsByType.get(relation);
  if (!relationUrl)
    throw Error('PublishingData relation is not known');
  const urlSuffix = `${publishingDataEndpointsByType.get(relation)}/${relationId}`;
  const [update, { isLoading }] = publishingDataApi.useAddPublishingDataMutation();
  const addPublishingData = async (data: PublishingDataCreateFormDto)=> update({ urlSuffix,  data }).unwrap();
  return { addPublishingData, isUpdating: isLoading };
};

export const useUpdatePublishingData = () => {
  const [ update] = publishingDataApi.useUpdatePublishingDataMutation();
  return async (newMediaType: PublishingDataFormDto) => {
    return update(newMediaType).unwrap();
  };
};
