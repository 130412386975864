import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { TextFieldProps } from 'formik-mui';

export const VatPickerField = (props: TextFieldProps & { sellerVat: number, rowVat: number }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
    sellerVat,
    rowVat
  } = props;

  const onChange = (e: SelectChangeEvent) => {
    setFieldValue(name, e.target.value);
  }

  return (
    <Select
      renderValue={(value: any) => <>{value ? `${value}%` : 'Без НДС'}</>}
      value={value}
      onChange={onChange}
      size='small'
    >
      <MenuItem value={0}>Без НДС</MenuItem>
      {(sellerVat !== rowVat && rowVat) && <MenuItem value={rowVat}> {rowVat}%</MenuItem>}
      <MenuItem value={sellerVat}> {sellerVat}%</MenuItem>
    </Select>
  )
}