import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { cashBalanceApi } from '../cash-balance.api';


export const useCashBalancePeriods = () => {
  const { data, isLoading, error } = cashBalanceApi.useGetCashBalancePeriodsQuery();
  return {
    cashBalancePeriods: data,
    error: extractNestJsErrorMessage(error),
    isLoading
  };
};
