import { useSearchParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import {
  formValuesFromUrlSearchParams,
  mergeFormValuesVsCurrentSearchParams,
} from 'utils/url-search-params.helper';
import { useEffect } from 'react';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';

type Props = {
  queryParamName: string,
  size?: 'small' | 'medium',
  label?: string,
}

type FormValues = Record<string, Date | null>

export const DateUrlParamsFilter = ({ queryParamName, size = 'medium', label = 'Дата' }: Props) => {
  const defaultValues: FormValues = {
    [queryParamName]: null
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const formik = useFormik({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultValues),
    onSubmit: () => {},
    validateOnChange: true,
  });

  const formValue: Date | null = formik.values[queryParamName] ?? null;

  useEffect(() => {
    if (!formik.isValid) return ;
    setSearchParams(prev => {
      const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultValues);
      if (Object.keys(formik.touched).length || formik.dirty) {
        // раз пользователь обновил фильтр, сбрасываем страницу на 0
        params.delete(PAGE_FIELD_NAME);
      }
      return params;
    });
  }, [formValue]);

  return (
    <FormikProvider value={formik}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        size={size}
        clearable
        component={DatePickerField}
      />
    </FormikProvider>
  );
};
