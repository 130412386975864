import { FormValues, validate, emptyValues } from './form-data';
import { useStateSelector } from 'shared/store';
import { FormDialog } from 'shared/ui/form-dialog';
import { useAddMediaDialog } from './lib';
import { Stack } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { MediaTypePickerField } from 'entities/media/media-type-picker';
import { CityPicker } from 'entities/geo/cities/city-picker';


type Props = {
  onSubmit: (data: FormValues) => Promise<void>;
}

export const MediaCreateDialog = ({ onSubmit }: Props) => {
  const isOpen = useStateSelector(state => state.ui.media.addNewMediaDialogOpen);
  const { closeDialog } = useAddMediaDialog();

  return (
    <FormDialog
      onClose={closeDialog}
      open={isOpen}
      renderTitle="Новое СМИ"
      renderForm={(props) => (
        <>
          <Stack direction="column" spacing={2}>
            <Field
              name="name"
              label="Название"
              required
              component={TextField}
            />
            <Field
              name="mediaTypeId"
              select
              label="Тип"
              required
              component={MediaTypePickerField}
            />
            <Field
              name='cityId'
              label='Город'
              required
              component={CityPicker}
            />
          </Stack >
        </>
      )}
      initialValues={emptyValues}
      onSubmit={onSubmit}
      validate={validate}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg'
      }} />
  );
};
