import { Divider } from '@mui/material';
import { CompanyDto, InvoiceStatus, LegalEntityBaseInfoDto } from '@sr/dto';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { CardPaper } from 'shared/ui/card-paper';
import { InvoiceStatusSpan } from '../invoice/status-span/status-span';

type Props = {
  buyer: CompanyDto,
  buyerLegalEntity: LegalEntityBaseInfoDto,
  sellerLegalEntity: LegalEntityBaseInfoDto,
  status: InvoiceStatus
}

export const SellerCard = ({ buyer, buyerLegalEntity, sellerLegalEntity, status }: Props) => {
  return (
    <CardPaper>
      <LegendList>
        <LegendItem title="Статус: " value={
          <>
            <InvoiceStatusSpan status={status} />
          </>
        } />
        {/* <LegendItem title="Сумма: " value={<PriceSpan price={invoice.totals.discountPriceTotal} />} /> */}
        <LegendItem title="Фирма: " value={<LinkToCompany company={buyer} />}></LegendItem>
        <LegendItem title="Юрлицо:" value={<LinkToLegalEntity legalEntity={buyerLegalEntity} />} />
        <LegendItem title="Наше юрлицо:" value={<LinkToLegalEntity legalEntity={sellerLegalEntity} />} />
        <Divider sx={{ pt: 1 }} />
        {/* <LegendItem title="Услуга: " value={invoiceRow.description} /> */}
        {/* <LegendItem title="Цена: " value={<PriceSpan price={invoiceRow.priceDiscount} />} /> */}
        {/* <LegendItem title="СМИ: " value={invoiceRow?.media &&
            <LinkToMedia media={invoiceRow.media}>
              {invoiceRow.media.name}
            </LinkToMedia>
          } /> */}
      </LegendList>
    </CardPaper>
  );
};
