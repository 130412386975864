import { UpdateUserRolesDto, UserRolesDto } from '@sr/dto';
import { userApi }                          from 'entities/user/api';

const injectedUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles         : builder.query<UserRolesDto[], number>({
      query: (userId: number) => `users/${userId}/roles`,
    }),
    updateRoles: builder.mutation<UpdateUserRolesDto, { userId: number, data: UpdateUserRolesDto }>({
      query: ({ userId, data }) => ({
        url   : `users/${userId}/roles`,
        body  : data,
        method: 'PUT',
      }),
    }),
  }),
});

export const userRoleApi = injectedUserApi.enhanceEndpoints({
  addTagTypes: ['user-roles'],
  endpoints  : {
    getRoles: {
      providesTags: (result, error, query) => result
        ? [{ type: 'user-roles', id: query }]
        : ['user-roles'],
    },
  },
});
