import { CityInfoDto } from '@sr/dto';
import { cityListApi } from 'entities/geo/cities/cities-list/apiSlice';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';

export const useDeleteCity = () => {
  const showConfirmDialog = useConfirmDialog();
  const { showSuccess, showError } = useSnack();

  const [deleteCity] = cityListApi.useDeleteCityMutation();

  return (city: CityInfoDto) => {
    showConfirmDialog({
      content: `Удалить город ${city?.name}?`
    })
      .then(() => deleteCity(city.id).unwrap())
      .then(() => showSuccess('Город успешно удален'))
      .catch((e) => showError('Ошибка удаления города', e));
  };
}