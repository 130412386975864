import { TableRow, TableCell, Stack, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { InvoiceRowDto, UpdateInvoiceRowDto } from '@sr/dto';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { MediaPickerField } from 'entities/media-picker';
import { VatPickerField } from 'shared/ui/formik-fields/vat-picker';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import PricePair from 'shared/ui/price-pair';
import PriceSpan from 'shared/ui/price-span';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { formatDate, formatDatePeriod } from 'utils/date-format-helpers';
import { FormData, toDto, fromDto, validate } from './invoice-row.form';
import { usePlacementCell, PlacementCell } from './placement-cell';

type RowProps = {
  row: InvoiceRowDto;
  isNewRow?: boolean;
  isReadOnly?: boolean;
  isClientInvoice: boolean;
  sellerVat: number;
  onPlaceClick?: (row: InvoiceRowDto) => void;
  onSaveClick: (data: UpdateInvoiceRowDto) => Promise<void>;
  onCancelClick?: (row: InvoiceRowDto) => void;
  onDeleteClick?: (row: InvoiceRowDto) => Promise<void>;
  isCompact?: boolean;
};

export const InvoiceRow = ({ isNewRow, isReadOnly = false, row, sellerVat, isClientInvoice, onPlaceClick, onSaveClick, onDeleteClick, onCancelClick, isCompact = false }: RowProps) => {
  const {
    media,
    description,
    startDate,
    endDate,
    amount,
    price,
    priceExclVat,
    priceDiscount,
    vat
  } = row;

  const [editMode, setEditMode] = useState(false);

  const onSubmitClick = async (values: FormData) => {
    setEditMode(false);
    return onSaveClick(toDto(row, values));
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };
  const period = `${formatDate(startDate)} - ${formatDate(endDate)} (${formatDatePeriod(new Date(startDate), new Date(endDate))})`;
  const dispalyEdit = editMode || isNewRow;

  const { buttonProps, disableDelete } = usePlacementCell(!!isNewRow, row, isClientInvoice);

  return (
    <TableRow
    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <Formik
        initialValues={fromDto(row)}
        onSubmit={onSubmitClick}
        validate={validate}
        enableReinitialize
      >{
          ({ submitForm, resetForm, dirty, isValid }) => (
            <>
              {isClientInvoice &&
                <TableCell width={300}>
                  {!dispalyEdit
                    ? (media ? <LinkToMedia media={media}>{media.name}</LinkToMedia> : '-')
                    : <Field name='mediaId' component={MediaPickerField} size='small' />}
                </TableCell>
              }
              <TableCell >
                {!dispalyEdit
                  ? description
                  : <Field name='description' component={TextField} size='small' multiline fullWidth />}
              </TableCell>

              <TableCell width={200} >
                {!dispalyEdit
                  ? period
                  : <>
                    <Stack direction='column' spacing={1}>
                      <Field name='startDate' label='' component={DatePickerField} size='small' sx={{ width: 150 }} />
                      <Field name='endDate' label='' component={DatePickerField} size='small' sx={{ width: 150 }} />
                    </Stack>
                  </>}
              </TableCell>

              <TableCell width={50}>
                {!dispalyEdit
                  ? `${amount} шт.`
                  : <Field name='amount' label='' component={TextField} size='small' />}
              </TableCell>

              <TableCell width={130}>
                {amount && <PriceSpan size='small' price={(priceExclVat / amount)} />}
              </TableCell>

              <TableCell width={130}>
                <PriceSpan size='small' price={priceExclVat} />
              </TableCell>

              <TableCell width={130}>
                {!dispalyEdit
                  ? <PricePair size='small' price={price} priceDiscount={priceDiscount} />
                  : <>
                    <Stack direction='column' spacing={1}>
                      <Field name='price' label='без скидки' component={TextField} size='small' />
                      <Field name='priceDiscount' label='со скидкой' component={TextField} size='small' />
                    </Stack>
                  </>
                }
              </TableCell>

              <TableCell width={100}>
                {!dispalyEdit
                  ? (vat ? `${vat}%` : '-')
                  : <Field name='vat' sellerVat={sellerVat} rowVat={row.vat} component={VatPickerField} />
                }
              </TableCell>

              { !isCompact &&
                 <PlacementCell {...buttonProps} onPlaceClick={() => onPlaceClick?.(row)} />
              }

              <TableCell align="right" width={130}>
                {!dispalyEdit
                  ?
                  <IconButton edge="end" onClick={toggleEdit} disabled={isReadOnly}>
                    <EditIcon />
                  </IconButton>
                  : <>
                    <IconButton edge="end"
                      onClick={submitForm}
                      disabled={!dirty || !isValid}
                      type='submit'>
                      <CheckIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => {
                      resetForm();
                      toggleEdit();
                      onCancelClick?.(row);
                    }}>
                      <CancelIcon />
                    </IconButton>
                  </>
                }
                {!isNewRow && <IconButton
                  edge="end"
                  disabled={isReadOnly || disableDelete}
                  onClick={() => onDeleteClick?.(row)}>
                  <DeleteIcon />
                </IconButton>}
              </TableCell>
            </>
          )}
      </Formik>
    </TableRow >);
};
