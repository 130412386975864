import { Step, StepLabel, Stepper } from '@mui/material'
import { ContractStatus, CONTRACT_STATUSES, CONTRACT_STATUSES_KEYS } from '@sr/dto'

export const ContractStatusStepper = ({ status }: { status: ContractStatus }) => {

  const steps = CONTRACT_STATUSES_KEYS.map(status => CONTRACT_STATUSES[status])

  return (
    <Stepper activeStep={status - 1}  >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}