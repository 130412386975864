import { IPagingData, PurchaseDto, PurchaseInfoDto } from '@sr/dto';
import { useCallback } from 'react';
import { fetchInvoicePurchases, fetchPurchases, fetchUnlinkedPurchases } from 'shared/api/purchases';
import { useReadOnlyRemoteCollection } from 'utils/remote-collection.hook';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const usePurchases = (paging: IPagingData, filterString?: string,) => {
  return useReadOnlyRemotePagedCollection<PurchaseInfoDto>({
    fetchHandler: useCallback(() => fetchPurchases(paging, filterString), [filterString, paging]),
  });
};

export const useUnlinkedPurchases = (statusId: number[]) => {
  return useReadOnlyRemoteCollection<PurchaseDto>({
    fetchHandler: useCallback(() => fetchUnlinkedPurchases(statusId), [])
  });
};


export const useInvoicePurchases = (invoiceId: number) => {
  return useReadOnlyRemoteCollection<PurchaseDto>({
    fetchHandler: useCallback(() => fetchInvoicePurchases(invoiceId), [invoiceId])
  });
};
