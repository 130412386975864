import { IPagedCollection, MediaInfoDto } from '@sr/dto';
import { TextWithHighlight } from 'shared/ui/text-with-highlight';
import { SearchResultSection } from './search-result-section';
import { LinkToMedia } from 'shared/ui/links/link-to-media';

type Props = {
  highlight: string;
  list?: IPagedCollection<MediaInfoDto>;
}

export const FoundMedia = ({ list, highlight }: Props) => {
  if (!list)
    return null;

  return (
    <SearchResultSection
      title="СМИ"
      items={list}
      content={list.items.map(entity =>
        <div key={entity.id}>
          <LinkToMedia media={entity}>
            <TextWithHighlight component='span' variant='body1' hlText={highlight}>{entity.name}</TextWithHighlight>
          </LinkToMedia>
        </div>)}
      emptyResultText="СМИ не найдены"
    />
  );
};

