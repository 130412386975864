import isValid from 'date-fns/isValid';
import { FormikErrors, FormikValues } from 'formik';

export function validateDateField<T extends FormikValues>(fieldName: keyof T, values: any, validationResult: any) {
  const propValue = values[fieldName] as any;
  const date = new Date(propValue);

  if (!isValid(date))
    validationResult[fieldName] = 'Неверный формат';

}