import { Box, Button, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AddContactMenu, ContactsList } from 'shared/ui/contact-list';
import { useState } from 'react';
import { useAddContact, useContacts, useDeleteContact } from 'features/contact/contacts.hooks';
import { ContactTypes, EntitiesWithContacts } from 'features/contact/contacts.types';
import { ILinkedEmail, ILinkedPhone, ILinkedUrl } from '@sr/dto';
import { useAddHandlers, usePromptedDeleteHandlers } from 'features/contact/contact-helpers.hooks';
import { DupesDisplayOptions, DupesExcludeLists } from 'entities/contact-dupes/types';

type Props = {
  relationId: number;
  relationType: EntitiesWithContacts,
  dupesDisplayOptions?: DupesDisplayOptions
};

export const ContactsPanel = ({ relationId, relationType, dupesDisplayOptions }: Props) => {
  const { contacts, isLoading } = useContacts({ relationId, relationType });
  const deleteContact = useDeleteContact();
  const { addContact: addEmail, isUpdating: isAddEmainPending } = useAddContact({
    relationId, relationType, contactType: ContactTypes.email
  });
  const { addContact: addPhone, isUpdating: isAddPhonePending } = useAddContact({
    relationId, relationType, contactType: ContactTypes.phone
  });
  const { addContact: addUrl, isUpdating: isAddUrlPending } = useAddContact({
    relationId, relationType, contactType: ContactTypes.url
  });

  const deleteHandlers = usePromptedDeleteHandlers({ relationId, relationType }, deleteContact);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const menuClickHandlers = useAddHandlers({
    onAddEmail: (email: string) => addEmail<ILinkedEmail>({ email }),
    onAddPhone: (phone: string) => addPhone<ILinkedPhone>({ phone }),
    onAddUrl: (url: string) => addUrl<ILinkedUrl>({ url })
  });

  const isFetching = isAddEmainPending || isAddPhonePending || isAddUrlPending || isLoading;

  if (!contacts)
    return null;


  let opts: DupesDisplayOptions;

  if (dupesDisplayOptions) {
    const originalExcludeLists: DupesExcludeLists = dupesDisplayOptions.excludeLists || {};
    const relatedExcludeList = originalExcludeLists[relationType] || [];

    opts = {
      ...dupesDisplayOptions,
      excludeLists: {
        ...originalExcludeLists,
        [relationType]: [...relatedExcludeList, relationId]
      }
    };
  }
  else {
    opts = {
      excludeLists: {
        [relationType]: [relationId]
      }
    };
  }


  return (
    <>
      <ContactsList contactsList={contacts} promptDeleteHandlers={deleteHandlers} dupesDisplayOptions={opts} />

      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size='small'
          startIcon={<AddIcon />}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          disabled={isFetching}
        >
          Добавить контакт
        </Button>
      </Box>

      <AddContactMenu
        onClose={() => setMenuAnchor(null)}
        menuAnchor={menuAnchor}
        menuClickHandlers={menuClickHandlers} />
    </>
  );
};

