import { LeadStatusPicker } from 'entities/lead/lead-status-picker';
import { FieldProps } from 'formik';

export const LeadStatusPickerField = ({ form, field }: FieldProps<number, any>) => {
  const { setFieldValue, errors, touched } = form;
  const { name, value } = field;
  const error = errors[name];
  const isTouched = touched[name];
  const errorText = isTouched
    ? typeof error === 'string'
      ? error
      : undefined
    : undefined;

  const handleChange = (value: any) => setFieldValue(name, value);

  return (
    <LeadStatusPicker
      label='Статус'
      value={value}
      onChange={handleChange}
      error={isTouched && error !== undefined}
      helperText={errorText}
    />);
};