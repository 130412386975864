import { Alert, AlertTitle, Box, Paper } from '@mui/material';

export const ErrorBanner = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <>
      <Paper sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {errorMessage}
          </Alert>
        </Box>
      </Paper>
    </>);
}