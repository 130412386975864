import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAddCityDialog } from './lib';

export const AddCityButton = () => {
  const { openDialog } = useAddCityDialog();

  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={() => openDialog()}
    >
      Добавить
    </Button>
  )
}