import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';


import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';

export interface FormData {
    firstName: string;
    secondName?: string;
    middleName?: string;
    position?: string;
}

const initialValues: FormData = {
  firstName: '',
};

type ValidationData = Partial<Record<keyof FormData, string>>;

interface DialogProps {
    open: boolean;
    submit: (formData: FormData) => Promise<void>;
    onClose: () => void;
}

export function AddPersonDialog(props: DialogProps) {
  const { open, submit } = props;

  const validate = (values: FormData) => {
    const errors: ValidationData = {};

    const requiredMsg = 'Поле обязательно';
    if (!values.firstName) errors.firstName = requiredMsg;

    return errors;
  };

  const handleSubmit = async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await submit(values);
    setSubmitting(false);
  };

  const handleClose = () => props.onClose();

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Новый контакт</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm, isSubmitting }: any) => (
            <Form>
              <Stack direction="column" spacing={2}>
                <Field
                  label='Имя'
                  component={TextField}
                  name='firstName'/>
                <Field
                  label='Фамилия'
                  component={TextField}
                  name='secondName'/>
                <Field
                  label='Отчество'
                  component={TextField}
                  name='middleName'/>
                <Field
                  label='Должность'
                  component={TextField}
                  name='position'/>
              </Stack>
              <DialogActions>
                <Button variant="contained"
                  autoFocus
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'Подождите...' : 'Сохранить'}
                </Button>
                <Button variant="outlined"
                  onClick={handleClose}
                  disabled={isSubmitting}>
                                    Закрыть
                </Button>
              </DialogActions>
            </Form>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
