import { createContext, ReactNode, useRef, useState } from 'react';
import { EmailDialog } from 'shared/ui/email-dialog';
import { PhoneDialog } from 'shared/ui/phone-dialog';
import { UrlDialog } from 'shared/ui/url-dialog';

type Callback<T> = (value: T) => Promise<void>;

export type AddContactsContextConfig = {
  showEmailDialog: (onSubmit: Callback<string>) => void;
  showPhoneDialog: (onSubmit: Callback<string>) => void;
  showUrlDialog: (onSubmit: Callback<string>) => void;
};

const defaultContextValue: AddContactsContextConfig = {
  showEmailDialog: () => { throw new Error('showEmailDialog handler is not initialized. Did you forget to provide AddContactsDialogContext?') },
  showPhoneDialog: () => { throw new Error('showPhoneDialog handler is not initialized. Did you forget to provide AddContactsDialogContext?') },
  showUrlDialog: () => { throw new Error('showUrlDialog handler is not initialized. Did you forget to provide AddContactsDialogContext?') },
}

export const AddContactsDialogContext = createContext<AddContactsContextConfig>(defaultContextValue);

type Props = {
  children: ReactNode;
};

export const AddContactsDialogProvider = (props: Props) => {
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [urlDialogOpen, setUrlDialogOpen] = useState(false);


  const onEmailSubmitRef = useRef<Callback<string> | null>(null);
  const onPhoneSubmitRef = useRef<Callback<string> | null>(null);
  const onUrlSubmitRef = useRef<Callback<string> | null>(null);

  const contextConfig: AddContactsContextConfig = {
    showEmailDialog: (onSubmit: Callback<string>) => {
      setEmailDialogOpen(true);
      onEmailSubmitRef.current = onSubmit;
    },
    showPhoneDialog: (onSubmit: Callback<string>) => {
      setPhoneDialogOpen(true);
      onPhoneSubmitRef.current = onSubmit;
    },
    showUrlDialog: (onSubmit: Callback<string>) => {
      setUrlDialogOpen(true);
      onUrlSubmitRef.current = onSubmit;
    },
  };

  const handleCloseEmailDialog = () => {
    setEmailDialogOpen(false);
    onEmailSubmitRef.current = null;
  };

  const handleClosePhoneDialog = () => {
    setPhoneDialogOpen(false);
    onPhoneSubmitRef.current = null;
  };

  const handleCloseUrlDialog = () => {
    setUrlDialogOpen(false);
    onUrlSubmitRef.current = null;
  };

  return (
    <AddContactsDialogContext.Provider value={contextConfig}>
      {props.children}

      <EmailDialog
        open={emailDialogOpen}
        onSubmit={(value) => {
          if (!onEmailSubmitRef.current) return Promise.reject();

          return onEmailSubmitRef.current(value)
            .then(handleCloseEmailDialog);
        }}
        onClose={handleCloseEmailDialog} />

      <PhoneDialog
        open={phoneDialogOpen}
        onSubmit={async (value) => {
          if (!onPhoneSubmitRef.current) return Promise.reject();
          return onPhoneSubmitRef.current(value)
            .then(handleClosePhoneDialog);
        }}
        onClose={handleClosePhoneDialog} />

      <UrlDialog
        open={urlDialogOpen}
        onSubmit={async (value) => {
          if (!onUrlSubmitRef.current) return Promise.reject();
          return onUrlSubmitRef.current(value)
            .then(handleCloseUrlDialog);
        }}
        onClose={handleCloseUrlDialog} />
    </AddContactsDialogContext.Provider>
  );
};