import { IPagedCollection, MediaInfoDto } from '@sr/dto';
import { mediaApi } from 'entities/media/api-slice';
import { MEDIA_BASE_URL } from 'shared/api/api';
import { CollectionParams } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';



const injectedApi = mediaApi.injectEndpoints({
  endpoints: (builder) => ({
    getMediaList: builder.query<IPagedCollection<MediaInfoDto>, CollectionParams>({
      query: ({ paging, filter }: CollectionParams) => ({
        url: `${MEDIA_BASE_URL}?${filter}`,
        params: paging && pagingDataToParams(paging),
        method: 'GET'
      }),
    }),
    deleteMedia: builder.mutation<void, number>({
      query: (linkId: number) => ({
        url: `${MEDIA_BASE_URL}/${linkId}`,
        method: 'DELETE'
      }),
    })
  }),
  overrideExisting: true
});

export const mediaListApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['*'],
  endpoints: {
    getMediaList: {
      providesTags: ['*']
    },
    deleteMedia: {
      invalidatesTags: ['*']
    }
  }
})